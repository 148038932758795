import * as React from 'react';
import { Drawer } from 'antd';

interface Props {
  isOpen: boolean;
  showOverlay?: boolean;
  width?: number;
  children: React.ReactNode;
  onClose(): void;
}

export default function CommonDrawer(props: Props) {
  const { children, isOpen, showOverlay = true, width = 250, onClose } = props;

  return (
    <Drawer visible={isOpen} mask={showOverlay} width={width} onClose={onClose}>
      {children}
    </Drawer>
  );
}
