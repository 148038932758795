import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';

import App from './App';

(window as any).__localeId__ = 'en';
declare let module: { hot: any };

const rootElement = document.getElementById('root');

const render = Component => {
  if (createRoot) {
    const root = createRoot(rootElement);
    root.render(<Component />);
  } else {
    ReactDOM.render(<Component />, rootElement);
  }
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default;
    render(NewApp);
  });
}
