export const MESSAGES = {
  COMMON_ERROR: 'Der skete en uventet fejl',
  MEDICINE_DATE_ERROR: 'Periode skal være inden for administrationsdatoer',
  MEDICINE_TYPE_ERROR: 'Ordination kan ikke være Dosisdispenseret og PN',
  MEDICINE_DAILY_DOSE_ERROR: 'Daglig dosis må ikke være højere end maksimal daglig dosis',
  MEDICINE_INC_DOSE_ERROR: 'Startdosis skal være lavere end slutdosis',
  MEDICINE_DEC_DOSE_ERROR: 'Startdosis skal være højere end slutdosis',
  ERRORS: {
    CENTER_HAS_DEPARTMENTS: 'Der er stadig afdelinger knyttet til dette center. Slet afdelinger for at slette center',
    COMMON_ERROR: 'Der skete en uventet fejl',
  },
  SUCCESS: {
    MEASUREMENTS_CREATE: 'Måling oprettet',
    MEASUREMENTS_UPDATE: 'Måling ændret',
  },
};
