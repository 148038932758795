import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Button, Collapse, Menu, MenuProps, Popover, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RcFile } from 'antd/lib/upload';
import { ApplicationState } from '../../../store';
import {
  CalendarState,
  clearCalendarItemsData,
  getDepartmentNotes,
  loadCitizenEnrollmentCalendarItems,
  loadEmployeeEnrollmentCalendarItems,
  removeCalendarDepartmentNotes,
  removeCitizenCalendarItemsData,
  removeEmployeeCalendarItemsData,
  resetCitizensCalendarItemsData,
  resetEmployeesCalendarItemsData,
  setCalendarDepartmentId,
  setSelectedCitizenEnrollmentsAction,
  setSelectedEmployeeEnrollmentsAction,
} from '../../../store/calendarStore';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { USER_ENROLLMENT_NAMESPACE } from '../../../constants/namespaces';
import { CommonState, setSidebarHoverStateAction, setSidebarState } from '../../../store/commonStore';
import { CitizenEnrollmentResult, EmployeeEnrollmentResult } from '../../../services/prodocApi';
import { Checkbox } from '../../_shared/_controls/Checkbox/Checkbox';
import { Upload } from '../../_shared/Upload/Upload';
import { CalendarItemsFilter } from '../CalendarItemsFilter/CalendarItemsFilter';
import Icon, { ICONS } from '../../_shared/Icon/Icon';
import CommonDrawer from '../../_shared/CommonDrawer/CommonDrawer';
import OverviewDetailsEmployee from '../../overviewPage/OverviewDetails/OverviewDetailsEmployee';
import OverviewDetailsCitizen from '../../overviewPage/OverviewDetails/OverviewDetailsCitizen';
import { useCalendarSidebar } from '../../../hooks/calendar-hooks';
import { UtilsCalendar } from '../../../utils/UtilsCalendar';

import './CalendarSidebar.scss';
import {
  CalendarOutlined,
  ContactsOutlined,
  InfoCircleOutlined,
  MailOutlined,
  MedicineBoxOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { ROUTES } from '../../../constants/routes';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';

const { Panel } = Collapse;

export default function CalendarSidebar() {
  const dispatch = useDispatch();
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const commonStore = useSelector<ApplicationState, CommonState>(state => state.common);
  const history = useHistory();
  const {
    departmentId: calendarDepartmentId,
    citizensEnrollments,
    employeesEnrollments,
    selectedEmployeeEnrollments,
    selectedCitizenEnrollments,
  } = calendarState;
  const { userEnrollments, showSidebar, showSidebarHover } = commonStore;
  const filteredEmployeeEnrollments = useMemo(() => {
    return employeesEnrollments.filter(item => item.departmentId === calendarDepartmentId);
  }, [calendarDepartmentId, employeesEnrollments]);
  const filteredCitizensEnrollments = useMemo(() => {
    return citizensEnrollments.filter(item => item.departmentId === calendarDepartmentId);
  }, [calendarDepartmentId, citizensEnrollments]);
  const [activeEmployeeData, setActiveEmployeeData] = React.useState(null);
  const [activeCitizenData, setActiveCitizenData] = React.useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const showDepartmentsSelector = userEnrollments.length > 1;
  const isActive = showSidebarHover || showSidebar;
  const wrapClass = classNames('calendar-sidebar', { 'calendar-sidebar--active': isActive });
  const isAllEmployeesSelected = selectedEmployeeEnrollments.length === filteredEmployeeEnrollments.length;
  const isAllCitizensSelected = selectedCitizenEnrollments.length === filteredCitizensEnrollments.length;

  useCalendarSidebar();

  const handleDepartmentChange = (value: string): void => {
    const enrollmentData = userEnrollments.find(item => item.departmentId === value);

    UtilsCalendar.saveSelectedSidebarDataToLocalStorage([], 'citizens');
    UtilsCalendar.saveSelectedSidebarDataToLocalStorage([], 'employees');

    dispatch(setCalendarDepartmentId(value));
    dispatch(removeCalendarDepartmentNotes(calendarDepartmentId));
    dispatch(getDepartmentNotes(value));
    dispatch(clearCalendarItemsData());
    localStorage.setItem(USER_ENROLLMENT_NAMESPACE, enrollmentData.id);
  };

  const handleAllEmployeesChange = (): void => {
    if (isAllEmployeesSelected) {
      dispatch(setSelectedEmployeeEnrollmentsAction([]));
      dispatch(resetEmployeesCalendarItemsData());
    } else {
      const newList = filteredEmployeeEnrollments.map(item => {
        const itemId = item.id;
        dispatch(loadEmployeeEnrollmentCalendarItems(itemId));

        return itemId;
      });

      dispatch(setSelectedEmployeeEnrollmentsAction(newList));
    }
  };

  const handleAllCitizensChange = () => {
    if (isAllCitizensSelected) {
      dispatch(resetCitizensCalendarItemsData());
      dispatch(setSelectedCitizenEnrollmentsAction([]));
    } else {
      const newList = filteredCitizensEnrollments.map(item => {
        const { id: itemId } = item;

        dispatch(loadCitizenEnrollmentCalendarItems(itemId));

        return itemId;
      });

      dispatch(setSelectedCitizenEnrollmentsAction(newList));
    }
  };

  const renderEmployees = () => {
    return filteredEmployeeEnrollments.map((item: EmployeeEnrollmentResult) => {
      const { firstname, lastname, id, color } = item;
      const displayedName = `${firstname} ${lastname}`;
      const checked = selectedEmployeeEnrollments.indexOf(id) >= 0;

      const handleChange = selected => {
        let newList;

        if (selected) {
          dispatch(loadEmployeeEnrollmentCalendarItems(id));

          newList = [...selectedEmployeeEnrollments, id];
        } else {
          newList = selectedEmployeeEnrollments.filter(searchItem => searchItem !== id);

          dispatch(removeEmployeeCalendarItemsData(id));
        }

        dispatch(setSelectedEmployeeEnrollmentsAction(newList));
      };

      const handleInfoOpen = () => {
        setActiveEmployeeData(item);
      };

      return (
        <div className="calendar-sidebar__item" key={id}>
          <Checkbox
            color={color}
            onChange={handleChange}
            label={
              <Typography.Text style={{ width: '190px' }} ellipsis={{ tooltip: displayedName }}>
                {displayedName}
              </Typography.Text>
            }
            checked={checked}
          />
          <button className="empty-btn" onClick={handleInfoOpen}>
            <Icon name={ICONS.info} />
          </button>
          {/* <Popover className="empty-btn" placement="topLeft" content={'MAgnus'} trigger="hover" open={false}>
            <button type="button" className="menu-btn">
              <span className="submenu-title-wrapper">
                <MoreOutlined translate="" />
              </span>
            </button>
          </Popover> */}
        </div>
      );
    });
  };
  function openPopowerMenu() {
    setIsPopoverOpen(true);
  }
  type MenuItem = Required<MenuProps>['items'][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key | null,
    icon: React.ReactNode,
    url: string,
    f?: () => void,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      onClick: () => handleMenuItemClick(url, f),
    } as MenuItem;
  }
  function handleMenuItemClick(url: string, f: () => void) {
    if (url) {
      history.push(url);
    } else {
      f();
    }
  }
  const renderCitizens = () => {
    return filteredCitizensEnrollments.map((item: CitizenEnrollmentResult) => {
      const { firstname, lastname, id, color, citizenId } = item;
      const displayedName = `${firstname} ${lastname}`;
      const checked = selectedCitizenEnrollments.indexOf(id) >= 0;

      const handleChange = selected => {
        let newList;

        if (selected) {
          dispatch(loadCitizenEnrollmentCalendarItems(id));

          newList = [...selectedCitizenEnrollments, id];
        } else {
          newList = selectedCitizenEnrollments.filter(searchItem => searchItem !== id);

          dispatch(removeCitizenCalendarItemsData(id));
        }

        dispatch(setSelectedCitizenEnrollmentsAction(newList));
      };

      const handleInfoOpen = () => {
        setActiveCitizenData(item);
      };
      const items: MenuItem[] = [
        getItem('Info', '0', <InfoCircleOutlined translate={undefined} />, '', handleInfoOpen),
        getItem(
          'Eksterne kontakter',
          '1',
          <ContactsOutlined translate={undefined} />,
          ROUTES.EXTERNALCONTACTS.replace(':citizenId', citizenId)
        ),
        getItem('Mål', '2', <FontAwesomeIcon icon={faBullseye} />, ROUTES.GOALS.replace(':citizenEnrollmentId?', id)),
        getItem(
          'Helbred og medicin',
          '3',
          <MedicineBoxOutlined translate={undefined} />,
          ROUTES.MEDICINE_HEALTH.replace(':citizenEnrollmentId?', id)
        ),
      ];
      const popowerContent = (
        <>
          <Menu style={{ width: 256 }} items={items} />
        </>
      );
      return (
        <div className="calendar-sidebar__item" key={id}>
          <Checkbox
            color={color}
            onChange={handleChange}
            label={
              <Typography.Text style={{ width: '190px' }} ellipsis={{ tooltip: displayedName }}>
                {displayedName}
              </Typography.Text>
            }
            checked={checked}
          />

          <Popover arrow={false} className="empty-btn" placement="topLeft" content={popowerContent} trigger="hover">
            <button type="button">
              <span className="submenu-title-wrapper">
                <MoreOutlined translate="" />
              </span>
            </button>
          </Popover>
        </div>
      );
    });
  };

  async function handleUpload(file: RcFile): Promise<void> {
    // @ts-ignore
    // await prodocApi.file.upload(calendarDepartmentId, 'Department', '', {
    //     fileName: file.name,
    //     data: file,
    // });
  }

  const handleInfoCLose = () => {
    setActiveEmployeeData(null);
    setActiveCitizenData(null);
  };

  const handleMouseLeave = () => {
    dispatch(setSidebarHoverStateAction(false));
  };

  const renderItemDetails = () => {
    if (activeEmployeeData) return <OverviewDetailsEmployee data={activeEmployeeData} />;
    if (activeCitizenData) return <OverviewDetailsCitizen data={activeCitizenData} />;
  };
  // ;

  return (
    <>
      <div className={wrapClass} onMouseLeave={handleMouseLeave}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: '0',
            }}>
            <Button
              type="text"
              shape="circle"
              onClick={() => dispatch(setSidebarState(!showSidebar))}
              icon={<Icon name={showSidebar ? ICONS.pushpinFilled : ICONS.pushpin} />}
            />
          </div>
        </div>
        <div className="calendar-sidebar__content">
          <div className="calendar-sidebar__wrap">
            <CalendarItemsFilter />
            <div className="calendar-sidebar__departments">
              {showDepartmentsSelector && (
                <div>
                  <Upload onUpload={handleUpload}>
                    <SelectMenu
                      title="Vælg afdeling"
                      value={calendarDepartmentId}
                      options={userEnrollments}
                      valueKey="departmentId"
                      labelKey="departmentName"
                      onChange={handleDepartmentChange}
                    />
                  </Upload>
                </div>
              )}
            </div>
            {!!calendarDepartmentId && (
              <div className="calendar-sidebar__options">
                <Collapse ghost defaultActiveKey="1">
                  <Panel header="Medarbejdere" key="1">
                    <Checkbox label="Vælg alle" checked={isAllEmployeesSelected} onChange={handleAllEmployeesChange} />
                    {renderEmployees()}
                  </Panel>
                </Collapse>
                <Collapse ghost defaultActiveKey="2">
                  <Panel header="Borgere" key="2">
                    <Checkbox label="Vælg alle" checked={isAllCitizensSelected} onChange={handleAllCitizensChange} />
                    {renderCitizens()}
                  </Panel>
                </Collapse>
              </div>
            )}
          </div>
        </div>
      </div>
      <CommonDrawer isOpen={activeCitizenData || activeEmployeeData} width={350} onClose={handleInfoCLose}>
        {renderItemDetails()}
      </CommonDrawer>
    </>
  );
}
