import { useDispatch, useSelector } from 'react-redux';
import { addLoadedKey, OverviewState, setOverviewData } from '../store/overviewStore';
import { ApplicationState } from '../store';
import prodocApi, { Department } from '../services/prodocApi';
import { UtilsOverview } from '../utils/UtilsOverview';
import { OverViewItem } from '../types/commonItems';
import { UtilsData } from '../utils/UtilsData';
import { UtilsAuth } from '../utils/UtilsAuth';

export function useOverviewItemsLoading() {
  const dispatch = useDispatch();
  const overviewState = useSelector<ApplicationState, OverviewState>(state => state.overview);
  const { data } = overviewState;

  const loadOverviewDepartments = async (centerId: string) => {
    try {
      let departments: Department[] = [];

      departments = await prodocApi.department.getManyFromCenter([centerId]);

      const sortedDepartments: Department[] = UtilsOverview.sortOverviewItems(departments);
      const overviewItemsDepartments: OverViewItem[] = UtilsOverview.buildCompanyOverviewData(sortedDepartments);
      const updatedTree = UtilsOverview.updateOverviewTree(data, centerId, overviewItemsDepartments);

      // Auto expand tree if a user has only one department
      if (departments.length === 1 && data.length === 1) {
        dispatch(addLoadedKey(departments[0].id));
      }

      dispatch(setOverviewData(updatedTree));
    } catch (e) {}
  };

  const loadOverviewPeople = async (departmentId: string) => {
    try {
      const employeeEnrollments = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds: [departmentId],
      });
      const citizensEnrollments = await prodocApi.citizenEnrollment.getManyByDepartments({
        departmentIds: [departmentId],
      });
      const citizensTitleItem: OverViewItem = {
        title: 'Borgere',
        type: 'title',
        key: UtilsData.generateId(),
        isLeaf: true,
        disabled: true,
      };
      const employeesTitleItem: OverViewItem = {
        title: 'Medarbejdere',
        type: 'title',
        key: UtilsData.generateId(),
        isLeaf: true,
        disabled: true,
      };

      const sortedCitizens = UtilsOverview.sortOverviewItems(citizensEnrollments);
      const sortedEmployees = UtilsOverview.sortOverviewItems(employeeEnrollments);
      const citizensOverviewItems = UtilsOverview.buildCompanyOverviewData(sortedCitizens);
      const employeesOverviewItems = UtilsOverview.buildCompanyOverviewData(sortedEmployees);
      const citizensItems = citizensOverviewItems.length ? [citizensTitleItem, ...citizensOverviewItems] : [];
      const employeesItems = employeesOverviewItems.length ? [employeesTitleItem, ...employeesOverviewItems] : [];
      const peoplesItems = [...citizensItems, ...employeesItems];
      const updatedTree = UtilsOverview.updateOverviewTree(data, departmentId, peoplesItems);

      dispatch(setOverviewData(updatedTree));
    } catch (e) {}
  };

  return { loadOverviewDepartments, loadOverviewPeople };
}
