import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import { Measurement } from '../../../../services/prodocApi';
import { useForm } from 'react-hook-form';
import { temperatureSchema } from '../../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../../hooks/validation-hooks';
import TextArea from '../../../_shared/_controls/TextArea/TextArea';

interface Props {
  measurement: Measurement;
  formName: string;
  onChange(measurement: Measurement, name: string);
}

const TemperatureForm = forwardRef((props: Props, ref) => {
  const { measurement, formName, onChange } = props;
  const { register, errors, unregister, setValue, clearError, reset, triggerValidation }: any = useForm({
    mode: 'onBlur',
    validationSchema: temperatureSchema,
    defaultValues: measurement,
  });
  const { bodyTemperature, remarks } = measurement;

  useRegisteredFields(temperatureSchema, register, unregister);

  useEffect(() => {
    reset();
  }, [measurement]);

  useImperativeHandle(ref, () => ({
    validateForm() {
      return triggerValidation();
    },
  }));

  const handleFieldChange = (value: any, name: string) => {
    const newmeasurement = { ...measurement, [name]: value };

    onChange(newmeasurement, formName);
    setValue(name, value);
    clearError(name);
  };

  return (
    <form className="common-form">
      <TextInput
        titleLink="https://www.sundhed.dk/borger/patienthaandbogen/undersoegelser/undersoegelser/oevrige-undersoegelser/temperaturmaaling/"
        title="Kropstemperatur"
        value={bodyTemperature || ''}
        name="bodyTemperature"
        type="number"
        error={errors.bodyTemperature && errors.bodyTemperature.message}
        onChange={handleFieldChange}
      />
      <TextArea title="Bemærkning" value={remarks} name="remarks" onChange={handleFieldChange} />
    </form>
  );
});

export default TemperatureForm;
