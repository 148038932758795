import React from 'react';
import ParticipantStatusLabel from '../ParticipantStatusLabel/ParticipantStatusLabel';
import { CalendarItemParticipant } from '../../../types/calendar';

interface Props {
  items: CalendarItemParticipant[];
}

export default function AppointmentParticipants(props: Props) {
  const { items } = props;

  const renderParticipants = () => {
    return items.map((participantItem, index) => {
      return (
        <ParticipantStatusLabel key={index} data={participantItem}/>
      );
    });
  };

  return (
    <div className="cai-participants">
      {renderParticipants()}
    </div>
  );
}
