import { ROUTES } from '../constants/routes';
import { ICONS } from '../components/_shared/Icon/Icon';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { URLS } from '../constants/urls';
import { AuthType } from '../services/prodocApi';
import { MenuItem } from '../types/common';

import createEmployeeIcon from '../assets/images/png/employee_plus.png';
import editEmployeeIcon from '../assets/images/png/employee_edit.png';
import GoalsIcon from '../assets/images/png/goals.png';
import organizationIcon from '../assets/images/png/organization.png';
import healthIcon from '../assets/images/png/health.png';

export function getMainMenuItems(): MenuItem[] {
  return [
    {
      id: 51,
      route: ROUTES.CENTER_OVERVIEW,
      title: 'Centre',
      icon: ICONS.bank,
      notAllowedFor: [AuthType.Employee, AuthType.Manager],
    },
    {
      id: 71,
      route: ROUTES.DEPARTMENT_OVERVIEW,
      title: 'Afdelinger',
      icon: ICONS.home,
      notAllowedFor: [AuthType.Employee],
    },

    {
      id: 1,
      route: ROUTES.CITIZEN_OVERVIEW,
      title: 'Borgere',
      icon: ICONS.user,
      notAllowedFor: [AuthType.Employee],
    },
    {
      id: 41,
      route: UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.OVERVIEW),
      icon: ICONS.crown,
      title: 'Medarbejdere',
      notAllowedFor: [],
    },
    // {
    //   id: 3,
    //   route: UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.ADD),
    //   title: 'Opret medarbejder',
    //   iconPng: createEmployeeIcon,
    //   notAllowedFor: [AuthType.Employee],
    // },
    // {
    //   id: 4,
    //   route: UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.DETAILS),
    //   iconPng: ICONS.crown,
    //   title: 'Rediger medarbejder',
    //   notAllowedFor: [],
    // },

    // {
    //   id: 5,
    //   route: ROUTES.CENTER_ADD,
    //   title: 'Opret center',
    //   icon: ICONS.bank,
    //   notAllowedFor: [AuthType.Employee],
    // },

    // {
    //   id: 6,
    //   route: UtilsNavigation.buildRoute(URLS.CENTER, URLS.DETAILS),
    //   title: 'Rediger center',
    //   icon: ICONS.bank,
    //   notAllowedFor: [AuthType.Employee],
    // },

    // {
    //   id: 7,
    //   route: UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.ADD),
    //   title: 'Opret afdeling',
    //   icon: ICONS.home,
    //   notAllowedFor: [AuthType.Employee],
    // },
    // {
    //   id: 8,
    //   route: UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.DETAILS),
    //   title: 'Rediger afdeling',
    //   icon: ICONS.home,
    //   notAllowedFor: [AuthType.Employee],
    // },
    // {
    //   id: 9,
    //   route: UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.GOALS),
    //   title: 'Mål',
    //   iconPng: GoalsIcon,
    //   notAllowedFor: [],
    // },
    {
      id: 10,
      route: ROUTES.OVERVIEW,
      title: 'Organisationsoverblik',
      icon: ICONS.apartment,
      notAllowedFor: [],
    },
    {
      id: 11,
      route: UtilsNavigation.buildRoute(URLS.MEASUREMENTS),
      title: 'Målinger',
      icon: ICONS.barchart,
      notAllowedFor: [],
    },
    // {
    //   id: 12,
    //   route: ROUTES.MEDICINE_HEALTH.replace(URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT, ''),
    //   title: 'Helbred og medicin',
    //   iconPng: healthIcon,
    //   notAllowedFor: [],
    // },
    {
      id: 13,
      route: ROUTES.FORCE,
      title: 'Magtanvendelser',
      icon: ICONS.alert,
      notAllowedFor: [],
    },
    {
      id: 15,
      route: ROUTES.DOCUMENTS,
      title: 'Dokumenter',
      icon: ICONS.file,
      notAllowedFor: [],
    },
    {
      id: 151,
      route: ROUTES.DOCUMENTS_PROCEDURES,
      title: 'Procedurer',
      icon: ICONS.file,
      notAllowedFor: [],
    },
    {
      id: 16,
      route: ROUTES.NOTES,
      title: 'Notater',
      icon: ICONS.read,
      notAllowedFor: [],
    },
    {
      id: 17,
      route: ROUTES.FORUM,
      title: 'Beskeder',
      icon: ICONS.message,
      notAllowedFor: [],
    },
    // {
    //   id: 18,
    //   route: UtilsNavigation.buildRoute(URLS.PLANNING, URLS.WORKPLAN, ':employeeId'),
    //   title: 'Level+',
    //   icon: ICONS.timeReg,
    //   notAllowedFor: [],
    // },
    {
      id: 14,
      route: '',
      title: 'Log ud',
      icon: ICONS.logout,
      isLogout: true,
      notAllowedFor: [],
    },
  ];
}
