import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Table } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import { UtilsMeasurements } from '../../../utils/UtilsMeasurements';
import Button from '../../_shared/_controls/Button/Button';
import { ICONS } from '../../_shared/Icon/Icon';

import './MeasurementsTable.scss';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import { MeasurementDto, MeasurementType } from '../../../services/prodocApi';
import {
  getColumnDateSearchProps,
  getColumnEnumSearchProps,
  getColumnTextSearchProps,
} from '../../../utils/UtilsTable';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { Dayjs } from 'dayjs';

interface Props {
  measurements: MeasurementDto[];
  isLoading: boolean;
  onEdit(data: any): void;
  onDelete(id: string);
}
interface MeasurementFilterData {
  citizenName: string;
  type?: MeasurementType;
  fromDate?: Date;
  toDate?: Date;
}
export default function MeasurementsTable(props: Props) {
  const { measurements, isLoading, onEdit, onDelete } = props;
  const { t } = useTranslation();
  const { translateEnumOptions } = useEnumOptionsTranslation();

  const [filterData, setFilterData] = useState<MeasurementFilterData>({
    citizenName: '',
  });
  const [list, setList] = useState([]);
  useEffect(() => {
    setList(measurements);
  }, [measurements]);

  const search = v => {
    const value = v.toLowerCase();
    setFilterData({ ...filterData, citizenName: value });
    filter(value, filterData.type, filterData.fromDate, filterData.toDate);
  };

  const searchType = v => {
    const value = v ? v.toLowerCase() : '';
    setFilterData({ ...filterData, type: value });
    filter(filterData.citizenName, value, filterData.fromDate, filterData.toDate);
  };

  function handleDateChange(date: Dayjs[]) {
    setFilterData({ ...filterData, fromDate: date[0]?.toDate(), toDate: date[1]?.toDate() });
    filter(filterData.citizenName, filterData.type, date[0]?.toDate(), date[1]?.toDate());
  }

  function filter(citizenName: string, type?: MeasurementType, fromDate?: Date, toDate?: Date) {
    let list = [...measurements].filter(x =>
      `${x.citizen?.firstname.toLowerCase()} ${x.citizen?.lastname.toLowerCase()}`.includes(citizenName.toLowerCase())
    );

    if (type) {
      list = list.filter(x => x.measurementType.toLowerCase() === type.toLowerCase());
    }

    if (fromDate) {
      list = list.filter(x => new Date(x.fromDate) >= fromDate);
    }

    if (toDate) {
      list = list.filter(x => new Date(x.fromDate) <= toDate);
    }

    setList(list);
  }

  const columns = () => {
    const baseColumns = [
      {
        title: 'Måling',
        render: (item: MeasurementDto) => {
          return t(item.measurementType);
        },
        ...getColumnEnumSearchProps(translateEnumOptions(MeasurementType), searchType),
      },
      {
        title: 'Resultat',
        render: (item: MeasurementDto) => {
          const value = UtilsMeasurements.getMeasurementValueByType(item, item.measurementType);

          if (item.measurementType === MeasurementType.Trafficlight) return t(value);

          if (item.measurementType === MeasurementType.Bvc) {
            const valuesString = UtilsMeasurements.getBvcFullValueString(item);

            return (
              <>
                {t(value)} <br /> {valuesString}
              </>
            );
          }

          return value;
        },
      },
      {
        title: 'Borger',
        render: (item: MeasurementDto) => {
          return `${item.citizen?.firstname} ${item.citizen?.lastname}`;
        },
        ...getColumnTextSearchProps(search),
      },
      {
        title: 'Forfatter',
        dataIndex: 'createdByName',
      },
      {
        title: 'Afdeling',
        dataIndex: 'createdByDepartmentName',
      },
    ];

    const actionsColumn = {
      title: '',
      render: row => {
        const handleEdit = () => {
          onEdit(row);
        };

        const handleDelete = () => {
          Modal.confirm({
            title: 'Ønsker du at slette denne måling?',
            okText: 'Ja',
            cancelText: 'Nej',
            onOk: () => {
              onDelete(row.id);
            },
          });
        };

        return (
          <div className="measurements-table__actions">
            <Button icon={ICONS.edit} onClick={handleEdit} />
            {UtilsAuth.isAdmin() && <Button icon={ICONS.delete} onClick={handleDelete} />}
          </div>
        );
      },
    };

    return [
      ...baseColumns,
      {
        title: 'Hvad skete der?',
        dataIndex: 'whatHappendText',
      },
      {
        title: 'Hvad gjorde du/i?',
        dataIndex: 'whatDidYouDoText',
      },
      {
        title: 'Bemærkning',
        dataIndex: 'remarks',
      },
      {
        title: 'Dato',
        dataIndex: 'date',
        render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : ''),
        ...getColumnDateSearchProps(handleDateChange),
      },
      actionsColumn,
    ];
  };

  return (
    <div className="measurements-table">
      <Table
        dataSource={list}
        loading={isLoading}
        columns={columns()}
        pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }}
        rowKey="id"
      />
    </div>
  );
}
