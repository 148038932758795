import React from 'react';
import Icon, { ICONS } from '../../_shared/Icon/Icon';
import { CalendarItemParticipant } from '../../../types/calendar';
import { CalendarStatus } from '../../../services/prodocApi';

import './ParticipantStatusLabel.scss';

interface Props {
  data: CalendarItemParticipant;
}

export default function ParticipantStatusLabel(props: Props) {
  const { data } = props;
  const { name, status } = data;

  const getIconName = () => {
    switch (status) {
      case CalendarStatus.Accepted:
        return ICONS.check;
      case CalendarStatus.Declined:
        return ICONS.close;
      default:
        return ICONS.question;
    }
  };

  return (
    <div className="ps-label">
      <div className="ps-label__name">{name}</div>
      <Icon name={getIconName()}/>
    </div>
  );
}
