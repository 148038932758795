import * as React from 'react';
import classNames from 'classnames';
import { Collapse } from 'antd';
import Icon, { ICONS } from '../../_shared/Icon/Icon';

import './OverviewDetailsField.scss';

const { Panel } = Collapse;

interface Props {
  label?: string;
  value?: string | number;
  important?: boolean;
  children?: React.ReactNode;
  onEdit?(): void;
}

export default function OverviewDetailsField(props: Props) {
  const { value, label, important, onEdit, children } = props;

  const fieldClass = classNames('ov-details-field__header', {
    'ov-details-field__header--important': !!important,
  });

  const fieldHeader = (
    <div className="ov-details-field__wrap">
      <p className={fieldClass}>
        {!!label && <span>{label}:</span>} {value}
      </p>
    </div>
  );

  const leaderEditButton = onEdit ? (
    <button onClick={onEdit} className="ov-details-field__edit-btn empty-btn">
      <Icon name={ICONS.edit} />
    </button>
  ) : null;

  return (
    <div className="ov-details-field">
      {children ? (
        <Collapse>
          <Panel header={fieldHeader} key="1" extra={leaderEditButton}>
            {children}
          </Panel>
        </Collapse>
      ) : (
        fieldHeader
      )}
    </div>
  );
}
