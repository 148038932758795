import { actionCreator, isType } from './Action';
import prodocApi from '../services/prodocApi';
import { EmployeeEnrollment } from '../services/prodocApi';
import { UtilsAuth } from '../utils/UtilsAuth';
import { SIDEBAR_STATE_NAMESPACE, USER_ENROLLMENT_NAMESPACE } from '../constants/namespaces';

export interface CommonState {
  userEnrollments: EmployeeEnrollment[];
  showSidebar: boolean;
  showSidebarHover: boolean;
}

const defaultState: CommonState = {
  userEnrollments: [],
  showSidebar: false,
  showSidebarHover: false,
};

const setUserEnrollmentsAction = actionCreator<EmployeeEnrollment[]>('SET_USER_ENROLLMENTS');
export const setSidebarStateAction = actionCreator<boolean>('SET_SIDEBAR_STATE');
export const setSidebarHoverStateAction = actionCreator<boolean>('SET_SIDEBAR_HOVER_STATE');

export default (state: CommonState = defaultState, action: any): CommonState => {
  if (isType(action, setUserEnrollmentsAction)) {
    return {
      ...state,
      userEnrollments: action.payload,
    };
  }

  if (isType(action, setSidebarStateAction)) {
    return { ...state, showSidebar: action.payload };
  }

  if (isType(action, setSidebarHoverStateAction)) {
    return { ...state, showSidebarHover: action.payload };
  }

  return state;
};

export const loadUserEnrollments = () => {
  return async dispatch => {
    try {
      const userId = UtilsAuth.getEmployeeId();
      const userEnrollments = await prodocApi.employeeEnrollment.getByEmployee(userId, true);

      const selectedEnrollmentId = localStorage.getItem(USER_ENROLLMENT_NAMESPACE);

      if (!userEnrollments.length) {
        localStorage.removeItem(USER_ENROLLMENT_NAMESPACE);
      }

      if (!selectedEnrollmentId && userEnrollments.length) {
        localStorage.setItem(USER_ENROLLMENT_NAMESPACE, userEnrollments[0].id);
      }

      dispatch(setUserEnrollmentsAction(userEnrollments));
    } catch (e) {}
  };
};

export const setSidebarState = (isOpened: boolean) => {
  return async dispatch => {
    localStorage.setItem(SIDEBAR_STATE_NAMESPACE, isOpened.toString());

    dispatch(setSidebarStateAction(isOpened));
  };
};

export const loadUserSidebarState = () => {
  return dispatch => {
    const sidebarState = localStorage.getItem(SIDEBAR_STATE_NAMESPACE);

    dispatch(setSidebarStateAction(sidebarState === 'true'));
  };
};
