import * as BrowserHistory from 'history';
import { UtilsAuth } from '../utils/UtilsAuth';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { URLS } from '../constants/urls';

export const history = BrowserHistory.createBrowserHistory();

export function navigateTo(route: string) {
  return () => {
    history.push(route);
  };
}

export function goBack() {
  return () => {
    history.goBack();
  };
}

export function navigateToHome() {
  return async (dispatch) => {
    const homeUrl = `/home/${UtilsAuth.getEmployeeId()}`;

    dispatch(navigateTo(homeUrl))
  };
}

export function navigateToCitizenEnroll(id: string) {
  return (dispatch) => {
    const url = UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.ENROLL, id);

    dispatch(navigateTo(url))
  };
}

export function navigateToEmployeeEnroll(id: string) {
  return (dispatch) => {
    const url = UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.ENROLL, id);

    dispatch(navigateTo(url))
  };
}

export function navigateToCenterDepartmentPage(centerId) {
  return (dispatch) => {
    const url = UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.ADD, centerId);

    dispatch(navigateTo(url))
  };
}
