import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import CitizenEnrollForm from '../../../components/citizen/CitizenEnrollForm/CitizenEnrollForm';
import { useValidation } from '../../../hooks/validation-hooks';
import { citizenEnrollValidationRules } from '../../../utils/UtilsValidation';
import { enrollCitizen } from '../../../store/citizenStore';
import prodocApi, { Citizen, EnrollFromType } from '../../../services/prodocApi';
import { useDispatch } from 'react-redux';

interface ICitizenEnrollModalProps {
  isOpen: boolean;
  onCancel: () => void;
  citizenEnrollmentId?: string;
  citizenId?: string;
}
export function CitizenEnrollModal(props: ICitizenEnrollModalProps) {
  const { isOpen, citizenEnrollmentId, onCancel, citizenId } = props;
  const dispatch = useDispatch();
  const [citizen, setCitizen] = React.useState<Citizen>();

  const [enrollmentData, setEnrollmentData] = useState({
    enrolledFrom: undefined,
    enrollmentDate: new Date(),
    enrolledDepartment: undefined,
  });
  const { enrolledFrom, enrollmentDate, enrolledDepartment } = enrollmentData;
  const { validate, errors } = useValidation(citizenEnrollValidationRules);

  React.useEffect(() => {
    async function get() {
      if (citizenEnrollmentId) {
        const enrollment = await prodocApi.citizenEnrollment.get(citizenEnrollmentId);
        const c = await prodocApi.citizen.getCitizenByEnrollmentId(enrollment.id);
        setCitizen(c);
      }
      if (citizenId) {
        const c = await prodocApi.citizen.get(citizenId);
        setCitizen(c);
      }
    }

    get();
  }, [citizenEnrollmentId, citizenId]);
  function handleEnroll() {
    if (validate(enrollmentData)) {
      dispatch(
        enrollCitizen(
          {
            citizenId: citizen.id,
            enrollmentDate: enrollmentDate,
            enrolledFrom: enrolledFrom as EnrollFromType,
          },
          [enrolledDepartment]
        )
      );
      onCancel();
    }
  }
  return (
    <Modal
      width={800}
      title="Indskriv borger"
      okText="Indskriv"
      cancelText="Annuller"
      open={isOpen}
      onOk={handleEnroll}
      onCancel={onCancel}>
      <CitizenEnrollForm
        onDateChange={e => setEnrollmentData({ ...enrollmentData, enrollmentDate: e })}
        onDepartmentChange={e => setEnrollmentData({ ...enrollmentData, enrolledDepartment: e })}
        onEnrolledFromChange={e => setEnrollmentData({ ...enrollmentData, enrolledFrom: e })}
        enrolledFrom={enrolledFrom}
        enrollmentDate={enrollmentDate}
        selectedDepartment={enrolledDepartment}
        errors={errors}
      />
    </Modal>
  );
}
