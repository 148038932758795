import * as React from 'react';
import { AutoComplete, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import ControlError from '../_controls/ControlError/ControlError';

import './Autocomplete.scss';

export const { Option: AcOption } = AutoComplete;
// @ts-ignore
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

interface Props {
  value?: string;
  data: any[];
  children?: React.ReactNode;
  placeholder?: string;
  title?: string;
  name?: string;
  error?: string;
  minSearchValue?: number;
  clearValueAfterSelect?: boolean;
  emptyText?: string;
  isLoading?: boolean;
  onSelect(value: string, name: string): void;
  onSearch(value: string, name: string): void;
  onChange(value: string, name: string): void;
  onDropDownChange?(open: boolean): void;
  onValueEmpty?(name: string): void;
  disabled?: boolean;
}

export default function Autocomplete(props: Props) {
  const {
    value,
    data,
    name,
    children,
    error,
    placeholder,
    minSearchValue = 3,
    title,
    clearValueAfterSelect,
    onChange,
    onSelect,
    onSearch,
    emptyText,
    isLoading,
    onDropDownChange,
    onValueEmpty,
    disabled,
  } = props;

  const [isOpen, setOpenState] = React.useState<boolean>(false);

  const debouncedState = useDebouncedCallback((value: string) => {
    onSearch(value, name);
  }, 300);

  const wrapperClass = classNames('autocomplete', {
    'autocomplete--error': error,
  });

  const handleChange = value => {
    if (!value && onValueEmpty) {
      onValueEmpty(name);
    }

    if (value.length >= minSearchValue) {
      debouncedState.callback(value);

      setOpenState(true);
    }

    onChange(value, name);
  };

  const handleSelect = value => {
    if (value) {
      const newLocalValue = clearValueAfterSelect ? '' : value;

      onChange(newLocalValue, name);
      onSelect(value, name);
    }
  };

  const handleDropDownChange = (open: boolean) => {
    setOpenState(open);

    if (onDropDownChange) {
      onDropDownChange(open);
    }
  };

  const renderEmpty = () => {
    if (!value || value.length < minSearchValue || !data) return false;

    return emptyText || 'Nothing found';
  };

  return (
    <div className={wrapperClass}>
      {title && <div>{title}</div>}
      <AutoComplete
        value={value}
        placeholder={placeholder}
        open={isOpen}
        onSearch={handleChange}
        onSelect={handleSelect}
        notFoundContent={renderEmpty()}
        onDropdownVisibleChange={handleDropDownChange}
        disabled={disabled}>
        {children as any}
      </AutoComplete>
      {isLoading && (
        <div className="autocomplete__loading-icon">
          <Spin indicator={loadingIcon} />
        </div>
      )}
      <ControlError text={error} />
    </div>
  );
}
