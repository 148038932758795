import React from 'react';
import { OverViewItem, OverviewItemData } from '../types/commonItems';
import { EntityTypes } from '../constants/common';
import { OverviewItemsTreeTitle } from '../components/overviewPage/OverviewItemsTree/OverviewItemsTreeTitle/OverviewItemsTreeTitle';

export class UtilsOverview {
  public static updateOverviewTree(
    existingTree: OverViewItem[],
    parentKey: string,
    newItems: OverViewItem[]
  ): OverViewItem[] {
    return existingTree.map(node => {
      if (node.key === parentKey) {
        return {
          ...node,
          children: newItems,
        };
      }

      if (node.children) {
        return {
          ...node,
          children: UtilsOverview.updateOverviewTree(node.children, parentKey, newItems),
        };
      }

      return node;
    });
  }

  public static getEntityTypeByItem(item: OverviewItemData): EntityTypes {
    if (item.hasOwnProperty('centerId')) {
      return EntityTypes.department;
    }

    if (item.hasOwnProperty('citizenId')) {
      return EntityTypes.citizen;
    }

    if (item.hasOwnProperty('employeeEnrollmentLeader')) {
      return EntityTypes.center;
    }

    if (item.hasOwnProperty('employeeId')) {
      return EntityTypes.employee;
    }

    return null;
  }

  public static buildOverviewItemTitle(item: any): string {
    return item.name ? item.name : `${item.firstname} ${item.lastname}`;
  }

  public static sortOverviewItems(data: any[]) {
    if (!data || !data.length) return [];

    const sortFieldName = data[0].name ? 'name' : 'firstname';

    return data.sort((a: any, b: any) => {
      if (a[sortFieldName].toLowerCase() > b[sortFieldName].toLowerCase()) {
        return 1;
      }

      if (a[sortFieldName].toLowerCase() < b[sortFieldName].toLowerCase()) {
        return -1;
      }

      return 0;
    });
  }

  public static getOverviewIdByEntityType(data: any, type: EntityTypes) {
    const { citizenId, employeeId, id } = data;

    if (type === EntityTypes.citizen) return citizenId;

    if (type === EntityTypes.employee) return employeeId;

    return id;
  }

  public static buildCompanyOverviewData(data: OverviewItemData[]): OverViewItem[] {
    if (!data) return [];

    return data.map((item: any) => {
      const type = UtilsOverview.getEntityTypeByItem(item);
      const itemTitle = UtilsOverview.buildOverviewItemTitle(item);
      const id = UtilsOverview.getOverviewIdByEntityType(item, type);

      return {
        title: <OverviewItemsTreeTitle title={itemTitle} type={type} itemId={id} data={item} />,
        type,
        key: item.id,
        itemData: item,
        isLeaf: !!item.firstname,
      };
    });
  }

  public static updateCompanyOverviewChildren(items: OverViewItem[], newItems: any[], itemForUpdate: OverViewItem) {
    const updatedItems = [...items];
    const { eventKey } = itemForUpdate;

    // Find and update an overview tree item
    updatedItems.forEach(item => {
      if (item.key === eventKey) {
        item.children = UtilsOverview.buildCompanyOverviewData(newItems);
      }

      if (item.children) {
        UtilsOverview.updateCompanyOverviewChildren(item.children, newItems, itemForUpdate);
      }
    });

    return updatedItems;
  }

  public static updateCompanyOverviewItemData(overviewData: OverViewItem[], itemToUpdate: OverviewItemData) {
    return overviewData.map(item => {
      if (item.key === itemToUpdate.id) {
        const title = UtilsOverview.buildOverviewItemTitle(itemToUpdate);
        const type = UtilsOverview.getEntityTypeByItem(itemToUpdate);

        return {
          ...item,
          title: <OverviewItemsTreeTitle title={title} type={type} itemId={itemToUpdate.id} data={item} />,
          itemData: itemToUpdate,
        };
      }

      return {
        ...item,
        children: item.children ? UtilsOverview.updateCompanyOverviewItemData(item.children, itemToUpdate) : undefined,
      };
    });
  }
}
