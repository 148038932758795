import React from 'react';
import Icon, { ICONS } from '../../_shared/Icon/Icon';
import { Tooltip } from '../../_shared/Tooltip/Tooltip';

import './MedicineLatestItem.scss';

interface Props {
  title: string;
  date: string;
  value: string | number;
  onAdd(): void;
  onClick(): void;
}

export default function MedicineLatestItem(props: Props) {
  const { title, value, date, onAdd, onClick } = props;

  return (
    <div className="ml-item">
      <Tooltip text="Vis historik">
        <button type="button" className="ml-item__title" onClick={onClick}>
          {title}
        </button>
      </Tooltip>
      <div className="ml-item__value">{value}</div>
      <button type="button" className="ml-item__add-btn" onClick={onAdd}>
        <Icon name={ICONS.plus} />
      </button>
      <div className="ml-item__date">{date}</div>
    </div>
  );
}
