import React from 'react';
import { Spin } from 'antd';

interface Props {
  active: boolean;
}

export const LoadingAnimation: React.FC<Props> = (props) => {
  const { active } = props;

  if (!active) return null;

  return (
    <Spin />
  );
};
