import { Citizen, CitizenEnrollment, CitizenEnrollmentResult } from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { PAGES_ITEMS, setPageItemSaved } from './pagesStore';
import { goBack, navigateTo, navigateToCitizenEnroll, navigateToHome } from './navigationStore';
import prodocApi from '../services/prodocApi';
import { CitizenDischarge, CitizenOverview, EditCitizenEntity } from '../types/citizen';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { UtilsData } from '../utils/UtilsData';
import { setOverviewData } from './overviewStore';
import { ApplicationState } from './index';
import { setUserDepartments } from './departmentStore';
import { UtilsItems } from '../utils/UtilsItems';
import { UtilsOverview } from '../utils/UtilsOverview';
import { MESSAGES } from '../constants/messages';
import { ROUTES } from '../constants/routes';

export interface CitizensState {
  citizensSearchResult: Citizen[];
  editCitizen: EditCitizenEntity;
  initialDepartmentId: string;
  citizenOverview: CitizenOverview;
  dischargeCitizens: Citizen[];
  enrollmentDepartments: string[];
  availableSearchCitizens: CitizenEnrollmentResult[];
}

const defaultState: CitizensState = {
  citizensSearchResult: null,
  editCitizen: {},
  initialDepartmentId: '',
  citizenOverview: {
    enrollments: [],
    enrollmentDepartments: [],
  },
  dischargeCitizens: [],
  enrollmentDepartments: [],
  availableSearchCitizens: [],
};

const addCitizenAction = actionCreator<{ citizen: Citizen }>('ADD_CITIZEN_ACTION');
export const setCitizensSearchResult = actionCreator<Citizen[]>('SET_CITIZENS_SEARCH_RESULT');
export const setCitizenEdit = actionCreator<EditCitizenEntity>('SET_CITIZEN_EDIT');
export const setCitizenOverview = actionCreator<CitizenOverview>('SET_CITIZEN_OVERVIEW');
export const setCitizenInitialDepartmentId = actionCreator<string>('SET_CITIZEN_INITIAL_DEPARTMENT_ID');
export const setCitizenForDischarge = actionCreator<CitizenEnrollment[]>('SET_CITIZEN_FOR_DISCHARGE');
export const setCitizenEnrollmentDepartments = actionCreator<string[]>('SET_CITIZEN_ENROLLMENT_DEPARTMENTS');
export const setAvailableSearchCitizens = actionCreator<CitizenEnrollmentResult[]>('SET_AVAILABLE_SEARCH_CITIZENS');

export default (state = defaultState, action: any) => {
  if (isType(action, setCitizensSearchResult)) {
    return { ...state, citizensSearchResult: action.payload };
  }

  if (isType(action, setCitizenEdit)) {
    return { ...state, editCitizen: action.payload };
  }

  if (isType(action, setCitizenOverview)) {
    return { ...state, citizenOverview: action.payload };
  }

  if (isType(action, setCitizenInitialDepartmentId)) {
    return { ...state, initialDepartmentId: action.payload };
  }

  if (isType(action, setCitizenForDischarge)) {
    return { ...state, dischargeCitizens: action.payload };
  }

  if (isType(action, setCitizenEnrollmentDepartments)) {
    return { ...state, enrollmentDepartments: action.payload };
  }

  if (isType(action, setAvailableSearchCitizens)) {
    return { ...state, availableSearchCitizens: action.payload };
  }

  return state;
};

export const addCitizen = (citizen: Citizen) => {
  return async dispatch => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CITIZEN_FORM, false));

      const createdCitizen = await prodocApi.citizen.add(citizen);

      dispatch(addCitizenAction({ citizen: createdCitizen }));
      dispatch(showSuccessMessage({ message: 'Borger oprettet' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CITIZEN_FORM, true));
      // dispatch(showCitizenEnrollModal(createdCitizen.id));
      dispatch(navigateToCitizenEnroll(createdCitizen.id));
    } catch (e) {
      if (!e.response) return;

      if (e.response.status === 409) {
        dispatch(showErrorMessage({ message: 'En borger med dette Cpr nummer er allerede oprettet' }));
      } else {
        dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
      }
    }
  };
};

export const searchCitizens = (searchStr: string) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      // const { citizens } = state;
      // const { availableSearchCitizens } = citizens;
      const citizensSearchResult: Citizen[] = await prodocApi.citizen.search(searchStr);
      // const filteredCitizens = citizensSearchResult.filter(citizen => {
      //   return !!availableSearchCitizens.find(item => item.citizenId === citizen.id);
      // });

      dispatch(setCitizensSearchResult(citizensSearchResult));
    } catch (e) {}
  };
};

export const getCitizenById = (citizenId: string) => {
  return async dispatch => {
    try {
      const citizen: Citizen = await prodocApi.citizen.get(citizenId);
      const citizenEnrollments = await prodocApi.citizenEnrollment.getByCitizen(citizenId, true);
      const handledCitizenData = UtilsItems.handleCitizenDefaultValues(citizen);

      dispatch(setCitizenEdit({ data: handledCitizenData, enrollments: citizenEnrollments }));
    } catch (e) {
      dispatch(showErrorMessage({ message: e.message }));
    }
  };
};

export const updateCitizen = (data: Citizen) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      await prodocApi.citizen.update(data);

      dispatch(showSuccessMessage({ message: data.firstname + ' ' + data.lastname + 's oplysninger opdateret' }));

      if (UtilsNavigation.shouldReturnToOverview()) {
        const updatedOverviewData = UtilsOverview.updateCompanyOverviewItemData(state.overview.data, data);

        dispatch(setOverviewData(updatedOverviewData));
        dispatch(goBack());
        return;
      }

      dispatch(navigateTo(ROUTES.CITIZEN_OVERVIEW));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const enrollCitizen = (data: CitizenEnrollment, departmentsIds: string[]) => {
  return async dispatch => {
    try {
      const citizenEnrollments = await prodocApi.citizenEnrollment.getManyByDepartments({
        departmentIds: departmentsIds,
      });
      const enrolledDepartmentsIds = [];
      const newEnrollments = [];

      for (const departmentId of departmentsIds) {
        const enrollData: CitizenEnrollment = { ...data, departmentId };
        const existingEnrollment = citizenEnrollments.find(item => {
          return item.departmentId === departmentId && data.citizenId === item.citizenId;
        });

        if (existingEnrollment) {
          enrolledDepartmentsIds.push(departmentId);
        } else {
          newEnrollments.push(enrollData);
        }
      }

      if (!enrolledDepartmentsIds.length) {
        for (const enrollment of newEnrollments) {
          const data = { ...enrollment };

          delete data.dischargedDate;
          await prodocApi.citizenEnrollment.add(data);
        }

        dispatch(showSuccessMessage({ message: 'Borger indskrevet' }));
      } else {
        dispatch(setCitizenEnrollmentDepartments(enrolledDepartmentsIds));
        dispatch(showErrorMessage({ message: 'Borgeren er allerede indskrevet på denne afdeling' }));
      }
    } catch (e) {
      dispatch(showErrorMessage({ message: 'Der skete en uventet fejl' }));
    }
  };
};

export const getCitizenOverviewData = (id: string) => {
  return async dispatch => {
    try {
      const enrollments = await prodocApi.citizenEnrollment.getByCitizen(id, true);

      const enrollmentDepartments = await Promise.all(
        enrollments.map(async item => {
          return await prodocApi.department.get(item.departmentId);
        })
      );

      dispatch(
        setCitizenOverview({
          enrollments,
          enrollmentDepartments,
        })
      );
    } catch (e) {}
  };
};

export const getEnrolledCitizensByDepartment = (departmentId: string) => {
  return async dispatch => {
    try {
      const departmentCitizens = await prodocApi.citizenEnrollment.getManyByDepartments({
        departmentIds: [departmentId],
      });

      dispatch(setCitizenForDischarge(departmentCitizens));
    } catch (e) {}
  };
};

export const dischargeCitizen = (data: CitizenDischarge) => {
  return async dispatch => {
    try {
      const { citizenEnrollmentId, date, cause } = data;

      await prodocApi.citizenEnrollment.discharge(citizenEnrollmentId, date, cause);

      dispatch(showSuccessMessage({ message: 'Borger udskrevet' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const loadCitizenEnrollDepartments = (citizenId: string) => {
  return async dispatch => {
    try {
      const userCenters = await prodocApi.center.getAllCreatedBy();
      const centersIds = userCenters.map(center => center.id);
      const userDepartments = await prodocApi.department.getManyFromCenter(centersIds);

      const citizenEnrollments: CitizenEnrollment[] =
        (await prodocApi.citizenEnrollment.getByCitizen(citizenId, true)) || [];
      const filteredDepartments = userDepartments.filter(department => {
        return citizenEnrollments.find(enrollment => enrollment.departmentId === department.id);
      });

      dispatch(setUserDepartments(filteredDepartments));
    } catch (e) {}
  };
};

export const loadAvailableSearchCitizens = () => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { department } = state;
      const { userDepartments } = department;
      const departmentIds = userDepartments.map(item => item.id);
      const citizens = await prodocApi.citizenEnrollment.getManyByDepartments({ departmentIds });
      const filteredCitizens = UtilsData.removeDuplicatesFromArray(citizens, 'id');

      dispatch(setAvailableSearchCitizens(filteredCitizens));
    } catch (e) {}
  };
};
