import React from 'react';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import { Measurement } from '../../../../services/prodocApi';
import TextArea from '../../../_shared/_controls/TextArea/TextArea';

interface Props {
  measurement: Measurement;
  formName: string;
  onChange(measurement: Measurement, name: string);
}

export default function MeasurementForm(props: Props) {
  const { measurement, formName, onChange } = props;
  const { m1, remarks } = measurement;

  const handleFieldChange = (value: any, name: string) => {
    const newData = { ...measurement, [name]: value };

    onChange(newData, formName);
  };

  return (
    <div className="common-form">
      <TextInput title="Ml" value={m1 || ''} name="m1" type="number" onChange={handleFieldChange} />
      <TextArea title="Bemærkning" value={remarks} name="remarks" onChange={handleFieldChange} />
    </div>
  );
}
