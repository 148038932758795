import React, { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HealthAreaStatusWindow from '../HealthAreaStatusWindow/HealthAreaStatusWindow';
import {
  CalendarItemType,
  HealthCare,
  HealthCareType,
  HealthCareParentType,
  HealthCareProblem,
  Note,
  CalendarItem,
} from '../../../services/prodocApi';
import { CommonOption } from '../../../types/common';
import { ApplicationState } from '../../../store';
import { useHealthCareAreaStatusChange, useHealthCareItemCreation } from '../../../hooks/medicine-hooks';
import { MedicineState } from '../../../store/medicineStore';
import HealthAreaEditWindow from '../HealthAreaEditWindow/HealthAreaEditWindow';
import { healthCareDefault } from '../../../services/defaults';
import CalendarNoteWindow from '../../Calendar/CalendarNoteWindow/CalendarNoteWindow';
import { UtilsData } from '../../../utils/UtilsData';
import { PagesState } from '../../../store/pagesStore';
import { HealthArea } from '../HealthArea/HealthArea';
import { createNote } from '../../../store/notesStore';

import './HealthAreas.scss';

const { Panel } = Collapse;

export default function HealthAreas() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createHealthCareItem } = useHealthCareItemCreation();
  const { changeAreaAllItemStatus, changeAreaCurrentItemStatus } = useHealthCareAreaStatusChange();
  const medicineStore = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const pagesStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const [showStatusWindow, setShowStatusWindow] = useState(false);
  const [showEditWindow, setShowEditWindow] = useState(false);
  const [isParentArea, setIsParentArea] = useState(false);
  const [showNoteWindow, setShowNoteWindow] = useState(false);
  const [noteItemData, setNoteItemData] = useState<CalendarItem>(
    UtilsData.getCalendarItemDefault(CalendarItemType.Note)
  );
  const [activeArea, setActiveArea] = useState<CommonOption>({ value: '', label: '' });
  const [editArea, setEditArea] = useState<HealthCare>(healthCareDefault());
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [parentStatus, setParentStatus] = useState(HealthCareProblem.None);
  const { healthCareAreasItems, activeCitizen } = medicineStore;
  const { calendarItem } = pagesStore;
  const statusList = useMemo(() => {
    return Object.entries(HealthCareProblem).map(([value, label]) => ({ label: t(label), value }));
  }, []);

  useEffect(() => {
    setExpandedPanels(statusList.map((item, index) => index));
  }, [activeCitizen]);

  useEffect(() => {
    if (calendarItem.saved) {
      setNoteItemData(UtilsData.getCalendarItemDefault(CalendarItemType.Note));
      setShowNoteWindow(false);
    }
  }, [calendarItem.saved]);

  const toggleStatusVisibility = (item?: CommonOption) => {
    if (showEditWindow) return;

    setShowStatusWindow(!showStatusWindow);

    if (item) {
      setActiveArea(item);
    }
  };

  const toggleEditVisibility = (item?: CommonOption) => {
    if (showStatusWindow) return;

    const selectedAreaData =
      healthCareAreasItems.find(healthItem => healthItem.care === item?.value) || healthCareDefault();

    if (!item) {
      setParentStatus(HealthCareProblem.None);
      setIsParentArea(false);
    }

    setShowEditWindow(!showEditWindow);
    setEditArea(selectedAreaData);
  };

  const handleStatusChange = (data: HealthCare) => {
    createHealthCareItem(data);
  };

  const handleAreaStatusChange = (item: HealthCare, isForAll: boolean, contentOnly: boolean) => {
    if (isForAll) {
      changeAreaAllItemStatus(item);
    } else {
      changeAreaCurrentItemStatus(item, parentStatus, contentOnly);
    }
  };

  const handlePanelExpand = data => {
    setExpandedPanels(data);
  };

  const toggleNoteWindow = () => {
    setShowNoteWindow(prevState => !prevState);
  };

  const handleNoteCreate = (data: Note) => {
    dispatch(createNote(data));
  };

  const renderSections = () => {
    return statusList.map((sectionItem, index) => {
      const { label, value } = sectionItem;
      const sectionItems = healthCareAreasItems.filter(healthItem => healthItem.problem === value);
      const sectionClassName = value.toLowerCase();

      const renderAreas = () => {
        const areasList = Object.keys(HealthCareParentType).map((name, index) => ({ name, position: index }));
        const filteredAreas = areasList.filter(({ name }) => {
          return sectionItems.find(subarea => subarea.care.indexOf(name) >= 0);
        });

        return filteredAreas.map(({ name, position }, index: number) => {
          const subAreas = healthCareAreasItems.filter(
            healthItem => healthItem.problem === value && healthItem.care.indexOf(name) >= 0
          );

          const handleAreaEdit = () => {
            const editItem: HealthCare = {
              ...healthCareDefault(),
              care: name as HealthCareType,
              problem: value as HealthCareProblem,
            };

            setShowEditWindow(true);
            setEditArea(editItem);
            setIsParentArea(true);
            setParentStatus(value as HealthCareProblem);
          };

          const handleNoteCreate = (subAreaName: HealthCareType) => {
            setNoteItemData({
              ...noteItemData,
              citizenEnrollmentId: activeCitizen,
              health: [subAreaName as unknown as HealthCareParentType],
            });
            toggleNoteWindow();
          };

          return (
            <HealthArea
              key={index}
              subAreas={subAreas}
              title={`${position}. ${t(name)}`}
              onAreaEdit={handleAreaEdit}
              onNoteCreate={handleNoteCreate}
              onSubAreaEdit={toggleEditVisibility}
            />
          );
        });
      };

      return (
        <Panel header={label} key={index} className={sectionClassName}>
          {Boolean(sectionItems.length) && <div className="health-areas__items">{renderAreas()}</div>}
        </Panel>
      );
    });
  };

  return (
    <div className="health-areas">
      <Collapse activeKey={expandedPanels} onChange={handlePanelExpand}>
        {renderSections()}
      </Collapse>
      <HealthAreaStatusWindow
        data={activeArea}
        isOpened={showStatusWindow}
        onClose={toggleStatusVisibility}
        onSave={handleStatusChange}
      />
      <HealthAreaEditWindow
        data={editArea}
        isParent={isParentArea}
        isOpened={showEditWindow}
        onClose={toggleEditVisibility}
        onSave={handleStatusChange}
        onAreaSave={handleAreaStatusChange}
      />
      <CalendarNoteWindow
        data={noteItemData}
        preselectedEnrollmentId={activeCitizen}
        isOpened={showNoteWindow}
        onClose={toggleNoteWindow}
        onUpdateCalendarItem={handleNoteCreate}
      />
    </div>
  );
}
