import React, { useEffect, useState } from 'react';
import { Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ColumnsType } from 'antd/lib/table';

import Button from '../../components/_shared/_controls/Button/Button';
import { ICONS } from '../../components/_shared/Icon/Icon';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import { ForceCalculationsCard } from '../../components/documents/ForceCalculationsCard/ForceCalculationsCard';
import { useCalendarEnrollmentsLoading, useSchemaPdfLink } from '../../hooks/calendar-hooks';
import { useEnumOptionsTranslation, usePageTitle } from '../../hooks/common-hooks';
import { useUseOfForceDownload } from '../../hooks/documents-hooks';
import { loadUserEnrollments } from '../../store/commonStore';
import {
  DocumentsState,
  loadDocumentsByCitizen,
  loadUseOfForceCalculationsDepartment,
} from '../../store/documentStore';
import { ApplicationState } from '../../store';
import { UseOfForceFilterData } from '../../types/documents';
import { UseOfForceDto, UseOfForceStatus } from '../../services/prodocApi';
import { DATE_TIME_FORMAT } from '../../constants/common';
import { UtilsAuth } from '../../utils/UtilsAuth';
import { getColumnDateSearchProps, getColumnEnumSearchProps, getColumnTextSearchProps } from '../../utils/UtilsTable';
import { Dayjs } from 'dayjs';

import './ForcePage.scss';

export default function ForcePage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getSchemaUpdateLink } = useSchemaPdfLink();
  const { downloadItem, downloadingId } = useUseOfForceDownload();
  const documentsState = useSelector<ApplicationState, DocumentsState>(state => state.documents);
  const [list, setList] = useState<UseOfForceDto[]>();
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const history = useHistory();
  const [filterData, setFilterData] = useState<UseOfForceFilterData>({
    citizenId: '',
    citizenName: '',
    status: UseOfForceStatus.Employee,
  });
  const { useOfForceList } = documentsState;
  useEffect(() => {
    dispatch(loadDocumentsByCitizen(filterData));
  }, []);
  useEffect(() => {
    setList([...useOfForceList]);
  }, [useOfForceList]);

  useCalendarEnrollmentsLoading();
  usePageTitle('Magtanvendelser');

  const windowEventHandler = event => {
    if (event.data.key === 'USE_OF_FORCE_POST') {
      dispatch(loadDocumentsByCitizen(filterData));
    }
  };

  useEffect(() => {
    dispatch(loadUserEnrollments());
    dispatch(loadUseOfForceCalculationsDepartment());
  }, []);

  useEffect(() => {
    window.addEventListener('message', windowEventHandler, false);

    return () => {
      window.removeEventListener('message', windowEventHandler);
    };
  }, [filterData]);

  const handleFilterChange = (data: UseOfForceFilterData) => {
    setFilterData(data);
    dispatch(loadDocumentsByCitizen(data));
  };
  const search = v => {
    const value = v.toLowerCase();
    setFilterData({ ...filterData, citizenName: value });
    filter(value, filterData.status, filterData.fromDate, filterData.toDate);
  };
  const searchStatus = value => {
    setFilterData({ ...filterData, status: value });
    filter(filterData.citizenName, value, filterData.fromDate, filterData.toDate);
  };
  function handleDateChange(date: Dayjs[]) {
    setFilterData({ ...filterData, fromDate: date[0]?.toDate(), toDate: date[1]?.toDate() });
    filter(filterData.citizenName, filterData.status, date[0]?.toDate(), date[1]?.toDate());
  }

  function filter(citizenName: string, status: string, fromDate?: Date, toDate?: Date) {
    let list = [...useOfForceList].filter(x =>
      `${x.citizen?.firstname.toLowerCase()} ${x.citizen?.lastname.toLowerCase()}`.includes(citizenName.toLowerCase())
    );
    if (status) {
      list = list.filter(x => x.status === status);
    }
    if (fromDate) {
      list = list.filter(x => new Date(x.createdOn) >= fromDate);
    }
    if (toDate) {
      list = list.filter(x => new Date(x.createdOn) <= toDate);
    }
    setList(list);
  }

  const columns: ColumnsType<UseOfForceDto> = [
    {
      title: 'Borger',
      render: ({ citizen }: UseOfForceDto) => `${citizen?.firstname} ${citizen?.lastname}`,
      ellipsis: true,
      ...getColumnTextSearchProps(search),
    },
    {
      title: 'Skema',
      render: ({ documentName }: UseOfForceDto) => t(documentName),
      key: 'documentName',
      ellipsis: true,
    },
    {
      title: 'Oprettet af',
      dataIndex: 'createdByName',
      key: 'createdByName',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => t(status),
      ellipsis: true,
      ...getColumnEnumSearchProps(translateEnumOptions(UseOfForceStatus), searchStatus),
    },
    ,
    {
      title: 'Oprettet dato',
      dataIndex: 'created',
      render: date => moment(date).format(DATE_TIME_FORMAT),
      ellipsis: true,
      sorter: (a, b) => Number(new Date(a.createdOn)) - Number(new Date(b.createdOn)),
      ...getColumnDateSearchProps(handleDateChange),
    },
    {
      title: 'Sidst ændret dato',
      dataIndex: 'modified',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : ''),
      ellipsis: true,
      sorter: (a, b) => Number(new Date(a.modifiedOn)) - Number(new Date(b.modifiedOn)),
      defaultSortOrder: 'descend',
    },
    {
      title: '',
      width: '100px',
      render: (value, row: UseOfForceDto) => {
        const isDownloading = downloadingId === row.id;
        const canEdit =
          row.status !== UseOfForceStatus.Authority ||
          (row.status === UseOfForceStatus.Authority && UtilsAuth.isAdmin());
        const handleDownload = () => {
          downloadItem(row.id, row.documentName);
        };

        return (
          <Space size={5}>
            <Button icon={ICONS.download} isLoading={isDownloading} onClick={handleDownload} />
          </Space>
        );
      },
    },
  ];
  function handleRowClicked(row: UseOfForceDto) {
    const canEdit =
      row.status !== UseOfForceStatus.Authority || (row.status === UseOfForceStatus.Authority && UtilsAuth.isAdmin());
    if (canEdit) {
      const url = getSchemaUpdateLink(row);
      history.push(url);
    }
  }
  return (
    <>
      {/* <Sidebar>
        <Button title="Magtanvendelser" className="fullwidth" />
      </Sidebar> */}
      <div className="page-full force">
        <ForceCalculationsCard />
        {/* <Card>
          <ForceFilter data={filterData} onChange={handleFilterChange} />
        </Card> */}
        <Table
          style={{ cursor: 'pointer' }}
          dataSource={list}
          columns={columns}
          pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }}
          rowKey="id"
          onRow={(row, rowIndex) => {
            return {
              onClick: () => handleRowClicked(row),
            };
          }}
        />
      </div>
    </>
  );
}
