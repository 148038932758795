import moment from 'moment';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import prodocApi, {
  ResourceCalendarItem,
  ResourceCalendarItemType,
  ResourceCalendarPage,
} from '../../services/prodocApi';
import { v4 as uuidv4 } from 'uuid';

export interface ILocalData {
  employeeId: string;
  fromDate: string;
  toDate: string;
  from: string;
  to: string;
  id: string;
  resourceCalendarItemType: ResourceCalendarItemType;
  resourceCalendarPage: ResourceCalendarPage;
  dayNumber: number;
  overrideHours: string;
  fullDay: boolean;
  departmentId: string;
}

interface ResourceCalendarStoreState {
  resourceCalendarItems: ILocalData[];
  selectedResourceCalendarItem: ILocalData;
  upsert: (localData: ILocalData) => void;
  updateSelectedResourceCalendarItem: (localData: ILocalData) => void;
  remove: (id: string) => void;
  load: (departmentId: string, resourceCalendarPage: ResourceCalendarPage) => void;
}

export const useResourceCalendarStore = create<ResourceCalendarStoreState>()(
  devtools((set, get) => ({
    resourceCalendarItems: [],
    selectedResourceCalendarItem: {
      employeeId: '',
      fromDate: '',
      toDate: '',
      from: '',
      to: '',
      id: '',
      resourceCalendarItemType: ResourceCalendarItemType.StandardHours,
      dayNumber: 0,
      overrideHours: '',
      fullDay: false,
      departmentId: '',
      resourceCalendarPage: ResourceCalendarPage.Planning,
    },
    remove: async id => {
      const items = await remove(get(), id);
      set({ resourceCalendarItems: items });
    },
    upsert: async localData => {
      console.log(localData);

      const items = await upsert(get(), localData);

      set({ resourceCalendarItems: items });
    },
    updateSelectedResourceCalendarItem: (localData: ILocalData) =>
      set(state => ({ ...state, selectedResourceCalendarItem: localData })),
    load: async (departmentId: string, resourceCalendarPage: ResourceCalendarPage) => {
      const items = await load(departmentId, resourceCalendarPage);
      set({ resourceCalendarItems: resourceCalendarItemToLocal(items) });
    },
  }))
);
async function load(departmentId: string, resourceCalendarPage: ResourceCalendarPage) {
  return await prodocApi.resourceCalendar.getResourceCalendarItems(departmentId, resourceCalendarPage);
}
async function remove(resourceCalendarStoreState: ResourceCalendarStoreState, id: string) {
  const { resourceCalendarItems } = resourceCalendarStoreState;
  await prodocApi.resourceCalendar.deleteResourceCalendarItem(id);
  return [...resourceCalendarItems.filter(x => x.id !== id)];
}
async function upsert(resourceCalendarStoreState: ResourceCalendarStoreState, localData: ILocalData) {
  const { resourceCalendarItems, selectedResourceCalendarItem } = resourceCalendarStoreState;
  const found = resourceCalendarItems.find(x => x.id == localData.id);

  let result;
  if (found) {
    const filteded = resourceCalendarItems.filter(x => x.id !== localData.id);
    result = [
      ...filteded,
      {
        fromDate: addTimeToDate(localData.fromDate, localData.from),
        toDate: addTimeToDate(localData.toDate, localData.to),
        employeeId: localData.employeeId,
        id: localData.id,
        resourceCalendarItemType: localData.resourceCalendarItemType,
        overrideHours: localData.fullDay ? '24:00' : localData.overrideHours,
        fullDay: localData.fullDay,
        departmentId: localData.departmentId,
        dayNumber: localData.dayNumber,
      },
    ];
    await prodocApi.resourceCalendar.updateResourceCalendarItem({
      fromDate: new Date(addTimeToDate(selectedResourceCalendarItem.fromDate, selectedResourceCalendarItem.from)),
      toDate: new Date(addTimeToDate(selectedResourceCalendarItem.toDate, selectedResourceCalendarItem.to)),
      employeeId: selectedResourceCalendarItem.employeeId,
      id: found.id,
      resourceCalendarItemType: selectedResourceCalendarItem.resourceCalendarItemType,
      overrideHours: selectedResourceCalendarItem.fullDay ? '24:00' : selectedResourceCalendarItem.overrideHours,
      fullDay: selectedResourceCalendarItem.fullDay,
      departmentId: selectedResourceCalendarItem.departmentId,
      dayNumber: selectedResourceCalendarItem.dayNumber,
      resourceCalendarPage: selectedResourceCalendarItem.resourceCalendarPage,
    });
  } else {
    result = [
      ...resourceCalendarItems,
      {
        fromDate: addTimeToDate(localData.fromDate, localData.from),
        toDate: addTimeToDate(localData.toDate, localData.to),
        employeeId: localData.employeeId,
        id: uuidv4(),
        resourceCalendarItemType: localData.resourceCalendarItemType,
        overrideHours: localData.fullDay ? '24:00' : localData.overrideHours,
        fullDay: localData.fullDay,
        departmentId: localData.departmentId,
        dayNumber: localData.dayNumber,
      },
    ];
    await prodocApi.resourceCalendar.addResourceCalendarItem({
      fromDate: new Date(addTimeToDate(selectedResourceCalendarItem.fromDate, selectedResourceCalendarItem.from)),
      toDate: new Date(addTimeToDate(selectedResourceCalendarItem.toDate, selectedResourceCalendarItem.to)),
      employeeId: selectedResourceCalendarItem.employeeId,
      id: uuidv4(),
      resourceCalendarItemType: selectedResourceCalendarItem.resourceCalendarItemType,
      overrideHours: selectedResourceCalendarItem.fullDay ? '24:00' : selectedResourceCalendarItem.overrideHours,
      fullDay: selectedResourceCalendarItem.fullDay,
      departmentId: selectedResourceCalendarItem.departmentId,
      dayNumber: selectedResourceCalendarItem.dayNumber,
      resourceCalendarPage: selectedResourceCalendarItem.resourceCalendarPage,
    });
  }

  return result;
}
function addTimeToDate(date: string, time: string) {
  let d = moment(date)
    .hour(Number(time.split(':')[0]))
    .minute(Number(time.split(':')[1]))
    .second(0)
    .millisecond(0);
  return d.toISOString();
}
function resourceCalendarItemToLocal(resourceCalendarItems: ResourceCalendarItem[]): ILocalData[] {
  return resourceCalendarItems.map(x => ({
    employeeId: x.employeeId,
    fromDate: moment(x.fromDate).toISOString(),
    toDate: moment(x.toDate).toISOString(),
    from: moment(x.fromDate).format('HH:mm'),
    to: moment(x.toDate).format('HH:mm'),
    id: x.id,
    resourceCalendarItemType: x.resourceCalendarItemType,
    resourceCalendarPage: x.resourceCalendarPage,
    dayNumber: x.dayNumber,
    overrideHours: x.overrideHours,
    fullDay: x.fullDay,
    departmentId: x.departmentId,
  }));
}
