import React, { useEffect, useState } from 'react';
import { usePageTitle } from '../../hooks/common-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Button, Table, Modal } from 'antd';
import { ColumnType } from 'antd/es/table';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants/common';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import prodocApi, { Citizen, ExternalContact, ExternalContactDto } from '../../services/prodocApi';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { FilterInputProp, getColumnTextFilter } from '../../utils/UtilsTable';

interface ExternalContactsList extends ExternalContactDto, FilterInputProp {}

export function ExternalContactsList() {
  const [externalContacts, setExternalContacts] = useState<ExternalContactsList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [citizen, setCitizen] = useState<Citizen>();
  const { citizenId } = useParams<{ citizenId: string }>();
  const history = useHistory();

  usePageTitle('Eksterne kontakter', citizen?.name);
  useEffect(() => {
    init();
  }, []);
  async function init() {
    try {
      setIsLoading(true);
      const externalConcats = await prodocApi.externalContact.getManyFromCitizen(citizenId);
      setExternalContacts(externalConcats as ExternalContactsList[]);
      const c = await prodocApi.citizen.get(citizenId);
      setCitizen(c);
    } finally {
      setIsLoading(false);
    }
  }
  function handleCreateExternalContactsPage() {
    history.push(ROUTES.ADDEXTERNALCONTACT.replace(':citizenId', citizenId));
  }
  function handleRowClicked(value: any) {
    history.push(ROUTES.EXTERNALCONTACT_DETAILS.replace(':id', value.id).replace(':citizenId', citizenId));
  }
  function deleteExternalContact(e: any, row: ExternalContact) {
    e.stopPropagation();
    e.preventDefault();
    Modal.confirm({
      content: `Er du sikker på at du ønsker at slette denne kontakt?`,
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: async () => {
        await prodocApi.externalContact.deleteExternalContactCitizen(row.id, citizenId);
        await init();
      },
    });
  }

  const columns: ColumnType<ExternalContactsList>[] = [
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
      ...getColumnTextFilter<ExternalContactsList>(externalContacts, 'name', c => setExternalContacts(c)),
    },
    {
      title: 'Kategori',
      dataIndex: 'categoryName',
      key: 'categoryName',
      ...getColumnTextFilter<ExternalContactsList>(externalContacts, 'categoryName', c => setExternalContacts(c)),
    },
    {
      title: 'Underkategori',
      dataIndex: 'subCategoryName',
      key: 'subCategoryName',
      ...getColumnTextFilter<ExternalContactsList>(externalContacts, 'subCategoryName', c => setExternalContacts(c)),
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'e-mail',
      dataIndex: 'email',
      key: 'email',
      ...getColumnTextFilter<ExternalContactsList>(externalContacts, 'email', c => setExternalContacts(c)),
    },
    {
      title: 'Oprettet',
      dataIndex: 'createdOn',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : ''),
    },
    {
      title: '',
      align: 'right',
      render: (value, row: any) => {
        return (
          <>
            <Tooltip title="Slet">
              <Button onClick={e => deleteExternalContact(e, row)} icon={<FontAwesomeIcon icon={faTrashCan} />} />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div className="citizen-page page">
      <Row>
        <Col span={24}>
          <div style={{ background: '#fafafa', padding: '8px' }}>
            <Tooltip title="Tilføj ekstern kontakt">
              <Button shape="circle" icon={<PlusOutlined translate="" />} onClick={handleCreateExternalContactsPage} />
            </Tooltip>
          </div>
          <Table
            style={{ cursor: 'pointer' }}
            loading={isLoading}
            pagination={{ pageSize: 15 }}
            dataSource={externalContacts.filter(x => !x.hidden)}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => handleRowClicked(record),
              };
            }}
            columns={columns}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
}
