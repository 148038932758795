import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CitizenEnrollForm from '../../../components/citizen/CitizenEnrollForm/CitizenEnrollForm';
import { CitizenEnrollment, Employee } from '../../../services/prodocApi';
import {
  CitizensState,
  enrollCitizen,
  getCitizenById,
  loadAvailableSearchCitizens,
  setCitizenEdit,
} from '../../../store/citizenStore';
import { navigateToHome } from '../../../store/navigationStore';
import { useCitizenNavigationItems, useRouterParams } from '../../../hooks/navigation';
import { loadUserDepartments } from '../../../store/userStore';
import { ApplicationState } from '../../../store';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { DepartmentState } from '../../../store/departmentStore';

export default function CitizenEnrollPage() {
  const dispatch = useDispatch();
  const [match] = useRouterParams();
  const { citizenItems } = useCitizenNavigationItems();
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const citizensState = useSelector<ApplicationState, CitizensState>(state => state.citizens);
  const departmentsState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const citizenId = match.params.id;
  const userId = userState.id;
  const { availableSearchCitizens } = citizensState;
  const { userDepartments } = departmentsState;

  usePageTitle('Indskriv borger på afdeling');

  useEffect(() => {
    if (!availableSearchCitizens.length && userDepartments.length) {
      dispatch(loadAvailableSearchCitizens());
    }
  }, [availableSearchCitizens.length, userDepartments.length]);

  useEffect(() => {
    if (citizenId) {
      dispatch(getCitizenById(citizenId));
    }

    return () => {
      dispatch(setCitizenEdit({}));
    };
  }, [citizenId]);

  useEffect(() => {
    if (userId) {
      dispatch(loadUserDepartments());
    }
  }, [userId]);

  const handleFormSubmit = (data: CitizenEnrollment, departmentsIds: string[]) => {
    dispatch(enrollCitizen(data, departmentsIds));
  };

  const handleCancel = () => {
    dispatch(navigateToHome());
  };

  return (
    <>
      <Sidebar>
        <NavigationMenu items={citizenItems} />
      </Sidebar>
      <div className="citizen-page page">{/* <CitizenEnrollForm onCancel={handleCancel} /> */}</div>
    </>
  );
}
