import * as React from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import {
  CaretRightOutlined,
  UserOutlined,
  LockOutlined,
  UserAddOutlined,
  BankOutlined,
  HomeOutlined,
  PushpinOutlined,
  PushpinFilled,
  ClusterOutlined,
  LogoutOutlined,
  CaretLeftOutlined,
  UserDeleteOutlined,
  PlusOutlined,
  EditOutlined,
  TeamOutlined,
  QuestionOutlined,
  CloseOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ReadOutlined,
  FlagOutlined,
  UnderlineOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  AlertOutlined,
  DashboardOutlined,
  WarningOutlined,
  BarChartOutlined,
  HeartOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  AppstoreOutlined,
  FileAddOutlined,
  UploadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined,
  MessageOutlined,
  TableOutlined,
  ApartmentOutlined,
  CrownOutlined,
  ExpandAltOutlined,
  DollarOutlined,
} from '@ant-design/icons';

import './Icon.scss';

export const ICONS = {
  menu: 'menu',
  menuFold: 'menu-fold',
  menuUnfold: 'menu-unfold',
  user: 'user',
  lock: 'lock',
  userAdd: 'user-add',
  bank: 'bank',
  home: 'home',
  pushpin: 'pushpin',
  pushpinFilled: 'pushpin-fiiled',
  cluster: 'cluster',
  logout: 'logout',
  caretRight: 'caret-right',
  caretLeft: 'caret-left',
  calendar: 'calendar',
  userDelete: 'user-delete',
  plus: 'plus',
  edit: 'edit',
  team: 'team',
  question: 'question',
  close: 'close',
  check: 'check',
  playCircle: 'playCircle',
  pauseCircle: 'pauseCircle',
  checkCircle: 'checkCircle',
  closeCircle: 'close',
  read: 'read',
  message: 'message',
  flagoutlined: 'flagoutlined',
  underline: 'underline',
  orderedList: 'orderedList',
  unorderedList: 'unorderedList',
  alert: 'alert',
  dashboard: 'dashboard',
  warning: 'warning',
  barchart: 'barchart',
  heart: 'heart',
  userGroupAdd: 'userGroupAdd',
  userGroupDelete: 'userGroupDelete',
  download: 'download',
  file: 'file',
  fileAdd: 'fileAdd',
  upload: 'upload',
  delete: 'delete',
  info: 'info',
  checkSquare: 'checkSquare',
  clock: 'clock',
  timeReg: 'timeReg',
  apartment: 'apartment',
  crown: 'crown',
  expand: 'expand',
  dollar: 'dollar',
};

interface Props {
  name: string;
  color?: string;
  className?: string;
  asSvg?: boolean;
}

export default function Icon(props: Props) {
  const { name, color, className, asSvg } = props;

  if (asSvg) {
    return <ReactSVG src={name} wrapper="span" className="icon" />;
  }

  const renderIcon = name => {
    const iconProps = {
      translate: '',
      className: classNames('icon', { [className]: Boolean(className) }),
      style: {
        color,
      },
    };

    const iconsElements = {
      [ICONS.menu]: <AppstoreOutlined {...iconProps} />,
      [ICONS.user]: <UserOutlined translate {...iconProps} />,
      [ICONS.lock]: <LockOutlined {...iconProps} />,
      [ICONS.userAdd]: <UserAddOutlined {...iconProps} />,
      [ICONS.bank]: <BankOutlined {...iconProps} />,
      [ICONS.home]: <HomeOutlined {...iconProps} />,
      [ICONS.pushpin]: <PushpinOutlined {...iconProps} />,
      [ICONS.cluster]: <ClusterOutlined {...iconProps} />,
      [ICONS.logout]: <LogoutOutlined {...iconProps} />,
      [ICONS.caretRight]: <CaretRightOutlined {...iconProps} />,
      [ICONS.caretLeft]: <CaretLeftOutlined {...iconProps} />,
      [ICONS.userDelete]: <UserDeleteOutlined {...iconProps} />,
      [ICONS.plus]: <PlusOutlined {...iconProps} />,
      [ICONS.edit]: <EditOutlined {...iconProps} />,
      [ICONS.team]: <TeamOutlined {...iconProps} />,
      [ICONS.question]: <QuestionOutlined {...iconProps} />,
      [ICONS.close]: <CloseOutlined {...iconProps} />,
      [ICONS.check]: <CheckOutlined {...iconProps} />,
      [ICONS.playCircle]: <PlayCircleOutlined {...iconProps} />,
      [ICONS.checkCircle]: <CheckCircleOutlined {...iconProps} />,
      [ICONS.closeCircle]: <CloseCircleOutlined {...iconProps} />,
      [ICONS.pauseCircle]: <PauseCircleOutlined {...iconProps} />,
      [ICONS.read]: <ReadOutlined {...iconProps} />,
      [ICONS.pushpinFilled]: <PushpinFilled {...iconProps} />,
      [ICONS.flagoutlined]: <FlagOutlined {...iconProps} />,
      [ICONS.underline]: <UnderlineOutlined {...iconProps} />,
      [ICONS.orderedList]: <OrderedListOutlined {...iconProps} />,
      [ICONS.unorderedList]: <UnorderedListOutlined {...iconProps} />,
      [ICONS.calendar]: <CalendarOutlined {...iconProps} />,
      [ICONS.menuFold]: <MenuFoldOutlined {...iconProps} />,
      [ICONS.menuUnfold]: <MenuUnfoldOutlined {...iconProps} />,
      [ICONS.alert]: <AlertOutlined {...iconProps} />,
      [ICONS.dashboard]: <DashboardOutlined {...iconProps} />,
      [ICONS.warning]: <WarningOutlined {...iconProps} />,
      [ICONS.barchart]: <BarChartOutlined {...iconProps} />,
      [ICONS.heart]: <HeartOutlined {...iconProps} />,
      [ICONS.userGroupAdd]: <UsergroupAddOutlined {...iconProps} />,
      [ICONS.userGroupDelete]: <UsergroupDeleteOutlined {...iconProps} />,
      [ICONS.download]: <DownloadOutlined {...iconProps} />,
      [ICONS.file]: <FileOutlined {...iconProps} />,
      [ICONS.fileAdd]: <FileAddOutlined {...iconProps} />,
      [ICONS.upload]: <UploadOutlined {...iconProps} />,
      [ICONS.delete]: <DeleteOutlined {...iconProps} />,
      [ICONS.info]: <InfoCircleOutlined {...iconProps} />,
      [ICONS.checkSquare]: <CheckSquareOutlined {...iconProps} />,
      [ICONS.clock]: <ClockCircleOutlined {...iconProps} />,
      [ICONS.message]: <MessageOutlined {...iconProps} />,
      [ICONS.timeReg]: <TableOutlined {...iconProps} />,
      [ICONS.apartment]: <ApartmentOutlined {...iconProps} />,
      [ICONS.crown]: <CrownOutlined {...iconProps} />,
      [ICONS.expand]: <ExpandAltOutlined translate {...iconProps} />,
      [ICONS.dollar]: <DollarOutlined translate {...iconProps} />,
    };

    return iconsElements[name];
  };

  return renderIcon(name);
}
