import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Table } from 'antd';
import { Drawer } from '../../_shared/Drawer/Drawer';
import { healthCareDefault } from '../../../services/defaults';
import TextEditor from '../../_shared/_controls/TextEditor/TextEditor';
import { HealthCare, HealthCareCitizenAct, HealthCareType, HealthCareProblem } from '../../../services/prodocApi';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { useCitizenMedicineRequest, useHealthCareAreCalendarItemsRequest } from '../../../hooks/medicine-hooks';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants/common';
import { HealthAreaStatusConfirmWindow } from './HealthAreaStatusConfirmWindow/HealthAreaStatusConfirmWindow';
import { MedicineState } from '../../../store/medicineStore';
import { UtilsMedicine } from '../../../utils/UtilsMedicine';

import './HealthAreaEditWindow.scss';

interface Props {
  data: HealthCare;
  isOpened: boolean;
  isParent: boolean;
  onClose(): void;
  onSave(data: HealthCare): void;
  onAreaSave(data: HealthCare, isForAll: boolean, contentOnly: boolean): void;
}

export default function HealthAreaEditWindow(props: Props) {
  const { data, isOpened, isParent, onClose, onSave, onAreaSave } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getCitizenMedicine } = useCitizenMedicineRequest();
  const { getCalendarItemsByArea, areaAppointments } = useHealthCareAreCalendarItemsRequest();
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const medicineState = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const [isChanged, setIsChanged] = useState(false);
  const [showParentChangeConfirm, setShowParentChangeConfirm] = useState(false);
  const [formData, setFormData] = useState(healthCareDefault());
  const [medicineOptions, setMedicineOptions] = useState([]);
  const { description, action, citizenAct, problem, medicineIds } = formData;
  const { care, id } = data;
  const { selectedCitizenData } = medicineState;
  const statusChangeState = pagesState[PAGES_ITEMS.MEDICINE_STATUS];
  const { saved } = statusChangeState;
  const isSaving = pagesState[PAGES_ITEMS.MEDICINE_STATUS].loading;
  const { id: citizenId, firstname, lastname } = selectedCitizenData;
  const loadCalendarItems = async (value: HealthCareType) => {
    getCalendarItemsByArea(value);
  };

  const loadMedicineItems = async (citizenId: string) => {
    const items = await getCitizenMedicine(citizenId);
    const convertedItems = UtilsMedicine.createMedicineSelectOptions(items);

    setMedicineOptions(convertedItems);
  };

  useEffect(() => {
    if (isOpened) {
      loadCalendarItems(care);
      setFormData({ ...data });
    }
  }, [isOpened, id]);

  useEffect(() => {
    if (saved && isOpened) {
      setFormData(healthCareDefault());
      setIsChanged(false);
      onClose();
    }
  }, [saved]);

  useEffect(() => {
    if (citizenId) {
      loadMedicineItems(citizenId);
    }
  }, [citizenId]);

  const handleFieldChange = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
    setIsChanged(true);
  };

  const handleClose = () => {
    if (!isChanged) {
      onClose();
      return;
    }

    dispatch(
      showConfirmModal({
        content: `Ønsker du at annullere? Ikke gemte oplysninger vil blive slettet`,
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          onClose();
          setIsChanged(false);
        },
      })
    );
  };

  const toggleParentStatusConfirm = () => {
    setShowParentChangeConfirm(!showParentChangeConfirm);
  };

  const handleFromSubmit = () => {
    if (isParent) {
      toggleParentStatusConfirm();
      return;
    }

    onSave(formData);
    setIsChanged(false);
  };

  const handleAllSubmit = (moveAll: boolean, contentOnly?: boolean) => {
    onAreaSave(formData, moveAll, contentOnly);
    toggleParentStatusConfirm();
  };

  const columns = [
    {
      title: 'Dato',
      dataIndex: 'fromDate',
      key: 'fromDate',
      render: date => moment(date).format(DATE_FORMAT),
    },
    {
      title: 'Tidspunkt',
      dataIndex: 'fromDate',
      key: 'fromDate',
      render: (time, row) => `${moment(time).format(TIME_FORMAT)} - ${moment(row.toDate).format(TIME_FORMAT)}`,
    },
    {
      title: 'Aftale',
      dataIndex: 'title',
      key: 'title',
    },
  ];

  return (
    <>
      <Drawer
        title={t(care)}
        subtitle={`${firstname} ${lastname}`}
        visible={isOpened}
        saving={isSaving}
        onClose={handleClose}
        onOk={handleFromSubmit}
        onCancel={handleClose}>
        <div className="hae-window">
          <form className="item-form hae-window__form">
            <SelectMenu
              title="Status"
              value={problem}
              name="problem"
              placeholder="Status"
              valueKey="value"
              labelKey="label"
              options={Object.entries(HealthCareProblem).map(([value, label]) => ({ label: t(label), value }))}
              onChange={handleFieldChange}
            />
            <TextEditor title="Beskrivelse" value={description} name="description" onChange={handleFieldChange} />
            <SelectMenu
              title="Ønsker borgeren, at der handles?"
              value={citizenAct}
              name="citizenAct"
              valueKey="value"
              labelKey="label"
              options={Object.entries(HealthCareCitizenAct).map(([value, label]) => ({ label: t(label), value }))}
              onChange={handleFieldChange}
            />
            <SelectMenu
              title="Medicin"
              value={medicineIds}
              multiple
              name="medicineIds"
              valueKey="value"
              labelKey="label"
              options={medicineOptions}
              onChange={handleFieldChange}
            />
            <TextEditor title="Handling" value={action} name="action" onChange={handleFieldChange} />
          </form>
          <div className="hae-window__calendar">
            <div className="hae-window__calendar-title">Aftaler</div>
            <div className="hae-window__calendar-table">
              <Table
                dataSource={areaAppointments}
                columns={columns}
                pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }}
              />
            </div>
          </div>
        </div>
      </Drawer>
      <HealthAreaStatusConfirmWindow
        isVisible={showParentChangeConfirm}
        onConfirm={handleAllSubmit}
        onCLose={toggleParentStatusConfirm}
      />
    </>
  );
}
