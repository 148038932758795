import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment, { MomentInput } from 'moment';
import { useTranslation } from 'react-i18next';
import {
  CalendarItem,
  CalendarItemType,
  CalendarStatus,
  Employee,
  HealthCareParentType,
} from '../../../services/prodocApi';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import CalendarItemDrawer from '../CalendarItemDrawer/CalendarItemDrawer';
import Button from '../../_shared/_controls/Button/Button';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { Checkbox } from '../../_shared/_controls/Checkbox/Checkbox';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { ApplicationState } from '../../../store';
import {
  CalendarState,
  changeAppointmentStatus,
  deleteCalendarItem,
  deleteParticipantsCalendarItems,
} from '../../../store/calendarStore';
import { repetitionOptions } from '../../../data/calendar';
import { calendarAppointmentSchema } from '../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import { UtilsCalendar } from '../../../utils/UtilsCalendar';
import { useParticipantsRequest } from '../../../hooks/calendar-hooks';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { CalendarItemExt, CalendarItemParticipant } from '../../../types/calendar';
import { showConfirmModal } from '../../_shared/Modal/ModalFunctions';
import AppointmentStatusChanger from '../AppointmentStatusChanger/AppointmentStatusChanger';
import AppointmentParticipants from '../AppointmentParticipants/AppointmentParticipants';
import TextEditor from '../../_shared/_controls/TextEditor/TextEditor';
import { AppointmentDeleteModal } from '../AppointmentDeleteModal/AppointmentDeleteModal';
import { LoadingAnimation } from '../../_shared/LoadingAnimation/LoadingAnimation';

import './AddAppointmentWindow.scss';
import { Schedule } from '../../_shared/_controls/Schedule/Schedule';
import { defaultMedicineSchedule } from '../../../data/medicine-data';
import { OrdinationSchedule } from '../../../types/medicine';

export interface Props {
  isOpened: boolean;
  defaultCitizenId: string;
  data: CalendarItemExt;
  onClose(): void;
  onUpdateCalendarItem(data: CalendarItem, citizens: string[], employees: string[]);
}

export default function AddAppointmentWindow(props: Props) {
  const { isOpened, defaultCitizenId, data, onClose, onUpdateCalendarItem } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const userStore = useSelector<ApplicationState, Employee>(state => state.user);
  const pageStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { getAppointmentParticipants } = useParticipantsRequest();
  const {
    citizenEnrollmentId: existedCitizenEnrollmentId,
    employeeEnrollmentId: existedEmployeeEnrollmentId,
    isAccepted,
    calendarStatus,
    isPrivate: isPrivateItem,
    locked,
    calendarItemType,
  } = data || {};
  const {
    employeesEnrollments = [],
    citizensEnrollments = [],
    departmentId,
    selectedCitizenEnrollments,
    selectedEmployeeEnrollments,
  } = calendarStore;
  const { id: currentUserId } = userStore;
  const { loading } = pageStore[PAGES_ITEMS.CALENDAR_ITEM];
  const isEditMode = !!existedCitizenEnrollmentId || !!existedEmployeeEnrollmentId;
  const initialData = { ...data } as CalendarItem;
  const isParticipant = useMemo(() => {
    return !!employeesEnrollments.find(
      item => item.employeeId === currentUserId && existedEmployeeEnrollmentId === item.id
    );
  }, [existedEmployeeEnrollmentId]);
  const isNotAcceptedByParticipant = (isParticipant && !isAccepted) || locked;

  const [defaultData, setDefaultData] = useState<CalendarItem>(initialData);
  const [localData, setLocalData] = useState<CalendarItem>(initialData);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: calendarAppointmentSchema,
    defaultValues: defaultData as any,
  });
  useRegisteredFields(calendarAppointmentSchema, register, unregister);
  const [repetitionType, setRepetitionType] = useState<string>();
  const [fullDate, setFullDate] = useState<Date>(data && data.fromDate);
  const [employees, setEmployees] = useState([]);
  const [citizens, setCitizens] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [participantsCount, setParticipantsCount] = useState(0);
  const [showDeleteWindow, setShowDeleteWindow] = useState(false);
  const [participants, setParticipants] = useState<CalendarItemParticipant[]>([]);
  const { title, note, fromDate, toDate, allday, health, isPrivate, done, isRepetition } = localData;
  const closeText = !isEditMode ? 'Annuller' : 'Luk';
  const isNew = data ? UtilsCalendar.isDefaultAppointment(data) : false;
  const isTimeTrack = calendarItemType === CalendarItemType.TimeTrack;
  const windowTitle = isTimeTrack ? 'Tidsregistering' : 'Aftale';
  const [schedule, setSchedule] = useState<OrdinationSchedule>(defaultMedicineSchedule);

  const privateIsDisabled = employees.length > 1 || !!citizens.length;
  const citizenEnrollmentsList = useMemo(
    () => citizensEnrollments.filter(item => item.departmentId === departmentId),
    [departmentId, citizensEnrollments.length]
  );

  const employeesEnrollmentsList = useMemo(
    () => employeesEnrollments.filter(item => item.departmentId === departmentId),
    [departmentId, employeesEnrollments.length]
  );
  const isHealthAreaFieldDisabled = citizens.length === 0;
  const showCreateSaveButton = !isNotAcceptedByParticipant && (!isEditMode || (isEditMode && showSaveButton));
  const healthOptions = useMemo(() => {
    const options = Object.entries(HealthCareParentType).map(([value, label]) => ({ label: t(label), value }));
    return options.filter(item => item.value !== HealthCareParentType.None);
  }, []);

  const loadItemParticipants = async () => {
    if (!data.id || !data.groupId) return;

    const res = await getAppointmentParticipants(data);

    const { citizens: itemCitizens, employees: itemEmployees, participants } = res;

    setCitizens(itemCitizens);
    setEmployees(itemEmployees);
    setParticipants(participants as CalendarItemParticipant[]);
    setParticipantsCount(itemCitizens.length + itemEmployees.length);

    setValue('citizens', itemCitizens);
    clearError('citizens');
    setValue('employees', itemEmployees);
    clearError('employees');
  };

  const setAppointmentParticipants = () => {
    if (!isNew) {
      loadItemParticipants();
    } else {
      setCitizens(selectedCitizenEnrollments);
      setValue('citizens', selectedCitizenEnrollments);
      setEmployees(selectedEmployeeEnrollments);
      setValue('employees', selectedEmployeeEnrollments);
    }
  };

  useEffect(() => {
    if (!isOpened) return;

    if (data) {
      setFullDate(data.fromDate);

      setAppointmentParticipants();
    } else {
      onClose();
    }
  }, [data]);

  useEffect(() => {
    if (isOpened) {
      const newData = { ...data };

      if (defaultCitizenId && !citizens.length && isNew) {
        const updatedCitizens = [defaultCitizenId];
        setValue('citizens', updatedCitizens);
        clearError('citizens');
        setCitizens(updatedCitizens);
      }

      setLocalData(newData);
      setDefaultData(newData);
      setIsEdited(false);

      Object.keys(newData).forEach(key => {
        setValue(key, newData[key]);
        clearError(key);
      });
    }
  }, [isOpened, defaultCitizenId, data]);

  useEffect(() => {
    // const isUserParticipant = !!participants.find(item => item.id === currentUserId);
    // if (isPrivateItem && !isUserParticipant) {
    //   setLocalData({ ...localData, title: 'Privat aftale', note: 'Privat aftale' });
    // }
  }, [participants, isPrivateItem]);

  const closeWindow = () => {
    onClose();
    setCitizens([]);
    setEmployees([]);
    setParticipants([]);
    setValue('employees', []);
    setShowSaveButton(false);
  };

  const handleFieldChange = (value: Date | string | boolean | MomentInput, name: string) => {
    setLocalData({ ...localData, [name]: value });
    setShowSaveButton(true);
    setValue(name, value);
    clearError(name);

    if (defaultData[name] !== value) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  };

  const handleDescriptionInput = () => {
    if (!showSaveButton) {
      setShowSaveButton(true);
    }
  };

  const handleRepetitionTypeChange = (value: string) => {
    setRepetitionType(value);
    setShowSaveButton(true);
  };

  const handleFormSubmit = () => {
    onUpdateCalendarItem(localData, citizens, employees);
  };

  const handleFullDateChange = (value: Date) => {
    const startDay = moment(value).startOf('day').toDate();
    const endDay = moment(value).endOf('day').toDate();

    setFullDate(value);
    setIsEdited(true);
    setLocalData({ ...localData, fromDate: startDay, toDate: endDay });
    setValue('fromDate', startDay);
    setValue('toDate', endDay);
    clearError('fromDate');
    clearError('toDate');
  };

  const handleEmployeesChange = (value: string[]) => {
    setValue('employees', value);
    clearError('employees');
    setEmployees(value);
    setShowSaveButton(true);
    setIsEdited(true);

    if (value.length) {
      setLocalData({ ...localData, isPrivate: false });
    }
  };

  const handleCitizensChange = (value: string[]) => {
    setCitizens(value);
    setShowSaveButton(true);
    setIsEdited(true);

    if (value.length) {
      setLocalData({ ...localData, isPrivate: false } as CalendarItem);
    }
  };

  const handleClose = () => {
    if (!isEdited) {
      closeWindow();
      return;
    }

    dispatch(
      showConfirmModal({
        content: `Ønsker du at annullere? Ikke gemte oplysninger vil blive slettet`,
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          closeWindow();
        },
      })
    );
  };

  const handleAccept = () => {
    handleClose();
    dispatch(changeAppointmentStatus(data, CalendarStatus.Accepted));
  };

  const handleDecline = () => {
    dispatch(changeAppointmentStatus(data, CalendarStatus.Declined));
  };

  const handleStatusChange = (newStatus: CalendarStatus) => {
    dispatch(changeAppointmentStatus(data, newStatus));
  };

  const toggleDeleteWindow = () => {
    setShowDeleteWindow(prevState => !prevState);
  };

  const handleDelete = () => {
    if (participantsCount > 1) {
      toggleDeleteWindow();
    } else {
      dispatch(
        showConfirmModal({
          content: `Er du sikker på at du ønsker at slette denne aftale?`,
          okText: 'Ja',
          cancelText: 'Nej',
          onOk: () => {
            dispatch(deleteCalendarItem(data.id));
          },
        })
      );
    }
  };

  const deleteItem = () => {
    dispatch(deleteCalendarItem(data.id));
    toggleDeleteWindow();
  };

  const deleteALl = () => {
    dispatch(deleteParticipantsCalendarItems(data.groupId));
    toggleDeleteWindow();
  };

  function handleRemovePeriod(id: string) {
    const updatedSchedule = { ...schedule };

    delete updatedSchedule[id];

    setSchedule(updatedSchedule);
  }

  function handleScheduleUpdate(id, data) {
    setSchedule({ ...schedule, [id]: data });
  }
  function getSchedule() {
    const scheduleItems = Object.values(schedule);

    return scheduleItems.map((item, index) => {
      return (
        <Schedule
          itemIndex={index}
          data={item}
          onPeriodAdd={handleScheduleUpdate}
          onPeriodRemove={handleRemovePeriod}
          onPeriodUpdate={handleScheduleUpdate}
          startDate={new Date()}
        />
      );
    });
  }
  return (
    <>
      <CalendarItemDrawer
        title={windowTitle}
        isOpen={isOpened}
        hideFooter={locked}
        onClose={handleClose}
        buttonsLeft={
          <div className="cai-form__btns-left">
            {isNotAcceptedByParticipant && (
              <>
                <Button title="Accepter" onClick={handleAccept} />
                <Button title="Afvis" onClick={handleDecline} />
              </>
            )}
          </div>
        }
        buttonsRight={
          <div className="cai-form__btns-right">
            {showCreateSaveButton && (
              <div className={isEditMode ? 'cai-save-btn-wrapper' : ''}>
                <LoadingAnimation active={loading} />
                <Button title="Gem" type="primary" disabled={loading} onClick={handleSubmit(handleFormSubmit)} />
              </div>
            )}
            {isEditMode && <Button title="Slet aftale" onClick={handleDelete} />}
            <Button title={closeText} onClick={handleClose} />
          </div>
        }>
        <form className="cai-form">
          <TextInput
            title="Emne"
            name="title"
            value={title}
            error={errors.title && errors.title.message}
            disabled={isNotAcceptedByParticipant}
            onChange={handleFieldChange}
          />
          <TextEditor
            title="Beskrivelse"
            name="note"
            value={note}
            disabled={isNotAcceptedByParticipant}
            onChange={handleFieldChange}
            onInput={handleDescriptionInput}
          />
          {!allday && (
            <>
              <DatePicker
                title="Starttidspunkt"
                showTime
                name="fromDate"
                format={DATE_TIME_FORMAT}
                value={fromDate}
                disabled={isNotAcceptedByParticipant}
                onChange={handleFieldChange}
              />
              <DatePicker
                title="Sluttidspunkt"
                showTime
                name="toDate"
                format={DATE_TIME_FORMAT}
                value={toDate}
                disabled={isNotAcceptedByParticipant}
                onChange={handleFieldChange}
              />
              {isTimeTrack && (
                <div className="cai-form__tt-duration">
                  Varighed: {UtilsCalendar.getTimeTrackDifference(toDate, fromDate)}
                </div>
              )}
            </>
          )}
          {!isTimeTrack && (
            <>
              <Checkbox
                onChange={handleFieldChange}
                name="done"
                label="Udfør opgave"
                checked={done}
                disabled={!isEditMode}
              />
              <div className="cai-form__full-day">
                <Checkbox
                  onChange={handleFieldChange}
                  name="allday"
                  label="Hele dagen"
                  checked={allday}
                  disabled={isNotAcceptedByParticipant}
                />
                {allday && <DatePicker value={fullDate} title="Dato" titleLeft onChange={handleFullDateChange} />}
              </div>
              <Checkbox
                className="cai-form__private"
                onChange={handleFieldChange}
                name="isPrivate"
                label="Privat aftale"
                checked={isPrivate}
                disabled={privateIsDisabled || locked}
              />
              {/* <Checkbox
                className="cai-form__private"
                onChange={handleFieldChange}
                name="isRepetition"
                label="Gentagelse"
                checked={isRepetition}
                disabled={locked}
              />
              {isRepetition && getSchedule()} */}

              <SelectMenu
                title="Sundhedsfaglig aftale"
                value={health}
                name="health"
                valueKey="value"
                labelKey="label"
                allowClear
                multiple
                disabled={isHealthAreaFieldDisabled}
                options={healthOptions}
                onChange={handleFieldChange}
              />
              <SelectMenu
                title="Gentagelse"
                options={repetitionOptions}
                value={repetitionType}
                valueKey="value"
                labelKey="label"
                disabled={isNotAcceptedByParticipant}
                onChange={handleRepetitionTypeChange}
              />
            </>
          )}
          <SelectMenu
            value={citizens}
            multiple
            title="Tilknyt borger(e)"
            placeholder="Skriv for at søge"
            valueKey="id"
            withSearch
            labelKey={['firstname', 'lastname']}
            options={citizenEnrollmentsList}
            disabled={isNotAcceptedByParticipant}
            onChange={handleCitizensChange}
          />
          <SelectMenu
            value={employees}
            multiple
            title="Tilknyt medarbejder(e)"
            placeholder="Skriv for at søge"
            valueKey="id"
            withSearch
            labelKey={['firstname', 'lastname']}
            error={errors.employees && errors.employees.message}
            options={employeesEnrollmentsList}
            disabled={isNotAcceptedByParticipant}
            onChange={handleEmployeesChange}
          />
          <AppointmentParticipants items={participants} />
          {isParticipant && isAccepted && !existedCitizenEnrollmentId && (
            <AppointmentStatusChanger status={calendarStatus} onStatusSave={handleStatusChange} />
          )}
        </form>
      </CalendarItemDrawer>
      <AppointmentDeleteModal
        isVisible={showDeleteWindow}
        onCLose={toggleDeleteWindow}
        onDelete={deleteItem}
        onDeleteAll={deleteALl}
      />
    </>
  );
}
