import React, { useEffect, useState } from 'react';
import prodocApi, { CitizenEnrollmentResult } from '../../../services/prodocApi';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Button, Card, Col, Row, Table, Tooltip } from 'antd';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import {
  ContactsOutlined,
  LoginOutlined,
  LogoutOutlined,
  MedicineBoxOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import CitizenDischargeForm from '../../../components/citizen/CitizenDischargeForm/CitizenDischargeForm';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { ColumnType } from 'antd/es/table';
import { CitizenEnrollModal } from '../CitizenEnrollPage/CitizenEnrollModal';

export function CitizenOverviewPage() {
  //getManyByCenter
  const [citizens, setCitizens] = useState<CitizenEnrollmentResult[]>();
  const history = useHistory();
  const [isDischargeOpen, setIsDischargeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCitizenEnrollment, setSelectedCitizenEnrollment] = React.useState<CitizenEnrollmentResult>();
  const [isEnrollOpen, setIsEnrollOpen] = useState(false);

  usePageTitle('Borgere');

  useEffect(() => {
    GetEnrollments();
  }, []);
  async function GetEnrollments() {
    try {
      setIsLoading(true);
      const result = await prodocApi.citizenEnrollment.getMyCitizenEnrollments();
      setCitizens(result);
    } finally {
      setIsLoading(false);
    }
  }
  async function showDischargeCitizen(e, citizenEnrollment: CitizenEnrollmentResult) {
    e.stopPropagation();
    setSelectedCitizenEnrollment(citizenEnrollment);
    setIsDischargeOpen(true);
  }
  async function showHealthCitizen(e, citizenEnrollment: CitizenEnrollmentResult) {
    e.stopPropagation();
    history.push(ROUTES.MEDICINE_HEALTH.replace(':citizenEnrollmentId?', citizenEnrollment.id));
  }
  async function showGoalCitizen(e, citizenEnrollment: CitizenEnrollmentResult) {
    e.stopPropagation();
    history.push(ROUTES.GOALS.replace(':citizenEnrollmentId?', citizenEnrollment.id));
  }
  async function showExternalContactsCitizen(e, citizenEnrollment: CitizenEnrollmentResult) {
    e.stopPropagation();
    history.push(ROUTES.EXTERNALCONTACTS.replace(':citizenId', citizenEnrollment.citizenId));
  }

  const columns: ColumnType<CitizenEnrollmentResult>[] = [
    {
      title: 'Navn',
      render: o => o.firstname + ' ' + o.lastname,
      key: 'name',
      sorter: (a, b) => (a.firstname || '').localeCompare(b.firstname || ''),
    },
    {
      title: 'Afdeling',
      dataIndex: 'departmentName',
      key: 'departmentName',
      sorter: (a, b) => (a.departmentName || '').localeCompare(b.departmentName || ''),
    },
    {
      title: 'Center',
      dataIndex: 'centerName',
      key: 'centerName',
      sorter: (a, b) => (a.centerName || '').localeCompare(b.centerName || ''),
    },
    {
      title: 'Oprettet',
      dataIndex: 'createdOn',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : ''),
      sorter: (a, b) => Date.parse(a.createdOn.toString()) - Date.parse(b.createdOn.toString()),
    },
    {
      title: '',
      align: 'right',
      render: (value, row: CitizenEnrollmentResult) => {
        return (
          <>
            <Tooltip title="Eksterne kontakter">
              <Button
                onClick={e => showExternalContactsCitizen(e, row)}
                icon={<ContactsOutlined translate={undefined} />}
              />
            </Tooltip>
            &nbsp;
            <Tooltip title="Mål">
              <Button onClick={e => showGoalCitizen(e, row)} icon={<FontAwesomeIcon icon={faBullseye} />} />
            </Tooltip>
            &nbsp;
            <Tooltip title="Helbred og medicin">
              <Button onClick={e => showHealthCitizen(e, row)} icon={<MedicineBoxOutlined translate={undefined} />} />
            </Tooltip>
            &nbsp;
            <Tooltip title="Indskriv borger">
              <Button icon={<LoginOutlined translate={undefined} />} onClick={onEnrollCitizen} />
            </Tooltip>
            <Tooltip title={`Udskriv borger fra ${row.departmentName}`}>
              <Button onClick={e => showDischargeCitizen(e, row)} icon={<LogoutOutlined translate={undefined} />} />
            </Tooltip>
          </>
        );
      },
    },
  ];
  function handleRowClicked(e) {
    history.push(ROUTES.CITIZEN_DETAILS.replace(':id?', e.id).replace(':overview?', ''));
  }
  function handleCreateCitizen() {
    history.push(ROUTES.CITIZEN_ADD);
  }
  async function handleDischarge(date: Date, cause: string, citizenEnrollmentId: string) {
    await prodocApi.citizenEnrollment.discharge(citizenEnrollmentId, date, cause);
    GetEnrollments();
  }
  function onEnrollCitizen(e): void {
    e.stopPropagation();
    setIsEnrollOpen(true);
  }

  return (
    <div className="citizen-page page-full">
      <Row gutter={6}>
        <Col span={24}>
          <div style={{ background: '#fafafa', padding: '8px' }}>
            <Tooltip title="Indskriv borger">
              <Button shape="circle" icon={<PlusOutlined translate="" />} onClick={handleCreateCitizen} />
            </Tooltip>
          </div>
          <Table
            style={{ cursor: 'pointer' }}
            loading={isLoading}
            pagination={{ pageSize: 15 }}
            dataSource={citizens}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => handleRowClicked(record),
              };
            }}
            columns={columns}
            rowKey="id"
          />
        </Col>
        {/* <Col span={1}>
          <Card bodyStyle={{ padding: 16 }}>
            <Tooltip title="Eksterne kontakter">
              <Button shape="circle" size="large" icon={<ContactsOutlined translate="" />} />
            </Tooltip>
            <Tooltip title="Eksterne kontakter">
              <Button shape="circle" size="large" icon={<ContactsOutlined translate="" />} />
            </Tooltip>
          </Card>
        </Col> */}
      </Row>

      <CitizenDischargeForm
        citizenEnrollment={selectedCitizenEnrollment}
        isDischargeOpen={isDischargeOpen}
        setIsDischargeOpen={setIsDischargeOpen}
        onDischarge={handleDischarge}
      />
      <CitizenEnrollModal
        isOpen={isEnrollOpen}
        onCancel={() => setIsEnrollOpen(false)}
        citizenEnrollmentId={selectedCitizenEnrollment?.id}
      />
    </div>
  );
}
