import { RefObject, useContext, useEffect, useState } from 'react';
import postalCities from '../data/postal-cities.json';
import PageTitleContext from '../context/PageTitleContext';
import { useTranslation } from 'react-i18next';
import prodocApi, { Tag } from '../services/prodocApi';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { CalendarState } from '../store/calendarStore';

export function usePageTitle(title: string, subTitle?: string) {
  const { setTitle, setSubTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setTitle(title);
    document.title = 'ProDoc - ' + title;
  }, [title]);
  useEffect(() => {
    setSubTitle(subTitle);
    document.title = 'ProDoc - ' + title;
  }, [subTitle]);
}

export function useZipCodeField() {
  const getZipCodeData = (value: string, fieldName: string, data: any) => {
    const cityName = postalCities[value];

    if (fieldName === 'zipCode' && value.length >= 4 && cityName) {
      return { ...data, city: cityName };
    }

    return data;
  };

  return { getZipCodeData };
}
export function useZipCode() {
  const getCityName = (zipCode: string) => {
    return postalCities[zipCode];
  };

  return { getCityName };
}
export function useEnumOptionsTranslation() {
  const { t } = useTranslation();

  const translateEnumOptions = (optionsEnum: any): { label: string; value: string }[] => {
    return Object.entries(optionsEnum)
      .map(([value, label]) => ({ label: t(label as string), value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  return { translateEnumOptions };
}

export function useTagsSearch() {
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const [tags, setTags] = useState<Tag[]>([]);
  const { departmentId } = calendarState;

  const searchTags = async (searchStr: string) => {
    try {
      const res = await prodocApi.tag.search(searchStr, departmentId);

      setTags(res);
    } catch (e) {}
  };

  return { searchTags, tags };
}

export function useClickOutside(ref: RefObject<HTMLElement>, callback: (target: HTMLElement) => void) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event.target);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
