export enum CalendarViewTypes {
  Day = 'timeGridDay',
  Week = 'timeGridWeek',
  Month = 'dayGridMonth',
  AgendaDay = 'listDay',
  AgendaWeek = 'listWeek',
  AgendaMonth = 'listMonth',
  Notes = 'notes',
  Medicine = 'medicine',
  MedicineAgenda = 'medicineAgenda',
  Calendar = 'calendar',
}

export enum NoteTypes {
  Citizen = 0,
  Department = 1,
}

export const AGENDA_VIEWS_RANGE_MAP = {
  [CalendarViewTypes.Day]: CalendarViewTypes.AgendaDay,
  [CalendarViewTypes.Week]: CalendarViewTypes.AgendaWeek,
  [CalendarViewTypes.Month]: CalendarViewTypes.AgendaMonth,
};

export const CALENDAR_MEDICINE_BORDER_COLORS = {
  EXPIRED: '#cc0000',
  TAKEN: '#009900',
  NOT_TAKEN: '#ffcc66',
};

export enum SCHEMA_FILES_NAMES {
  SCHEMA1A = 'Schema1A',
  SCHEMA1B = 'Schema1B',
  SCHEMA2 = 'Schema2',
  SCHEMA3 = 'Schema3',
  SCHEMAA = 'SchemaA',
  SCHEMAB = 'SchemaB',
  SCHEMAC = 'SchemaC',
  BILAGAB = 'BilagSchema1AAnd1B',
  BILAG1A = 'Bilag1A',
}

export enum CalendarItemsFilterValues {
  Appointments = '1',
  Notes = '2',
  Medicine = '3',
  UseOfForce = '4',
  Goals = '5',
  Health = '6',
  TimeTrack = '7',
  Measurements = '8',
  Postings = '9',
}
