import { Modal, ModalFuncProps } from 'antd';
import './Modal.scss';

export function showConfirmModal(options: ModalFuncProps) {
  return () => {
    Modal.confirm({
      ...options,
    });
  };
}
