import * as React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import ControlError from '../ControlError/ControlError';
import { Dayjs } from 'dayjs';

import './TimePicker.scss';

interface Props {
  value?: Date | string;
  name?: string;
  title?: string;
  error?: string;
  titleLeft?: boolean;
  disabled?: boolean;
  onChange(value: Date, name?: string): void;
}

export default function TimePicker(props: Props) {
  const { name, title, error, titleLeft, value, disabled, onChange } = props;
  const parsedValue: any = value ? moment(new Date(value), 'HH:mm:ss') : undefined;

  const wrapperClass = classNames('time-picker', {
    'time-picker--error': error,
    'time-picker--title-left': titleLeft,
  });

  const handleChange = (value: Dayjs) => {
    const newValue = value ? value.toDate() : undefined;

    onChange(newValue, name);
  };

  return (
    <div className={wrapperClass}>
      {title && <div className="time-picker__title">{title}</div>}
      <AntTimePicker
        inputReadOnly
        disabled={disabled}
        value={parsedValue}
        onChange={handleChange}
        allowClear={false}
        format="HH:mm"
        showSecond={false}
        // getPopupContainer={trigger => trigger.parentElement}
      />
      <ControlError text={error} />
    </div>
  );
}
