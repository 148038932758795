export const DAWA_API = 'https://dawa.aws.dk';
export const DOCUMENT_DOWNLOAD_URL = 'https://api-test-prodoc.azurewebsites.net/api/useofforce/downloadPdf';

export enum EntityTypes {
  center = 'center',
  department = 'department',
  citizen = 'citizen',
  employee = 'employee',
}

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
