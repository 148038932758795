import moment from 'moment';
import { ResourceCalendarItemType } from '../../services/prodocApi';
import { ILocalData } from './ResourceCalendarStore';

export const colors: { [key: string]: string } = {
  Administrative: '#bdbdbd',
  Relaxation: '#d81b60',
  TheChildFirstSickDay: '#fb8c00',
  TheChildSecondSickDay: '#f4511e',
  PaidTimeOff: '#616161',
  Vacation: '#0d47a1',
  Vacation6: '#FF4081',
  Course: '#fdd835',
  StandardHours: '#43a047',
  CaringDay: '#8e24aa',
  LeaveOfAbsence: '#6d4c41',
  Overtime: '#1b5e20',
  SeniorLessons: '#4fc3f7',
  Sick: '#e53935',
  ProtectedDayOff: '#E81DB2',
};
const regexp = RegExp('^[0-2][0-9]:[0-5][0-9]$');

// export type IData = Omit<ILocalData, 'from' | 'to'>;
// {
// }
// export interface ResourceCalendarItemDto extends ResourceCalendarItem {
//   from: string;
//   to: string;
//   fromDate: string;
//   toDate: string;
// }
export function getTotalHours(employeeId: string, data: ILocalData[], selectedDates: number[]) {
  //eid
  let totalMinutes = 0;

  var max = Math.max(...selectedDates);
  var min = Math.min(...selectedDates);
  let maxDate = moment(moment(max).format('yyyy-MM-DD'));
  let minDate = moment(moment(min).format('yyyy-MM-DD'));
  const calculateMinutesPrDay = (minutesTotal: number, id: string) => {
    return (minutesTotal / data.filter(x => x.id === id).length) | 0;
  };

  data
    .filter(x => x.employeeId === employeeId)
    .forEach(x => {
      if (moment(x.fromDate) >= minDate && moment(x.toDate) <= maxDate) {
        if (x.overrideHours) {
          // const split = x.overrideHours.split(':');
          const minutesTotal = Number(x.overrideHours) * 60;
          totalMinutes += calculateMinutesPrDay(minutesTotal, x.id);
        } else {
          totalMinutes += moment(x.toDate).diff(moment(x.fromDate), 'minutes');
        }
      }
    });
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours} timer og ${minutes} min`;
}
export function timeCorrect(localData: ILocalData, selectedDate: any) {
  var from = moment(moment(localData.fromDate).format('yyyy-MM-DD') + ' ' + localData.from);
  var to = moment(moment(localData.toDate).format('yyyy-MM-DD') + ' ' + localData.to);
  return (
    regexp.test(localData.from) &&
    regexp.test(localData.to) &&
    moment(moment(selectedDate).format('yyyy-MM-DD') + ' ' + localData.from).isValid() &&
    moment(moment(selectedDate).format('yyyy-MM-DD') + ' ' + localData.to).isValid() &&
    from < to
  );
}

export function correctTime(time: string) {
  if (time.length === 1) {
    return `0${time}:00`;
  } else if (time.length === 2) {
    return `${time}:00`;
  } else if (time.length === 4) {
    return `${time}:0`;
  }
  return time;
}

export function checkEqualsDates(date1: string, date2: Date) {
  return moment(date1).format('DD-MM-yyyy') == moment(date2).format('DD-MM-yyyy');
}
export function generateFullDateArray(data: ILocalData[]): ILocalData[] {
  let extra: ILocalData[] = [];
  let d = data.filter(x => {
    const diff = moment(x.toDate).diff(x.fromDate, 'days');
    if (diff > 0) {
      for (let i = 0; i < diff + 1; i++) {
        if (i == 0) {
          extra.push({
            ...x,
            fromDate: moment(x.fromDate).toISOString(),
            toDate: moment(x.fromDate).hour(0).minute(0).toISOString(),
          });
        } else if (diff === i) {
          extra.push({
            ...x,
            fromDate: moment(x.toDate).hour(0).minute(0).toISOString(),
            toDate: moment(x.toDate).add(1, 'day').toISOString(),
          });
        } else {
          extra.push({
            ...x,
            fromDate: moment(x.fromDate).add(i, 'day').hour(0).minute(0).toISOString(),
            toDate: moment(x.fromDate)
              .add(i + 1, 'day')
              .hour(0)
              .minute(0)
              .toISOString(),
          });
        }
      }
      return false;
    }
    return true;
  });
  return [...d, ...extra];
}
export function order<T>(xs: T[], key): T[] {
  const grouped = xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
  let arr = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const property in grouped) {
    arr.push(grouped[property]);
  }
  arr = arr.sort((a, b) => b.length - a.length);
  const result = [];
  arr.forEach(element => {
    element.forEach(element2 => {
      result.push(element2);
    });
  });
  return result as T[];
}
