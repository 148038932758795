import * as React from 'react';
import { DAWA_API } from '../constants/common';
import prodocApi from '../services/prodocApi';
import { useState } from 'react';
import { CitizenDepartmentOption } from '../types/common';
import { UtilsData } from '../utils/UtilsData';

export function useCitizenEnrollAutocomplete() {
  const [data, setData] = React.useState<any>({});

  const search = async (value: string, name: string) => {
    const url = `${DAWA_API}/kommuner?autocomplete=true&q=${value}`;

    try {
      const res = await fetch(url);
      const resData = await res.json();

      setData({
        ...data,
        [name]: resData,
      });
    } catch (e) {}
  };

  return [data, search];
}

export function useEmployeeDepartmentsRequest() {
  const loadDepartment = async (employeeId: string) => {
    try {
      const enrollments = (await prodocApi.employeeEnrollment.getByEmployee(employeeId, true)) || [];

      if (!enrollments.length || enrollments.length > 1) {
        return null;
      }

      return enrollments[0].departmentId;
    } catch (e) {}
  };

  return { loadDepartment };
}

export function useCitizenDepartmentsRequest() {
  const loadDepartment = async (citizenId: string) => {
    try {
      const enrollments = (await prodocApi.citizenEnrollment.getByCitizen(citizenId, true)) || [];

      if (!enrollments.length || enrollments.length > 1) {
        return null;
      }

      return enrollments[0].departmentId;
    } catch (e) {}
  };

  return { loadDepartment };
}

export function useCitizenDepartmentSearch(useCitizenEnrollment?: boolean) {
  const [items, setItems] = useState<CitizenDepartmentOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const search = async (searchStr: string) => {
    try {
      setIsLoading(true);

      const res = await prodocApi.departmentCitizenEnrollment.search(searchStr);
      const convertedRes = UtilsData.convertCitizenDepartmentResToOption(res, !!useCitizenEnrollment);

      setItems(convertedRes);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return { items, search, isLoading };
}
