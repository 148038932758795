import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import {
  addCalendarItem,
  CalendarState,
  clearCalendarItemsData,
  createPnCalendarItem,
  evaluatePnCalendarItem,
  reloadCalendarItemsForCitizen,
  setSelectedCalendarItemAction,
  updateCalendarItem,
} from '../../store/calendarStore';
import prodocApi, {
  CalendarItem,
  CalendarItemType,
  Employee,
  MeasurementType,
  Medicine,
  Note,
} from '../../services/prodocApi';
import { getSelectedDateSpan, setSelectedDateSpan } from '../../services/localStorageService';
import AddAppointmentWindow from '../../components/Calendar/AddAppointmentWindow/AddAppointmentWindow';
import { Card } from '../../components/_shared/Card/Card';
import { ApplicationState } from '../../store';
import Calendar from '../../components/Calendar/Calendar';
import { showConfirmModal } from '../../components/_shared/Modal/ModalFunctions';
import { PagesState } from '../../store/pagesStore';
import CalendarNoteWindow from '../../components/Calendar/CalendarNoteWindow/CalendarNoteWindow';
import { calendarItemDefault } from '../../services/defaults';
import { loadUserEnrolledDepartments } from '../../store/departmentStore';
import { DateSelectArg } from '@fullcalendar/common';
import MeasurementWindow from '../../components/measurement/MeasurementWindow/MeasurementWindow';
import { CalendarPnWindow } from '../../components/Calendar/CalendarPnWindow/CalendarPnWindow';
import { CalendarMedicineWindow } from '../../components/Calendar/CalendarMedicineWindow/CalendarMedicineWindow';
import { CalendarPnEvaluationWindow } from '../../components/Calendar/CalendarPnEvaluationWindow/CalendarPnEvaluationWindow';
import { createNote, editNote } from '../../store/notesStore';
import { MeasurementState, setMeasurementWindowStateAction } from '../../store/measurementStore';
import { CommonState, setSidebarHoverStateAction } from '../../store/commonStore';

import './Home.scss';
import CalendarPostingWindow from '../../components/Calendar/CalendarPostingWindow/CalendarPostingWindow';

export const Home = () => {
  const dispatch = useDispatch();
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const userStore = useSelector<ApplicationState, Employee>(state => state.user);
  const measurementsStore = useSelector<ApplicationState, MeasurementState>(state => state.measurement);
  const { showSidebarHover } = useSelector<ApplicationState, CommonState>(state => state.common);

  const { calendarItems, selectedCalendarItem, selectedCitizenEnrollments } = calendarStore;
  const { calendarItem } = pagesStore;
  const { id: userId } = userStore;
  const { entityObjectType } = selectedCalendarItem || calendarItemDefault();
  const [selectedOptions] = React.useState(getSelectedDateSpan());
  const [selectedContextMenuId, setSelectedContextMenuId] = useState('');
  const preselectedCitizenEnrollment = selectedCitizenEnrollments.length === 1 ? selectedCitizenEnrollments[0] : '';
  const isMedicineItemActive =
    selectedCalendarItem?.calendarItemType === CalendarItemType.Medicine && !selectedCalendarItem?.entityId;
  const isPnEvaluationActive =
    selectedCalendarItem?.calendarItemType === CalendarItemType.PN &&
    selectedCalendarItem?.id !== '' &&
    (!selectedCalendarItem?.pnEvaluated || !!selectedCalendarItem?.note);
  const measurementTypeName = entityObjectType ? entityObjectType.split('.')[1] : '';
  const history = useHistory();

  useEffect(() => {
    setSelectedDateSpan(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (calendarItem.saved) {
      setSelectedContextMenuId('');
    }
  }, [calendarItem.saved]);

  useEffect(() => {
    if (userId) {
      dispatch(loadUserEnrolledDepartments());
    }

    return () => {
      dispatch(clearCalendarItemsData());
    };
  }, [userId]);

  const handleCalendarItemCreate = (itemId: CalendarItemType, calendarEvent: DateSelectArg) => {
    const selectedItem: CalendarItem = {
      ...calendarItemDefault(),
      fromDate: calendarEvent.start,
      toDate: calendarEvent.end,
      allday: calendarEvent.allDay,
      calendarItemType: itemId,
      groupId: '00000000-0000-0000-0000-000000000000',
    };

    setSelectedContextMenuId(itemId);
    dispatch(setSelectedCalendarItemAction({ item: selectedItem }));
  };

  const handleDrawerClose = () => {
    setSelectedContextMenuId('');
    dispatch(setSelectedCalendarItemAction({ item: null }));
  };

  const clearSelectedItem = () => {
    dispatch(setSelectedCalendarItemAction({ item: null }));
  };

  const isCalendarItemsCollapsed = (item: CalendarItem): string => {
    let itemWithCollapsing = '';
    const itemStart = moment(item.fromDate);
    const itemEnd = moment(item.toDate);

    calendarItems.forEach(existingItem => {
      const existingItemStart = moment(existingItem.fromDate);
      const existingItemEnd = moment(existingItem.toDate);

      if (
        existingItemStart.isBetween(itemStart, itemEnd) ||
        existingItemEnd.isBetween(itemStart, itemEnd) ||
        itemStart.isBetween(existingItemStart, existingItemEnd) ||
        itemEnd.isBetween(existingItemStart, existingItemEnd)
      ) {
        itemWithCollapsing = existingItem.title;
      }
    });

    return itemWithCollapsing;
  };

  const handleCalendarItemSave = (data: CalendarItem, citizensIds: string[], employeesIds: string[]) => {
    const collapsedItemTitle = isCalendarItemsCollapsed(data);

    if (collapsedItemTitle && !data.id) {
      dispatch(
        showConfirmModal({
          content: `Aftalen er i konflikt med en anden aftale hos ${collapsedItemTitle}. Vil du fortsætte?`,
          okText: 'Ja',
          cancelText: 'Nej',
          onOk: () => {
            dispatch(addCalendarItem(data, citizensIds, employeesIds));
          },
        })
      );
    } else if (!collapsedItemTitle && !data.id) {
      dispatch(addCalendarItem(data, citizensIds, employeesIds));
    } else {
      dispatch(updateCalendarItem(data, citizensIds, employeesIds));
    }
  };

  const handleSlotClick = async (item: CalendarItem) => {
    switch (item.calendarItemType) {
      case CalendarItemType.BirthDay:
        return;
      case CalendarItemType.Measurement:
        dispatch(setMeasurementWindowStateAction(true));
        dispatch(setSelectedCalendarItemAction({ item }));
        break;
      case CalendarItemType.UseOfForce:
        const doc = await prodocApi.useOfForce.get(item.entityId);
        history.push(`/useofforce?doc=${doc.documentName}&v=${doc.version}&id=${doc.id}`);
        break;
      default:
        setSelectedContextMenuId(item.calendarItemType);
        dispatch(setSelectedCalendarItemAction({ item }));
    }
  };

  const handleNotesCreate = (data: Note) => {
    if (data.id) {
      dispatch(editNote(data));
    } else {
      dispatch(createNote(data));
    }
  };
  const handleUpdateItems = () => {
    if (preselectedCitizenEnrollment) {
      dispatch(reloadCalendarItemsForCitizen(preselectedCitizenEnrollment));
    }
  };
  const handlePnCreate = (data: Medicine) => {
    dispatch(createPnCalendarItem(data));
  };

  const handlePnEvaluate = (data: Medicine) => {
    dispatch(evaluatePnCalendarItem(data));
  };

  const handleSidebarTriggerEnter = () => {
    dispatch(setSidebarHoverStateAction(true));
  };

  return (
    <>
      <Card className="home-card">
        {!showSidebarHover && <div className="home-card__left-edge" onMouseEnter={handleSidebarTriggerEnter} />}
        <div className="home-content">
          <Calendar
            data={calendarItems}
            onCalendarItemCreate={handleCalendarItemCreate}
            onEventClick={handleSlotClick}
          />
        </div>
        <AddAppointmentWindow
          onClose={handleDrawerClose}
          isOpened={
            selectedContextMenuId === CalendarItemType.Normal || selectedContextMenuId === CalendarItemType.TimeTrack
          }
          data={selectedCalendarItem}
          defaultCitizenId=""
          onUpdateCalendarItem={handleCalendarItemSave}
        />
        <CalendarNoteWindow
          data={selectedCalendarItem}
          isOpened={selectedContextMenuId === CalendarItemType.Note}
          preselectedEnrollmentId={preselectedCitizenEnrollment}
          onClose={handleDrawerClose}
          onUpdateCalendarItem={handleNotesCreate}
        />

        {selectedContextMenuId === CalendarItemType.Posting && (
          <CalendarPostingWindow
            data={selectedCalendarItem}
            isOpened={selectedContextMenuId === CalendarItemType.Posting}
            preselectedEnrollmentId={preselectedCitizenEnrollment}
            onClose={handleDrawerClose}
            onUpdateCalendarItem={handleUpdateItems}
          />
        )}
        {selectedContextMenuId === CalendarItemType.PN && !selectedCalendarItem?.id && (
          <CalendarPnWindow
            data={selectedCalendarItem}
            isOpened={selectedContextMenuId === CalendarItemType.PN && !selectedCalendarItem?.id}
            preselectedEnrollmentId={preselectedCitizenEnrollment}
            onClose={handleDrawerClose}
            onUpdateItem={handlePnCreate}
          />
        )}
        <CalendarPnEvaluationWindow
          data={selectedCalendarItem}
          isOpened={isPnEvaluationActive}
          onClose={clearSelectedItem}
          onUpdateCalendarItem={handlePnEvaluate}
        />
        <MeasurementWindow
          preselectedId={selectedCalendarItem?.entityId}
          calendarItem={selectedCalendarItem}
          preselectedEnrollmentId={preselectedCitizenEnrollment}
          preselectedType={measurementsStore.preselectedType}
          onClose={clearSelectedItem}
        />
        <CalendarMedicineWindow
          isOpened={isMedicineItemActive}
          data={selectedCalendarItem}
          onClose={clearSelectedItem}
        />
      </Card>
    </>
  );
};

export default Home;
