import * as React from 'react';
import { useDispatch } from 'react-redux';
import Icon, { ICONS } from '../../../_shared/Icon/Icon';
import { navigateTo } from '../../../../store/navigationStore';
import { UtilsNavigation } from '../../../../utils/UtilsNavigation';
import { URLS } from '../../../../constants/urls';
import { EntityTypes } from '../../../../constants/common';
import { useUserRole } from '../../../../hooks/user-hooks';
import employeeIcon from '../../../../assets/images/png/employee_plus.png';
import { setCitizenInitialDepartmentId } from '../../../../store/citizenStore';

import './OverviewItemsTreeTitle.scss';

interface Props {
  title: string;
  type: string;
  itemId: string;
  data: any;
}

export const OverviewItemsTreeTitle = (props: Props) => {
  const dispatch = useDispatch();
  const { isEmployee } = useUserRole();
  const { title, type, itemId, data } = props;
  const hideEditButton = isEmployee && (type === EntityTypes.center || type === EntityTypes.department);

  const handleEditClick = (e) => {
    e.preventDefault();

    const url = type === EntityTypes.department
      ? UtilsNavigation.buildRoute(type, URLS.DETAILS, itemId, data.centerId, URLS.OVERVIEW)
      : UtilsNavigation.buildRoute(type, URLS.DETAILS, itemId, URLS.OVERVIEW);

    if (type === EntityTypes.citizen) {
      dispatch(setCitizenInitialDepartmentId(data.departmentId));
    }

    dispatch(navigateTo(url));
  };

  const renderIcon = () => {
    switch (type) {
      case EntityTypes.center: {
        return <Icon name={ICONS.bank} />;
      }
      case EntityTypes.department: {
        return <Icon name={ICONS.home} />;
      }
      case EntityTypes.employee: {
        return <img className="icon" src={employeeIcon} alt="" />;
      }
      case EntityTypes.citizen: {
        return <Icon name={ICONS.user} />;
      }
    }
  };

  return (
    <div className="ot-title">
      {renderIcon()}
      <span>{title}</span>
      {!hideEditButton && (
        <button type="button" onMouseDown={handleEditClick}>
          <Icon name={ICONS.edit} />
        </button>
      )}
    </div>
  );
};
