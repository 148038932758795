import * as React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import classNames from 'classnames';
import { Moment, MomentInput } from 'moment';
import ControlError from '../ControlError/ControlError';
import { DATE_FORMAT } from '../../../../constants/common';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import './DatePicker.scss';

interface Props {
  value?: Date | string | MomentInput;
  name?: string;
  title?: string;
  error?: string;
  showTime?: boolean;
  format?: string;
  titleLeft?: boolean;
  disabled?: boolean;
  placeholder?: string;
  allowClear?: boolean;
  minDate?: MomentInput | Date;
  maxDate?: MomentInput | Date;
  onChange(value: MomentInput | Date, name?: string): void;
}

export default function DatePicker(props: Props) {
  const {
    name,
    title,
    error,
    format = DATE_FORMAT,
    titleLeft,
    showTime,
    value,
    disabled,
    placeholder,
    allowClear,
    onChange,
    minDate,
    maxDate,
  } = props;
  const disabledDate = (date: Dayjs) => {
    if (minDate) return date?.isBefore(minDate.toString()) || date?.isSame(minDate.toString());

    if (maxDate) return date?.isAfter(maxDate.toString()) || date?.isSame(maxDate.toString());

    return false;
  };

  const wrapperClass = classNames('date-picker', {
    'date-picker--error': error,
    'date-picker--title-left': titleLeft,
  });

  const handleChange = (value: Dayjs) => {
    const newValue = value ? value.toDate() : undefined;

    onChange(newValue, name);
  };
  return (
    <div className={wrapperClass}>
      {title && <div className="date-picker__title">{title}</div>}
      <AntDatePicker
        inputReadOnly
        disabled={disabled}
        format={format}
        value={value ? dayjs(value?.toString()) : null}
        showTime={showTime}
        onChange={handleChange}
        allowClear={allowClear}
        placeholder={placeholder}
        disabledDate={disabledDate}
        getPopupContainer={trigger => trigger.parentElement}
      />
      <ControlError text={error} />
    </div>
  );
}
