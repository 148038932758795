import { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import prodocApi, {
  CalendarItem,
  CalendarItemType,
  HealthCare,
  HealthCareType,
  HealthCareParentType,
  HealthCareProblem,
  Medicine,
  MedicineType,
  Note,
} from '../services/prodocApi';
import { PAGES_ITEMS, setPageItemLoading, setPageItemSaved } from '../store/pagesStore';
import { ApplicationState } from '../store';
import { showSuccessMessage } from '../store/notificationStore';
import { loadHealthCareItems, MedicineState } from '../store/medicineStore';
import { calendarItemDefault, noteDefault } from '../services/defaults';
import { CalendarState } from '../store/calendarStore';
import { CommonState } from '../store/commonStore';

export function useMedicineCalendarItemsCreation() {
  const { t } = useTranslation();
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const { citizensEnrollments } = calendarState;
  const { userEnrollments } = commonState;

  const createCalendarItems = async (data: HealthCare, prevStatus: HealthCareProblem, noteOnly?: boolean) => {
    try {
      const { citizenEnrollmentId, care } = data;
      const enrollment = citizensEnrollments.find(item => item.id === citizenEnrollmentId);
      const areaNameEnumItem = Object.entries(HealthCareType).find(([label, value]) => value === care);
      const areaName = t(areaNameEnumItem[1]);
      const noteContent = `${areaName} ændret fra ${t(prevStatus)} til ${t(data.problem)}`;
      const title = `${areaName} ændret`;
      const { departmentId } = enrollment;
      const employeeEnrollment = userEnrollments.find(item => item.departmentId === departmentId);
      const parentArea = care.split('_')[0] as HealthCareParentType;
      const { employeeId, id: employeeEnrollmentId } = employeeEnrollment;

      const noteItem: Note = {
        ...noteDefault(),
        departmentId: '',
        citizenEnrollmentId,
        citizenId: enrollment.citizenId,
        fromDate: new Date(),
        toDate: new Date(),
        text: noteContent,
      };

      const appointmentItem: CalendarItem = {
        ...calendarItemDefault(),
        departmentId,
        employeeId,
        employeeEnrollmentId,
        calendarItemType: CalendarItemType.Normal,
        citizenEnrollmentId,
        fromDate: new Date(),
        toDate: new Date(),
        note: noteContent,
        healthAppointment: true,
        title,
        isAccepted: true,
        health: [parentArea],
      };

      if (!noteOnly) await prodocApi.calendarItem.add([appointmentItem]);
      await prodocApi.note.add(noteItem);
    } catch (e) {}
  };

  return { createCalendarItems };
}

export function useHealthCareItemCreation() {
  const dispatch = useDispatch();
  const medicineState = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const { activeCitizen } = medicineState;

  const createHealthCareItem = async (data: HealthCare) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.MEDICINE_STATUS, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.MEDICINE_STATUS, true));

      const requestData: HealthCare = { ...data, citizenEnrollmentId: activeCitizen };

      await prodocApi.healthCare.add(requestData);

      dispatch(loadHealthCareItems(activeCitizen));
      dispatch(showSuccessMessage({ message: 'Område opdateret' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.MEDICINE_STATUS, true));
    } catch {
      dispatch(setPageItemSaved(PAGES_ITEMS.MEDICINE_STATUS, false));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.MEDICINE_STATUS, false));
    }
  };

  return { createHealthCareItem };
}

export function useHealthCareAreCalendarItemsRequest() {
  const [areaAppointments, setAreaAppointments] = useState<CalendarItem[]>([]);
  const medicineState = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const { activeCitizen } = medicineState;

  const getCalendarItemsByArea = async (value: HealthCareType) => {
    if (activeCitizen) {
      const parentAreaValue = value.split('_')[0];
      const items = await prodocApi.calendarItem.getFromHealthCare(activeCitizen, parentAreaValue);
      const sortedItems = items.sort((a, b) => {
        if (moment(a.fromDate).isAfter(moment(b.fromDate))) return -1;
        if (moment(a.fromDate).isBefore(moment(b.fromDate))) return 1;

        return 0;
      });

      setAreaAppointments(sortedItems);
    }
  };

  return { getCalendarItemsByArea, areaAppointments };
}

export function useHealthCareAreaStatusChange() {
  const dispatch = useDispatch();
  const medicineState = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const { healthCareAreasItems, activeCitizen } = medicineState;

  const changeStatusForMultipleItems = async (items: HealthCare[], data: HealthCare) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.MEDICINE_STATUS, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.MEDICINE_STATUS, true));

      let prevStatus = null;

      const updatedItems = items.map(item => {
        const { care, problem } = item;

        if (!prevStatus) prevStatus = problem;

        return { ...data, care, citizenEnrollmentId: activeCitizen };
      });

      for (const item of updatedItems) {
        await prodocApi.healthCare.add(item);
      }

      dispatch(loadHealthCareItems(activeCitizen));
      dispatch(showSuccessMessage({ message: 'Område opdateret' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.MEDICINE_STATUS, true));
    } catch (e) {
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.MEDICINE_STATUS, false));
    }
  };

  const changeAreaAllItemStatus = async (data: HealthCare) => {
    const { care } = data;
    const allItemsWithParentStatus = healthCareAreasItems.filter(item => item.care.indexOf(care) === 0);

    await changeStatusForMultipleItems(allItemsWithParentStatus, data);
  };

  const changeAreaCurrentItemStatus = async (data: HealthCare, oldStatus: HealthCareProblem, contentOnly: boolean) => {
    const { care } = data;
    const currentItemsWithParentStatus = healthCareAreasItems.filter(item => {
      return item.care.indexOf(care) === 0 && item.problem === oldStatus;
    });
    const newData: HealthCare = contentOnly ? { ...data, problem: oldStatus } : data;

    await changeStatusForMultipleItems(currentItemsWithParentStatus, newData);
  };

  return { changeAreaAllItemStatus, changeAreaCurrentItemStatus };
}

export function useMedicineSearch() {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const clearResults = () => {
    setResults([]);
  };

  const searchMedicine = async searchStr => {
    try {
      if (!searchStr) {
        setResults([]);
        return;
      }

      setIsLoading(true);

      const res = await prodocApi.medicine.search(searchStr);

      setResults(res);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return { searchMedicine, clearResults, results, isLoading };
}

export function useCitizenMedicineRequest() {
  const getCitizenMedicine = async (citizenId: string, pnOnly?: boolean): Promise<Medicine[]> => {
    try {
      const res = await prodocApi.medicine.getManyByCitizenWithoutCalendarItem(citizenId);

      return res.filter(item => {
        const isItemExpired = moment(item.toDate).isSameOrBefore(moment());
        const isPn = pnOnly ? item.medicineType.indexOf(MedicineType.PN) >= 0 : true;

        return !isItemExpired && isPn;
      });
    } catch (e) {
      return [];
    }
  };

  return { getCitizenMedicine };
}

export function usePn24HoursDosesCount() {
  const get24hoursDosesCount = async (productId: string, citizenEnrollmentId: string) => {
    try {
      let dose = 0;
      const res = await prodocApi.medicine.getPnLast24Hours(productId, citizenEnrollmentId);

      res.forEach(item => {
        dose += item.actualDose ? parseInt(item.actualDose) : 0;
      });

      return dose;
    } catch (e) {
      return 0;
    }
  };

  return { get24hoursDosesCount };
}

export function useMedicineData() {
  const getMedicineData = async (id: string) => {
    try {
      return prodocApi.medicine.get(id);
    } catch (e) {}
  };

  return { getMedicineData };
}
