import React, { useMemo } from 'react';
import { Tabs } from 'antd';
import DatePicker from '../../../_shared/_controls/DatePicker/DatePicker';
import { OrdinationDailyTable } from '../OrdinationDailyTable/OrdinationDailyTable';
import Button from '../../../_shared/_controls/Button/Button';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import { OrdinationPeriod, OrdinationTableRow } from '../../../../types/medicine';
import { OrdinationScheduleType } from '../../../../constants/medicine';
import { defaultMedicinePeriod } from '../../../../data/medicine-data';
import { UtilsData } from '../../../../utils/UtilsData';
import { MESSAGES } from '../../../../constants/messages';
import { UtilsDate } from '../../../../utils/UtilsDate';

import './MedicineAdministrationGroup.scss';

interface Props {
  data: OrdinationPeriod;
  itemIndex: number;
  minDate: Date;
  maxDate: Date;
  disableEndDate: boolean;
  onPeriodAdd(id: string, data: OrdinationPeriod): void;
  onPeriodRemove(id: string): void;
  onPeriodUpdate(id: string, data: OrdinationPeriod): void;
}

const { TabPane } = Tabs;

export const MedicineAdministrationGroup: React.FC<Props> = props => {
  const { data, itemIndex, minDate, maxDate, disableEndDate, onPeriodAdd, onPeriodRemove, onPeriodUpdate } = props;
  const { rows, startDate, endDate, id, skipWeek, dayMonth, skipMonth } = data;
  const showRemoveButton = itemIndex > 0;
  const startDateError = useMemo(() => {
    return UtilsDate.isBefore(startDate, minDate) ? MESSAGES.MEDICINE_DATE_ERROR : '';
  }, [startDate, minDate]);
  const endDateError = useMemo(() => {
    return UtilsDate.isAfter(endDate, maxDate) ? MESSAGES.MEDICINE_DATE_ERROR : '';
  }, [endDate, maxDate]);

  const handleFieldChange = (value: any, name: string) => {
    onPeriodUpdate(id, { ...data, [name]: value });
  };

  const handlePeriodAdd = () => {
    const newId = UtilsData.generateId();

    onPeriodAdd(newId, { ...defaultMedicinePeriod, id: newId });
  };

  const handlePeriodRemove = () => {
    onPeriodRemove(id);
  };

  const handleRowsUpdate = (type: string, items: OrdinationTableRow[]) => {
    const updatedRows = { ...rows, [type]: items };

    onPeriodUpdate(id, { ...data, rows: updatedRows });
  };

  const setEndDateToOrdinationEndDate = () => {
    handleFieldChange(maxDate, 'endDate');
  };

  return (
    <div className="ord-adm common-form">
      <DatePicker
        title="Periode startdato"
        name="startDate"
        value={startDate}
        maxDate={disableEndDate ? undefined : endDate}
        error={startDateError}
        onChange={handleFieldChange}
      />
      <DatePicker
        title="Periode slutdato"
        name="endDate"
        value={endDate}
        minDate={startDate}
        disabled={disableEndDate}
        error={endDateError}
        onChange={handleFieldChange}
      />
      <Button title="Samme som adm. slutdato" type="primary" onClick={setEndDateToOrdinationEndDate} />
      <div className="ord-adm__tabs-wrap">
        <Tabs defaultActiveKey="1" type="card" size="small">
          <TabPane tab="Daglig" key="1">
            <OrdinationDailyTable
              items={rows[OrdinationScheduleType.Daily]}
              type={OrdinationScheduleType.Daily}
              onChange={handleRowsUpdate}
            />
          </TabPane>
          <TabPane tab="Ugentlig" key="2">
            <div className="ord-adm__week">
              <TextInput
                title="Hver x uge"
                type="number"
                value={skipWeek}
                name="skipWeek"
                onChange={handleFieldChange}
              />
            </div>
            <OrdinationDailyTable
              items={rows[OrdinationScheduleType.Weekly]}
              type={OrdinationScheduleType.Weekly}
              onChange={handleRowsUpdate}
            />
          </TabPane>
          <TabPane tab="Månedlig" key="3">
            <div className="ord-adm__month">
              <TextInput
                title="Hver x måned"
                type="number"
                value={skipMonth}
                name="skipMonth"
                onChange={handleFieldChange}
              />
              <TextInput
                title="Dag i måned"
                type="number"
                value={dayMonth}
                name="dayMonth"
                maxNum={29}
                onChange={handleFieldChange}
              />
            </div>
            <OrdinationDailyTable
              items={rows[OrdinationScheduleType.Monthly]}
              type={OrdinationScheduleType.Monthly}
              onChange={handleRowsUpdate}
            />
          </TabPane>
          <TabPane tab="Speciel" key="4">
            <div />
          </TabPane>
        </Tabs>
        <div className="ord-adm__actions">
          <Button title="Tilføj periode" type="primary" onClick={handlePeriodAdd} />
          {showRemoveButton && <Button title="Slet periode" onClick={handlePeriodRemove} />}
        </div>
      </div>
    </div>
  );
};
