import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Employee, EmployeeEnrollment, Position } from '../../../services/prodocApi';
import { navigateToHome } from '../../../store/navigationStore';
import { useCitizenNavigationItems, useRouterParams } from '../../../hooks/navigation';
import { loadUserDepartments } from '../../../store/userStore';
import EmployeeEnrollForm from '../../../components/employee/EmployeeEnrollForm/EmployeeEnrollForm';
import { enrollEmployee, getEmployeeById, setEmployeeEdit } from '../../../store/employeeStore';
import { ApplicationState } from '../../../store';
import { usePageTitle } from '../../../hooks/common-hooks';
import { useEmployeeLeadersCheck } from '../../../hooks/employee-hooks';
import { showConfirmModal } from '../../../components/_shared/Modal/ModalFunctions';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';

export default function EmployeeEnrollPage() {
  const dispatch = useDispatch();
  const [match] = useRouterParams();
  const { employeeItems } = useCitizenNavigationItems();
  const { departmentsHaveLeader, centersHaveLeader } = useEmployeeLeadersCheck();
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const employeeId = match.params.id;
  const userId = userState.id;

  usePageTitle('Opret medarbejder på afdeling');

  React.useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeById(employeeId));
    }

    return () => {
      dispatch(setEmployeeEdit({}));
    };
  }, [employeeId]);

  React.useEffect(() => {
    if (userId) {
      dispatch(loadUserDepartments());
    }
  }, [userId]);

  const createEnrollments = async (data: EmployeeEnrollment, departmentsIds: string[]) => {
    let leadersCanBeAdded = true;
    const { position } = data;
    const isDepartmentLeader = position === Position.HeadOfDepartment;
    const isCenterLeader = position === Position.OrganizationalseniorLeader;

    if (isDepartmentLeader) {
      const departmentsHaveLeaders = await departmentsHaveLeader(departmentsIds);

      leadersCanBeAdded = !departmentsHaveLeaders;
    }

    if (isCenterLeader) {
      const centersHaveLeaders = await centersHaveLeader(departmentsIds);

      leadersCanBeAdded = !centersHaveLeaders;
    }

    if (!leadersCanBeAdded) return;

    dispatch(enrollEmployee(data, departmentsIds));
  };

  const handleFormSubmit = async (data: EmployeeEnrollment, departmentsIds: string[]) => {
    const { position } = data;
    const isDepartmentLeader = position === Position.HeadOfDepartment;
    const isCenterLeader = position === Position.OrganizationalseniorLeader;

    if (isDepartmentLeader || isCenterLeader) {
      dispatch(
        showConfirmModal({
          title: 'Dette vil gøre medarbejderen til leder. Ønsker du at fortsætte?',
          okText: 'Ja',
          cancelText: 'Nej',
          onOk: () => {
            createEnrollments(data, departmentsIds);
          },
        })
      );
    } else {
      createEnrollments(data, departmentsIds);
    }
  };

  const handleCancel = () => {
    // dispatch(navigateToHome());
  };

  return (
    <>
      <Sidebar>
        <NavigationMenu items={employeeItems} />
      </Sidebar>
      <div className="employee-page page">
        <div className="employee-edit">
          <div className="employee-edit__wrap">
            <EmployeeEnrollForm onSubmit={handleFormSubmit} onCancel={handleCancel} />
          </div>
        </div>
      </div>
    </>
  );
}
