import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames';
import fIcon from '../../../../assets/images/png/f.png';
import kIcon from '../../../../assets/images/png/k.png';
import uIcon from '../../../../assets/images/png/underline.png';
import ControlError from '../ControlError/ControlError';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';

interface Props {
  value: string;
  title?: string;
  name?: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange(value: string, name?: string): void;
  onInput?(): void;
}

export default function TextEditor(props: Props) {
  const { title, placeholder, error, value = '', name, disabled, onChange, onInput } = props;
  const defaultValue = EditorState.createEmpty();
  const [localValue, setLocalValue] = useState(defaultValue);
  const wrapStyles = classNames('text-editor__wrap', {
    'text-editor__wrap--error': !!error,
    'text-editor__wrap--disabled': disabled,
  });
  const toolbarSettings = {
    options: ['inline', 'list'],
    inline: {
      options: ['bold', 'italic', 'underline'],
      bold: { icon: fIcon },
      italic: { icon: kIcon },
      underline: { icon: uIcon },
    },
    list: {
      options: ['unordered', 'ordered'],
    },
  };

  useEffect(() => {
    const contentBlock = htmlToDraft(value || '');

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setLocalValue(editorState);
    }
  }, [value]);

  const handleChange = (value) => {
    setLocalValue(value);

    if (value.getCurrentContent().getPlainText()) {
      onInput && onInput();
    }
  };

  const handleValueSave = () => {
    const valueToSave = draftToHtml(convertToRaw(localValue.getCurrentContent()));

    onChange(valueToSave, name);
  };

  return (
    <div className="text-editor">
      {!!title && <div className="text-editor__title">{title}</div>}
      <div className={wrapStyles}>
        <Editor
          editorState={localValue}
          toolbar={toolbarSettings}
          localization={{ locale: 'da' }}
          disabled={disabled}
          onBlur={handleValueSave}
          placeholder={placeholder}
          toolbarClassName="text-editor__toolbar"
          editorClassName="text-editor__area"
          onEditorStateChange={handleChange}
        />
        <ControlError text={error}/>
      </div>
    </div>
  );
};
