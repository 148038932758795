import prodocApi, { Measurement, MeasurementType } from '../services/prodocApi';
import { CommonObject } from '../types/common';

export class UtilsMeasurements {
  static countBmiValue(height: number, weight: number) {
    const bmi = weight / (height * height);

    return isNaN(bmi) || bmi.toString() === 'Infinity' ? 0 : bmi.toFixed(1);
  }

  static getMeasurementValueByType(item: any, type: MeasurementType) {
    const {
      millimol,
      systolisk,
      diastolisk,
      puls,
      bodyTemperature,
      height,
      weight,
      m1,
      confusion,
      irritability,
      loudBehavior,
      verbalThreats,
      physicalThreats,
      attack,
      riskAssessment,
    } = item;

    switch (type) {
      case MeasurementType.Bloodsugar:
        return `${millimol} Mmol/l`;
      case MeasurementType.Bloodpressure:
        return `Blodtryk ${systolisk}/${diastolisk} Puls ${puls}`;
      case MeasurementType.Liquid:
        return `${m1} ml`;
      case MeasurementType.Temperature:
        return `${bodyTemperature} grader`;
      case MeasurementType.Trafficlight:
        return riskAssessment;
      case MeasurementType.Bvc:
        const score = confusion + irritability + loudBehavior + verbalThreats + physicalThreats + attack;

        return `Samlet score ${score}`;
      case MeasurementType.Bmi:
        const bmiValue = UtilsMeasurements.countBmiValue(height, weight);

        return `Højde ${height} Meter, Vægt ${weight} kg, Bmi ${bmiValue}`;
      default:
        return '';
    }
  }

  static getMeasurementMinifiedValueByType(item: Measurement) {
    const {
      millimol,
      systolisk,
      diastolisk,
      puls,
      bodyTemperature,
      height,
      weight,
      m1,
      confusion,
      irritability,
      loudBehavior,
      verbalThreats,
      physicalThreats,
      attack,
      riskAssessment,
    } = item;

    switch (item.measurementType) {
      case MeasurementType.Bloodsugar:
        return `${millimol}`;
      case MeasurementType.Bloodpressure:
        return `${systolisk}/${diastolisk} - ${puls}`;
      case MeasurementType.Liquid:
        return `${m1} ml`;
      case MeasurementType.Temperature:
        return `${bodyTemperature}`;
      case MeasurementType.Trafficlight:
        return riskAssessment;
      case MeasurementType.Bvc:
        const score = confusion + irritability + loudBehavior + verbalThreats + physicalThreats + attack;

        return `Samlet score ${score}`;
      case MeasurementType.Bmi:
        return UtilsMeasurements.countBmiValue(height, weight);
      default:
        return '';
    }
  }

  static async loadMeasurementsByType(citizenEnrollmentId: string, type: MeasurementType) {
    return prodocApi.measurement.getByType(citizenEnrollmentId, type);
  }

  static getBvcFullValueString(data: Measurement): string {
    const valuesNamesMap = {
      irritability: 'Irritabilitet',
      confusion: 'Forvirring',
      loudBehavior: 'Støjende adfærd',
      verbalThreats: 'Verbale trusler',
      physicalThreats: 'Fysiske trusler',
      attack: 'Angreb på ting eller genstande',
    };

    const { confusion, irritability, loudBehavior, verbalThreats, physicalThreats, attack } = data as Measurement;
    const values = { confusion, irritability, loudBehavior, verbalThreats, physicalThreats, attack };
    const valuesLabels = [];

    Object.keys(values).forEach(item => {
      if (values[item]) {
        valuesLabels.push(valuesNamesMap[item]);
      }
    });

    return valuesLabels.join(', ');
  }

  static getMeasurementTypeFromItem(data: CommonObject): MeasurementType {
    const { millimol, bodyTemperature, diastolisk, height, m1, irritability } = data;

    if (millimol !== undefined) return MeasurementType.Bloodsugar;
    if (bodyTemperature !== undefined) return MeasurementType.Temperature;
    if (diastolisk !== undefined) return MeasurementType.Bloodpressure;
    if (height !== undefined) return MeasurementType.Bmi;
    if (m1 !== undefined) return MeasurementType.Liquid;
    if (irritability !== undefined) return MeasurementType.Bvc;

    return MeasurementType.Trafficlight;
  }

  static updateMeasurementWindowDataByType(data: CommonObject, item: CommonObject, type: MeasurementType): any {
    switch (type) {
      case MeasurementType.Bloodpressure:
        return { ...data, bloodPressure: item };
      case MeasurementType.Temperature:
        return { ...data, temperature: item };
      case MeasurementType.Bloodsugar:
        return { ...data, bloodSugar: item };
      case MeasurementType.Bvc:
        return { ...data, bvc: item };
      case MeasurementType.Bmi:
        return { ...data, bmi: item };
      case MeasurementType.Liquid:
        return { ...data, fluid: item };
      default:
        return { ...data, trafficLight: item };
    }
  }

  static normalizeMeasurementCalendarTitle(title: string): string {
    return title.replace(/�/gi, 'å');
  }
}
