import React, { useEffect, useMemo, useState } from 'react';
import { CitizenEnrollment, RiskAssessment, Measurement } from '../../../../services/prodocApi';
import RadioButtons from '../../../_shared/_controls/RadioButtons/RadioButtons';
import { useTranslation } from 'react-i18next';
import TextArea from '../../../_shared/_controls/TextArea/TextArea';

interface Props {
  measurement: Measurement;
  formName: string;
  enrollmentData: CitizenEnrollment;
  onChange(measurement: Measurement, name: string);
}

export default function TrafficLightsForm(props: Props) {
  const { measurement, formName, enrollmentData, onChange } = props;
  const { t } = useTranslation();
  const { riskAssessment, description, whatHappendText, whatDidYouDoText } = measurement;
  const [handlingValue, setHandlingValue] = useState('');
  const options = useMemo(() => {
    return Object.entries(RiskAssessment).map(([value, label]) => ({ label: t(label), value }));
  }, []);

  const {
    riskAssessmentRed,
    riskAssessmentYellow,
    riskAssessmentGreen,
    riskAssessmentRedAct,
    riskAssessmentYellowAct,
    riskAssessmentGreenAct,
  } = enrollmentData;

  useEffect(() => {
    switch (riskAssessment) {
      case RiskAssessment.Red: {
        setHandlingValue(riskAssessmentRed);
        handleFieldChange(riskAssessmentRedAct, 'description');
        break;
      }
      case RiskAssessment.Green: {
        setHandlingValue(riskAssessmentGreen);
        handleFieldChange(riskAssessmentGreenAct, 'description');
        break;
      }
      case RiskAssessment.Yellow: {
        setHandlingValue(riskAssessmentYellow);
        handleFieldChange(riskAssessmentYellowAct, 'description');
        break;
      }
      default: {
        handleFieldChange('', 'description');
        setHandlingValue('');
      }
    }
  }, [riskAssessment, enrollmentData.id]);

  const handleFieldChange = (value: any, name: string) => {
    const newData = { ...measurement, [name]: value };

    onChange(newData, formName);
  };

  return (
    <div className="common-form">
      <RadioButtons
        title="Risikovurdering"
        value={riskAssessment}
        name="riskAssessment"
        options={options}
        valueKey="value"
        labelKey="label"
        onChange={handleFieldChange}
      />
      <TextArea title="Hvad skete der?" value={whatHappendText} name="whatHappendText" onChange={handleFieldChange} />
      <TextArea
        title="Hvad gjorde du/i?"
        value={whatDidYouDoText}
        name="whatDidYouDoText"
        onChange={handleFieldChange}
      />
      <TextArea title="Beskrivelse" value={description} name="description" readonly onChange={handleFieldChange} />
      <TextArea title="Handling" value={handlingValue} readonly onChange={handleFieldChange} />
    </div>
  );
}
