import { Button, Col, Form, Input, Row } from 'antd';
import * as jwtDecode from 'jwt-decode';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { Card } from '../../components/_shared/Card/Card';
import Icon, { ICONS } from '../../components/_shared/Icon/Icon';
import prodocApi from '../../services/prodocApi';
import { AuthType } from '../../services/prodocApi';
import { showErrorMessage } from '../../store/notificationStore';
import './Login.scss';
import { USER_ENROLLMENT_NAMESPACE, USER_TOKEN_NAMESPACE } from '../../constants/namespaces';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [username, setUsername] = useState('');
  const [hasLoginError, setHasLoginError] = useState(false);
  const [hasKeyPress, setHasKeyPress] = useState(false);

  const login = async (username: string, password: string) => {
    try {
      const oauth = await prodocApi.token.create(username, password);
      const token = oauth.token;
      const decoded = jwtDecode(token) as any;
      const userId = decoded.userId;

      await localStorage.setItem(USER_TOKEN_NAMESPACE, token);
      const userEnrollments = await prodocApi.employeeEnrollment.getByEmployee(userId, true);
      const leaderCenters = await prodocApi.center.getByLeader(userId);
      const administrativeCenters = await prodocApi.center.getByAdministrative(userId);
      const isUserAdmin = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] == AuthType.Admin;
      const isUserAllowedTolLogin =
        !!userEnrollments.length || !!leaderCenters.length || !!administrativeCenters.length || isUserAdmin;

      if (isUserAllowedTolLogin) {
        const selectedEnrollmentId = localStorage.getItem(USER_ENROLLMENT_NAMESPACE);

        // if (!selectedEnrollmentId && userEnrollments.length) {
        localStorage.setItem(USER_ENROLLMENT_NAMESPACE, userEnrollments[0].id);
        // }

        return userId;
      } else {
        dispatch(showErrorMessage({ message: 'Du har ikke adgang til systemet. Kontakt en administrator' }));
        await localStorage.removeItem(USER_TOKEN_NAMESPACE);
        setDisabled(false);

        return null;
      }
    } catch (e) {
      dispatch(showErrorMessage({ message: 'Brugernavn eller password er forkert' }));
    } finally {
      setDisabled(false);
    }
  };

  const loginClicked = async e => {
    e.preventDefault();
    setDisabled(true);
    setHasLoginError(false);

    try {
      const employeeId = await login(username, password);

      if (employeeId) {
        history.push(`/home/${employeeId}`);
      }
    } catch (error) {
      setDisabled(false);
      setHasLoginError(true);
      throw error;
    }
  };

  const keyPress = event => {
    setHasKeyPress(true);

    if (event.charCode === 13) {
      event.preventDefault();

      loginClicked(event);
    }
  };

  const usernameError = (() => {
    if (!hasKeyPress) {
      return '';
    }

    if (hasLoginError) {
      return 'Brugernavn eller password er forkert';
    }
    return username ? '' : 'Indtast brugernavn';
  })();

  const passwordError = (() => {
    if (!hasKeyPress) {
      return '';
    }

    if (hasLoginError) {
      return 'Brugernavn eller Password er forkert';
    }

    return password ? '' : 'Indtast password';
  })();
  const handleForgotPassword = () => {
    history.push('login/forgot');
    // setIsInForgotMode(true);
  };
  return (
    <div className="card">
      <Card title="Login">
        <form onSubmit={loginClicked} className="login-form">
          <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError}>
            <Input
              prefix={<Icon name={ICONS.user} />}
              onKeyPress={keyPress}
              disabled={disabled}
              style={{ width: '300px' }}
              placeholder="Brugernavn"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Form.Item>
          <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError}>
            <Input.Password
              prefix={<Icon name={ICONS.lock} />}
              onKeyPress={keyPress}
              disabled={disabled}
              required={true}
              style={{ width: '300px' }}
              placeholder="Kodeord"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Button className="button" type="link" onClick={handleForgotPassword}>
                Glemt kodeord
              </Button>
            </Col>
            <Col span={12}>
              <Form.Item style={{ margin: 0 }}>
                <Button loading={disabled} htmlType="submit" className="button" type="primary">
                  Log ind
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </form>
      </Card>
    </div>
  );
};

export default LoginPage;
