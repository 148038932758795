import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Button from '../../_shared/_controls/Button/Button';
import { Card } from '../../_shared/Card/Card';
import { employeeDischargeSchema } from '../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import { EmployeeDischarge } from '../../../types/employee';
import { ApplicationState } from '../../../store';
import { DepartmentState } from '../../../store/departmentStore';
import { UtilsData } from '../../../utils/UtilsData';
import { EmployeeState, getDischargeEmployeesEnrollmentsByDepartment } from '../../../store/employeeStore';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { useRouterParams } from '../../../hooks/navigation';
import { CardActions } from '../../_shared/CardActions/CardActions';

interface Props {
  onSubmit(data: EmployeeDischarge): void;
  onCancel(): void;
}

export default function EmployeeDischargeForm(props: Props) {
  const { onCancel, onSubmit } = props;

  const dispatch = useDispatch();
  const [match] = useRouterParams();
  const departmentStore = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const employeeState = useSelector<ApplicationState, EmployeeState>(state => state.employees);
  const departments = departmentStore.userDepartments;
  const { dischargeEmployeesEnrollments } = employeeState;
  const defaultData = useMemo(() => UtilsData.getEmployeeDischargeDefaults(), []);
  const defaultEmployeeId = match.params.employeeId;

  const [formData, setFormData] = React.useState<EmployeeDischarge>(defaultData);
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: employeeDischargeSchema,
    defaultValues: defaultData as any,
  });
  useRegisteredFields(employeeDischargeSchema, register, unregister);

  useEffect(() => {
    if (departments.length) {
      const defaultDepartmentId = departments[0].id;

      setFormData({ ...formData, departmentId: defaultDepartmentId });
      setValue('departmentId', defaultDepartmentId);

      dispatch(getDischargeEmployeesEnrollmentsByDepartment(defaultDepartmentId));
    } else {
      setFormData({ ...formData, employeeId: '', departmentId: '' });
      setValue('citizenId', '');
      setValue('departmentId', '');
    }
  }, [departments.length]);

  useEffect(() => {
    if (defaultEmployeeId && dischargeEmployeesEnrollments.length) {
      const defaultEnrollment = dischargeEmployeesEnrollments.find(item => item.employeeId === defaultEmployeeId);
      const defaultEnrollmentId = defaultEnrollment?.id || formData.employeeId;

      setFormData({ ...formData, employeeId: defaultEnrollmentId });
    }
  }, [defaultEmployeeId, dischargeEmployeesEnrollments.length]);

  const { departmentId, date, employeeId } = formData;

  const handleCancel = () => {
    onCancel();
  };

  const handleFieldChange = (value: string | Date, name: string) => {
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    clearError(name);

    if (name === 'departmentId') {
      dispatch(getDischargeEmployeesEnrollmentsByDepartment(value as string));
    }
  };

  const handleFormSubmit = () => {
    onSubmit(formData);
  };

  return (
    <form className="common-form item-form">
      <Card>
        <SelectMenu
          title="Vælg afdeling"
          name="departmentId"
          valueKey="id"
          labelKey="name"
          value={departmentId}
          options={departments}
          error={errors.departmentId && errors.departmentId.message}
          onChange={handleFieldChange}
        />
        <SelectMenu
          title="Vælg medarbejder"
          name="employeeId"
          valueKey="id"
          labelKey={['firstname', 'lastname']}
          value={employeeId}
          options={dischargeEmployeesEnrollments}
          error={errors.employeeId && errors.employeeId.message}
          onChange={handleFieldChange}
        />
        <DatePicker
          value={date}
          title="Afslutningsdato"
          name="date"
          error={errors.date && errors.date.message}
          onChange={handleFieldChange}
        />
        <CardActions
          rightActions={
            <>
              <Button title="Afslut medarbejder" htmlType="submit" onClick={handleSubmit(handleFormSubmit)} />
              <Button title="Annuller" onClick={handleCancel} />
            </>
          }
        />
      </Card>
    </form>
  );
}
