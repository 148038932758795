import React from 'react';

// set the defaults
const PageTitleContext = React.createContext({
  title: '',
  subTitle: '',
  setTitle: (() => {}) as any,
  setSubTitle: (() => {}) as any,
});

export default PageTitleContext;
