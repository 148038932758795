import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import {
  addCitizen,
  CitizensState,
  enrollCitizen,
  getCitizenById,
  setCitizenEdit,
  updateCitizen,
} from '../../../store/citizenStore';
import prodocApi, { Citizen, EnrollFromType } from '../../../services/prodocApi';
import { navigateTo, navigateToHome, navigateToCitizenEnroll } from '../../../store/navigationStore';
import { UtilsData } from '../../../utils/UtilsData';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { ROUTES } from '../../../constants/routes';
import CitizenCreateForm from '../../../components/citizen/CitizenCreateForm/CitizenCreateForm';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import Button from '../../../components/_shared/_controls/Button/Button';

import './CitizenCreatePage.scss';
import { useHistory, useParams } from 'react-router';
import { CitizenEnrollModal } from '../CitizenEnrollPage/CitizenEnrollModal';

export default function CitizenCreatePage() {
  const dispatch = useDispatch();
  const { match } = useReactRouter<{ id: string }>();
  const citizenState = useSelector<any, CitizensState>(state => state.citizens);
  const citizenId = match.params.id;
  const { editCitizen } = citizenState;
  const citizenData = editCitizen.data || UtilsData.getCitizenDefaults();
  const history = useHistory();
  const [isEnrollOpen, setIsEnrollOpen] = React.useState(false);
  const [citizenEnrollmentId, setCitizenEnrollmentId] = React.useState<string>();

  usePageTitle('Opret borger');

  React.useEffect(() => {
    if (citizenId) {
      dispatch(getCitizenById(citizenId));
    }

    return () => {
      dispatch(setCitizenEdit({}));
    };
  }, [citizenId]);

  async function handleFormSubmit(
    citizen: Citizen,
    enrollment: {
      enrolledFrom: string;
      enrollmentDate: Date;
      enrolledDepartment: string;
    }
  ) {
    const createdCitizen = await prodocApi.citizen.add(citizen);
    await prodocApi.citizenEnrollment.add({
      citizenId: createdCitizen.id,
      enrollmentDate: enrollment.enrollmentDate,
      enrolledFrom: enrollment.enrolledFrom as EnrollFromType,
      departmentId: enrollment.enrolledDepartment,
    });
    history.push(ROUTES.CITIZEN_OVERVIEW);
  }

  const handleEnroll = async (id: string) => {
    setCitizenEnrollmentId(id);
    setIsEnrollOpen(true);
  };
  function handleCancel() {
    setIsEnrollOpen(false);
    history.push(ROUTES.CITIZEN_OVERVIEW);
  }
  return (
    <>
      <div className="citizen-page page">
        <CitizenCreateForm
          data={citizenData}
          onSubmit={handleFormSubmit}
          onCancel={handleCancel}
          onEnroll={handleEnroll}
        />
      </div>

      {citizenEnrollmentId && (
        <CitizenEnrollModal isOpen={isEnrollOpen} onCancel={handleCancel} citizenId={citizenEnrollmentId} />
      )}
    </>
  );
}
