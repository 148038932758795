import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import prodocApi, { ForumMessageResult, Note } from '../../services/prodocApi';
import { ApplicationState } from '../../store';
import { usePageTitle } from '../../hooks/common-hooks';
import { NotesFilterData } from '../../types/notes';
import { loadNotes, NotesState } from '../../store/notesStore';
import { showConfirmModal } from '../../components/_shared/Modal/ModalFunctions';
import { useNoteDelete, useNoteUpdate } from '../../hooks/notes-hooks';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import { Button, Col, Divider, Input } from 'antd';

import './ForumPage.scss';
import { PAGES_ITEMS, PagesState } from '../../store/pagesStore';
import { Row, Card, Modal, Tooltip } from 'antd';
import {
  createMessage,
  deleteComment,
  deleteMessage,
  ForumState,
  loadForumMessages,
  updateMessage,
} from '../../store/forumStore';
import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PushpinFilled,
  PushpinOutlined,
} from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import { UtilsAuth } from '../../utils/UtilsAuth';
import { update } from '../../store/measurementStore';
import { useValidation } from '../../hooks/validation-hooks';
import { messageSchema } from '../../utils/UtilsValidation';
import TextArea from '../../components/_shared/_controls/TextArea/TextArea';
import TextInput from '../../components/_shared/_controls/TextInput/TextInput';
moment.locale('da');

export default function ForumPage() {
  const dispatch = useDispatch();
  const { updateNote, syncUpdatedNoteWithDataSource, isLoading: isNoteSaving } = useNoteUpdate();
  const { deleteNote, syncDeletedNoteWithDataSource } = useNoteDelete();
  const forumState = useSelector<ApplicationState, ForumState>(state => state.forum);
  const { forumMessages } = forumState;
  const [createVisible, setCreateVisible] = useState(false);
  const [commentVisible, setCommentVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState<ForumMessageResult>({});
  const [commentIndex, setCommentIndex] = useState<number>();
  const [filterData, setFilterData] = useState<NotesFilterData>({
    citizenId: '',
    departmentId: '',
    goalId: '',
    health: undefined,
  });

  usePageTitle('Beskeder');
  const { validate, errors } = useValidation(messageSchema);

  useEffect(() => {
    dispatch(loadForumMessages());
  }, []);

  const handleNoteUpdate = async (data: Note) => {
    const isUpdated = await updateNote(data);

    if (isUpdated) {
      syncUpdatedNoteWithDataSource(data);
    }
  };

  const handleCreateMessage = () => {
    setCreateVisible(true);
    setIsEdit(false);
  };
  const handleOk = () => {
    if (validate(message)) {
      if (isEdit) {
        dispatch(updateMessage(message));
      } else {
        dispatch(createMessage(message));
      }
      setCreateVisible(false);
    }
  };
  const cardStyle = {
    width: '100%',
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    margin: '5px',
  };
  const handleChange = (value: string, name: string) => {
    setMessage({ ...message, [name]: value });
  };
  const handleEdit = (id: string) => {
    const found = forumMessages.find(x => x.id === id);
    setMessage(found);
    setCreateVisible(true);
    setIsEdit(true);
  };
  const handleEditComment = (id: string, index: number) => {
    const found = forumMessages.find(x => x.id === id);
    setIsEdit(true);
    setMessage(found);
    setCommentIndex(index);
    setComment(found.comments[index].comment);
    setCommentVisible(true);
    validate(found);
  };
  const handleComment = (id: string) => {
    const found = forumMessages.find(x => x.id === id);
    setMessage(found);
    setCommentVisible(true);
    validate(found);
  };
  const handlePin = (id: string, pinned: boolean) => {
    const found = forumMessages.find(x => x.id === id);
    found.pinned = pinned;
    dispatch(updateMessage(found));
  };
  const handleDelete = (id: string) => {
    Modal.confirm({
      title: 'Ønsker du at slette beskeden?',
      content: 'Slet?',
      okText: 'Ok',
      cancelText: 'Annuller',
      onOk: () => dispatch(deleteMessage(id)),
    });
  };
  const handleCommentDelete = (id: string, index: number) => {
    Modal.confirm({
      title: 'Ønsker du at slette kommentaren?',
      content: 'Slet?',
      okText: 'Ok',
      cancelText: 'Annuller',
      onOk: () => dispatch(deleteComment(id, index)),
    });
  };

  const handleCommentSave = async () => {
    if (isEdit) {
      message.comments[commentIndex].comment = comment;
    } else {
      if (!message.comments) {
        message.comments = [];
      }
      var employee = await prodocApi.employee.get();

      message.comments.push({ comment, createdBy: UtilsAuth.getEmployeeId(), createdByName: employee.fullName });
    }
    dispatch(updateMessage(message));
    setCommentVisible(false);
  };

  return (
    <>
      <div className="page" style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          {forumMessages.map((x, i) => (
            <Row key={i}>
              <Card
                size="small"
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      <Tooltip title="Pin">
                        <Button
                          size="small"
                          icon={
                            x.pinned ? (
                              <PushpinFilled translate={undefined} />
                            ) : (
                              <PushpinOutlined translate={undefined} />
                            )
                          }
                          onClick={() => handlePin(x.id, !x.pinned)}
                        />
                      </Tooltip>
                      <span style={{ marginLeft: '5px' }}>{x.header}</span>
                    </span>
                    <span>
                      <Tooltip title="Kommentar">
                        <Button
                          size="small"
                          icon={<CommentOutlined translate={undefined} />}
                          onClick={() => handleComment(x.id)}
                        />
                      </Tooltip>
                      &nbsp;
                      {UtilsAuth.getEmployeeId() === x.createdBy && (
                        <Tooltip title="Redigér">
                          <Button
                            size="small"
                            icon={<EditOutlined translate={undefined} />}
                            onClick={() => handleEdit(x.id)}
                          />
                        </Tooltip>
                      )}
                      &nbsp;
                      {UtilsAuth.getEmployeeId() === x.createdBy && (
                        <Tooltip title="Slet">
                          <Button
                            size="small"
                            icon={<DeleteOutlined translate={undefined} onClick={() => handleDelete(x.id)} />}
                          />
                        </Tooltip>
                      )}
                    </span>
                  </div>
                }
                style={cardStyle}
                bordered={false}>
                <span style={{ fontSize: '16px' }}>{x.text}</span>
                <div style={{ fontSize: '12px', display: 'flex', justifyContent: 'end', fontStyle: 'italic' }}>
                  <div>
                    {x.createdByName} - {moment(x.createdOn).format('LLL')}
                  </div>
                </div>
                <div>
                  {x.comments &&
                    x.comments.map((c, i) => {
                      return (
                        <span key={i}>
                          <Divider />
                          <div style={{ marginLeft: '50px', fontSize: '14px', fontWeight: 'bold' }}>
                            {c.createdByName} - {moment(c.createdOn).format('LLL')}
                          </div>
                          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginLeft: '50px', fontSize: '14px' }}>{c.comment}</div>
                            <span>
                              {UtilsAuth.getEmployeeId() === c.createdBy && (
                                <Tooltip title="Redigér">
                                  <Button
                                    size="small"
                                    icon={<EditOutlined translate={undefined} />}
                                    onClick={() => handleEditComment(x.id, i)}
                                  />
                                </Tooltip>
                              )}
                              &nbsp;
                              {UtilsAuth.getEmployeeId() === c.createdBy && (
                                <Tooltip title="Slet">
                                  <Button
                                    size="small"
                                    icon={
                                      <DeleteOutlined
                                        translate={undefined}
                                        onClick={() => handleCommentDelete(x.id, i)}
                                      />
                                    }
                                  />
                                </Tooltip>
                              )}
                            </span>
                          </span>
                        </span>
                      );
                    })}
                </div>
              </Card>
            </Row>
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
          <Tooltip title="Opret besked">
            <Button
              type="primary"
              style={{ marginBottom: '8px' }}
              icon={<PlusOutlined translate={undefined} />}
              onClick={handleCreateMessage}
              size="large"
            />
          </Tooltip>
        </div>
      </div>
      <Modal
        title="Opret besked"
        open={createVisible}
        onOk={handleOk}
        onCancel={() => setCreateVisible(false)}
        okText="Gem"
        cancelText="Annuller">
        <Row gutter={8} style={{ marginBottom: '18px' }}>
          <TextInput
            name="header"
            onChange={handleChange}
            value={message.header}
            error={errors.header}
            style={{ width: '100%' }}
          />
        </Row>
        <Row gutter={8}>
          <TextArea
            name="text"
            onChange={handleChange}
            value={message.text}
            error={errors.text}
            style={{ width: '100%' }}
          />
        </Row>
      </Modal>
      <Modal
        title="Kommentar"
        open={commentVisible}
        onOk={handleCommentSave}
        onCancel={() => setCommentVisible(false)}
        okText="Gem"
        cancelText="Annuller">
        <TextArea name="text" onChange={value => setComment(value)} value={comment} />
      </Modal>
    </>
  );
}
