import prodocApi, { Measurement } from '../services/prodocApi';
import { useState } from 'react';

export function useMeasurementDataLoading() {
  const [data, setData] = useState<Measurement>();

  const resetData = () => setData(undefined);

  const loadItemData = async (id: string) => {
    try {
      let res = await prodocApi.measurement.get(id);
      setData(res);
    } catch (e) {}
  };

  return { loadItemData, data, resetData };
}
