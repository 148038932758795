import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  createGoal,
  createGoalStatus,
  GoalsState,
  loadCitizenEnrollments,
  loadGoals,
  updateGoal,
} from '../../../store/goalStore';
import prodocApi, {
  CalendarItem,
  CalendarItemType,
  Citizen,
  CitizenEnrollment,
  Employee,
  Goal,
  GoalResult,
  GoalScorer,
  GoalStatus,
  Note,
} from '../../../services/prodocApi';
import { Card } from '../../../components/_shared/Card/Card';
import { CalendarState, loadCalendarSidebarCitizens, loadCalendarSidebarEmployees } from '../../../store/calendarStore';
import { ApplicationState } from '../../../store';
import SelectMenu from '../../../components/_shared/_controls/SelectMenu/SelectMenu';
import GoalWindow from '../../../components/goals/GoalWindow/GoalWindow';
import GoalSection from '../../../components/goals/GoalSection/GoalSection';
import { ICONS } from '../../../components/_shared/Icon/Icon';
import { UtilsData } from '../../../utils/UtilsData';
import { useGoalFilter } from '../../../hooks/user-hooks';
import { usePageTitle } from '../../../hooks/common-hooks';
import { USER_ENROLLMENT_NAMESPACE } from '../../../constants/namespaces';
import { CommonState, loadUserEnrollments } from '../../../store/commonStore';
import { GoalStatusWindow } from '../../../components/goals/GoalStatusWindow/GoalStatusWindow';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { PAGES_ITEMS, PagesState, setPageItemSaved } from '../../../store/pagesStore';
import CalendarNoteWindow from '../../../components/Calendar/CalendarNoteWindow/CalendarNoteWindow';
import { CalendarItemExt } from '../../../types/calendar';
import { createNote } from '../../../store/notesStore';

import './GoalsPage.scss';
import { log } from 'console';
import { Button, Tooltip } from 'antd';
import { MedicineBoxOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd/lib';

export default function GoalsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getFilterResult, setFilterResult } = useGoalFilter();
  const { goalsItems } = useCitizenNavigationItems();
  const userStore = useSelector<ApplicationState, Employee>(state => state.user);
  const goalsStore = useSelector<ApplicationState, GoalsState>(state => state.goals);
  const commonStore = useSelector<ApplicationState, CommonState>(state => state.common);
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { citizenEnrollmentId } = useParams<{ citizenEnrollmentId: string }>();
  const defaultGoal = useMemo(
    () => ({ ...UtilsData.getGoalDefaults(), citizenEnrollmentId, goalStatus: GoalStatus.OnGoing }),
    [citizenEnrollmentId]
  );
  const isNoteSaved = pagesStore[PAGES_ITEMS.CALENDAR_ITEM].saved;

  const [selectedCitizenEnrollment, setSelectedCitizenEnrollment] = useState<CitizenEnrollment>();
  const [filter, setFilter] = useState([]);
  const [showGoalDrawer, setShowGoalDrawer] = useState(false);
  const [showStatusWindow, setShowStatusWindow] = useState(false);
  const [noteCalendarItem, setNoteCalendarItem] = useState<CalendarItem | undefined>();
  const [defaultStatusGoal, setDefaultStatusGoal] = useState('');
  const [goalData, setGoalData] = useState<Goal>(defaultGoal);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [citizen, setCitizen] = useState<Citizen>();

  const { id: userId } = userStore;
  const { userEnrollments } = commonStore;
  const { selectedCitizenEnrollments } = calendarStore;
  const defaultEnrollmentId = selectedCitizenEnrollments?.length === 1 ? selectedCitizenEnrollments[0] : '';
  // const goals = loadedItems[citizenEnrollmentId] || [];
  async function loadGoals() {
    const ce = await prodocApi.citizenEnrollment.get(citizenEnrollmentId);
    const g = await prodocApi.goal.getByCitizenEnrollment(citizenEnrollmentId, false);
    const c = await prodocApi.citizen.get(ce.citizenId);
    setCitizen(c);
    setGoals(g);
    setSelectedCitizenEnrollment(ce);
  }
  useEffect(() => {
    if (citizenEnrollmentId) {
      loadGoals();
    }
  }, []);
  const statusGoals = useMemo(
    () => goals.filter(item => item.goalStatus === GoalStatus.Paused || item.goalStatus === GoalStatus.OnGoing),
    [goals]
  );

  usePageTitle('Mål', citizen?.name);

  const initFilter = async () => {
    const data = (await getFilterResult()) || [];

    setFilter(data);
  };

  useEffect(() => {
    if (userId) {
      dispatch(loadCitizenEnrollments());
      dispatch(loadUserEnrollments());
      dispatch(loadCalendarSidebarCitizens());
      dispatch(loadCalendarSidebarEmployees());
      initFilter();
    }

    return () => {
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL_STATUS, false));
    };
  }, [userId]);

  useEffect(() => {
    if (isNoteSaved) {
      setNoteCalendarItem(undefined);
    }
  }, [isNoteSaved]);

  const toggleStatusWindow = () => {
    setShowStatusWindow(prevState => !prevState);
  };

  const closeNoteWindow = () => {
    setNoteCalendarItem(undefined);
  };

  const createStatus = (data: GoalScorer[]) => {
    dispatch(createGoalStatus(data));
  };

  const createNewNote = (data: Note) => {
    dispatch(createNote(data));
  };

  const handleSelectCitizen = value => {
    setSelectedCitizenEnrollment(value);
    setGoalData({ ...goalData, citizenEnrollmentId: value });
  };

  const handleFilterChange = value => {
    setFilterResult(value);
    setFilter(value);
  };

  const handleGoalWindowOpen = () => {
    setShowGoalDrawer(true);
  };

  const handleGoalAdd = () => {
    setGoalData({ ...defaultGoal, citizenEnrollmentId: citizenEnrollmentId });
    handleGoalWindowOpen();
  };

  const handleSubGoalAdd = (goalId: string) => {
    setGoalData({ ...defaultGoal, citizenEnrollmentId: citizenEnrollmentId, parentGoalId: goalId });
    handleGoalWindowOpen();
  };

  async function handleGoalCreate(data: Goal) {
    await prodocApi.goal.add(data);
    loadGoals();
    setShowGoalDrawer(false);
  }

  const handleNoteCreate = (goalId: string) => {
    const calendarItem: CalendarItemExt = UtilsData.getCalendarItemDefault();
    let goalData;

    goals.forEach((item: GoalResult) => {
      const subGoal = item.subGoals.find(subGoal => subGoal.id === goalId);

      if (subGoal) return (goalData = subGoal);

      if (item.id === goalId) goalData = item;
    });

    calendarItem.calendarItemType = CalendarItemType.Note;
    calendarItem.citizenEnrollmentId = citizenEnrollmentId;
    calendarItem.goal = goalData;

    setNoteCalendarItem(calendarItem);
  };

  async function handleGoalUpdate(data: Goal) {
    await prodocApi.goal.update(data);
    loadGoals();
    setShowGoalDrawer(false);
  }

  const handleGoalWindowClose = () => {
    setShowGoalDrawer(false);
  };

  const handleGoalEdit = (data: Goal) => {
    setGoalData(data);
    handleGoalWindowOpen();
  };

  const handleStatusCreate = (goalId: string) => {
    toggleStatusWindow();
    setDefaultStatusGoal(goalId);
  };

  const renderGoals = () => {
    return goals.map((goalItem, index) => {
      if (filter.length) {
        const statusSelected = filter.find(item => item === goalItem.goalStatus);

        if (!statusSelected) return null;
      }

      if (goalItem.parentGoalId) return null;

      return (
        <GoalSection
          key={index}
          items={goals}
          data={goalItem}
          onEdit={handleGoalEdit}
          onSubGoalCreate={handleSubGoalAdd}
          onStatusCreate={handleStatusCreate}
          onNoteCreate={handleNoteCreate}
        />
      );
    });
  };

  return (
    <>
      <div className="goals page-full" style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          <Card style={{ marginBottom: '8px' }}>
            <Row>
              <Col span={6}>
                <SelectMenu
                  title="Type"
                  value={filter}
                  placeholder="Måltype"
                  valueKey="value"
                  labelKey="label"
                  multiple
                  options={Object.entries(GoalStatus).map(([value, label]) => ({ label: t(label), value }))}
                  onChange={handleFilterChange}
                />
              </Col>
            </Row>
          </Card>
          <Card className="">
            <Col span={1}>
              <Tooltip title="Opret mål">
                <Button shape="circle" icon={<PlusOutlined translate={undefined} />} onClick={handleGoalAdd} />
              </Tooltip>
            </Col>
            {renderGoals()}
          </Card>
          <GoalWindow
            data={goalData}
            citizenName={'selectedCitizenFullName'}
            isVisible={showGoalDrawer}
            onCreate={handleGoalCreate}
            onUpdate={handleGoalUpdate}
            onClose={handleGoalWindowClose}
          />
          <CalendarNoteWindow
            isOpened={!!noteCalendarItem}
            data={noteCalendarItem}
            preselectedEnrollmentId={citizenEnrollmentId}
            onClose={closeNoteWindow}
            onUpdateCalendarItem={createNewNote}
          />
          <GoalStatusWindow
            defaultGoal={defaultStatusGoal}
            isOpened={showStatusWindow}
            goals={statusGoals}
            onSave={createStatus}
            onClose={toggleStatusWindow}
          />
        </div>
      </div>
    </>
  );
}
