import React, { useState } from 'react';
import { Card } from '../../../components/_shared/Card/Card';
import { usePageTitle } from '../../../hooks/common-hooks';
import { GoalStatusHead } from '../../../components/goals/GoalStatusHead/GoalStatusHead';
import { GoalStatusFilerData } from '../../../types/goals';
import { GoalStatusTable } from '../../../components/goals/GoalStatusTable/GoalStatusTable';
import { GoalStatusWindow } from '../../../components/goals/GoalStatusWindow/GoalStatusWindow';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';

import './GoalStatusPage.scss';

export default function GoalStatusPage() {
  const { goalsItems } = useCitizenNavigationItems();
  // const [showStatusWindow, setShowStatusWindow] = useState(false);
  const [filterData, setFilterData] = useState<GoalStatusFilerData>({ citizenId: '' });

  usePageTitle('Mål');

  return (
    <>
      <Sidebar>
        <NavigationMenu items={goalsItems} />
      </Sidebar>
      <div className="page goal-status-page">
        <Card>
          <GoalStatusHead filterData={filterData} onFilterDataChange={setFilterData} />
          <GoalStatusTable data={[]} />
        </Card>
      </div>
      {/* <GoalStatusWindow goals={[]} isOpened={showStatusWindow} onSave={() => {}} /> */}
    </>
  );
}
