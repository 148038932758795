import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Input, Row } from 'antd';
import React, { RefObject, useEffect, useRef } from 'react';
// import DatePicker from '../components/_shared/_controls/DatePicker/DatePicker';
import SelectMenu from '../components/_shared/_controls/SelectMenu/SelectMenu';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { MomentInput } from 'moment';
import { Dayjs } from 'dayjs';
export interface FilterInputProp {
  hidden: boolean;
}
interface FilterInputProps extends FilterDropdownProps {
  callback(value: string);
}
const { RangePicker } = DatePicker;
const FilterInput: React.FC<FilterInputProps> = ({ callback, setSelectedKeys, confirm, visible }) => {
  const ref = useRef() as RefObject<any>;

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        ref.current.focus();
      }, 100);
    }
  }, [visible]);

  return (
    <div style={{ padding: 8 }}>
      <Input
        ref={ref}
        allowClear
        onPressEnter={e => {
          confirm();
          callback((e.target as any).value);
        }}
        onBlur={e => {
          confirm();
          callback(e.target.value);
        }}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      />
    </div>
  );
};

export function getColumnTextSearchProps(callback: (e: string) => void) {
  return {
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => {
      return (
        <FilterInput
          prefixCls=""
          selectedKeys={[]}
          callback={callback}
          visible={visible}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          close={() => {}}
        />
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}
export function getColumnTextFilter<T>(
  externalContacts: FilterInputProp[],
  dataIndex: string,
  callback: (filtered: T[]) => void
) {
  return {
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => {
      return (
        <FilterInput
          prefixCls=""
          selectedKeys={[]}
          callback={c => {
            externalContacts.forEach(
              x => (x.hidden = !x[dataIndex].toLocaleLowerCase().includes(c.toLocaleLowerCase()))
            );
            callback([...externalContacts] as unknown as T[]);
          }}
          visible={visible}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          close={() => {}}
        />
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}
export function getColumnMultiEnumSearchProps(
  enumValues: { label: string; value: string }[],
  callback: (e: any) => void
) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <>
        {enumValues.map(x => (
          <Row>
            <Checkbox
              style={{ padding: 8, minWidth: 250, cursor: 'pointer' }}
              onClick={e => {
                let sk = [];
                if (selectedKeys.find(s => x.value === s.value)) {
                  sk = [...selectedKeys.filter(s => x.value !== s.value)];
                  setSelectedKeys(sk);
                } else {
                  sk = [...selectedKeys, x];
                  setSelectedKeys(sk);
                }
                callback(sk.map(p => p.value));
              }}>
              {x.label}
            </Checkbox>
          </Row>
        ))}
      </>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}
export function getColumnEnumSearchProps(enumValues: { label: string; value: string }[], callback: (e: any) => void) {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <>
        {enumValues.map(x => (
          <Row>
            <div
              style={{ padding: 8, minWidth: 250, cursor: 'pointer' }}
              onClick={e => {
                let sk = [];
                if (selectedKeys.find(s => x.value === s.value)) {
                  sk = [...selectedKeys.filter(s => x.value !== s.value)];
                  setSelectedKeys(sk);
                } else {
                  sk = [...selectedKeys, x];
                  setSelectedKeys(sk);
                }
                callback(x.value);
                confirm();
              }}>
              {x.label}
            </div>
          </Row>
        ))}
        <Row>
          <div
            style={{ padding: 8, minWidth: 250, cursor: 'pointer' }}
            onClick={e => {
              callback(undefined);
              setSelectedKeys([]);
              confirm();
            }}>
            {'Nulstil'}
          </div>
        </Row>
      </>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}
export function getColumnDateSearchProps(onDateChange: (value: Dayjs[]) => void) {
  return {
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8, display: 'flex', gap: '7px' }}>
        <RangePicker
          onChange={e => {
            setSelectedKeys(e ? [e] : []);
            onDateChange(e ? e : [undefined, undefined]);
            confirm();
          }}
          allowClear
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        translate=""
        style={{ color: filtered ? '#1890ff' : undefined, marginRight: '7px', fontSize: '16px' }}
      />
    ),
  };
}
export function stringSorter(a: unknown, b: unknown, prop: string) {
  return a[prop].localeCompare(b[prop]);
}
