import { useDispatch, useSelector } from 'react-redux';
import prodocApi, { CalendarItem, CalendarItemType, Employee, UseOfForceDto } from '../services/prodocApi';
import { CalendarItemParticipant } from '../types/calendar';
import { ApplicationState } from '../store';
import {
  CalendarState,
  loadCalendarSidebarCitizens,
  loadCalendarSidebarEmployees,
  loadCitizenEnrollmentCalendarItems,
  loadEmployeeEnrollmentCalendarItems,
  setCalendarDepartmentId,
  setMedicineNavigationData,
  setSelectedCitizenEnrollmentsAction,
  setSelectedEmployeeEnrollmentsAction,
} from '../store/calendarStore';
import { useEffect, useState } from 'react';
import { loadUserDepartments } from '../store/userStore';
import { DepartmentState } from '../store/departmentStore';
import { CalendarItemsFilterValues, SCHEMA_FILES_NAMES } from '../constants/calendar';
import { USER_ENROLLMENT_NAMESPACE } from '../constants/namespaces';
import { CommonState, loadUserEnrollments } from '../store/commonStore';
import { UtilsCalendar } from '../utils/UtilsCalendar';
import { UtilsAuth } from '../utils/UtilsAuth';

export function useParticipantsRequest() {
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const { employeesEnrollments } = calendarState;

  const getAppointmentParticipants = async (calendarItem: CalendarItem) => {
    try {
      const citizens: string[] = [];
      const employees: string[] = [];
      const participants: CalendarItemParticipant[] = [];
      const res = await prodocApi.calendarItem.getManyByGroupId(calendarItem.groupId);
      const withoutNotes = res.filter(item => item.calendarItemType !== CalendarItemType.Note);

      withoutNotes.forEach(item => {
        const { citizenEnrollmentId, employeeEnrollmentId, calendarStatus } = item;

        if (citizenEnrollmentId) {
          citizens.push(citizenEnrollmentId);
        }

        if (employeeEnrollmentId && !citizenEnrollmentId && employees.indexOf(employeeEnrollmentId) < 0) {
          const employee = employeesEnrollments.find(employeeItem => employeeEnrollmentId === employeeItem.id);

          employees.push(employeeEnrollmentId);

          if (employee) {
            const { firstname, lastname, employeeId } = employee;
            const fullName = `${firstname} ${lastname}`;

            participants.push({ name: fullName, status: calendarStatus, id: employeeId });
          }
        }
      });

      return { citizens, employees, participants };
    } catch (e) {}
  };

  return { getAppointmentParticipants };
}

export function useCalendarEnrollmentsLoading() {
  const dispatch = useDispatch();
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const { id: userId } = userState;
  const { userDepartments } = departmentState;

  useEffect(() => {
    if (userId) {
      dispatch(loadUserDepartments());
    }
  }, [userId]);

  useEffect(() => {
    if (userDepartments.length) {
      dispatch(loadCalendarSidebarCitizens());
    }
  }, [userDepartments.length]);
}

export function useSchemaPdfLink() {
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const { employeesEnrollments, citizensEnrollments, selectedCitizenEnrollments } = calendarState;

  const getSchemaCreateLink = (name: SCHEMA_FILES_NAMES) => {
    const employeeEnrollmentId = localStorage.getItem(USER_ENROLLMENT_NAMESPACE);
    const citizenEnrollmentId = selectedCitizenEnrollments[0];
    const employeeEnrollment = employeesEnrollments.find(item => item.id === employeeEnrollmentId);
    const citizenEnrollment = citizensEnrollments.find(item => item.id === citizenEnrollmentId);
    const { employeeId } = employeeEnrollment;
    const citizenId = citizenEnrollment?.citizenId || '';

    return `/useofforce?cid=${citizenId}&doc=${name}&v=v1&eid=${employeeId}&eeid=${employeeEnrollmentId}&ceid=${citizenEnrollmentId}`;
  };

  const getSchemaUpdateLink = (data: UseOfForceDto) => {
    const { documentName, version, id } = data;
    const employeeEnrollmentId = localStorage.getItem(USER_ENROLLMENT_NAMESPACE);

    return `/useofforce?doc=${documentName}&v=${version}&id=${id}&eeid=${employeeEnrollmentId}`;
  };

  return { getSchemaCreateLink, getSchemaUpdateLink };
}

export function useCalendarFilteredData() {
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const [calendarItems, setCalendarItems] = useState<CalendarItem[]>([]);
  const { calendarItemsType, calendarData } = calendarState;
  const { appointments, notes, medicine, goals, health, timeTrack, measurements, useOfForce, postings } = calendarData;

  const filterData = () => {
    let updatedCalendarItems: CalendarItem[] = [];

    if (calendarItemsType.includes(CalendarItemsFilterValues.Appointments)) {
      updatedCalendarItems = updatedCalendarItems.concat(appointments);
    }

    if (calendarItemsType.includes(CalendarItemsFilterValues.Notes)) {
      updatedCalendarItems = updatedCalendarItems.concat(notes);
    }

    if (calendarItemsType.includes(CalendarItemsFilterValues.Medicine)) {
      updatedCalendarItems = updatedCalendarItems.concat(medicine);
    }

    if (calendarItemsType.includes(CalendarItemsFilterValues.Goals)) {
      updatedCalendarItems = updatedCalendarItems.concat(goals);
    }

    if (calendarItemsType.includes(CalendarItemsFilterValues.Health)) {
      updatedCalendarItems = updatedCalendarItems.concat(health);
    }

    if (calendarItemsType.includes(CalendarItemsFilterValues.TimeTrack)) {
      updatedCalendarItems = updatedCalendarItems.concat(timeTrack);
    }

    if (calendarItemsType.includes(CalendarItemsFilterValues.Measurements)) {
      updatedCalendarItems = updatedCalendarItems.concat(measurements);
    }

    if (calendarItemsType.includes(CalendarItemsFilterValues.UseOfForce)) {
      updatedCalendarItems = updatedCalendarItems.concat(useOfForce);
    }
    if (calendarItemsType.includes(CalendarItemsFilterValues.Postings)) {
      updatedCalendarItems = updatedCalendarItems.concat(postings);
    }

    setCalendarItems(updatedCalendarItems);
  };

  useEffect(() => {
    filterData();
  }, [calendarItemsType.length, appointments.length, notes.length, medicine.length, health.length, postings.length]);

  return { calendarItems, setCalendarItems };
}

export function useCalendarSidebar() {
  const dispatch = useDispatch();
  const commonStore = useSelector<ApplicationState, CommonState>(state => state.common);
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const { userEnrollments } = commonStore;
  const {
    employeesEnrollments,
    citizensEnrollments,
    medicineNavigationData,
    departmentId: calendarDepartmentId,
    selectedCitizenEnrollments,
    selectedEmployeeEnrollments,
  } = calendarState;

  // Load initial data
  useEffect(() => {
    dispatch(loadUserEnrollments());
  }, []);

  // Load citizens and employees enrollments list
  useEffect(() => {
    if (userEnrollments.length) {
      dispatch(loadCalendarSidebarCitizens());
      dispatch(loadCalendarSidebarEmployees());
    }
  }, [userEnrollments.length]);

  // Set first department if a user has only one enrollment
  useEffect(() => {
    if (userEnrollments.length === 1 && employeesEnrollments.length) {
      dispatch(setCalendarDepartmentId(userEnrollments[0].departmentId));
    }
  }, [employeesEnrollments.length, userEnrollments.length]);

  // Set citizen from medicine table
  useEffect(() => {
    if (!!medicineNavigationData && calendarDepartmentId) {
      const medicineCitizenEnrollmentId = medicineNavigationData.citizenEnrollment;

      dispatch(loadCitizenEnrollmentCalendarItems(medicineCitizenEnrollmentId));
      dispatch(setSelectedCitizenEnrollmentsAction([medicineCitizenEnrollmentId]));
    }

    return () => {
      dispatch(setMedicineNavigationData(undefined));
    };
  }, [medicineNavigationData, calendarDepartmentId]);

  // Init citizens enrollments calendar data
  useEffect(() => {
    if (calendarDepartmentId && citizensEnrollments.length) {
      const savedCitizensEnrollmentsIds = UtilsCalendar.getSelectedSidebarDataFromLocalStorage('citizens');

      savedCitizensEnrollmentsIds.forEach(item => dispatch(loadCitizenEnrollmentCalendarItems(item)));

      dispatch(setSelectedCitizenEnrollmentsAction(savedCitizensEnrollmentsIds));
    }
  }, [calendarDepartmentId, citizensEnrollments.length]);

  // Init employees enrollments calendar data
  useEffect(() => {
    if (calendarDepartmentId && employeesEnrollments.length) {
      const savedEmployeesEnrollmentsIds = UtilsCalendar.getSelectedSidebarDataFromLocalStorage('employees');
      const userId = UtilsAuth.getEmployeeId();
      const currentUserEnrollment = employeesEnrollments.find(
        item => item.employeeId === userId && item.departmentId === calendarDepartmentId
      );

      if (currentUserEnrollment && !savedEmployeesEnrollmentsIds.includes(currentUserEnrollment.id)) {
        savedEmployeesEnrollmentsIds.push(currentUserEnrollment.id);
      }

      savedEmployeesEnrollmentsIds.forEach(item => dispatch(loadEmployeeEnrollmentCalendarItems(item)));

      dispatch(setSelectedEmployeeEnrollmentsAction(savedEmployeesEnrollmentsIds));
    }
  }, [calendarDepartmentId, employeesEnrollments.length]);

  // Save selected citizens enrollments to local storage
  useEffect(() => {
    if (!calendarDepartmentId) return;

    UtilsCalendar.saveSelectedSidebarDataToLocalStorage(selectedCitizenEnrollments, 'citizens');
  }, [selectedCitizenEnrollments.length]);

  // Save selected employees enrollments to local storage
  useEffect(() => {
    if (!calendarDepartmentId) return;

    UtilsCalendar.saveSelectedSidebarDataToLocalStorage(selectedEmployeeEnrollments, 'employees');
  }, [selectedEmployeeEnrollments.length]);
}
