import React from 'react';
import { Tag as AntdTag } from 'antd';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export default function Tag(props: Props) {
  const { children } = props;

  return (
    <AntdTag onClick={props.onClick} className={classNames(props.className, 'tag')} style={props.style}>
      {children}
    </AntdTag>
  );
}
