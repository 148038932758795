import React, { forwardRef, RefObject } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import ControlError from '../ControlError/ControlError';

import './TextInput.scss';

interface Props {
  value: string | number;
  title?: string;
  name?: string;
  type?: string;
  error?: string;
  className?: string;
  caution?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  maxNum?: number;
  numberStep?: number;
  autoComplete?: string;
  bordered?: boolean;
  onChange?(value: any, name: string): void;
  onBlur?(value: any, name: string): void;
  titleLink?: string;
  style?: React.CSSProperties;
}

const TextInput = forwardRef((props: Props, ref: RefObject<any>) => {
  const {
    value,
    name,
    title,
    type,
    caution,
    error,
    disabled,
    readonly,
    numberStep,
    className,
    onChange,
    onBlur,
    maxNum,
    autoComplete,
    bordered,
    titleLink,
    style,
  } = props;
  const isNumber = type === 'number';

  const wrapperClass = classNames('common-input', {
    'common-input--error': error,
    'common-input--caution': value && caution,
    'common-input--readonly': readonly,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const newValue = isNumber ? target.valueAsNumber : target.value;

    if (isNumber && maxNum && maxNum < newValue) return;

    onChange(newValue, target.name);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    const newValue = type === 'number' ? target.valueAsNumber : target.value;

    onBlur && onBlur(newValue, target.name);
  };

  return (
    <div className={wrapperClass} style={style}>
      {title && titleLink ? (
        <a href={titleLink} target="_blank">
          {title}
        </a>
      ) : (
        <div>{title}</div>
      )}
      <Input
        bordered={bordered}
        autoComplete={autoComplete}
        type={type || 'text'}
        value={value}
        name={name}
        className={className}
        readOnly={readonly}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={ref}
        step={numberStep}
        disabled={disabled}
      />
      <ControlError text={error} />
    </div>
  );
});

export default TextInput;
