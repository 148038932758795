import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { CalendarItemsFilterOptions } from '../../../data/calendar';
import { ApplicationState } from '../../../store';
import { CalendarState, setCalendarItemsType } from '../../../store/calendarStore';

import './CalendarItemsFilter.scss';

export const CalendarItemsFilter: React.FC = () => {
  const dispatch = useDispatch();
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const { calendarItemsType } = calendarState;

  const handleChange = value => {
    dispatch(setCalendarItemsType(value));
  };

  return (
    <div className="calendar-filter">
      <SelectMenu
        maxTagCount={1}
        title="Kalender filter"
        value={calendarItemsType as string[]}
        options={CalendarItemsFilterOptions}
        multiple
        labelKey="label"
        valueKey="value"
        onChange={handleChange}
      />
    </div>
  );
};
