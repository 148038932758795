import * as React from 'react';

import './ControlError.scss';

interface Props {
  text: string
}

export default function ControlError(props: Props) {
  const { text } = props;

  if (!text) {
    return null;
  }

  return (
    <div className="control-label">{text}</div>
  );
}
