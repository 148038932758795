import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { departmentDefault } from '../../../services/defaults';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { navigateTo, navigateToHome } from '../../../store/navigationStore';
import { ROUTES } from '../../../constants/routes';
import { usePageTitle, useZipCode } from '../../../hooks/common-hooks';
import Button from '../../../components/_shared/_controls/Button/Button';
import prodocApi, { Center, Department } from '../../../services/prodocApi';
import { Card, Row, Col } from 'antd';
import { CardActions } from '../../../components/_shared/CardActions/CardActions';
import SelectMenu from '../../../components/_shared/_controls/SelectMenu/SelectMenu';
import TextInput from '../../../components/_shared/_controls/TextInput/TextInput';
import { departmentSchema } from '../../../utils/UtilsValidation';
import { useValidation } from '../../../hooks/validation-hooks';
import { useHistory } from 'react-router';

export default function DepartmentCreatePage() {
  const [department, setDepartment] = useState(departmentDefault());
  const [centers, setCenters] = useState<Center[]>([]);
  // const [errors, setErrors] = React.useState<Department>({} as Department);
  const { validate, errors } = useValidation(departmentSchema);
  const history = useHistory();
  const { address, centerId, city, email, name, phoneNumber, zipCode } = department;
  const { getCityName } = useZipCode();

  usePageTitle('Opret afdeling');
  async function createDepartment() {
    if (validate(department)) {
      await prodocApi.department.add(department);
      history.push(ROUTES.DEPARTMENT_OVERVIEW);
    }
  }

  useEffect(() => {
    async function init() {
      var result = await prodocApi.center.getMyCenters();
      setCenters(result);
    }
    init();
  }, []);

  function handleCancel() {
    history.push(ROUTES.DEPARTMENT_OVERVIEW);
  }

  function handleFieldsChange(value: string, name: string) {
    const d = { ...department, [name]: value };

    if (name === 'zipCode') {
      setDepartment({ ...department, [name]: value, city: getCityName(value) });
    } else {
      setDepartment({ ...department, [name]: value });
    }
    validate(d);
  }

  return (
    <>
      <div className="department-page page">
        <form className="department-form item-form">
          <Card>
            <Row gutter={8}>
              <Col span={12}>
                <SelectMenu
                  title="Vælg center*"
                  name="centerId"
                  value={centerId}
                  options={centers}
                  valueKey="id"
                  labelKey="name"
                  error={errors.centerId}
                  onChange={handleFieldsChange}
                />
              </Col>
              <Col span={12}>
                <TextInput
                  title="Afdelingsnavn*"
                  name="name"
                  value={name}
                  error={errors.name}
                  onChange={handleFieldsChange}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <TextInput title="Adresse" name="address" value={address} onChange={handleFieldsChange} />
              </Col>
              <Col span={4}>
                <TextInput title="Postnummer" name="zipCode" value={zipCode} onChange={handleFieldsChange} />
              </Col>
              <Col span={8}>
                <TextInput title="By" readonly value={city} onChange={handleFieldsChange} />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <TextInput title="Telefonnummer" name="phoneNumber" value={phoneNumber} onChange={handleFieldsChange} />
              </Col>
              <Col span={12}>
                <TextInput
                  title="Email"
                  name="email"
                  error={errors.email}
                  value={email}
                  onChange={handleFieldsChange}
                />
              </Col>
            </Row>
            <CardActions
              rightActions={
                <>
                  <Button title="Opret" type="primary" onClick={createDepartment} />
                  <Button title="Annuller" onClick={handleCancel} />
                </>
              }
            />
          </Card>
        </form>
      </div>
    </>
  );
}
