import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionSetLatestMeasurements,
  loadCitizenLatestMeasurements,
  loadHealthCareItems,
  MedicineState,
} from '../../../store/medicineStore';
import { ApplicationState } from '../../../store';
import MeasurementWindow from '../../measurement/MeasurementWindow/MeasurementWindow';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import HealthAreas from '../HealthAreas/HealthAreas';

import './HealthSection.scss';
import { MeasurementType } from '../../../services/prodocApi';

export default function HealthSection(props: { latestType: MeasurementType }) {
  const dispatch = useDispatch();
  const { latestType } = props;
  const medicineStore = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { activeCitizen: citizenEnrollmentId } = medicineStore;
  const { saved: measurementsAreUpdated } = pagesState[PAGES_ITEMS.MEASUREMENT_ITEM];

  useEffect(() => {
    if (citizenEnrollmentId) {
      dispatch(loadCitizenLatestMeasurements(citizenEnrollmentId));
      dispatch(loadHealthCareItems(citizenEnrollmentId));
    }

    return () => {
      dispatch(actionSetLatestMeasurements([]));
    };
  }, [citizenEnrollmentId]);

  useEffect(() => {
    if (measurementsAreUpdated && citizenEnrollmentId) {
      dispatch(loadCitizenLatestMeasurements(citizenEnrollmentId));
    }
  }, [measurementsAreUpdated]);

  return (
    <>
      <HealthAreas />

      <MeasurementWindow preselectedEnrollmentId={citizenEnrollmentId} preselectedType={latestType} />
    </>
  );
}
