import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

interface Props {
  title?: string;
  value: any;
  options: any[];
  labelKey: string;
  valueKey: string;
  name?: string;
  disabled?: boolean;
  onChange(value: any, name: string): void;
}

export default function RadioButtons(props: Props) {
  const { title, value, options, name, labelKey, valueKey, disabled, onChange } = props;

  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value, name);
  };

  const renderButtons = () => {
    return options.map((item, index) => {
      return (
        <Radio key={index} disabled={disabled} value={item[valueKey]}>{item[labelKey]}</Radio>
      );
    });
  };

  return (
    <div className="radio-buttons">
      {!!title && <div>{title}</div>}
      <Radio.Group onChange={handleChange} value={value}>
        {renderButtons()}
      </Radio.Group>
    </div>
  );
}
