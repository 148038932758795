import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import { useEffect, useState } from 'react';

export function useMatchRoute() {
  const location = useLocation();

  function routeMatch(route: keyof typeof ROUTES) {
    const m = matchPath(location.pathname, {
      path: ROUTES[route],
      exact: true,
      strict: false,
    });
    return !!m;
  }
  function currentRoute() {
    for (var a of Object.entries(ROUTES)) {
      const m = matchPath(location.pathname, {
        path: a[1],
        exact: true,
        strict: false,
      });
      if (m) {
        return a[1];
      }
    }
  }
  return { routeMatch, currentRoute };
}
