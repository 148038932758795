import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Tooltip } from '../../_shared/Tooltip/Tooltip';
import Icon, { ICONS } from '../../_shared/Icon/Icon';
import { ApplicationState } from '../../../store';
import { CommonState, setSidebarHoverStateAction } from '../../../store/commonStore';
import { MenuItem } from '../../../types/common';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { URLS } from '../../../constants/urls';
import { ROUTES } from '../../../constants/routes';

import GoalsIcon from '../../../assets/images/png/goals.png';
import GoalsBlueIcon from '../../../assets/images/png/goals-blue.png';
import healthIcon from '../../../assets/images/png/health.png';
import healthBlueIcon from '../../../assets/images/png/health-blue.png';

import './Sidebar.scss';

interface Props {}

const SIDEBAR_MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    route: UtilsNavigation.buildRoute(URLS.CITIZEN, URLS.GOALS),
    title: 'Mål',
    iconPng: GoalsIcon,
    iconPngActive: GoalsBlueIcon,
  },
  {
    id: 2,
    route: UtilsNavigation.buildRoute(URLS.MEASUREMENTS),
    title: 'Målinger',
    icon: ICONS.barchart,
  },
  // {
  //   id: 3,
  //   route: ROUTES.MEDICINE_HEALTH.replace(URLS.PARAMS.CITIZEN_ENROLLMENT_ID_OPT, ''),
  //   title: 'Helbred og medicin',
  //   iconPng: healthIcon,
  //   iconPngActive: healthBlueIcon,
  // },
  {
    id: 13,
    route: ROUTES.FORCE,
    title: 'Magtanvendelser',
    icon: ICONS.alert,
  },
  {
    id: 15,
    route: ROUTES.DOCUMENTS,
    title: 'Dokumenter',
    icon: ICONS.file,
  },
  {
    id: 16,
    route: ROUTES.NOTES,
    title: 'Notater',
    icon: ICONS.read,
  },
];

export const Sidebar: React.FC<Props> = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const { showSidebar, showSidebarHover } = useSelector<ApplicationState, CommonState>(state => state.common);
  const wrapperClass = classNames('sidebar', { 'sidebar--active': true /*showSidebar || showSidebarHover*/ });

  const handleSidebarTriggerEnter = () => {
    dispatch(setSidebarHoverStateAction(true));
  };

  const handleMouseLeave = () => {
    dispatch(setSidebarHoverStateAction(false));
  };

  const renderMenuItems = (): JSX.Element[] => {
    return SIDEBAR_MENU_ITEMS.map(({ id, route, icon, iconPng, title, iconPngActive }) => (
      <Tooltip text={title} placement="right">
        <NavLink
          to={route}
          key={id}
          // @ts-ignore
          className={({ isActive }) => (isActive ? 'active' : '')}>
          {icon && <Icon name={icon} />}
          {iconPng && <img src={iconPng} alt="" />}
          {iconPngActive && <img className="icon-active" src={iconPngActive} alt="" />}
        </NavLink>
      </Tooltip>
    ));
  };

  return (
    <div className={wrapperClass} onMouseEnter={handleSidebarTriggerEnter} onMouseLeave={handleMouseLeave}>
      {/* <div className="sidebar__menu">
        {renderMenuItems()}
      </div> */}
      <div className="sidebar__content">{children}</div>
    </div>
  );
};
