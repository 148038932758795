import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { usePageTitle } from '../../../hooks/common-hooks';
import { DocumentsFilerData } from '../../../types/documents';
import { DocumentsFilter } from '../../../components/documents/DocumentsFilter/DocumentsFilter';
import { DocumentsTable } from '../../../components/documents/DocumentsTable/DocumentsTable';
import { DocumentsState, loadDocuments, setActiveDocument, setDocumentsList } from '../../../store/documentStore';
import { ApplicationState } from '../../../store';
import { AuthType, Employee, FileShareDto } from '../../../services/prodocApi';
import { useDocumentReadToggle } from '../../../hooks/documents-hooks';
import { DocumentEditWindow } from '../../../components/documents/DocumentEditWindow/DocumentEditWindow';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';

import './DocumentsProceduresPage.scss';

export default function DocumentsProceduresPage() {
  const dispatch = useDispatch();
  const { documentsItems } = useCitizenNavigationItems();
  const { toggleDocumentReadState } = useDocumentReadToggle();
  const documentsState = useSelector<ApplicationState, DocumentsState>(state => state.documents);
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const [filterData, setFilterData] = useState<DocumentsFilerData>({
    citizenEnrollmentId: '',
    departmentId: '',
    tags: [],
  });
  const { documentsList } = documentsState;
  const isDocumentUpdated = pagesState[PAGES_ITEMS.DOCUMENT_UPDATING].saved;
  const isAdmin = userState.authType === AuthType.Admin;

  usePageTitle('Procedurer');

  const reloadDocuments = () => {
    dispatch(loadDocuments(filterData, true));
  };
  useEffect(() => {
    dispatch(loadDocuments(filterData, true));

    return () => {
      dispatch(setDocumentsList([]));
    };
  }, []);

  useEffect(() => {
    if (isDocumentUpdated) reloadDocuments();
  }, [isDocumentUpdated]);

  const handleDocumentReadChange = async (data: FileShareDto, isRead: boolean) => {
    const isUpdated = await toggleDocumentReadState(data, isRead);

    if (isUpdated) dispatch(loadDocuments(filterData, true));
  };

  const handleDocumentEdit = (data: FileShareDto) => {
    dispatch(setActiveDocument(data));
  };

  return (
    <>
      {/* <Sidebar>
        <NavigationMenu items={documentsItems} />
      </Sidebar> */}
      <div className="page-full documents">
        {/* <DocumentsFilter data={filterData} hideTags onChange={handleFilterChange} /> */}
        <DocumentsTable
          data={documentsList}
          isProcedures
          showDeleteButton={isAdmin}
          onReadStateChange={handleDocumentReadChange}
          onDocumentEdit={handleDocumentEdit}
          onListUpdate={reloadDocuments}
        />
      </div>
      <DocumentEditWindow />
    </>
  );
}
