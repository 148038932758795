import { Col, Input, Row, Select, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import prodocApi, {
  Department,
  EmployeeEnrollmentResult,
  ResourceCalendarItemType,
  ResourceCalendarPage,
} from '../../services/prodocApi';
import { Button, Modal } from 'antd';
import { IMaskInput, IMask } from 'react-imask';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';
import { Portal } from 'react-portal';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';

import {
  checkEqualsDates,
  colors,
  correctTime,
  generateFullDateArray,
  getTotalHours,
  order,
  timeCorrect,
} from './ResourceCalendarUtil';
import { ILocalData, useResourceCalendarStore } from './ResourceCalendarStore';
import { LocalStorage } from '../../services/storage';
import { UtilsAuth } from '../../utils/UtilsAuth';
import { UtilsNavigation } from '../../utils/UtilsNavigation';
import { TransferDialog } from './TransferDialog';
import { Sidebar } from '../layout/Sidebar/Sidebar';
import SelectMenu from '../_shared/_controls/SelectMenu/SelectMenu';
import { NavigationMenu } from '../_shared/NavigationMenu/NavigationMenu';
import { usePageTitle } from '../../hooks/common-hooks';
import { useCitizenNavigationItems, useNavigation } from '../../hooks/navigation';
import { URLS } from '../../constants/urls';

import './ResourceCalendar.scss';
import DatePicker from '../_shared/_controls/DatePicker/DatePicker';

export function ResourceCalendar() {
  const [employees, setEmployees] = useState<EmployeeEnrollmentResult[]>([]);
  const resourceCalendarStore = useResourceCalendarStore();
  const {
    updateSelectedResourceCalendarItem,
    selectedResourceCalendarItem,
    resourceCalendarItems: data,
    upsert,
    remove,
    load,
  } = resourceCalendarStore;

  // const [selectedResourceCalendarItem, updateSelectedResourceCalendarItem] = useState<ILocalData>({
  //   employeeId: '',
  //   fromDate: '',
  //   toDate: '',
  //   from: '',
  //   to: '',
  //   id: '',
  //   resourceCalendarItemType: ResourceCalendarItemType.StandardHours,
  //   dayNumber: 0,
  //   overrideHours: '',
  //   fullDay: false,
  // });

  const [isAddTimeOpen, setIsAddTimeOpen] = useState(false);
  const [isPushTemplate, setIsPushTemplate] = useState(false);
  // const [data, setData] = useState<ILocalData[]>([]);
  const showModal = (employeeId, date) => {
    updateSelectedResourceCalendarItem({
      ...selectedResourceCalendarItem,
      employeeId,
      fromDate: date,
      toDate: date,
      from: '',
      to: '',
      id: '',
      resourceCalendarItemType: ResourceCalendarItemType.StandardHours,
      resourceCalendarPage: selectedPageValue,
      dayNumber: 0,
      overrideHours: '',
      fullDay: false,
      departmentId,
    });
    setIsAddTimeOpen(true);
  };
  const { employeeId, departmentId } = useParams() as any;
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const [selectedDepartmentId, setSelectedDepartmentId] = useState();

  const [selectedDates, setSelectedDates] = useState<number[]>([]);
  const { t } = useTranslation();
  const isTemplateMode = window.location.href.includes('/template/');
  const isDraftMode = window.location.href.includes('/draft/');
  const { push } = useHistory();
  const [navigateTo] = useNavigation();
  const [departments, setDepartments] = useState<Department[]>([]);

  const selectedPageValue = isTemplateMode
    ? ResourceCalendarPage.Template
    : isDraftMode
    ? ResourceCalendarPage.Draft
    : ResourceCalendarPage.Planning;
  usePageTitle('Level+');
  useEffect(() => {
    async function get() {
      const userEnrollments = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds: [departmentId],
      });

      const employees = await prodocApi.employeeEnrollment.getManyByDepartments({
        departmentIds: userEnrollments.map(x => x.departmentId),
      });
      setEmployees(employees.filter((a, i) => employees.findIndex(s => a.employeeId === s.employeeId) === i));
    }

    if (departmentId) {
      get();
    }
  }, [departmentId]);

  useEffect(() => {
    async function getDepartments() {
      const userEnrollments = await prodocApi.employeeEnrollment.getByEmployee(UtilsAuth.getEmployeeId(), true);
      const departmentIds = userEnrollments.map(item => item.departmentId);
      const userDepartments = await prodocApi.department.getMany(departmentIds);
      setDepartments(userDepartments);
    }

    getDepartments();
  }, []);

  // useEffect(() => {
  //   setSelectedDepartmentId(departmentId);
  // }, [departmentId]);

  useEffect(() => {
    const dates = [];
    let dDay = selectedDate.getDay() > 0 ? selectedDate.getDay() : 7;
    let first = selectedDate.getDate() - dDay + 1;
    let firstDayWeek = new Date(selectedDate.setDate(first));
    dates.push(new Date(firstDayWeek));
    for (let i = 0; i < 6; i++) {
      dates.push(new Date(firstDayWeek.setDate(firstDayWeek.getDate() + 1)));
    }

    setSelectedDates(dates);
  }, [selectedDate]);

  // useEffect(() => {
  //   loadFromLocalStorage();
  //   // loadData();
  // }, []);

  useEffect(() => {
    // loadFromLocalStorage();
    if (departmentId) {
      load(departmentId, selectedPageValue);
    }
  }, [departmentId, selectedPageValue]);

  async function addData(dayNumber: number) {
    upsert(selectedResourceCalendarItem);
  }

  async function updateData(dayNumber: number) {
    upsert(selectedResourceCalendarItem);
  }

  function handelOverwriteDraftFromTemplate() {
    var template = LocalStorage.getItem('/planning/template/:employeeId');
    // var draft = LocalStorage.getItem('/planning/draft/:employeeId');
    if (template) {
      prepareTemplate(JSON.parse(template));
      // LocalStorage.setItem('/planning/draft/:employeeId', template);
      // setData(JSON.parse(template));
    }
  }

  function prepareTemplate(template: any) {
    LocalStorage.setItem('/planning/draft/:employeeId', template);
  }

  function handleColumnClick(e) {
    showModal(e.target.getAttribute('data-eid'), e.target.getAttribute('data-date'));
  }

  async function handleOk() {
    const found = data.find(x => x.id == selectedResourceCalendarItem.id);
    const dayNumber = isTemplateMode
      ? ~~moment
          .duration(moment(selectedResourceCalendarItem.fromDate).diff(moment(new Date()).startOf('week')))
          .asDays() + 1
      : -1;

    let result;
    if (found) {
      //update
      updateData(dayNumber);
    } else {
      addData(dayNumber);
    }

    setIsAddTimeOpen(false);
  }

  function handleTagClick(e, d) {
    e.stopPropagation();
    var found = data.find(x => x.id === d.id);
    console.log(data);

    const local: ILocalData = {
      ...found,
      from: moment(found.fromDate).format('HH:mm'),
      to: moment(found.toDate).format('HH:mm'),
      overrideHours: found.overrideHours,
    };
    updateSelectedResourceCalendarItem(local);
    setIsAddTimeOpen(true);
  }

  function handleSelectedDateChange(func: 'prev' | 'next' | 'reset') {
    let d: Date;
    if (func === 'prev') {
      if (isTemplateMode && getWeekHeader() === 'Uge 1') {
        d = selectedDate;
      } else {
        d = moment(selectedDate).add(-7, 'day').toDate();
      }
    } else if (func === 'next') {
      d = moment(selectedDate).add(7, 'day').toDate();
    } else {
      d = new Date();
    }

    setSelectedDate(d);
  }

  function handleResourceCalendarItemTypeChange(resourceCalendarItemType: ResourceCalendarItemType) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, resourceCalendarItemType });
  }

  function handleDraftChange(value: string) {
    push(value.replace(':employeeId', employeeId).replace(':departmentId', departmentId));
  }

  const resourceCalendarItemTypes = Object.values(ResourceCalendarItemType);

  function getWeekHeader() {
    if (isTemplateMode) {
      return `Uge ${Number(moment(selectedDate).format('WW')) - Number(moment(new Date()).format('WW')) + 1}`;
    }
    return `Uge ${moment(selectedDate).format('WW')}`;
  }

  function getDateHeader(d: Date) {
    if (isTemplateMode) {
      return moment(d).format('dddd');
    }
    if (moment(d).format('DD-MM-yyyy') === moment(new Date()).format('DD-MM-yyyy')) {
      return <span style={{ fontWeight: 900 }}>{moment(d).format('ddd DD-MM-yyyy')}</span>;
    }
    return moment(d).format('ddd DD-MM-yyyy');
  }

  function handleFromChange(value: moment.Moment) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, fromDate: value.toISOString() });
  }

  function handleToChange(value: moment.Moment) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, toDate: value.toISOString() });
  }

  function setLocalDataTo(to: string) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, to });
  }

  function setCorrectLocalDataTo(to: string) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, to: correctTime(to) });
  }

  function setLocalDataFrom(from: string) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, from });
  }

  function setCorrectLocalDataFrom(from: string) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, from: correctTime(from) });
  }

  function handleOverwriteHours(overrideHours: string) {
    updateSelectedResourceCalendarItem({ ...selectedResourceCalendarItem, overrideHours });
  }

  function handleDeleteTag(e: React.MouseEvent<HTMLElement>, id: string) {
    e.preventDefault();

    Modal.confirm({
      title: 'Slet',
      icon: <QuestionCircleOutlined translate={'NA'} />,
      content: 'Ønsker du at slette?',
      okText: 'Ok',
      cancelText: 'Luk',
      onOk: () => remove(id),
    });
  }

  function handleDepartmentChange(e) {
    let url = '';
    switch (selectedPageValue) {
      case ResourceCalendarPage.Planning:
        url = UtilsNavigation.buildRoute(URLS.PLANNING, URLS.WORKPLAN, employeeId, e);
        break;
      case ResourceCalendarPage.Draft:
        url = UtilsNavigation.buildRoute(URLS.PLANNING, URLS.DRAFT, employeeId, e);
        break;
      case ResourceCalendarPage.Template:
        url = UtilsNavigation.buildRoute(URLS.TEMPLATE, URLS.TEMPLATE, employeeId, e);
        break;
    }
    navigateTo(url);
  }

  const { planningItems } = useCitizenNavigationItems();

  return (
    <>
      <Sidebar>
        <NavigationMenu items={planningItems} />
      </Sidebar>
      <div style={{ width: '100%', backgroundColor: '#FFF' }}>
        <Row>
          <Col style={{ margin: '7px' }}>
            {!isTemplateMode && (
              <Button className="controls" onClick={() => handleSelectedDateChange('reset')}>
                I dag
              </Button>
            )}
            <Button
              style={{ marginRight: 0 }}
              className="controls"
              onClick={() => handleSelectedDateChange('prev')}
              icon={<LeftOutlined translate="true" />}
            />
            <Button
              className="controls"
              onClick={() => handleSelectedDateChange('next')}
              icon={<RightOutlined translate="true" />}
            />
            {(isDraftMode || isTemplateMode) && (
              <Button className="controls" onClick={() => setIsPushTemplate(true)}>
                Overfør...
              </Button>
            )}
          </Col>
          <Col style={{ margin: '7px' }}>
            <SelectMenu
              placeholder="Vælg afdeling"
              name="departmentId"
              valueKey="id"
              labelKey="name"
              value={departments.length ? departmentId : ''}
              options={departments}
              onChange={handleDepartmentChange}
            />
          </Col>
        </Row>
        <div className="row">
          <div className="column _12 bold">{getWeekHeader()}</div>
          {selectedDates.map(d => (
            <div className="column" key={d.toString()}>
              {getDateHeader(new Date(d))}
            </div>
          ))}
          <div className="column">Antal timer</div>
        </div>
        {employees.map((x, i) => {
          const alldates = order<ILocalData>(generateFullDateArray(data), 'id');

          return (
            <div key={x.id} className="row">
              <div className="column _12">{`${x.firstname} ${x.lastname}`}</div>
              {selectedDates.map((d, i) => (
                <div
                  key={x.id + d}
                  style={{ cursor: 'pointer' }}
                  className="column"
                  data-eid={x.id}
                  data-date={moment(d).toISOString()}
                  onClick={handleColumnClick}>
                  {alldates
                    .filter(y => y.employeeId == x.id && checkEqualsDates(y.fromDate, new Date(d)))
                    .map(z => (
                      <Tooltip
                        key={x.id + d + z.employeeId + z.id}
                        placement="topLeft"
                        title={t(resourceCalendarItemTypes.find(x => x === z.resourceCalendarItemType))}>
                        <Tag
                          className="rtag"
                          color={colors[z.resourceCalendarItemType]}
                          closable
                          onClose={e => handleDeleteTag(e, z.id)}
                          onClick={e => handleTagClick(e, z)}>
                          {`${moment(z.fromDate).format('HH:mm')}-${moment(z.toDate).format('HH:mm')}`}
                        </Tag>
                      </Tooltip>
                    ))}
                </div>
              ))}
              <div className="column">{getTotalHours(x.id, alldates, selectedDates)}</div>
            </div>
          );
        })}
        <TransferDialog
          onCancel={() => setIsPushTemplate(false)}
          onOk={handelOverwriteDraftFromTemplate}
          isVisible={isPushTemplate}
        />
        {/* <Modal
        width="415px"
        title={<b>Skabelon</b>}
        open={isPushTemplate}
        footer={[
          <Button key="back" onClick={() => setIsPushTemplate(false)}>
            Nej
          </Button>,
          <Button key="submit" type="primary" onClick={handelOverwriteDraftFromTemplate}>
            Ja
          </Button>,
        ]}
        closable
        onCancel={() => setIsPushTemplate(false)}>
        <Row gutter={8}>
          <Col>
            <div>Overfør?</div>
          </Col>
        </Row>
      </Modal> */}
        <Modal
          width={'415px'}
          title={<b>{moment(selectedResourceCalendarItem.fromDate).format('dddd \\d. DD-MM-yyyy')}</b>}
          open={isAddTimeOpen}
          footer={[
            <Button key="back" onClick={() => setIsAddTimeOpen(false)}>
              Luk
            </Button>,
            <Button
              disabled={
                !selectedResourceCalendarItem.resourceCalendarItemType ||
                !timeCorrect(selectedResourceCalendarItem, selectedDate)
              }
              key="submit"
              type="primary"
              onClick={handleOk}>
              Ok
            </Button>,
          ]}
          onCancel={() => setIsAddTimeOpen(false)}>
          <Row gutter={8}>
            <Col>
              <div>Type</div>
              <Select
                value={selectedResourceCalendarItem.resourceCalendarItemType}
                style={{ width: '366px' }}
                onChange={handleResourceCalendarItemTypeChange}
                options={resourceCalendarItemTypes
                  .sort((a, b) => t(a).localeCompare(t(b)))
                  .map(x => ({
                    value: x,
                    label: (
                      <Row>
                        <Col style={{ marginTop: '2px', marginRight: '3px' }}>
                          <span className="dot" style={{ backgroundColor: colors[x] }} />
                        </Col>
                        <Col>
                          <span>{t(x)}</span>
                        </Col>
                      </Row>
                    ),
                  }))}
              />
            </Col>
          </Row>
          <Row gutter={8} style={{ marginTop: '8px' }}>
            <Col>
              <div>Fra</div>
              <DatePicker
                format="DD-MM-YYYY"
                value={moment(selectedResourceCalendarItem.fromDate)}
                onChange={handleFromChange}
                allowClear={false}
              />
            </Col>
            <Col>
              <div>kl</div>
              <IMaskInput
                // className="ant-picker"
                translate=""
                value={selectedResourceCalendarItem.from}
                mask="HH:MM"
                blocks={{
                  HH: {
                    mask: IMask.MaskedRange,
                    to: 23,
                    from: 0,
                  },
                  MM: {
                    mask: IMask.MaskedRange,
                    to: 59,
                    from: 0,
                  },
                }}
                unmask={true} // true|false|'typed'
                onAccept={(value, mask) => setLocalDataFrom(mask.value)}
                onBlur={e => setCorrectLocalDataFrom(e.target.value)}
                placeholderChar="Enter number here"
              />
            </Col>
          </Row>
          <Row gutter={8} style={{ marginTop: '8px' }}>
            <Col>
              <div>Til</div>
              <DatePicker
                format="DD-MM-YYYY"
                value={moment(selectedResourceCalendarItem.toDate)}
                onChange={handleToChange}
                allowClear={false}
              />
            </Col>
            <Col>
              <div>kl</div>
              <IMaskInput
                // className="ant-picker"
                translate=""
                value={selectedResourceCalendarItem.to}
                mask="HH:MM"
                blocks={{
                  HH: {
                    mask: IMask.MaskedRange,
                    to: 23,
                    from: 0,
                  },
                  MM: {
                    mask: IMask.MaskedRange,
                    to: 59,
                    from: 0,
                  },
                }}
                unmask={true}
                onAccept={(value, mask) => setLocalDataTo(mask.value)}
                onBlur={e => setCorrectLocalDataTo(e.target.value)}
              />
            </Col>
            <Row gutter={8} style={{ marginTop: '8px' }}>
              <Col>
                <div>Angiv timer for perioden</div>
                <Input
                  disabled={selectedResourceCalendarItem.fullDay}
                  type="number"
                  value={selectedResourceCalendarItem.overrideHours}
                  onChange={e => handleOverwriteHours(e.target.value)}
                  // onBlur={e => setCorrectOverrideHours(e.target.value)}
                />
              </Col>
            </Row>
          </Row>
        </Modal>
      </div>
    </>
  );
}
