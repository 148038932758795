import { actionCreator, isType } from './Action';
import prodocApi, {
  DocumentType,
  FileParameter,
  FileShare,
  FileShareDto,
  UploadType,
  UseOfForceCalculations,
  UseOfForceDto,
} from '../services/prodocApi';
import { DocumentsFilerData, DocumentsUploadData, UseOfForceFilterData } from '../types/documents';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { MESSAGES } from '../constants/messages';
import { PAGES_ITEMS, setPageItemLoading, setPageItemSaved } from './pagesStore';

export interface DocumentsState {
  useOfForceList: UseOfForceDto[];
  documentsList: FileShare[];
  showDocumentWindow: boolean;
  showDocumentEditWindow: boolean;
  useOfForceCalculationsDepartment?: UseOfForceCalculations;
  useOfForceCalculationsCitizen?: UseOfForceCalculations;
  activeDocument: FileShareDto | null;
}

export const defaultState: DocumentsState = {
  useOfForceList: [],
  documentsList: [],
  showDocumentWindow: false,
  showDocumentEditWindow: false,
  activeDocument: null,
};

const setUseOfForceList = actionCreator<UseOfForceDto[]>('SET_USE_OF_FORCE_LIST');
export const setDocumentsList = actionCreator<FileShare[]>('SET_DOCUMENTS_LIST');
export const setActiveDocument = actionCreator<FileShare | null>('SET_DOCUMENT_ACTIVE_ITEM');
const setUseOfForceCalculationsDepartment = actionCreator<UseOfForceCalculations>(
  'SET_USE_OF_FORCE_CALCULATIONS_DEPARTMENT'
);
const setUseOfForceCalculationsCitizen = actionCreator<UseOfForceCalculations>('SET_USE_OF_FORCE_CALCULATIONS_CITIZEN');
export const setDocumentWindowState = actionCreator<boolean>('SET_DOCUMENT_WINDOW_STATE');
export const setDocumentEditWindowState = actionCreator<boolean>('SET_DOCUMENT_EDIT_WINDOW_STATE');

export default (state: DocumentsState = defaultState, action: any): DocumentsState => {
  if (isType(action, setUseOfForceList)) {
    return { ...state, useOfForceList: action.payload };
  }

  if (isType(action, setDocumentsList)) {
    return { ...state, documentsList: action.payload };
  }

  if (isType(action, setDocumentWindowState)) {
    return { ...state, showDocumentWindow: action.payload };
  }

  if (isType(action, setDocumentEditWindowState)) {
    return { ...state, showDocumentEditWindow: action.payload };
  }

  if (isType(action, setUseOfForceCalculationsDepartment)) {
    return { ...state, useOfForceCalculationsDepartment: action.payload };
  }

  if (isType(action, setUseOfForceCalculationsCitizen)) {
    return { ...state, useOfForceCalculationsCitizen: action.payload };
  }

  if (isType(action, setActiveDocument)) {
    const document = action.payload;
    const openedState = document ? true : state.showDocumentWindow;

    return { ...state, activeDocument: document, showDocumentEditWindow: openedState };
  }

  return state;
};

export const loadUseOfForceCalculationsDepartment = () => {
  return async dispatch => {
    try {
      const item = await prodocApi.useOfForce.getDepartmentCountValues();

      dispatch(setUseOfForceCalculationsDepartment(item));
    } catch (e) {}
  };
};

export const loadUseOfForceCalculationsCitizen = (id: string) => {
  return async dispatch => {
    try {
      const item = await prodocApi.useOfForce.getCitizenCountValues(id);

      dispatch(setUseOfForceCalculationsCitizen(item));
    } catch (e) {}
  };
};

export const loadDocumentsByCitizen = (data: UseOfForceFilterData) => {
  return async dispatch => {
    try {
      const { citizenId, status, citizenName } = data;
      const items = await prodocApi.useOfForce.getAll();

      // const itemsSearch = await prodocApi.useOfForce.search(false, citizenName, status);

      // dispatch(loadUseOfForceCalculationsCitizen(citizenId));

      dispatch(setUseOfForceList(items));
    } catch (e) {}
  };
};

export const uploadDocuments = (data: DocumentsUploadData) => {
  return async dispatch => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.DOCUMENT_UPLOADING, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.DOCUMENT_UPLOADING, true));

      const { files, citizenId, type, departmentId } = data;

      for (const file of files) {
        const entityId = type === UploadType.Citizen ? citizenId : departmentId;
        const fileData: FileParameter = { data: file.data, fileName: file.data.name };
        const isProcedureTypeSelected = file.types.includes(DocumentType.Procedures);
        const documentType = isProcedureTypeSelected ? DocumentType.Procedures : DocumentType.Document;

        await prodocApi.file.upload(entityId, type, documentType, file.tagsIds, fileData);
      }

      dispatch(showSuccessMessage({ message: 'Fil uploadet' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.DOCUMENT_UPLOADING, true));
    } catch (e) {
      dispatch(setPageItemSaved(PAGES_ITEMS.DOCUMENT_UPLOADING, false));
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.DOCUMENT_UPLOADING, false));
    }
  };
};

export const loadDocuments = (data: DocumentsFilerData, isProcedures: boolean) => {
  return async dispatch => {
    try {
      // const { citizenEnrollmentId, departmentId, tags } = data;
      // let loadedItems = [];
      const documentType = isProcedures ? DocumentType.Procedures : DocumentType.Document;

      // if (citizenEnrollmentId) {
      //   loadedItems = await prodocApi.file.getManyByCitizenEnrollment(citizenEnrollmentId, tags, documentType, '');
      // }

      // if (departmentId) {
      //   loadedItems = await prodocApi.file.getManyByDepartment(departmentId, tags, documentType, '');
      // }
      var loadedItems = await prodocApi.file.getManyByDocumentType(documentType);

      dispatch(setDocumentsList(loadedItems));
    } catch (e) {}
  };
};

export const updateDocument = (data: FileShareDto) => {
  return async dispatch => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.DOCUMENT_UPDATING, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.DOCUMENT_UPDATING, true));

      const res = await prodocApi.file.update(data);

      dispatch(showSuccessMessage({ message: 'Fil uploadet' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.DOCUMENT_UPDATING, true));
    } catch (e) {
      dispatch(setPageItemSaved(PAGES_ITEMS.DOCUMENT_UPDATING, false));
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.DOCUMENT_UPDATING, false));
    }
  };
};
