import React from 'react';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import { Measurement } from '../../../../services/prodocApi';
import { Checkbox } from '../../../_shared/_controls/Checkbox/Checkbox';
import TextArea from '../../../_shared/_controls/TextArea/TextArea';

interface Props {
  measurement: Measurement;
  formName: string;
  onChange(measurement: Measurement, name: string);
}

export default function MeasurementForm(props: Props) {
  const { measurement, formName, onChange } = props;
  const { remarks, confusion, irritability, loudBehavior, verbalThreats, physicalThreats, attack } = measurement;
  const score = confusion + irritability + loudBehavior + verbalThreats + physicalThreats + attack;

  const handleFieldChange = (value: any, name: string) => {
    const isRemarks = name === 'remarks';
    const convertedValue = value ? 1 : 0;
    const newValue = isRemarks ? value : convertedValue;
    const newData = { ...measurement, [name]: newValue };

    onChange(newData, formName);
  };

  return (
    <div className="common-form">
      <Checkbox label="Forvirring" checked={!!confusion} name="confusion" onChange={handleFieldChange} />
      <Checkbox label="Irritabilitet" checked={!!irritability} name="irritability" onChange={handleFieldChange} />
      <Checkbox label="Støjende adfærd" checked={!!loudBehavior} name="loudBehavior" onChange={handleFieldChange} />
      <Checkbox label="Verbale trusler" checked={!!verbalThreats} name="verbalThreats" onChange={handleFieldChange} />
      <Checkbox
        label="Fysiske trusler"
        checked={!!physicalThreats}
        name="physicalThreats"
        onChange={handleFieldChange}
      />
      <Checkbox label="Angreb på ting eller genstande" checked={!!attack} name="attack" onChange={handleFieldChange} />
      <TextInput title="Samlet score" value={score || ''} type="number" readonly onChange={handleFieldChange} />
      <TextArea title="Bemærkning" value={remarks} name="remarks" onChange={handleFieldChange} />
    </div>
  );
}
