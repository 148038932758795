import React from 'react';
import { Goal, GoalResult } from '../../../services/prodocApi';
import Button from '../../_shared/_controls/Button/Button';
import { ICONS } from '../../_shared/Icon/Icon';
import { GoalCard } from '../GoalCard/GoalCard';

import './GoalSection.scss';

interface Props {
  items: Goal[];
  data: GoalResult;
  onEdit(data: Goal): void;
  onSubGoalCreate(goalId: string): void;
  onStatusCreate(goalId: string): void;
  onNoteCreate(goalId: string): void;
}

export default function GoalSection(props: Props) {
  const { data, onEdit, onSubGoalCreate, onStatusCreate, onNoteCreate } = props;
  const { id, subGoals = [] } = data;

  const handleCardEdit = (itemData: Goal) => {
    onEdit(itemData);
  };

  const handleSubGoalCreate = () => {
    onSubGoalCreate(id);
  };

  const renderSubItems = () => {
    if (!subGoals?.length) return null;

    return subGoals.map((item, index) => {
      return <GoalCard key={index} data={item} isSubItem onEdit={handleCardEdit} onNoteClick={onNoteCreate} />;
    });
  };

  return (
    <div className="goal-section">
      <div className="goal-section__content">
        <GoalCard data={data} onEdit={handleCardEdit} onStatusClick={onStatusCreate} onNoteClick={onNoteCreate} />
        {renderSubItems()}
        <div className="goal-section__sub-wrap">
          <Button title="Opret delmål" icon={ICONS.plus} onClick={handleSubGoalCreate} />
        </div>
      </div>
    </div>
  );
}
