import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Router as ReactRouter } from 'react-router-dom';
import { history } from './store/navigationStore';
import { auth, notAuth } from './utils/UtilsAuth';
import { ROUTES } from './constants/routes';

import LoginPage from './pages/Login/Login';
import MasterControl from './components/MasterControl/MasterControl';
import Home from './pages/Home/Home';
import EmployeeCreatePage from './pages/Employee/EmployeeCreatePage/EmployeeCreatePage';
import EmployeeEditPage from './pages/Employee/EmployeeEditPage/EmployeeEditPage';
import EmployeeEnrollPage from './pages/Employee/EmployeeEnrollPage/EmployeeEnrollPage';
import CitizenCreatePage from './pages/Citizen/CitizenCreatePage/CitizenCreatePage';
import OverviewPage from './pages/OverviewPage/OverviewPage';
import CitizenEnrollPage from './pages/Citizen/CitizenEnrollPage/CitizenEnrollPage';
import CitizenDischargePage from './pages/Citizen/CitizenDischargePage/CitizenDischargePage';
import EmployeeDischargePage from './pages/Employee/EmployeeDischargePage/EmployeeDischargePage';
import GoalsPage from './pages/Goals/GoalsPage/GoalsPage';
import GoalStatusPage from './pages/Goals/GoalStatusPage/GoalStatusPage';
import NotesPage from './pages/NotesPage/NotesPage';
import CitizenEditPage from './pages/Citizen/CitizenEditPage/CitizenEditPage';
import MeasurementsPage from './pages/Measurements/MeasurementsPage/MeasurementsPage';
import MeasurementEditPage from './pages/Measurements/MeasurementEditPage/MeasurementEditPage';
import MedicinePage from './pages/MedicinePage/MedicinePage';
import CenterCreatePage from './pages/Center/CenterCreatePage/CenterCreatePage';
import CenterEditPage from './pages/Center/CenterEditPage/CenterEditPage';
import DepartmentCreatePage from './pages/Department/DepartmentCreatePage/DepartmentCreatePage';
import DepartmentEditPage from './pages/Department/DepartmentEditPage/DepartmentEditPage';
import ForcePage from './pages/ForcePage/ForcePage';
import DocumentsListPage from './pages/Documents/DocumentsListPage/DocumentsListPage';
import ForgotPasswordPage from './pages/Login/ForgotPassword';
import NewPasswordPage from './pages/Login/NewPassword';
import DocumentsProceduresPage from './pages/Documents/DocumentsProceduresPage/DocumentsProceduresPage';
import UseOfForcePage from './pages/UseOfForce/UseOfForce';
import ForumPage from './pages/Forum/ForumPage';
import { ResourceCalendar } from './components/ResourceCalendar/ResourceCalendar';
import { CitizenOverviewPage } from './pages/Citizen/CitizenOverviewPage/CitizenOverviewPage';
import { CenterOverviewPage } from './pages/Center/CenterOverviewPage/CenterOverviewPage';
import { DepartmentOverview } from './pages/Department/DepartmentOverview/DepartmentOverview';
import { EmployeeOverviewPage } from './pages/Employee/EmployeeOverviewPage/EmployeeOverviewPage';
import { ExternalContactsList } from './pages/ExternalContacts/ExternalContactsList';
import { CreateExternalContact } from './pages/ExternalContacts/CreateExternalContact';
import prodocApi from './services/prodocApi';

export default function Router() {
  return (
    <ReactRouter history={history}>
      <Switch>
        <Redirect exact={true} path="/" to={ROUTES.LOGIN} />
        <Route exact={true} path={ROUTES.LOGIN} component={notAuth(LoginPage)} />
        <Route exact={true} path={ROUTES.FORGOT} component={notAuth(ForgotPasswordPage)} />
        <Route exact={true} path={ROUTES.NEWPASSWORD} component={notAuth(NewPasswordPage)} />

        <MasterControl className="wrapper flexContainer">
          <Route exact={true} path={ROUTES.HOME} component={auth(Home)} />
          <Route exact={true} path={ROUTES.CENTER_ADD} component={auth(CenterCreatePage)} />
          <Route exact={true} path={ROUTES.CENTER_DETAILS} component={auth(CenterEditPage)} />
          <Route exact={true} path={ROUTES.CENTER_OVERVIEW} component={auth(CenterOverviewPage)} />

          <Route exact={true} path={ROUTES.DEPARTMENT_OVERVIEW} component={auth(DepartmentOverview)} />
          <Route exact={true} path={ROUTES.DEPARTMENT_ADD} component={auth(DepartmentCreatePage)} />
          <Route exact={true} path={ROUTES.DEPARTMENT_DETAILS} component={auth(DepartmentEditPage)} />
          <Route exact={true} path={ROUTES.EMPLOYEE_ADD} component={auth(EmployeeCreatePage)} />
          <Route exact={true} path={ROUTES.EMPLOYEE_DETAILS} component={auth(EmployeeEditPage)} />
          <Route exact={true} path={ROUTES.EMPLOYEE_ENROLL} component={auth(EmployeeEnrollPage)} />
          <Route exact={true} path={ROUTES.EMPLOYEE_DISCHARGE} component={auth(EmployeeDischargePage)} />
          <Route exact={true} path={ROUTES.EMPLOYEE_OVERVIEW} component={auth(EmployeeOverviewPage)} />
          <Route exact={true} path={ROUTES.CITIZEN_ADD} component={auth(CitizenCreatePage)} />
          <Route exact={true} path={ROUTES.CITIZEN_DETAILS} component={auth(CitizenEditPage)} />
          <Route exact={true} path={ROUTES.CITIZEN_ENROLL} component={auth(CitizenEnrollPage)} />
          <Route exact={true} path={ROUTES.CITIZEN_DISCHARGE} component={auth(CitizenDischargePage)} />
          <Route exact={true} path={ROUTES.CITIZEN_OVERVIEW} component={auth(CitizenOverviewPage)} />

          <Route exact={true} path={ROUTES.OVERVIEW} component={auth(OverviewPage)} />
          <Route exact={true} path={ROUTES.USEOFFORCE} component={auth(UseOfForcePage)} />
          <Route exact={false} path={ROUTES.MEDICINE}>
            <Route exact={true} path={ROUTES.MEDICINE_CARD} component={auth(MedicinePage)} />
            <Route exact={true} path={ROUTES.MEDICINE_FMK} component={auth(MedicinePage)} />
            <Route exact={true} path={ROUTES.MEDICINE_HEALTH} component={auth(MedicinePage)} />
            <Route exact={true} path={ROUTES.MEDICINE_HISTORY} component={auth(MedicinePage)} />
          </Route>
          <Route exact={true} path={ROUTES.GOALS} component={auth(GoalsPage)} />
          <Route exact={true} path={ROUTES.EXTERNALCONTACTS} component={auth(ExternalContactsList)} />
          <Route exact={true} path={ROUTES.ADDEXTERNALCONTACT} component={auth(CreateExternalContact)} />
          <Route exact={true} path={ROUTES.EXTERNALCONTACT_DETAILS} component={auth(CreateExternalContact)} />

          <Route exact={true} path={ROUTES.GOAL_STATUS} component={auth(GoalStatusPage)} />
          <Route exact={true} path={ROUTES.GOAL_STATUSES} component={auth(GoalStatusPage)} />
          <Route exact={true} path={ROUTES.NOTES} component={auth(NotesPage)} />
          <Route exact={true} path={ROUTES.FORUM} component={auth(ForumPage)} />
          <Route exact={true} path={ROUTES.MEASUREMENTS} component={auth(MeasurementsPage)} />
          <Route exact={true} path={ROUTES.MEASUREMENTS_EDIT} component={auth(MeasurementEditPage)} />
          <Route exact={true} path={ROUTES.FORCE} component={auth(ForcePage)} />
          <Route exact={true} path={ROUTES.DOCUMENTS} component={auth(DocumentsListPage)} />
          <Route exact={true} path={ROUTES.DOCUMENTS_PROCEDURES} component={auth(DocumentsProceduresPage)} />
          <Route exact={true} path={ROUTES.PLANNING} component={auth(ResourceCalendar)} />
          <Route exact={true} path={ROUTES.TEMPLATE} component={auth(ResourceCalendar)} />
          <Route exact={true} path={ROUTES.DRAFT} component={auth(ResourceCalendar)} />
        </MasterControl>
      </Switch>
    </ReactRouter>
  );
}
