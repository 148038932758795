import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import CitizenEditForm from '../../../components/citizen/CitizenEditForm/CitizenEditForm';
import { CitizensState, enrollCitizen, updateCitizen } from '../../../store/citizenStore';
import { UtilsData } from '../../../utils/UtilsData';
import prodocApi, { Citizen, CitizenEnrollment, Employee, EnrollFromType } from '../../../services/prodocApi';
import { ROUTES } from '../../../constants/routes';
import { ApplicationState } from '../../../store';
import { DepartmentState } from '../../../store/departmentStore';
import { usePageTitle } from '../../../hooks/common-hooks';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { Modal } from 'antd';
import { useHistory, useParams } from 'react-router';
import CitizenEnrollForm from '../../../components/citizen/CitizenEnrollForm/CitizenEnrollForm';
import { useValidation } from '../../../hooks/validation-hooks';
import { citizenEnrollValidationRules } from '../../../utils/UtilsValidation';
import { CitizenEnrollModal } from '../CitizenEnrollPage/CitizenEnrollModal';

export default function CitizenEditPage() {
  const dispatch = useDispatch();
  const citizenState = useSelector<any, CitizensState>(state => state.citizens);
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  // const citizenEnrollmentId = match.params.id;
  const userId = userState.id;
  const { editCitizen } = citizenState;
  const { userDepartments } = departmentState;
  const citizenData = editCitizen.data || UtilsData.getCitizenDefaults();
  const history = useHistory();
  const { id: citizenEnrollmentId } = useParams<{ id: string }>();
  const [citizen, setCitizen] = React.useState<Citizen>();
  const [enrollErrors, setEnrollErrors] = React.useState<CitizenEnrollment>({} as CitizenEnrollment);
  const { validate, errors } = useValidation(citizenEnrollValidationRules);

  const [isEnrollOpen, setIsEnrollOpen] = useState(false);

  const [enrollmentData, setEnrollmentData] = useState({
    enrolledFrom: undefined,
    enrollmentDate: new Date(),
    enrolledDepartment: undefined,
  });

  usePageTitle('Rediger borger');

  React.useEffect(() => {
    async function get() {
      const enrollment = await prodocApi.citizenEnrollment.get(citizenEnrollmentId);
      const citizen = await prodocApi.citizen.getCitizenByEnrollmentId(enrollment.id);
      setCitizen(citizen);
    }

    get();
  }, [citizenEnrollmentId]);

  const handleFormSubmit = (data: Citizen) => {
    dispatch(updateCitizen(data));
  };

  const handleCancel = () => {
    history.push(ROUTES.CITIZEN_OVERVIEW);
  };
  const { enrolledFrom, enrollmentDate, enrolledDepartment } = enrollmentData;
  function handleEnroll() {
    if (validate(enrollmentData)) {
      dispatch(
        enrollCitizen(
          {
            citizenId: citizen.id,
            enrollmentDate: enrollmentDate,
            enrolledFrom: enrolledFrom as EnrollFromType,
          },
          [enrolledDepartment]
        )
      );
      setIsEnrollOpen(false);
    }
  }
  return (
    <>
      <div className="citizen-page page">
        <CitizenEditForm
          citizenState={citizen}
          onSubmit={handleFormSubmit}
          onCancel={handleCancel}
          onEnrollCitizen={() => setIsEnrollOpen(true)}
        />
      </div>
    </>
  );
}
