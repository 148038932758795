import { Button, Col, Form, Input, notification, Row } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import useReactRouter from 'use-react-router';
import { Card } from '../../components/_shared/Card/Card';
import Icon, { ICONS } from '../../components/_shared/Icon/Icon';
import prodocApi from '../../services/prodocApi';
import { defaultSettings } from '../../store/notificationStore';
import './Login.scss';

export const NewPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { history } = useReactRouter();
  const { search } = useLocation();
  const [hasKeyPress, setHasKeyPress] = useState(false);

  const handleCancel = () => {
    history.push('/login');
  };
  const passwordError = (() => {
    if (!hasKeyPress) {
      return '';
    }
    if (password.length < 5) {
      return 'Kodeord skal være mindt 5 tegn langt';
    }
    if (password !== passwordCheck) {
      return 'Kodeord matcher ikke';
    }
    return '';
  })();
  const keyPress = event => {
    setHasKeyPress(true);

    if (event.charCode === 13) {
      event.preventDefault();

      // loginClicked(event);
    }
  };
  const handleSavePassword = async () => {
    const id = new URLSearchParams(search).get('id');
    if (!id) {
      return;
    }
    try {
      await prodocApi.forgotPassword.updatePassword(id, password);
      notification.success({ ...defaultSettings, message: 'Brugerens kodeord er opdateret' });

      history.push('/login');
    } catch (error) {
      notification.error({ ...defaultSettings, message: 'Det er sket en fejl ved opdatering af kodeordet' });
    }
  };
  return (
    <div className="card">
      <Card title="Opret nyt kodeord">
        <form className="login-form">
          <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError}>
            <Input
              prefix={<Icon name={ICONS.lock} />}
              onKeyPress={keyPress}
              style={{ width: '300px' }}
              placeholder="Kodeord"
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
            />
          </Form.Item>
          <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError}>
            <Input
              prefix={<Icon name={ICONS.lock} />}
              onKeyPress={keyPress}
              style={{ width: '300px' }}
              placeholder="Gentag Kodeord"
              value={passwordCheck}
              onChange={e => setPasswordCheck(e.target.value)}
              type="password"
            />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Button disabled={disabled} className="button" type="link" onClick={handleCancel}>
                Annuller
              </Button>
            </Col>
            <Col span={12}>
              <Button disabled={disabled} className="button" type="primary" onClick={handleSavePassword}>
                Gem
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </div>
  );
};

export default NewPasswordPage;
