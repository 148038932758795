import React from 'react';
import Icon from '../../_shared/Icon/Icon';

import './MainMenuItem.scss';

interface Props {
  icon?: string;
  iconPng?: string;
  title: string;
  onClick(e: any): void;
}

export function MainMenuItem(props: Props) {
  const { title, icon, iconPng, onClick } = props;

  return (
    <button className="main-menu-item" type="button" onClick={onClick}>
      {icon && <Icon name={icon} />}
      {iconPng && <img className="icon" src={iconPng} alt="" />}
      <span className="main-menu-item__title" title={title}>{title}</span>
    </button>
  );
}
