import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import Button from '../../_shared/_controls/Button/Button';
import { ICONS } from '../../_shared/Icon/Icon';
import { DocumentsFileInputItem } from './DocumentsFileInputItem/DocumentsFileInputItem';
import { DocumentFile } from '../../../types/documents';

import './DocumentsFileInput.scss';
import { DocumentType } from '../../../services/prodocApi';

interface Props {
  name: string;
  clearList: boolean;
  onChange(files: DocumentFile[], name: string): void;
  error: string;
}

export const DocumentsFileInput: React.FC<Props> = props => {
  const { onChange, name, clearList, error } = props;
  const [filesMap, setFilesMap] = useState<{ [key: string]: DocumentFile }>({});
  const [filesList, setFilesList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (clearList) setFilesList([]);
  }, [clearList]);

  const handleFilesChange = data => {
    const fileList = data.fileList as UploadFile[];
    const updatedFilesMap = {};

    fileList.forEach(item => {
      updatedFilesMap[item.uid] = { data: item.originFileObj, tagsIds: [], types: [] };
    });

    setFilesList(fileList);
    setFilesMap(updatedFilesMap);
    onChange(Object.values(updatedFilesMap), name);
  };

  const fileNameChange = (fileId: string, fileName: string) => {
    const updatedFilesMap = { ...filesMap };
    const fileToUpdate = updatedFilesMap[fileId].data;
    const blob = fileToUpdate.slice(0, fileToUpdate.size, fileToUpdate.type);
    const newFile = new File([blob], fileName, { type: fileToUpdate.type });

    updatedFilesMap[fileId].data = newFile;

    setFilesMap(updatedFilesMap);
    onChange(Object.values(updatedFilesMap), name);
  };

  const fileTagsChange = (fileId: string, tagsIds: string[]) => {
    const updatedFilesMap = { ...filesMap };

    updatedFilesMap[fileId].tagsIds = tagsIds;

    setFilesMap(updatedFilesMap);
    onChange(Object.values(updatedFilesMap), name);
  };

  const fileTypesChange = (fileId: string, types: DocumentType[]) => {
    const updatedFilesMap = { ...filesMap };

    updatedFilesMap[fileId].types = types;

    setFilesMap(updatedFilesMap);
    onChange(Object.values(updatedFilesMap), name);
  };

  const renderItem = (
    originNode: React.ReactElement,
    file: UploadFile,
    fileList: object[],
    actions: { remove: any },
  ) => {
    return (
      <DocumentsFileInputItem
        data={file}
        onDelete={actions.remove}
        onNameChange={fileNameChange}
        onTagsChange={fileTagsChange}
        onTypesChange={fileTypesChange}
      />
    );
  };

  return (
    <div className="df-input">
      <Upload itemRender={renderItem} fileList={filesList} customRequest={() => {}} onChange={handleFilesChange}>
        <Button icon={ICONS.upload} title="Gennemse" error={error} />
      </Upload>
    </div>
  );
};
