import React, { useEffect, useMemo, useState } from 'react';
import { MomentInput } from 'moment';

import prodocApi, { CitizenEnrollmentResult, Note, Posting } from '../../../services/prodocApi';
import CalendarItemDrawer from '../CalendarItemDrawer/CalendarItemDrawer';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import Button from '../../_shared/_controls/Button/Button';
import { NoteTypes } from '../../../constants/calendar';
import { postingDefault } from '../../../services/defaults';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { CalendarItemExt } from '../../../types/calendar';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import TextArea from '../../_shared/_controls/TextArea/TextArea';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { Col, Row } from 'antd';
import { Textbox } from '../../_shared/_controls/Textbox/Textbox';

export interface Props {
  isOpened: boolean;
  preselectedEnrollmentId?: string;
  data?: CalendarItemExt;
  isLoading?: boolean;
  onClose(): void;
  onUpdateCalendarItem();
}

export default function CalendarPostingWindow(props: Props) {
  const { data, isOpened, isLoading, onClose, onUpdateCalendarItem } = props;
  console.log(data);

  const { translateEnumOptions } = useEnumOptionsTranslation();

  const [posting, setPosting] = useState<Posting>({
    ...postingDefault(),
  });

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [deltaAmount, setDeltaAmount] = useState<number>(0);
  const [sum, setSum] = useState(0);
  const [citizenEnrollments, setCitizenEnrollments] = useState<CitizenEnrollmentResult[]>([]);

  const { amount, transactionDate, description, appendixes, date, citizenEnrollmentId } = posting;

  const handleFieldChange = (value: Date | string | boolean | MomentInput, name: string) => {
    setPosting({ ...posting, [name]: value });
  };

  useEffect(() => {
    async function init() {
      const enrollments = await prodocApi.citizenEnrollment.getMyCitizenEnrollments();
      setCitizenEnrollments(enrollments);
    }
    init();
  }, []);
  useEffect(() => {
    async function init() {
      if (data) {
        if (data.entityId) {
          const p = await prodocApi.posting.get(data.entityId);
          setPosting(p);
          setDeltaAmount(p.amount);
        } else {
          setPosting({ ...posting, date: data?.fromDate || new Date(), transactionDate: data?.fromDate || new Date() });
        }
      }
    }
    init();
  }, [data]);
  useEffect(() => {
    async function init() {
      const s = await prodocApi.posting.calculate(citizenEnrollmentId);
      setSum(s);
    }
    init();
  }, [posting]);

  async function handleSave(e: any) {
    try {
      setIsSaving(true);
      if (!posting.id) {
        await prodocApi.posting.add(posting);
      } else {
        await prodocApi.posting.update(posting);
      }
      onUpdateCalendarItem && onUpdateCalendarItem();
      onClose && onClose();
    } finally {
      setIsSaving(false);
    }
  }

  function calculate() {
    const a = amount || 0;
    return Number(sum) + Number(a) - Number(deltaAmount);
  }

  return (
    <CalendarItemDrawer
      title="Postering"
      isOpen={isOpened}
      buttonsRight={
        <>
          <Button title="Gem" type="primary" disabled={isSaving || !citizenEnrollmentId} onClick={handleSave} />
          <Button title="Annuller" onClick={onClose} />
        </>
      }
      onClose={onClose}>
      <div className="common-form">
        <SelectMenu
          value={citizenEnrollmentId}
          name="citizenEnrollmentId"
          title="Vælg borger"
          placeholder="Vælg borger"
          valueKey="id"
          withSearch
          allowClear
          labelKey={['firstname', 'lastname']}
          options={citizenEnrollments}
          onChange={handleFieldChange}
        />
        <SelectMenu
          value="Standard"
          name="NA"
          title="Konto"
          placeholder="Konto"
          valueKey="NA"
          withSearch
          disabled
          labelKey={['NA', 'NA']}
          options={['Standard']}
          onChange={handleFieldChange}
        />
        <TextInput
          value={amount}
          name="amount"
          title="Beløb"
          // type="number"
          onChange={handleFieldChange}
          style={{ marginBottom: 0 }}
          disabled={!citizenEnrollmentId}
        />
        <div style={{ marginBottom: 20, marginLeft: 10 }}>Saldo efter postering {calculate()} dkr</div>
        <DatePicker
          showTime
          title="Dato"
          name="date"
          value={date}
          onChange={handleFieldChange}
          format={DATE_TIME_FORMAT}
          disabled={!citizenEnrollmentId}
        />
        <DatePicker
          showTime
          title="Transaktionsdato"
          name="transactionDate"
          value={transactionDate}
          onChange={handleFieldChange}
          format={DATE_TIME_FORMAT}
          disabled={!citizenEnrollmentId}
        />
        <TextArea
          title="Beskrivelse"
          name="description"
          value={description}
          onChange={handleFieldChange}
          disabled={!citizenEnrollmentId}
        />
      </div>
    </CalendarItemDrawer>
  );
}
