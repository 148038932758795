import React, { useState } from 'react';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { statusOptions } from '../../../data/calendar';
import Button from '../../_shared/_controls/Button/Button';
import { CalendarStatus } from '../../../services/prodocApi';

import './AppointmentStatusChanger.scss';

interface Props {
  status: CalendarStatus;
  onStatusSave(status: CalendarStatus): void;
}

export default function AppointmentStatusChanger(props: Props) {
  const { status, onStatusSave } = props;

  const [localStatus, setLocalStatus] = useState(status);

  const handleStatusChange = (newStatus: CalendarStatus) => {
    setLocalStatus(newStatus);
  };

  const handleStatusSave = () => {
    onStatusSave(localStatus);
  };

  return (
    <div className="status-changer">
      <SelectMenu
        value={localStatus}
        options={statusOptions}
        valueKey="value"
        labelKey="label"
        onChange={handleStatusChange}
      />
      <Button title="Opdater" onClick={handleStatusSave}/>
    </div>
  );
}
