import * as React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useNavigation } from '../../../hooks/navigation';
import Autocomplete, { AcOption } from '../Autocomplete/Autocomplete';
import Button from '../_controls/Button/Button';
import { useCprValidation } from '../../../hooks/validation-hooks';

import './ItemsSearch.scss';

interface Props {
  data: any[],
  type: 'employee' | 'citizen' | 'department' | 'center'
  addButtonText?: string,
  optionLabelItems: string[],
  placeholder?: string,
  error?: string,
  title?: string,
  name?: string,
  value?: string,
  defaultOption?: any,
  updateResultAction(data: any): any;
  onSelect(value: string, name?: string): void;
  onAdd?(): void;
  searchAction?(searchStr: string): Function;
  onSearch?(value: string): void;
  onChange?(value: string, name?: string): void;
}

export default function ItemsSearch(props: Props) {
  const {
    data,
    placeholder,
    searchAction,
    type,
    optionLabelItems,
    error,
    title,
    name,
    value: fieldValue,
    defaultOption,
    addButtonText,
    updateResultAction,
    onSearch,
    onAdd,
    onSelect,
    onChange,
  } = props;

  const dispatch = useDispatch();
  const [navigateTo] = useNavigation();
  const { isCprValid } = useCprValidation();
  const [localValue, setLocalValue] = React.useState('');
  const wrapperClass = classNames('items-search', {
    'items-search--error': error,
  });

  const getDisplayedValue = (item: any, isOption?: boolean): any => {
    if (isOption) {
      return optionLabelItems.map((labelKey: string, index: number) => {
        const className = `option-${type}-${labelKey}`;
        let displayedValue = item[labelKey];

        if (labelKey === 'cpr') {
          displayedValue = <span className="no-format">Cpr nr: <em>{displayedValue}</em></span>;
        }

        return <span key={index} className={className}><em>{displayedValue} </em></span>;
      });
    }

    return optionLabelItems.map((labelKey: string) => {
      if (labelKey === 'cpr') {
        return `Cpr nr: ${item[labelKey]}`;
      }

      return item[labelKey];
    }).join(' ');
  };

  React.useEffect(() => {
    if (defaultOption) {
      const defaultValue = getDisplayedValue(defaultOption);

      setLocalValue(defaultValue);
    }
  }, [defaultOption]);

  React.useEffect(() => {
    if (fieldValue) {
      setLocalValue(fieldValue);
    }
  }, [fieldValue]);

  const handleChange = (value: string) => {
    // Cpr field accepts only numbers
    if (name === 'cpr' && !isCprValid(value)) return;

    if (!value) {
      onSelect(value, name);
    }

    setLocalValue(value);
    onChange && onChange(value, name);
  };

  const handleSearch = (value: string) => {
    if (searchAction) {
      dispatch(searchAction(value));
    }

    if (onSearch) {
      onSearch(value);
    }
  };

  const handleSelect = (value: string) => {
    const selectedItem = data.find((item: any) => item.id === value);
    const selectedValue = getDisplayedValue(selectedItem);

    setLocalValue(fieldValue || selectedValue);

    dispatch(updateResultAction(null));
    onSelect(value, name);
  };

  const handleDropDownChange = (open: boolean) => {
    if (data && !data.length && !open) {
      dispatch(updateResultAction(null));
    }
  };

  const handleEmptyValue = () => {
    dispatch(updateResultAction(null));
  };

  const handleAddNavigate = () => {
    navigateTo(`/${type}/add`);

    if (onAdd) {
      onAdd();
    }
  };

  const renderOptions = () => {
    if (!data) return null;

    return data.map((item: any) => {
      const { id } = item;
      const label = getDisplayedValue(item, true);

      return (
        <AcOption key={id} value={id}>{label}</AcOption>
      );
    });
  };

  return (
    <div className={wrapperClass}>
      <Autocomplete
        data={data}
        value={localValue}
        placeholder={placeholder}
        error={error}
        title={title}
        clearValueAfterSelect
        onChange={handleChange}
        onSearch={handleSearch}
        onSelect={handleSelect}
        onDropDownChange={handleDropDownChange}
        onValueEmpty={handleEmptyValue}
      >
        {renderOptions()}
      </Autocomplete>
      {addButtonText && (
        <Button title={addButtonText} onClick={handleAddNavigate}/>
      )}
    </div>
  );
}
