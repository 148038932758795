import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '../../_shared/Drawer/Drawer';
import { ApplicationState } from '../../../store';
import { DocumentsState, setDocumentWindowState, uploadDocuments } from '../../../store/documentStore';
import { DocumentsFileInput } from '../DocumentsFileInput/DocumentsFileInput';
import Button from '../../_shared/_controls/Button/Button';
import RadioButtons from '../../_shared/_controls/RadioButtons/RadioButtons';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { UploadType } from '../../../services/prodocApi';
import { CalendarState, loadCalendarCitizensEnrollments } from '../../../store/calendarStore';
import { DocumentsUploadData } from '../../../types/documents';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { DepartmentState } from '../../../store/departmentStore';
import { loadUserDepartments } from '../../../store/userStore';
import { loadUserEnrollments } from '../../../store/commonStore';
import { LoadingAnimation } from '../../_shared/LoadingAnimation/LoadingAnimation';

import './DocumentAddWindow.scss';

const docTypes = [
  {
    label: 'Borger',
    value: UploadType.Citizen,
  },
  {
    label: 'Afdeling',
    value: UploadType.Department,
  },
];

export const DocumentAddWindow: React.FC = () => {
  const dispatch = useDispatch();
  const documentsState = useSelector<ApplicationState, DocumentsState>(state => state.documents);
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const departmentsStore = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const isSaved = pagesStore[PAGES_ITEMS.DOCUMENT_UPLOADING].saved;
  const isLoading = pagesStore[PAGES_ITEMS.DOCUMENT_UPLOADING].loading;

  const [formData, setFormData] = useState<DocumentsUploadData>(new DocumentsUploadData());
  const [errors, setErrors] = useState({ isCitizenTypeError: '', isDepartmentTypeError: '', fileError: '' });
  const { showDocumentWindow } = documentsState;
  const { citizenId, departmentId, type } = formData;
  const isCitizenType = type === UploadType.Citizen;
  const { citizensEnrollments, departmentId: calendarDepartmentId } = calendarStore;
  const { userDepartments } = departmentsStore;

  const citizenEnrollmentsList = useMemo(() => {
    return citizensEnrollments.filter(item => item.departmentId === calendarDepartmentId);
  }, [calendarDepartmentId, citizensEnrollments.length]);

  useEffect(() => {
    if (isSaved) {
      handleClose();
    }
  }, [isSaved]);
  useEffect(() => {
    if (showDocumentWindow) {
      dispatch(loadUserEnrollments());
      dispatch(loadUserDepartments());
      dispatch(loadCalendarCitizensEnrollments());
    }
  }, [showDocumentWindow]);

  const handleClose = () => {
    dispatch(setDocumentWindowState(false));
    setFormData(new DocumentsUploadData());
  };

  const handleSave = () => {
    if (!hasErrors()) {
      dispatch(uploadDocuments(formData));
    }
  };

  const handleFieldChange = (value: any, name: string) => {
    setErrors({ ...errors, fileError: '', isCitizenTypeError: '', isDepartmentTypeError: '' });
    setFormData({ ...formData, [name]: value });
  };
  function hasErrors() {
    const isCitizenTypeError = isCitizenType && !formData.citizenId ? 'Skal udfyldes' : '';
    const isDepartmentTypeError = !isCitizenType && !formData.departmentId ? 'Skal udfyldes' : '';
    const fileError = formData.files.length === 0 ? 'Vælg mindst 1 fil at uploade' : '';

    setErrors({ ...errors, isCitizenTypeError, isDepartmentTypeError, fileError });
    return !!isCitizenTypeError || !!isDepartmentTypeError || !!fileError;
  }

  return (
    <Drawer
      width={600}
      title="Filupload"
      visible={showDocumentWindow}
      onClose={handleClose}
      buttons={
        <div className="documents-window__actions">
          <div>
            <Button
              className="documents-window__save-btn"
              title="Upload filer"
              type="primary"
              disabled={isLoading}
              onClick={handleSave}
            />
            <LoadingAnimation active={isLoading} />
          </div>
          <Button title="Annuller" onClick={handleClose} />
        </div>
      }>
      <form className="common-form">
        <DocumentsFileInput
          error={errors.fileError}
          name="files"
          clearList={!showDocumentWindow}
          onChange={handleFieldChange}
        />
        <RadioButtons
          value={type}
          options={docTypes}
          valueKey="value"
          labelKey="label"
          name="type"
          onChange={handleFieldChange}
        />
        {isCitizenType ? (
          <SelectMenu
            value={citizenId}
            name="citizenId"
            title="Vælg borger"
            placeholder="Vælg borger"
            valueKey="id"
            withSearch
            allowClear
            error={errors.isCitizenTypeError}
            labelKey={['firstname', 'lastname']}
            options={citizenEnrollmentsList}
            onChange={handleFieldChange}
          />
        ) : (
          <SelectMenu
            value={departmentId}
            name="departmentId"
            title="Vælg afdeling"
            placeholder="Vælg afdeling"
            valueKey="id"
            labelKey="name"
            withSearch
            allowClear
            error={errors.isDepartmentTypeError}
            options={userDepartments}
            onChange={handleFieldChange}
          />
        )}
      </form>
    </Drawer>
  );
};
