import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePageTitle } from '../../../hooks/common-hooks';
import { DocumentsFilter } from '../../../components/documents/DocumentsFilter/DocumentsFilter';
import { DocumentsFilerData } from '../../../types/documents';
import { useCalendarEnrollmentsLoading } from '../../../hooks/calendar-hooks';
import { DocumentsTable } from '../../../components/documents/DocumentsTable/DocumentsTable';
import { DocumentsState, loadDocuments, setActiveDocument, setDocumentsList } from '../../../store/documentStore';
import { ApplicationState } from '../../../store';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { AuthType, Employee, FileShareDto } from '../../../services/prodocApi';
import { DocumentEditWindow } from '../../../components/documents/DocumentEditWindow/DocumentEditWindow';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';

import './DocumentsListPage.scss';

export default function DocumentsListPage() {
  const dispatch = useDispatch();
  const { documentsItems } = useCitizenNavigationItems();
  const documentsState = useSelector<ApplicationState, DocumentsState>(state => state.documents);
  const pagesStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const [filterData, setFilterData] = useState<DocumentsFilerData>({
    citizenEnrollmentId: '',
    departmentId: '',
    tags: [],
  });
  const { documentsList } = documentsState;
  const isDocumentUpdated = pagesStore[PAGES_ITEMS.DOCUMENT_UPDATING].saved;
  const isAdmin = userState.authType === AuthType.Admin;

  useCalendarEnrollmentsLoading();
  usePageTitle('Dokumenter');

  const reloadDocuments = () => {
    dispatch(loadDocuments(filterData, false));
  };

  useEffect(() => {
    dispatch(loadDocuments(filterData, false));

    return () => {
      dispatch(setDocumentsList([]));
    };
  }, []);

  useEffect(() => {
    if (isDocumentUpdated) reloadDocuments();
  }, [isDocumentUpdated]);

  const handleDocumentEdit = (data: FileShareDto) => {
    dispatch(setActiveDocument(data));
  };

  return (
    <>
      {/* <Sidebar>
        <NavigationMenu items={documentsItems} />
      </Sidebar> */}
      <div className="page-full documents">
        {/* <DocumentsFilter data={filterData} onChange={handleFilterChange} /> */}
        <DocumentsTable
          data={documentsList}
          showDeleteButton={isAdmin}
          onDocumentEdit={handleDocumentEdit}
          onListUpdate={reloadDocuments}
        />
      </div>
      <DocumentEditWindow />
    </>
  );
}
