import * as React from 'react';
import { Typography } from 'antd';
import { getSelectedDateSpan } from '../../../services/localStorageService';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MetaState } from '../../../store/metaStore';
import { useState } from 'react';
import { useEffect } from 'react';
import { setUser } from '../../../store/userStore';
import { navigateToHome } from '../../../store/navigationStore';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import MainMenu from '../../MainMenu/MainMenu';
import { ApplicationState } from '../../../store';
import { Employee } from '../../../services/prodocApi';
import { URLS } from '../../../constants/urls';
import Icon, { ICONS } from '../../_shared/Icon/Icon';
import PageTitleContext from '../../../context/PageTitleContext';
import logo from '../../../assets/images/logo.svg';
import { setDocumentWindowState } from '../../../store/documentStore';

import './Header.scss';
import { CommonState, setSidebarState } from '../../../store/commonStore';

export default function Header() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const meta = useSelector((state: any) => state.meta) as MetaState;
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const { firstname, lastname } = userState;
  const { showSidebar } = commonState;
  const isHomeScreen = pathname.indexOf(URLS.HOME) >= 0;
  let dateString = '';
  const { title, subTitle } = React.useContext(PageTitleContext);

  const [admin, setAdmin] = useState();

  useEffect(() => {
    const set = async () => {
      dispatch(setUser());
      // @ts-ignore
      setAdmin(await UtilsAuth.isAdmin());
    };

    set();
  }, [dispatch]);

  const handleLogoClick = () => {
    dispatch(navigateToHome());
  };

  if (meta.selectedDate) {
    if (getSelectedDateSpan() === 'week') {
      const dateStringFrom = moment(meta.selectedDate.start).format('dddd \\d. DD MMMM YYYY');
      const dateStringTo = moment(meta.selectedDate.end).format('dddd \\d. DD MMMM YYYY');
      dateString = `${dateStringFrom} - ${dateStringTo}`;
    }

    if (getSelectedDateSpan() === 'month') {
      const dateStringFrom = moment(meta.selectedDate.start).add(7, 'days').format('MMMM YYYY');
      dateString = `${dateStringFrom}`;
    }

    if (getSelectedDateSpan() === 'day') {
      dateString = moment(meta.selectedDate.start).format('dddd \\d. DD MMMM YYYY');
    }
  }

  const toggleSidebar = () => {
    dispatch(setSidebarState(!showSidebar));
  };

  const handleDocumentAdd = () => {
    dispatch(setDocumentWindowState(true));
  };

  return (
    <header className="header pageHeader">
      {/* <button className="header__calendar-btn" type="button" onClick={toggleSidebar}>
        <Icon name={showSidebar ? ICONS.menuFold : ICONS.menuUnfold}/>
      </button> */}
      <div className="header__row">
        <div className="header__content">
          <div>
            <img className="header__logo" alt="" src={logo} onClick={handleLogoClick} />
            <div className="header__username">
              {firstname} {lastname}
            </div>
          </div>
          <div id="calendarControlPortal" />
        </div>
        <div>
          <h1 className="page-title">{title}</h1>
          {subTitle && <div>{subTitle}</div>}
        </div>
        <div className="header__actions">
          <div>
            <Typography.Title style={{ marginTop: 7 }} level={3}>
              {dateString}
            </Typography.Title>
            <button className="header__doc-btn empty-btn" onClick={handleDocumentAdd}>
              <Icon name={ICONS.fileAdd} />
            </button>
            <MainMenu />
          </div>
        </div>
      </div>
    </header>
  );
}
