import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouterParams } from '../../../hooks/navigation';
import { CenterState } from '../../../store/centerStore';
import { centerDefault } from '../../../services/defaults';
import prodocApi, { Center } from '../../../services/prodocApi';
import { ROUTES } from '../../../constants/routes';
import { usePageTitle, useZipCode } from '../../../hooks/common-hooks';
import Button from '../../../components/_shared/_controls/Button/Button';
import { useHistory } from 'react-router';
import TextInput from '../../../components/_shared/_controls/TextInput/TextInput';
import { Card, Row, Col } from 'antd';
import { CardActions } from '../../../components/_shared/CardActions/CardActions';
import { useValidation } from '../../../hooks/validation-hooks';
import { centerSchema } from '../../../utils/UtilsValidation';

export default function CenterEditPage() {
  const [match] = useRouterParams();
  const history = useHistory();
  const [center, setCenter] = useState<Center>({});
  const centerId = match.params.id;
  const { validate, errors } = useValidation(centerSchema);
  const { getCityName } = useZipCode();

  usePageTitle('Rediger center');

  useEffect(() => {
    async function init() {
      const result: Center = await prodocApi.center.get(centerId);
      setCenter(result);
    }
    init();
  }, []);

  async function update() {
    if (validate(center)) {
      await prodocApi.center.update(center);
      history.push(ROUTES.CENTER_OVERVIEW);
    }
  }

  const handleCancel = () => {
    history.push(ROUTES.CENTER_OVERVIEW);
  };
  function handleFieldsChange(value: string, name: string) {
    if (name === 'zipCode') {
      setCenter({ ...center, [name]: value, city: getCityName(value) });
    } else {
      setCenter({ ...center, [name]: value });
    }
  }

  const { name, id, address, zipCode, city, phoneNumber, email } = center;

  return (
    <>
      <div className="center-page page item-form">
        <Card>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput
                title="Center navn*"
                name="name"
                value={name}
                error={errors?.name}
                onChange={handleFieldsChange}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput title="Adresse" name="address" value={address} onChange={handleFieldsChange} />
            </Col>
            <Col span={4}>
              <TextInput title="Postnummer" name="zipCode" value={zipCode} onChange={handleFieldsChange} />
            </Col>
            <Col span={8}>
              <TextInput title="By" readonly value={city} onChange={handleFieldsChange} />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput title="Telefonnummer" name="phoneNumber" value={phoneNumber} onChange={handleFieldsChange} />
            </Col>
            <Col span={12}>
              <TextInput title="Email" name="email" value={email} error={errors.email} onChange={handleFieldsChange} />
            </Col>
          </Row>
          <CardActions
            rightActions={
              <>
                <Button title="Opdater" type="primary" onClick={update} />
                <Button title="Annuller" onClick={handleCancel} />
              </>
            }
          />
        </Card>
      </div>
    </>
  );
}
