import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CalendarItem, MedicineState, MedicineType } from '../../../services/prodocApi';
import CalendarItemDrawer from '../CalendarItemDrawer/CalendarItemDrawer';
import Button from '../../_shared/_controls/Button/Button';
import { calendarItemDefault, medicineDefault, medicineStateDefault } from '../../../services/defaults';
import { CalendarMedicineWindowForm } from './CalendarMedicineWindowForm/CalendarMedicineWindowForm';
import { MedicineCalendarItem } from '../../../types/calendar';
import { UtilsMedicine } from '../../../utils/UtilsMedicine';
import { UtilsDate } from '../../../utils/UtilsDate';
import { addStateToMedicineItem } from '../../../store/medicineStore';

interface Props {
  isOpened: boolean;
  preselectedEnrollmentId?: string;
  data: CalendarItem;
  onClose(): void;
}

export const CalendarMedicineWindow: React.FC<Props> = props => {
  const { data, isOpened, onClose } = props;
  const dispatch = useDispatch();
  const handledData = data || calendarItemDefault();
  const { title, fromDate, medicineState, medicineData } = handledData as MedicineCalendarItem;
  const { dailyDose, medicineType } = medicineData || medicineDefault();
  const [formData, setFormData] = useState<MedicineState>(UtilsMedicine.getDefaultMedicineState());
  const subtitle = `Dosis ${dailyDose}`;
  const isSchemaMedicine = medicineType.indexOf(MedicineType.BySchema) >= 0;

  useEffect(() => {
    if (medicineState) {
      const { medicinePassout, passoutFailedExplanation } = medicineState;

      setFormData(prevState => ({ ...prevState, medicinePassout, passoutFailedExplanation }));
    }
  }, [medicineState]);

  useEffect(() => {
    if (isOpened && !formData.timeStamp && !UtilsDate.isDefaultDate(fromDate)) {
      setFormData(prevState => ({ ...prevState, timeStamp: fromDate }));
    }
  }, [fromDate, isOpened]);

  const handleClose = () => {
    setFormData(UtilsMedicine.getDefaultMedicineState());
    onClose();
  };

  const handleFormDataChange = (data: MedicineState) => {
    setFormData(data);
  };

  const handleFormSubmit = () => {
    dispatch(addStateToMedicineItem(formData, medicineData));
    handleClose();
  };

  return (
    <CalendarItemDrawer
      isOpen={isOpened}
      title={title}
      subtitle={subtitle}
      buttonsRight={
        <>
          <Button title="Gem" type="primary" onClick={handleFormSubmit} />
          <Button title="Annuller" onClick={handleClose} />
        </>
      }
      onClose={handleClose}>
      <CalendarMedicineWindowForm data={formData} showDose={isSchemaMedicine} onChange={handleFormDataChange} />
    </CalendarItemDrawer>
  );
};
