import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UploadFile } from 'antd/lib/upload/interface';
import Icon, { ICONS } from '../../../_shared/Icon/Icon';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';
import SelectMenu from '../../../_shared/_controls/SelectMenu/SelectMenu';
import { Tooltip } from '../../../_shared/Tooltip/Tooltip';
import { useEnumOptionsTranslation, useTagsSearch } from '../../../../hooks/common-hooks';
import { DocumentType } from '../../../../services/prodocApi';

import './DocumentsFileInputItem.scss';

interface Props {
  data: UploadFile;
  onNameChange(id: string, name: string): void;
  onTagsChange(id: string, tags: string[]): void;
  onTypesChange(id: string, types: DocumentType[]): void;
  onDelete(): void;
}

export const DocumentsFileInputItem: React.FC<Props> = (props) => {
  const { data, onDelete, onNameChange, onTagsChange, onTypesChange } = props;
  const { uid, name } = data;
  const { searchTags, tags } = useTagsSearch();
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const [editMode, setEditMode] = useState(false);
  const [localName, setLocalName] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [types, setTypes] = useState<DocumentType[]>([]);

  useEffect(() => {
    setLocalName(name);
  }, [uid]);

  const debouncedTagsSearch = useDebouncedCallback(
    (value: string) => {
      searchTags(value);
    },
    300,
  );

  const toggleEditMode = () => {
    setEditMode(prevState => !prevState);
  };

  const handleNameSave = () => {
    toggleEditMode();
    onNameChange(uid, localName);
  };

  const handleTagsChange = (value: any) => {
    debouncedTagsSearch.callback(value);
  };

  const handleTagsSelect = (value: string[]) => {
    setSelectedTags(value);
    onTagsChange(uid, value);
  };

  const handleTypeChange = (value: any[]) => {
    onTypesChange(uid, value);
    setTypes(value);
  };

  return (
    <div className="df-item">
      <div className="df-item__left">
        <div className="df-item__name-wrap">
          {
            editMode
              ? <TextInput value={localName} onChange={setLocalName} onBlur={handleNameSave} />
              : (
                <Tooltip text={localName}>
                  <div className="df-item__name">{localName}</div>
                </Tooltip>
              )
          }
        </div>
        <div className="df-item__actions">
          <button type="button" className="empty-btn" onClick={toggleEditMode}>
            <Icon name={ICONS.edit} />
          </button>
          <button type="button" className="empty-btn" onClick={onDelete}>
            <Icon name={ICONS.delete} />
          </button>
        </div>
      </div>
      <div className="df-item__right">
        <SelectMenu
          title="Tags"
          options={tags}
          multiple
          value={selectedTags}
          valueKey="id"
          labelKey="name"
          onChange={handleTagsSelect}
          onSearch={handleTagsChange}
        />
        <SelectMenu
          title="Type"
          options={translateEnumOptions(DocumentType)}
          multiple
          value={types}
          valueKey="value"
          labelKey="label"
          onChange={handleTypeChange}
        />
      </div>
    </div>
  );
};
