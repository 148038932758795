import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MedicineCitizenField } from './MedicineCitizenField/MedicineCitizenField';
import { MedicineState, updateMedicineCitizen } from '../../../store/medicineStore';
import { ApplicationState } from '../../../store';

import './MedicineCitizenFields.scss';
import { Col, Row } from 'antd';
import { CitizenEnrollmentResult } from '../../../services/prodocApi';
interface Props {
  disabled: boolean;
  citizenEnrollment: CitizenEnrollmentResult;
}
export const MedicineCitizenFields: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { disabled, citizenEnrollment } = props;
  const medicineState = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const { selectedCitizenData } = medicineState;
  const { cave, vaccines, diagnoses } = selectedCitizenData;

  const handleCaveSave = (value: string, name: string) => {
    if (selectedCitizenData[name] != value) {
      const updatedCitizenData = { ...selectedCitizenData, [name]: value };
      dispatch(updateMedicineCitizen(updatedCitizenData));
    }
  };

  return (
    <div className="mc-fields">
      <Row gutter={8}>
        <Col span={8}>
          <MedicineCitizenField disabled={disabled} title="Cave" value={cave} name="cave" onSave={handleCaveSave} />
        </Col>
        <Col span={8}>
          <MedicineCitizenField
            disabled={disabled}
            title="Diagnoser"
            value={diagnoses}
            name="diagnoses"
            onSave={handleCaveSave}
          />
        </Col>
        <Col span={8}>
          <MedicineCitizenField
            disabled={disabled}
            title="Vaccinationer"
            value={vaccines}
            name="vaccines"
            onSave={handleCaveSave}
          />
        </Col>
      </Row>
    </div>
  );
};
