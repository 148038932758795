import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

interface Props {
  text: string | React.ReactNode;
  placement?: TooltipPlacement;
}

export const Tooltip: React.FC<Props> = (props) => {
  const { text, placement, children } = props;

  return (
    <AntTooltip title={text} placement={placement}>{children}</AntTooltip>
  );
};
