import React from 'react';
import { Col, Row } from 'antd';
import { Card } from '../../_shared/Card/Card';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { DocumentsState } from '../../../store/documentStore';

interface Props {}

export const ForceCalculationsCard: React.FC<Props> = props => {
  const documentsState = useSelector<ApplicationState, DocumentsState>(state => state.documents);
  const { useOfForceCalculationsDepartment, useOfForceCalculationsCitizen } = documentsState;
  const hasCitizenCalculations = !!useOfForceCalculationsCitizen;

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ whiteSpace: 'nowrap' }}>
          <b>{useOfForceCalculationsDepartment?.daysSince || 0}</b> dage siden sidste magtanvendelse i afdelingen
          (§&nbsp;124d)
        </div>
        {hasCitizenCalculations && (
          <div style={{ whiteSpace: 'nowrap' }}>
            <b>{useOfForceCalculationsCitizen?.daysSince || 0}</b> dage siden sidste magtanvendelse på borgeren
            (§&nbsp;124d)
          </div>
        )}
        <div style={{ whiteSpace: 'nowrap' }}>
          <b>{useOfForceCalculationsDepartment?.totalYearCount || 0}</b> magtanvendelser i år i afdelingen (§&nbsp;124d)
        </div>
        {hasCitizenCalculations && (
          <div style={{ whiteSpace: 'nowrap' }}>
            <b>{useOfForceCalculationsCitizen?.totalYearCount || 0}</b> Magtanvendelser i år på borgeren (§&nbsp;124d)
          </div>
        )}
      </div>
    </Card>
  );
};
