import { useState } from 'react';
import prodocApi, { Goal, GoalStatus } from '../services/prodocApi';

export function useGoalsRequest() {
  const [goals, setGoals] = useState<Goal[]>([]);

  const resetGoals = () => setGoals([]);

  const loadGoals = async (citizenEnrollmentId: string) => {
    try {
      let goals = [];

      const res = await prodocApi.goal.getByCitizenEnrollment(citizenEnrollmentId, true);

      res.forEach(item => {
        goals.push(item);

        const filteredSubGoals = item.subGoals.filter(
          ({ goalStatus }) => goalStatus === GoalStatus.OnGoing || goalStatus === GoalStatus.Paused
        );

        goals = goals.concat(filteredSubGoals);
      });

      setGoals(goals);
    } catch (e) {}
  };

  return { goals, loadGoals, resetGoals };
}
