import moment from 'moment';
import {
  Citizen,
  HealthCare,
  HealthCareDto,
  HealthCareType,
  Measurement,
  MeasurementType,
  Medicine,
  MedicineState as MedicineStateEntity,
  MedicineType,
} from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import prodocApi from '../services/prodocApi';
import { UtilsMedicine } from '../utils/UtilsMedicine';
import { citizenDefault } from '../services/defaults';
import { ApplicationState } from './index';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { MESSAGES } from '../constants/messages';
import { PAGES_ITEMS, setPageItemLoading, setPageItemSaved } from './pagesStore';
import { HealthCareHistoryFilterParams, OrdinationSchedule } from '../types/medicine';
import { loadMedicineCalendarItems, reloadCalendarItemsForCitizen, removeCalendarMedicineItems } from './calendarStore';

export interface MedicineState {
  latestMeasurements: Measurement[];
  activeCitizen: string;
  healthCareAreasItems: HealthCare[];
  healthCareAreasItemsHistory: HealthCareDto[];
  selectedCitizenData: Citizen;
  medicineItems: Medicine[];
  expiredMedicineItems: Medicine[];
}

const defaultState: MedicineState = {
  latestMeasurements: [],
  activeCitizen: '',
  healthCareAreasItems: [],
  healthCareAreasItemsHistory: [],
  selectedCitizenData: citizenDefault(),
  medicineItems: [],
  expiredMedicineItems: [],
};

//https://api.medicinpriser.dk/v1/produkter/panodil?format=json
export const actionSetLatestMeasurements = actionCreator<Measurement[]>('SET_LATEST_MEASUREMENTS');
export const actionSetMedicineActiveCitizen = actionCreator<string>('SET_MEDICINE_ACTIVE_CITIZEN');
export const actionSetHealthCareAreasItem = actionCreator<HealthCare[]>('SET_HEALTH_CARE_AREAS_ITEMS');
export const actionSetHealthCareAreasItemHistory = actionCreator<HealthCare[]>('SET_HEALTH_CARE_AREAS_ITEMS_HISTORY');
export const actionSetHealthSelectedCitizenData = actionCreator<Citizen>('SET_HEALTH_SELECTED_CITIZEN_DATA');
export const actionSetMedicineItems = actionCreator<Medicine[]>('SET_MEDICINE_ITEMS');
export const actionSetExpiredMedicineItems = actionCreator<Medicine[]>('SET_EXPIRED_MEDICINE_ITEMS');

export default (state = defaultState, action: any) => {
  if (isType(action, actionSetLatestMeasurements)) {
    return { ...state, latestMeasurements: action.payload };
  }

  if (isType(action, actionSetMedicineActiveCitizen)) {
    return { ...state, activeCitizen: action.payload };
  }

  if (isType(action, actionSetHealthCareAreasItem)) {
    return { ...state, healthCareAreasItems: action.payload };
  }

  if (isType(action, actionSetHealthCareAreasItemHistory)) {
    return { ...state, healthCareAreasItemsHistory: action.payload };
  }

  if (isType(action, actionSetHealthSelectedCitizenData)) {
    return { ...state, selectedCitizenData: action.payload };
  }

  if (isType(action, actionSetMedicineItems)) {
    return { ...state, medicineItems: action.payload };
  }

  if (isType(action, actionSetExpiredMedicineItems)) {
    return { ...state, expiredMedicineItems: action.payload };
  }

  return state;
};

export const loadCitizenLatestMeasurements = (citizenEnrollmentId: string) => {
  return async (dispatch, getState) => {
    try {
      // const state: ApplicationState = getState();
      // const { citizensEnrollments } = state.calendar;
      let latestItems: Measurement[] = [];
      // const enrollmentObj = citizensEnrollments.find(item => item.id === citizenEnrollmentId);
      // const result = await prodocApi.measurement.getLatestFromCitizen(enrollmentObj.citizenId);

      // console.log(result);

      const bloodSugarItems = await prodocApi.measurement.getByType(citizenEnrollmentId, MeasurementType.Bloodsugar);
      const bloodPressureItems = await prodocApi.measurement.getByType(
        citizenEnrollmentId,
        MeasurementType.Bloodpressure
      );
      const bmiItems = await prodocApi.measurement.getByType(citizenEnrollmentId, MeasurementType.Bmi);
      const TemperatureItems = await prodocApi.measurement.getByType(citizenEnrollmentId, MeasurementType.Temperature);

      const bloodSugarLatest = bloodSugarItems.shift();
      const bloodPressureLatest = bloodPressureItems.shift();
      const bmiLatest = bmiItems.shift();
      const temperatureLatest = TemperatureItems.shift();

      if (bloodSugarLatest) {
        latestItems.push(bloodSugarLatest);
      }

      if (bloodPressureLatest) {
        latestItems.push(bloodPressureLatest);
      }

      if (bmiLatest) {
        latestItems.push(bmiLatest);
      }

      if (temperatureLatest) {
        latestItems.push(temperatureLatest);
      }

      dispatch(actionSetLatestMeasurements(latestItems));
    } catch (e) {}
  };
};

export const loadHealthCareItems = (citizenEnrollmentId: string) => {
  return async dispatch => {
    try {
      const res = await prodocApi.healthCare.getLatestByCitizenEnrollmentId(citizenEnrollmentId);

      const areasList = UtilsMedicine.createAreasDefaultsList();
      const filteredAreas = areasList.filter(item => {
        if (item.care === HealthCareType.None) return false;

        return !res.find(loadedItem => loadedItem.care === item.care);
      });
      const combinedItems = [...res, ...filteredAreas];

      dispatch(actionSetHealthCareAreasItem(combinedItems));
    } catch (e) {}
  };
};

export const loadHealthCareItemsHistory = (citizenEnrollmentId: string) => {
  return async dispatch => {
    try {
      const res = await prodocApi.healthCare.getByCitizenEnrollment(citizenEnrollmentId);

      dispatch(actionSetHealthCareAreasItemHistory(res));
    } catch (e) {}
  };
};

export const loadHealthCitizenData = (citizenId: string) => {
  return async dispatch => {
    try {
      const citizenData = await prodocApi.citizen.get(citizenId);

      dispatch(actionSetHealthSelectedCitizenData(citizenData));
    } catch (e) {}
  };
};

export const createOrdinationItem = (data: Medicine, schedule: OrdinationSchedule) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.ORDINATION, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.ORDINATION, true));

      const state: ApplicationState = getState();
      const { medicine, calendar } = state;
      const { activeCitizen, selectedCitizenData } = medicine;
      const activeEnrollment = await prodocApi.citizenEnrollment.get(activeCitizen);
      const { citizenId, id } = activeEnrollment;
      const { medicineType } = data;
      const isIncreasing = medicineType.indexOf(MedicineType.Increasing) >= 0;
      const isDecreasing = medicineType.indexOf(MedicineType.Decreasing) >= 0;
      const disableEndDate = isIncreasing || isDecreasing;
      const scheduleJson = UtilsMedicine.serializeSchedule(schedule, disableEndDate);
      const requestData: Medicine = {
        ...data,
        citizenId,
        dailyDose: data.dailyDose.toString(),
        maxDailyDose: data.maxDailyDose.toString(),
        incDecDose: data.incDecDose.toString(),
        incDecEveryX: data.incDecEveryX.toString(),
        incDecEndDose: data.incDecEndDose.toString(),
        incDecStartDose: data.incDecStartDose.toString(),
        citizenEnrollmentId: id,
        cave: selectedCitizenData.cave,
        schedules: scheduleJson,
      };

      // console.log(schedule, 'schedule');

      await prodocApi.medicine.add(true, requestData);

      dispatch(setPageItemSaved(PAGES_ITEMS.ORDINATION, true));
      dispatch(showSuccessMessage({ message: 'Ordination oprettet' }));
    } catch (e) {
      dispatch(setPageItemSaved(PAGES_ITEMS.ORDINATION, false));
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.ORDINATION, false));
    }
  };
};

export const updateOrdinationItem = (data: Medicine, schedule: OrdinationSchedule) => {
  return async dispatch => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.ORDINATION, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.ORDINATION, true));

      const { medicineType } = data;
      const isIncreasing = medicineType.indexOf(MedicineType.Increasing) >= 0;
      const isDecreasing = medicineType.indexOf(MedicineType.Decreasing) >= 0;
      const disableEndDate = isIncreasing || isDecreasing;

      const scheduleJson = UtilsMedicine.serializeSchedule(schedule, disableEndDate);
      const requestData: Medicine = {
        ...data,
        dailyDose: data.dailyDose.toString(),
        maxDailyDose: data.maxDailyDose.toString(),
        incDecDose: data.incDecDose.toString(),
        incDecEveryX: data.incDecEveryX.toString(),
        incDecEndDose: data.incDecEndDose.toString(),
        incDecStartDose: data.incDecStartDose.toString(),
        schedules: scheduleJson,
      };

      await prodocApi.medicine.update(true, requestData);

      dispatch(setPageItemSaved(PAGES_ITEMS.ORDINATION, true));
      dispatch(showSuccessMessage({ message: 'Ordination opdateret' }));
    } catch (e) {
      dispatch(setPageItemSaved(PAGES_ITEMS.ORDINATION, false));
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.ORDINATION, false));
    }
  };
};

export const loadMedicineItems = (citizenEnrollmentId: string) => {
  return async (dispatch, getState) => {
    const state: ApplicationState = getState();
    const { calendar } = state;
    const { citizensEnrollments } = calendar;
    const activeEnrollment = citizensEnrollments.find(item => item.id === citizenEnrollmentId);
    const citizen = await prodocApi.citizen.getCitizenByEnrollmentId(citizenEnrollmentId);

    const res = await prodocApi.medicine.getManyByCitizenWithoutCalendarItem(citizen.id);
    const expiredItems: Medicine[] = [];
    const medicineItems = res.filter(item => {
      const isExpired = moment(item.toDate).isSameOrBefore(moment());

      if (isExpired) expiredItems.push(item);

      return !isExpired;
    });

    dispatch(actionSetMedicineItems(medicineItems));
    dispatch(actionSetExpiredMedicineItems(expiredItems));
  };
};

export const updateMedicineCitizen = (data: Citizen) => {
  return async dispatch => {
    try {
      const res = await prodocApi.citizen.update(data);

      dispatch(showSuccessMessage({ message: 'Borger oplysninger opdateret' }));

      dispatch(actionSetHealthSelectedCitizenData(res));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const addStateToMedicineItem = (stateData: MedicineStateEntity, data: Medicine) => {
  return async dispatch => {
    try {
      const medicineStates = data.medicineStates || [];
      let updatedStates = [];
      const isExists = medicineStates.find(item => {
        return moment(item.timeStamp).valueOf() === moment(stateData.timeStamp).valueOf();
      });

      if (isExists) {
        updatedStates = medicineStates.map(item => {
          if (moment(item.timeStamp).valueOf() === moment(stateData.timeStamp).valueOf()) return stateData;

          return item;
        });
      } else {
        updatedStates = [...medicineStates, stateData];
      }

      const requestData = { ...data, medicineStates: updatedStates };

      await prodocApi.medicine.update(true, requestData);

      dispatch(reloadCalendarItemsForCitizen(data.citizenEnrollmentId));
      dispatch(showSuccessMessage({ message: 'Medicin administreret' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};
