import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '../../../_shared/Drawer/Drawer';
import { MedicineOrdinationGroup } from '../MedicineOrdinationGroup/MedicineOrdinationGroup';
import { MedicineAdministrationGroup } from '../MedicineAdministrationGroup/MedicineAdministrationGroup';
import { showConfirmModal } from '../../../_shared/Modal/ModalFunctions';
import { UtilsData } from '../../../../utils/UtilsData';
import { Medicine, MedicineType } from '../../../../services/prodocApi';
import { PAGES_ITEMS, PagesState } from '../../../../store/pagesStore';
import { ApplicationState } from '../../../../store';
import { createOrdinationItem, MedicineState, updateOrdinationItem } from '../../../../store/medicineStore';
import { OrdinationSchedule } from '../../../../types/medicine';
import { defaultMedicineSchedule } from '../../../../data/medicine-data';
import { UtilsMedicine } from '../../../../utils/UtilsMedicine';
import Button from '../../../_shared/_controls/Button/Button';
import { UtilsDate } from '../../../../utils/UtilsDate';
import { LoadingAnimation } from '../../../_shared/LoadingAnimation/LoadingAnimation';

import './MedicineOrdinationWindow.scss';

interface Props {
  isOpened: boolean;
  data: Medicine;
  onCLose(): void;
}

export const MedicineOrdinationWindow: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { isOpened, data, onCLose } = props;
  const pageState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const medicineState = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const [ordinationData, setOrdinationData] = useState(UtilsData.getMedicineDefaults());
  const [schedule, setSchedule] = useState<OrdinationSchedule>(defaultMedicineSchedule);
  const [isEdited, setIsEdited] = useState(false);
  const isSaved = pageState[PAGES_ITEMS.ORDINATION].saved;
  const isSaving = pageState[PAGES_ITEMS.ORDINATION].loading;
  const type = ordinationData?.medicineType || '';
  const isPnOrdination = type === MedicineType.PN;
  const isIncreasing = type === MedicineType.Increasing;
  const isDecreasing = type === MedicineType.Decreasing;
  const disableEndDate = isIncreasing || isDecreasing;
  const {
    fromDate: administrativeStartDate,
    toDate: administrativeEndDate,
    dailyDose,
    maxDailyDose,
    incDecStartDose,
    incDecEndDose,
    incDecEveryX,
    incDecDose,
    doseUnit,
  } = ordinationData;
  const { selectedCitizenData } = medicineState;
  const { firstname, lastname } = selectedCitizenData;
  const dosesAreCorrect = useMemo(() => {
    return !maxDailyDose || !dailyDose || maxDailyDose >= dailyDose;
  }, [dailyDose, maxDailyDose]);
  const datesAreIncorrect = useMemo(() => {
    const itemWithIncorrectDates = Object.values(schedule).find(item => {
      return (
        UtilsDate.isBefore(item.startDate, administrativeStartDate) ||
        UtilsDate.isAfter(item.endDate, administrativeEndDate)
      );
    });

    return !!itemWithIncorrectDates;
  }, [administrativeStartDate, administrativeEndDate, schedule]);
  const incDosesCorrect = useMemo(() => {
    if (!isIncreasing) return true;

    return !incDecStartDose || !incDecEndDose || parseInt(incDecStartDose) < parseInt(incDecEndDose);
  }, [isIncreasing, incDecStartDose, incDecEndDose]);
  const decDosesCorrect = useMemo(() => {
    if (!isDecreasing) return true;

    return !incDecStartDose || !incDecEndDose || parseInt(incDecStartDose) > parseInt(incDecEndDose);
  }, [isDecreasing, incDecStartDose, incDecEndDose]);

  useEffect(() => {
    if (isSaved) {
      setOrdinationData(UtilsData.getMedicineDefaults());
      onCLose();
      setIsEdited(false);
    }
  }, [isSaved]);

  useEffect(() => {
    const updatedData = data ? data : UtilsData.getMedicineDefaults();
    const dataSchedule = UtilsMedicine.deserializeSchedule(data?.schedules);

    setOrdinationData(updatedData);
    setSchedule(dataSchedule);
  }, [data]);

  useEffect(() => {
    if (isPnOrdination) {
      setSchedule(defaultMedicineSchedule);
    }
  }, [isPnOrdination]);

  const isSaveDisabled = () => {
    if (isSaving) return true;

    if (isIncreasing || isDecreasing) {
      return !incDecStartDose || !incDecEndDose || !incDecEveryX || !incDecDose;
    }

    return datesAreIncorrect || !dosesAreCorrect || !incDosesCorrect || !decDosesCorrect || !dailyDose || !doseUnit;
  };

  const handleOrdinationChange = (newData: Medicine) => {
    setOrdinationData(newData);
    setIsEdited(true);
  };

  const handleClose = () => {
    if (!isEdited) {
      onCLose();
      return;
    }

    dispatch(
      showConfirmModal({
        content: `Ønsker du at annullere? Ikke gemte oplysninger vil blive slettet`,
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          onCLose();
        },
      })
    );
  };

  const handleRemovePeriod = (id: string) => {
    const updatedSchedule = { ...schedule };

    delete updatedSchedule[id];

    setSchedule(updatedSchedule);
  };

  const handleScheduleUpdate = (id, data) => {
    setSchedule({ ...schedule, [id]: data });
  };

  const handleSave = () => {
    if (ordinationData.id) {
      dispatch(updateOrdinationItem(ordinationData, schedule));
    } else {
      dispatch(createOrdinationItem(ordinationData, schedule));
    }
  };

  const renderPeriods = () => {
    const scheduleItems = Object.values(schedule);

    return scheduleItems.map((item, index) => {
      return (
        <MedicineAdministrationGroup
          key={index}
          data={item}
          itemIndex={index}
          minDate={administrativeStartDate}
          maxDate={administrativeEndDate}
          disableEndDate={disableEndDate}
          onPeriodAdd={handleScheduleUpdate}
          onPeriodRemove={handleRemovePeriod}
          onPeriodUpdate={handleScheduleUpdate}
        />
      );
    });
  };

  return (
    <Drawer
      visible={isOpened}
      width={1000}
      subtitle={`${firstname} ${lastname}`}
      onClose={handleClose}
      buttons={
        <div className="ordination-window__actions">
          <LoadingAnimation active={isSaving} />
          <Button title="Gem" disabled={isSaveDisabled()} type="primary" onClick={handleSave} />
          <Button title="Annuller" onClick={handleClose} />
        </div>
      }>
      <div className="ordination-window">
        <div className="ordination-window__row">
          <div className="ordination-window__col">
            <div className="ordination-window__title">Opret ordination</div>
            <MedicineOrdinationGroup
              data={ordinationData}
              isActive={isOpened}
              isDosesCorrect={dosesAreCorrect}
              incDosesCorrect={incDosesCorrect}
              decDosesCorrect={decDosesCorrect}
              onChange={handleOrdinationChange}
            />
          </div>
          {!isPnOrdination && (
            <div className="ordination-window__col">
              <div className="ordination-window__title">Administrering</div>
              {renderPeriods()}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};
