import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDepartmentById } from '../../../store/departmentStore';
import { useRouterParams } from '../../../hooks/navigation';
import PageTitleContext from '../../../context/PageTitleContext';
import { ROUTES } from '../../../constants/routes';
import Button from '../../../components/_shared/_controls/Button/Button';
import { useHistory } from 'react-router';
import prodocApi, { Department } from '../../../services/prodocApi';
import { Card, Row, Col } from 'antd';
import { CardActions } from '../../../components/_shared/CardActions/CardActions';
import TextInput from '../../../components/_shared/_controls/TextInput/TextInput';
import { useZipCode } from '../../../hooks/common-hooks';

export default function DepartmentEditPage() {
  const dispatch = useDispatch();
  // const centerState = useSelector<any, DepartmentState>(state => state.department);
  const { getCityName } = useZipCode();

  const { setTitle } = React.useContext(PageTitleContext);
  const [match] = useRouterParams();
  // const { editDepartment } = centerState;
  // const departmentData = editDepartment || departmentDefault();
  const [department, setDepartment] = useState<Department>({});
  const departmentId = match.params.id;
  const history = useHistory();
  setTitle('Rediger afdeling');

  useEffect(() => {
    async function init() {
      const result = await prodocApi.department.get(departmentId);
      setDepartment(result);
    }
    init();
  }, []);

  useEffect(() => {
    if (departmentId) {
      dispatch(getDepartmentById(departmentId));
    }
  }, [departmentId]);

  async function update() {
    await prodocApi.department.update(department);
    history.push(ROUTES.DEPARTMENT_OVERVIEW);
  }

  const handleCancel = () => {
    history.push(ROUTES.DEPARTMENT_OVERVIEW);
  };
  function handleFieldsChange(value: string, name: string) {
    if (name === 'zipCode') {
      setDepartment({ ...department, [name]: value, city: getCityName(value) });
    } else {
      setDepartment({ ...department, [name]: value });
    }
  }
  const { centerId, name, address, zipCode, city, phoneNumber, email, id } = department;
  return (
    <>
      <div className="department-page page item-form">
        <Card>
          <Row gutter={8}>
            <Col span={8}>
              <TextInput
                title="Afdelingsnavn*"
                name="name"
                value={name}
                // error={errors.name?.message}
                onChange={handleFieldsChange}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput title="Adresse" name="address" value={address} onChange={handleFieldsChange} />
            </Col>
            <Col span={4}>
              <TextInput title="Postnummer" name="zipCode" value={zipCode} onChange={handleFieldsChange} />
            </Col>
            <Col span={8}>
              <TextInput title="By" readonly value={city} onChange={handleFieldsChange} />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput title="Telefonnummer" name="phoneNumber" value={phoneNumber} onChange={handleFieldsChange} />
            </Col>
            <Col span={12}>
              <TextInput
                title="Email"
                name="email"
                // error={errors.email?.message}
                value={email}
                onChange={handleFieldsChange}
              />
            </Col>
          </Row>
          <CardActions
            rightActions={
              <>
                <Button title="Opdater" type="primary" onClick={update} />
                <Button title="Annuller" onClick={handleCancel} />
              </>
            }
          />
        </Card>
      </div>
    </>
  );
}
