import prodocApi from '../services/prodocApi';
import { useEffect, useState } from 'react';
import { citizenDefault, citizenEnrollmentDefault } from '../services/defaults';
import { Citizen } from '../services/prodocApi';

export function useExistingUserCheck() {
  const isCprUsed = async (cpr: string) => {
    try {
      const res = await prodocApi.citizen.getByCpr(cpr);

      return res ? res.id : '';
    } catch (e) {
      return '';
    }
  };

  const isUidUsed = async (uid: string) => {
    try {
      const res = await prodocApi.citizen.getByForeignId(uid);

      return res ? res.id : '';
    } catch (e) {
      return '';
    }
  };

  return { isCprUsed, isUidUsed };
}

export function useCitizenData(citizenId: string) {
  const [citizen, setCitizen] = useState<Citizen>(citizenDefault());

  const getCitizen = async () => {
    try {
      const data = await prodocApi.citizen.get(citizenId);

      setCitizen(data);
    } catch (e) {}
  };

  useEffect(() => {
    if (citizenId) {
      getCitizen();
    }
  }, [citizenId]);

  return { citizen };
}

export function useCitizenEnrollmentRequest() {
  const getCitizenEnrollmentById = async (id: string) => {
    try {
      return prodocApi.citizenEnrollment.get(id);
    } catch (e) {
      return citizenEnrollmentDefault();
    }
  };

  return { getCitizenEnrollmentById };
}
