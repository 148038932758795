import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';

import { DATE_TIME_FORMAT } from '../../../constants/common';
import { loadHealthCareItemsHistory, MedicineState } from '../../../store/medicineStore';
import { ApplicationState } from '../../../store';
import { HealthCareType, HealthCareProblem, HealthCareDto } from '../../../services/prodocApi';
import {
  getColumnDateSearchProps,
  getColumnEnumSearchProps,
  getColumnTextSearchProps,
} from '../../../utils/UtilsTable';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { Dayjs } from 'dayjs';

import './HealthHistory.scss';
interface HealthHistoryProps {
  citizenEnrollmentId: string;
}
export const HealthHistory = (props: HealthHistoryProps) => {
  const { citizenEnrollmentId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const medicineStore = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const { healthCareAreasItemsHistory } = medicineStore;
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const [columnFilter, setColumnFilter] = useState({
    problem: '',
    care: '',
    citizenName: '',
    createdFrom: undefined,
    createdTo: undefined,
    area: undefined,
  });
  const [data, setData] = useState<HealthCareDto[]>([]);

  useEffect(() => {
    dispatch(loadHealthCareItemsHistory(citizenEnrollmentId));
  }, []);

  useEffect(() => {
    setData(healthCareAreasItemsHistory);
  }, [healthCareAreasItemsHistory]);

  function handleDateChange(date: Dayjs[]) {
    filter(date[0], 'createdFrom');
    filter(date[1], 'createdTo');
  }

  const filter = (v, name: string) => {
    const f = { ...columnFilter, [name]: v };

    setColumnFilter(f);

    let d = [...healthCareAreasItemsHistory];

    if (f.problem) {
      d = d.filter(x => x.problem === f.problem);
    }

    if (f.care) {
      d = d.filter(x => x.care === f.care);
    }

    if (f.citizenName) {
      d = d.filter(x => x.citizenName.toLocaleLowerCase().includes(f.citizenName.toLocaleLowerCase()));
    }

    if (f.createdFrom) {
      d = d.filter(x => new Date(x.createdOn) >= f.createdFrom);
    }

    if (f.createdTo) {
      d = d.filter(x => new Date(x.createdOn) <= f.createdTo);
    }

    if (f.area) {
      d = d.filter(x => x.care.startsWith(f.area));
    }

    setData(d);
  };

  const area = [...new Set(Object.entries(HealthCareType).map(([value, label]) => value.split('_')[0]))].map(x => ({
    value: x,
    label: t(x),
  }));

  const columns: ColumnsType<HealthCareDto> = [
    {
      title: 'Beskrivelse',
      ellipsis: {
        showTitle: false,
      },
      render: care => (
        <Tooltip placement="topLeft" title={<span dangerouslySetInnerHTML={{ __html: care.description }} />}>
          {care.description.replace(/(<([^>]+)>)/gi, '')}
        </Tooltip>
      ),
    },
    {
      title: 'Handling',
      ellipsis: {
        showTitle: false,
      },
      render: care => (
        <Tooltip placement="topLeft" title={<span dangerouslySetInnerHTML={{ __html: care.action }} />}>
          {care.action.replace(/(<([^>]+)>)/gi, '')}
        </Tooltip>
      ),
    },
    {
      title: 'Område',
      dataIndex: 'care',
      render: care => t(care.split('_')[0]),
      ...getColumnEnumSearchProps(area, v => filter(v, 'area')),
    },
    {
      title: 'Tilstand',
      dataIndex: 'care',
      render: care => t(care),
      ...getColumnEnumSearchProps(translateEnumOptions(HealthCareType), v => filter(v, 'care')),
    },
    {
      title: 'Status',
      dataIndex: 'problem',
      render: problem => t(problem),
      ...getColumnEnumSearchProps(translateEnumOptions(HealthCareProblem), v => filter(v, 'problem')),
    },
    {
      title: 'Medarbejder',
      dataIndex: 'createdByName',
    },
    {
      title: 'Dato',
      dataIndex: 'created',
      width: '1%',
      render: date => <span style={{ whiteSpace: 'nowrap' }}>{date ? moment(date).format(DATE_TIME_FORMAT) : ''}</span>,
      ...getColumnDateSearchProps(handleDateChange),
    },
  ];

  return (
    <div className="health-history">
      <div className="health-history__table-wrap">
        <Table
          rowKey="id"
          pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }}
          dataSource={data}
          columns={columns}
        />
      </div>
    </div>
  );
};
