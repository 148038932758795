import { Citizen, Employee } from '../services/prodocApi';
import moment from 'moment';

export class UtilsItems {
  public static formatCprNumber(value: string) {
    let modifiedValue = value;

    if (value.length >= 7 && value.indexOf('-') < 0) {
      const firstPart = value.substring(0, 6);
      const secondPart = value.substring(6, value.length);

      modifiedValue = `${firstPart}-${secondPart}`;
    }

    return modifiedValue;
  }

  public static getEmployeeFullName(data: Employee) {
    if (!data) return null;

    const { lastname, firstname } = data;

    return `${firstname} ${lastname}`;
  };

  public static handleCitizenDefaultValues(data: Citizen): Citizen {
    const { convictionStartDate, convictionEndtDate } = data;
    const handledConvictionStartDate = moment(convictionStartDate).get('year') === 1 ? null : convictionStartDate;
    const handledConvictionEndDate = moment(convictionEndtDate).get('year') === 1 ? null : convictionEndtDate;

    return {
      ...data,
      convictionStartDate: handledConvictionStartDate as Date,
      convictionEndtDate: handledConvictionEndDate as Date,
    };
  }
}
