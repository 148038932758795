import { CitizenEnrollmentResult, Goal, GoalResult, GoalScorer } from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import prodocApi from '../services/prodocApi';
import { ApplicationState } from './index';
import { UtilsData } from '../utils/UtilsData';
import { UtilsGoals } from '../utils/UtilsGoals';
import { PAGES_ITEMS, setPageItemLoading, setPageItemSaved } from './pagesStore';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { MESSAGES } from '../constants/messages';

export interface GoalsState {
  loadedItems: { [key: string]: Goal[] };
  citizenEnrollments: CitizenEnrollmentResult[];
}

const defaultState: GoalsState = {
  loadedItems: {},
  citizenEnrollments: [],
};

const setGoalsAction = actionCreator<{ id: string; items: GoalResult[] }>('SET_GOALS_ACTION');
const setCitizenEnrollmentsAction = actionCreator<CitizenEnrollmentResult[]>('SET_GOALS_CITIZEN_ENROLLMENTS_ACTION');

export default (state: GoalsState = defaultState, action: any): GoalsState => {
  if (isType(action, setGoalsAction)) {
    return {
      ...state,
      loadedItems: {
        ...state.loadedItems,
        [action.payload.id]: action.payload.items,
      },
    };
  }

  if (isType(action, setCitizenEnrollmentsAction)) {
    return {
      ...state,
      citizenEnrollments: action.payload,
    };
  }

  return state;
};

export const loadGoals = (citizenEnrollmentId: string) => {
  return async dispatch => {
    try {
      const res = await prodocApi.goal.getByCitizenEnrollment(citizenEnrollmentId, false);

      const sortedItems = UtilsGoals.sortGoalsByStatus(res);

      dispatch(setGoalsAction({ id: citizenEnrollmentId, items: sortedItems }));
    } catch (e) {}
  };
};

export const loadCitizenEnrollments = () => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const userState = state.user;

      const userEnrollments = await prodocApi.employeeEnrollment.getByEmployee(userState.id, true);
      const employeeDepartmentsIds = userEnrollments.map(item => item.departmentId);

      const citizensEnrollments = await prodocApi.citizenEnrollment.getManyByDepartments({
        departmentIds: employeeDepartmentsIds,
      });
      let resArr: CitizenEnrollmentResult[] = [];

      citizensEnrollments.forEach(item => {
        resArr = resArr.concat(item);
      });

      const filteredItems = UtilsData.removeDuplicatesFromArray(resArr, 'id');

      dispatch(setCitizenEnrollmentsAction(filteredItems));
    } catch (e) {}
  };
};

export const createGoal = (data: Goal) => {
  return async dispatch => {
    try {
      dispatch(setPageItemLoading(PAGES_ITEMS.GOAL, true));
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL, false));

      const { citizenEnrollmentId } = data;

      await prodocApi.goal.add(data);

      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL, true));
      dispatch(loadGoals(citizenEnrollmentId));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL, false));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.GOAL, false));
    }
  };
};

export const updateGoal = (data: Goal) => {
  return async dispatch => {
    try {
      dispatch(setPageItemLoading(PAGES_ITEMS.GOAL, true));
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL, false));

      const { citizenEnrollmentId } = data;

      await prodocApi.goal.update(data);

      dispatch(loadGoals(citizenEnrollmentId));
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL, true));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL, false));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.GOAL, false));
    }
  };
};

export const createGoalStatus = (data: GoalScorer[]) => {
  return async dispatch => {
    try {
      dispatch(setPageItemLoading(PAGES_ITEMS.GOAL_STATUS, true));
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL_STATUS, false));

      for (const item of data) {
        await prodocApi.goalScorer.add(item);
      }

      dispatch(showSuccessMessage({ message: 'Status is created!' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.GOAL_STATUS, true));
    } catch (e) {
      dispatch(showErrorMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.GOAL_STATUS, false));
    }
  };
};
