export const URLS = {
  LOGIN: 'login',
  FORGOT: 'forgot',
  NEWPASSWORD: 'newpassword',
  HOME: 'home',
  PLANNING: 'planning',
  TEMPLATE: 'template',
  DRAFT: 'draft',
  WORKPLAN: 'workplan',
  ADD: 'add',
  ENROLL: 'enroll',
  DETAILS: 'details',
  LIST: 'list',
  CENTER: 'center',
  DEPARTMENT: 'department',
  EMPLOYEE: 'employee',
  CITIZEN: 'citizen',
  OVERVIEW: 'overview',
  USEOFFORCE: 'useofforce',
  DISCHARGE: 'discharge',
  ADMIN: 'admin',
  GOALS: 'goals',
  EXTERNALCONTACTS: 'externalcontacts',
  EXTERNALCONTACT: 'externalcontact',

  STATUS: 'status',
  STATUSES: 'statuses',
  NOTES: 'notes',
  FORUM: 'forum',
  FORCE: 'force',
  DOCUMENTS: 'documents',
  DOCUMENTS_PROCEDURES: 'documents-procedures',
  MEASUREMENTS: 'measurements',
  MEASUREMENT: 'measurement',
  MEDICINE: 'medicine',
  MEDICINE_HEALTH: 'medicine/health',
  MEDICINE_CARD: 'medicine/card',
  MEDICINE_FMK: 'medicine/fmk',
  MEDICINE_HISTORY: 'medicine/history',
  PARAMS: {
    ID: ':id',
    ID_OPT: ':id?',
    TYPE_OPT: ':type?',
    EMPLOYEE_ID: ':employeeId',
    EMPLOYEE_ID_OPT: ':employeeId?',
    CITIZEN_ID_OPT: ':citizenId?',
    CITIZEN_ID: ':citizenId',
    CENTER_ID_OPT: ':centerId?',
    DEPARTMENT_ID_OPT: ':departmentId?',
    OVERVIEW: ':overview?',
    CITIZEN_ENROLLMENT_ID_OPT: ':citizenEnrollmentId?',
  },
};
