import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import CalendarMenuWrap from './CalendarMenuWrap/CalendarMenuWrap';
import Icon, { ICONS } from '../../_shared/Icon/Icon';
import prodocApi, { CalendarItem, CalendarItemType, MeasurementType } from '../../../services/prodocApi';
import { setMeasurementPreselectedTypeAction, setMeasurementWindowStateAction } from '../../../store/measurementStore';
import { CalendarViewTypes, SCHEMA_FILES_NAMES } from '../../../constants/calendar';
import { useSchemaPdfLink } from '../../../hooks/calendar-hooks';
import { CalendarState } from '../../../store/calendarStore';
import { ApplicationState } from '../../../store';

import './CalendarMenu.scss';
import { useHistory } from 'react-router';
import { DateSelectArg } from '@fullcalendar/react';
import { useTranslation } from 'react-i18next';

interface Props {
  event: DateSelectArg;
  calendarViewType: CalendarViewTypes;
  onCalendarItemSelect(itemId?: CalendarItemType): void;
}

const { SubMenu } = Menu;

export default function (props: Props) {
  const { event, calendarViewType, onCalendarItemSelect } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getSchemaCreateLink } = useSchemaPdfLink();
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const disableAppointmentsItem = calendarViewType === CalendarViewTypes.Medicine;
  const { selectedCitizenEnrollments } = calendarState;
  const disableDocuments = selectedCitizenEnrollments.length !== 1;
  const history = useHistory();

  const handleAppointmentCreate = (): void => {
    onCalendarItemSelect(CalendarItemType.Normal);
  };

  const handleNoteCreate = (): void => {
    onCalendarItemSelect(CalendarItemType.Note);
  };

  const handlePnWindowOpen = (): void => {
    onCalendarItemSelect(CalendarItemType.PN);
  };
  function onChreateUth() {}
  async function handleUthWindowOpen() {
    window.open('https://dpsd.csc-scandihealth.com/Form/PublicSubmission.aspx?form=DPSD_Public');
    // Modal.confirm({
    //   title: 'Ønsker du at oprette en kalender aftale?',
    //   okText: 'Ja',
    //   cancelText: 'Nej',
    //   onOk: () => {
    //     onChreateUth();
    //   },
    // });
    // const item: CalendarItem = {} as CalendarItem;
    // const res = await prodocApi.calendarItem.add([item]);

    console.log(event);
  }
  const handleTimeTrackCreate = (): void => {
    onCalendarItemSelect(CalendarItemType.TimeTrack);
  };

  const handleSchemaClick = (data): void => {
    const url = getSchemaCreateLink(data.key);

    history.push(`${url}&dt=${new Date(event.start).toISOString()}`);
  };

  const handleMeasurementCreate = (data): void => {
    onCalendarItemSelect();
    dispatch(setMeasurementWindowStateAction(true));
    dispatch(setMeasurementPreselectedTypeAction(data.key));
  };
  const handleNewPostWindowOpen = (): void => {
    onCalendarItemSelect(CalendarItemType.Posting);
  };
  return (
    <CalendarMenuWrap event={event?.jsEvent}>
      <Menu mode="vertical" className="calendar-menu" selectable={false}>
        <Menu.Item
          disabled={disableAppointmentsItem}
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
          key="1"
          onClick={handleAppointmentCreate}>
          Aftale
        </Menu.Item>
        {disableDocuments ? (
          <Menu.Item key="" disabled icon={<Icon name={ICONS.alert} />} title="Vælg én borger">
            Magtanvendelse
          </Menu.Item>
        ) : (
          <SubMenu
            popupClassName="calendar-menu__submenu"
            disabled={disableDocuments}
            key="2"
            icon={<Icon name={ICONS.alert} />}
            title="Magtanvendelse">
            <Menu.Item key={SCHEMA_FILES_NAMES.SCHEMA1A} onClick={handleSchemaClick}>
              Skema 1A
            </Menu.Item>
            <Menu.Item key={SCHEMA_FILES_NAMES.SCHEMA1B} onClick={handleSchemaClick}>
              Skema 1B
            </Menu.Item>
            <Menu.Item key={SCHEMA_FILES_NAMES.SCHEMA2} onClick={handleSchemaClick}>
              Skema 2
            </Menu.Item>
            <Menu.Item key={SCHEMA_FILES_NAMES.SCHEMA3} onClick={handleSchemaClick}>
              Skema 3
            </Menu.Item>
            <Menu.Item key={SCHEMA_FILES_NAMES.BILAGAB} onClick={handleSchemaClick}>
              Bilag 1A + 1B
            </Menu.Item>
            <Menu.Item key={SCHEMA_FILES_NAMES.BILAG1A} onClick={handleSchemaClick}>
              Bilag 1A
            </Menu.Item>
          </SubMenu>
        )}
        <SubMenu popupClassName="calendar-menu__submenu" key="3" icon={<Icon name={ICONS.dashboard} />} title="Måling">
          <Menu.Item key={MeasurementType.Bloodsugar} onClick={handleMeasurementCreate}>
            {t(MeasurementType.Bloodsugar)}
          </Menu.Item>
          <Menu.Item key={MeasurementType.Bloodpressure} onClick={handleMeasurementCreate}>
            {t(MeasurementType.Bloodpressure)}
          </Menu.Item>
          <Menu.Item key={MeasurementType.Bmi} onClick={handleMeasurementCreate}>
            {t(MeasurementType.Bmi)}
          </Menu.Item>
          <Menu.Item key={MeasurementType.Bvc} onClick={handleMeasurementCreate}>
            {t(MeasurementType.Bvc)}
          </Menu.Item>
          <Menu.Item key={MeasurementType.Liquid} onClick={handleMeasurementCreate}>
            {t(MeasurementType.Liquid)}
          </Menu.Item>
          <Menu.Item key={MeasurementType.Temperature} onClick={handleMeasurementCreate}>
            {t(MeasurementType.Temperature)}
          </Menu.Item>
          <Menu.Item key={MeasurementType.Trafficlight} onClick={handleMeasurementCreate}>
            {t(MeasurementType.Trafficlight)}
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="4" icon={<FontAwesomeIcon icon={faStickyNote} />} onClick={handleNoteCreate}>
          Notat
        </Menu.Item>
        <Menu.Item key="5" icon={<Icon name={ICONS.warning} />} onClick={handlePnWindowOpen}>
          PN administrering
        </Menu.Item>
        <Menu.Item key="10" icon={<Icon name={ICONS.dollar} />} onClick={handleNewPostWindowOpen}>
          Ny postering
        </Menu.Item>
        <Menu.Item key="8" icon={<Icon name={ICONS.expand} />} onClick={handleUthWindowOpen}>
          UTH skema
        </Menu.Item>
        <Menu.Item key="6" icon={<Icon name={ICONS.clock} />} onClick={handleTimeTrackCreate}>
          Tidsregistering
        </Menu.Item>
      </Menu>
    </CalendarMenuWrap>
  );
}
