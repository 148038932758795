import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { ApplicationState } from '../../../store';
import { CalendarState } from '../../../store/calendarStore';
import { GoalStatusFilerData } from '../../../types/goals';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { GoalStatus } from '../../../services/prodocApi';
import Button from '../../_shared/_controls/Button/Button';

import './GoalStatusHead.scss';
import { Card, Col, Row } from 'antd';

interface Props {
  filterData: GoalStatusFilerData;
  onFilterDataChange(data: GoalStatusFilerData): void;
}

export const GoalStatusHead: React.FC<Props> = props => {
  const { filterData, onFilterDataChange } = props;
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const { citizensEnrollments } = calendarState;
  const { citizenId, status } = filterData;
  const statusOptions = useMemo(() => translateEnumOptions(GoalStatus), []);

  const handleFieldChange = (value: any, name: string) => {
    onFilterDataChange({ ...filterData, [name]: value });
  };

  return (
    <Card>
      <Row gutter={8}>
        <Col span={6}>
          <SelectMenu
            title="Vælg borger"
            value={citizenId}
            name="citizenId"
            options={citizensEnrollments}
            valueKey="citizenId"
            labelKey={['firstname', 'lastname']}
            onChange={handleFieldChange}
          />
        </Col>
        <Col span={6}>
          <SelectMenu
            title="Vælg mål"
            value={status}
            name="status"
            options={statusOptions}
            valueKey="value"
            labelKey="label"
            onChange={handleFieldChange}
          />
        </Col>
      </Row>
    </Card>
  );
};
