import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  onSubmit?(): void;
}

export const Form: React.FC<Props> = (props) => {
  const { children, className, onSubmit } = props;
  const wrapClass = classNames('common-form', { [className]: !!className });

  const handleSubmit = () => {
    onSubmit && onSubmit();
  };

  return (
    <form className={wrapClass} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};
