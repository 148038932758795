import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Employee } from '../services/prodocApi';
import { employeeDefault } from '../services/defaults';
import prodocApi from '../services/prodocApi';
import { showErrorMessage } from '../store/notificationStore';
import { UtilsData } from '../utils/UtilsData';

export function useEmployeeData(employeeId: string) {
  const [employee, setEmployee] = useState<Employee>(employeeDefault());

  const getCitizen = async () => {
    try {
      if (!employeeId) return;

      const data = await prodocApi.employee.getById(employeeId);

      setEmployee(data);
    } catch (e) {}
  };

  useEffect(() => {
    if (employeeId) {
      getCitizen();
    }
  }, [employeeId]);

  return { employee };
}

export function useExistingEmployeeCheck() {
  const isCprUsed = async (cpr: string) => {
    try {
      const res = await prodocApi.employee.getByCpr(cpr);

      return res ? res.id : '';
    } catch (e) {
      return '';
    }
  };

  return { isCprUsed };
}

export function useEmployeeLeadersCheck() {
  const dispatch = useDispatch();

  const departmentsHaveLeader = async (departmentsIds: string[]) => {
    try {
      const departments = await prodocApi.department.getMany(departmentsIds);

      for (let i = 0; i < departments.length; i++) {
        if (departments[i].employeeEnrollmentLeaderId) {
          const message = `Der er allerede en leder på ${departments[i].name}`;

          dispatch(showErrorMessage({ message }));
          return true;
        }
      }

      return false;
    } catch (e) {
      return true;
    }
  };

  const centersHaveLeader = async (departmentsIds: string[]) => {
    try {
      const departments = await prodocApi.department.getMany(departmentsIds);
      const filteredDepartments = UtilsData.removeDuplicatesFromArray(departments, 'centerId');
      const centersIds = filteredDepartments.map(item => item.centerId);
      const centers = await prodocApi.center.getMany(centersIds);

      for (let i = 0; i < centers.length; i++) {
        if (centers[i].employeeEnrollmentLeaderId) {
          const message = `Der er allerede en leder på ${centers[i].name}`;

          dispatch(showErrorMessage({ message }));
          return true;
        }
      }

      return false;
    } catch (e) {
      return true;
    }
  };

  return { departmentsHaveLeader, centersHaveLeader };
}

export function useEmployeeEnrollmentData() {
  const [leaderData, setLeaderData] = useState<Employee>(employeeDefault());

  const getEmployeeDataByEnrollmentId = async (enrollmentId: string) => {
    try {
      if (!enrollmentId) {
        setLeaderData(employeeDefault());
        return;
      }

      const enrollmentData = await prodocApi.employeeEnrollment.get(enrollmentId);
      const data = await prodocApi.employee.getById(enrollmentData.employeeId);

      setLeaderData(data);
    } catch (e) {}
  };

  return { getEmployeeDataByEnrollmentId, leaderData };
}
