import prodocApi, { Note } from '../services/prodocApi';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { NotesState, setNotes } from '../store/notesStore';
import { showSuccessMessage } from '../store/notificationStore';
import { useState } from 'react';

export function useNoteData() {
  const getNoteData = async (id: string): Promise<Note> => {
    try {
      const data = await prodocApi.note.get(id);

      return { ...data, fromDate: moment(data.fromDate).toDate() };
    } catch (e) {}
  };

  return { getNoteData };
}

export function useNoteUpdate() {
  const dispatch = useDispatch();
  const notesState = useSelector<ApplicationState, NotesState>(state => state.notes);
  const [isLoading, setIsLoading] = useState(false);
  const { notes } = notesState;

  const syncUpdatedNoteWithDataSource = (data: Note) => {
    const isDepartmentNote = !!data.departmentId;
    const updatedList = notes.map(item => (item.id === data.id ? data : item));

    dispatch(setNotes(updatedList));
  };

  const updateNote = async (data: Note): Promise<boolean> => {
    try {
      setIsLoading(true);

      await prodocApi.note.update(data);

      dispatch(showSuccessMessage({ message: 'Notat ændret' }));

      return true;
    } catch (e) {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { updateNote, syncUpdatedNoteWithDataSource, isLoading };
}

export function useNoteDelete() {
  const dispatch = useDispatch();
  const notesState = useSelector<ApplicationState, NotesState>(state => state.notes);
  const { notes } = notesState;

  const syncDeletedNoteWithDataSource = (data: Note) => {
    const updatedList = notes.filter(item => item.id !== data.id);

    dispatch(setNotes(updatedList));
  };

  const deleteNote = async (id: string): Promise<boolean> => {
    try {
      await prodocApi.note.delete(id);

      dispatch(showSuccessMessage({ message: 'Note is deleted' }));

      return true;
    } catch (e) {
      return false;
    }
  };

  return { deleteNote, syncDeletedNoteWithDataSource };
}
