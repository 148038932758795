import { Button, Col, Form, Input, notification, Row } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import useReactRouter from 'use-react-router';
import { Card } from '../../components/_shared/Card/Card';
import Icon, { ICONS } from '../../components/_shared/Icon/Icon';
import prodocApi from '../../services/prodocApi';
import { defaultSettings } from '../../store/notificationStore';
import './Login.scss';

export const ForgotPasswordPage = () => {
  const [username, setUsername] = useState('');
  const keyPress = async e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleSendMail();
    }
  };
  const { history } = useReactRouter();

  const handleSendMail = async () => {
    await prodocApi.forgotPassword.forgot(username);
    notification.success({ ...defaultSettings, message: 'En email er sendt til brugerens email adresse' });
    history.push('/login');
  };

  const handleCancel = () => {
    history.push('/login');
  };
  return (
    <div className="card">
      <Card title="Glemt kodeord">
        <form className="login-form">
          <Form.Item>
            <Input
              prefix={<Icon name={ICONS.user} />}
              onKeyPress={keyPress}
              style={{ width: '300px' }}
              placeholder="Brugernavn"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Button className="button" type="link" onClick={handleCancel}>
                Annuller
              </Button>
            </Col>
            <Col span={12}>
              <Button className="button" type="primary" onClick={handleSendMail}>
                Send mail
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </div>
  );
};

export default ForgotPasswordPage;
