import { Action, actionCreator, isType } from './Action';
import { CitizenEnrollment, Measurement, MeasurementType } from '../services/prodocApi';
import prodocApi from '../services/prodocApi';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { PAGES_ITEMS, setPageItemLoading, setPageItemSaved } from './pagesStore';
import { ApplicationState } from './index';
import { citizenEnrollmentDefault } from '../services/defaults';
import { MESSAGES } from '../constants/messages';
import { UtilsMeasurements } from '../utils/UtilsMeasurements';
import { CommonObject } from '../types/common';
import { addCalendarItemsData, reloadCalendarItemsForCitizen } from './calendarStore';

export interface MeasurementState {
  isWindowOpen: boolean;
  preselectedType: MeasurementType | null;
  citizenItems: Measurement[];
  measurementCitizenEnrollment: CitizenEnrollment;
}

const defaultState: MeasurementState = {
  isWindowOpen: false,
  citizenItems: [],
  preselectedType: null,
  measurementCitizenEnrollment: citizenEnrollmentDefault(),
};

export const setMeasurementWindowStateAction = actionCreator<boolean>('SET_MEASUREMENT_WINDOW_STATE');
export const setMeasurementPreselectedTypeAction = actionCreator<MeasurementType | null>(
  'SET_MEASUREMENT_PRESELECTED_TYPE'
);
export const setCitizenMeasurementsAction = actionCreator<Measurement[]>('SET_CITIZEN_MEASUREMENTS');
export const setCitizenMeasurementsEnrollmentAction = actionCreator<CitizenEnrollment>(
  'SET_CITIZEN_MEASUREMENTS_ENROLLMENT'
);

export default (state: MeasurementState = defaultState, action: Action<any>) => {
  if (isType(action, setMeasurementWindowStateAction)) {
    return { ...state, isWindowOpen: action.payload };
  }

  if (isType(action, setCitizenMeasurementsAction)) {
    return { ...state, citizenItems: action.payload };
  }

  if (isType(action, setCitizenMeasurementsEnrollmentAction)) {
    return { ...state, measurementCitizenEnrollment: action.payload };
  }

  if (isType(action, setMeasurementPreselectedTypeAction)) {
    return { ...state, preselectedType: action.payload };
  }

  return state;
};

export const updateMeasurementItem = (data: Measurement) => {
  return (dispatch, getState) => {
    const state: ApplicationState = getState();
    const { citizenItems } = state.measurement;
    const updatedItems = citizenItems.map(item => (item.id === data.id ? data : item));

    dispatch(setCitizenMeasurementsAction(updatedItems));
  };
};

export const create = (data: Measurement) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.MEASUREMENT_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.MEASUREMENT_ITEM, true));

      await prodocApi.measurement.add(data);

      const state: ApplicationState = getState();
      const { selectedCitizenEnrollments } = state.calendar;

      if (selectedCitizenEnrollments.includes(data.citizenEnrollmentId)) {
        dispatch(reloadCalendarItemsForCitizen(data.citizenEnrollmentId));
      }

      dispatch(showSuccessMessage({ message: MESSAGES.SUCCESS.MEASUREMENTS_CREATE }));
      dispatch(setPageItemSaved(PAGES_ITEMS.MEASUREMENT_ITEM, true));
    } catch (e) {
      dispatch(showErrorMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.MEASUREMENT_ITEM, false));
    }
  };
};

export const update = (data: Measurement) => {
  return async dispatch => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.MEASUREMENT_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.MEASUREMENT_ITEM, true));

      const res = await prodocApi.measurement.update(data);

      dispatch(updateMeasurementItem(res));
      dispatch(showSuccessMessage({ message: MESSAGES.SUCCESS.MEASUREMENTS_UPDATE }));
      dispatch(setPageItemSaved(PAGES_ITEMS.MEASUREMENT_ITEM, true));
    } catch (e) {
      dispatch(showErrorMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.MEASUREMENT_ITEM, false));
    }
  };
};

export const loadMeasurements = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemLoading(PAGES_ITEMS.MEASUREMENT_LIST, true));

      const res = await prodocApi.measurement.getAll();

      dispatch(setCitizenMeasurementsAction(res));
    } catch (e) {
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.MEASUREMENT_LIST, false));
    }
  };
};
export const loadMeasurementEnrollment = (citizenId: string, departmentId: string) => {
  return async dispatch => {
    try {
      const result = await prodocApi.citizenEnrollment.getByCitizen(citizenId, true);

      const foundEnrollment = result.find(item => item.departmentId === departmentId) || citizenEnrollmentDefault();

      dispatch(setCitizenMeasurementsEnrollmentAction(foundEnrollment));
    } catch (e) {}
  };
};

export const updateMeasurementEnrollment = (data: CitizenEnrollment) => {
  return async dispatch => {
    try {
      await prodocApi.citizenEnrollment.update(data);

      dispatch(showSuccessMessage({ message: 'Oplysninger opdateret' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};

export const deleteMeasurement = (id: string) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { measurement } = state;
      const { citizenItems } = measurement;

      await prodocApi.measurement.delete(id);

      const filteredItems = citizenItems.filter(item => item.id !== id);

      dispatch(setCitizenMeasurementsAction(filteredItems));
      dispatch(showSuccessMessage({ message: 'Måling slettet' }));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};
