import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DATE_FORMAT } from '../../../constants/common';
import { Medicine } from '../../../services/prodocApi';
import { UtilsMedicine } from '../../../utils/UtilsMedicine';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { MedicineCalendarNavigationData } from '../../../types/medicine';
import { removeCalendarMedicineItems, setMedicineNavigationData } from '../../../store/calendarStore';
import { ICONS } from '../../_shared/Icon/Icon';
import Button from '../../_shared/_controls/Button/Button';

import './MedicineTable.scss';

const { Column, ColumnGroup } = Table;

interface Props {
  title: string;
  data: Medicine[];
  isExpired: boolean;
  onEdit(data: Medicine): void;
}

export const MedicineTable: React.FC<Props> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { title, data, onEdit, isExpired } = props;
  const tableWrapClass = classNames('medicine-table', {
    'medicine-table--expired': isExpired,
  });
  const renderCell = (value: string) => {
    return value;
  };

  const renderDate = date => (date ? renderCell(moment(date).format(DATE_FORMAT)) : '');
  const renderMedicine = item => {
    if (!item.product) return '';

    return renderCell(`${item.product.navn} ${item.product.styrke}`);
  };
  const renderDose = item => (item ? renderCell(t(item)) : '');
  const renderDosage = item => (item ? renderCell(item.product?.pakning) : '');

  const renderExtraditionDate = row => {
    const nextScheduleDate = UtilsMedicine.getNextTakeMedicineDate(row.schedules);

    if (!nextScheduleDate) return '';

    const handleCLick = () => {
      const navigationData: MedicineCalendarNavigationData = {
        citizenEnrollment: row.citizenEnrollmentId,
        citizenId: row.citizenId,
        date: nextScheduleDate,
      };

      dispatch(removeCalendarMedicineItems(row.citizenEnrollmentId));
      dispatch(setMedicineNavigationData(navigationData));
      push(UtilsNavigation.getHomeUrl());
    };

    return (
      <button type="button" onClick={handleCLick}>
        {moment(nextScheduleDate).format(DATE_FORMAT)}
      </button>
    );
  };

  const renderActionsButton = (value: string, row) => {
    const handleCLick = () => {
      onEdit(row);
    };

    return <Button icon={ICONS.edit} onClick={handleCLick} className="medicine-table__edit-btn" />;
  };

  return (
    <Table
      dataSource={data}
      pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }}
      rowKey="id"
      className={tableWrapClass}>
      <ColumnGroup title={title} className="medicine-table__title">
        <Column title="Medicin" render={renderMedicine} />
        <Column title="Dispenseringsform" render={renderDosage} />
        <Column title="Doseringsenhed" dataIndex="doseUnit" render={renderDose} />
        <Column title="Daglig dosis" dataIndex="dailyDose" render={renderDose} />
        <Column title="Administrativ startdato" dataIndex="fromDate" render={renderDate} />
        <Column title="Administrativ slutdato" dataIndex="toDate" render={renderDate} />
        <Column title="Næste udlevering" dataIndex="" render={renderExtraditionDate} />
        <Column title="" dataIndex="" render={renderActionsButton} />
      </ColumnGroup>
    </Table>
  );
};
