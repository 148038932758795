import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import prodocApi, { Employee } from '../../../services/prodocApi';
import { employeeDefault } from '../../../services/defaults';
import {
  addEmployee,
  EmployeeState,
  getEmployeeById,
  loadAvailableSearchEmployees,
  setEmployeeEdit,
  updateEmployee,
} from '../../../store/employeeStore';
import { useRouterParams } from '../../../hooks/navigation';
import { navigateTo, navigateToEmployeeEnroll } from '../../../store/navigationStore';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { ROUTES } from '../../../constants/routes';
import { setCenterEdit } from '../../../store/centerStore';
import { DepartmentState, setDepartmentEdit } from '../../../store/departmentStore';
import { URLS } from '../../../constants/urls';
import { loadUserDepartments } from '../../../store/userStore';
import { ApplicationState } from '../../../store';
import EmployeeCreateForm from '../../../components/employee/EmployeeCreateForm/EmployeeCreateForm';
import { usePageTitle } from '../../../hooks/common-hooks';
import { useHistory } from 'react-router';
import { notification } from 'antd';
import { showSuccessMessage } from '../../../utils/NotificationUtil';

function EmployeeCreatePage() {
  const dispatch = useDispatch();
  const [match] = useRouterParams();
  const employeesState = useSelector<any, EmployeeState>(state => state.employees);
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const { editEmployee, leaderCreate } = employeesState;
  const { userDepartments } = departmentState;
  const employeeData = editEmployee.data || employeeDefault();
  const userId = userState.id;
  const employeeId = match.params.id;
  const history = useHistory();

  usePageTitle('Opret medarbejder');

  React.useEffect(() => {
    if (userId) {
      dispatch(loadUserDepartments());
    }
  }, [userId]);

  React.useEffect(() => {
    if (userDepartments.length) {
      dispatch(loadAvailableSearchEmployees());
    }
  }, [userDepartments.length]);

  React.useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeById(employeeId));
    }

    return () => {
      dispatch(setEmployeeEdit({}));
    };
  }, [employeeId]);

  async function handleFormSubmit(data: Employee, selectedDepartmentId: string) {
    var employee = await prodocApi.employee.add(data);
    await prodocApi.employeeEnrollment.add({ departmentId: selectedDepartmentId, employeeId: employee.id });
    showSuccessMessage({ message: 'Aftale oprettet' });
    history.push(ROUTES.EMPLOYEE_OVERVIEW);
  }

  const handleLeaderCreationCanceling = () => {
    if (leaderCreate.department) {
      const route = UtilsNavigation.buildRoute(URLS.DEPARTMENT, URLS.ADD);
      dispatch(navigateTo(route));
      dispatch(setDepartmentEdit(leaderCreate.department));
      return;
    }

    dispatch(navigateTo(ROUTES.CENTER_ADD));
    dispatch(setCenterEdit(leaderCreate.center));
  };

  const handleCancel = () => {
    history.push(ROUTES.EMPLOYEE_OVERVIEW);
  };

  const handleEnroll = (id: string) => {
    dispatch(navigateToEmployeeEnroll(id));
  };

  return (
    <>
      <div className="employee-page page">
        <EmployeeCreateForm
          data={employeeData}
          onSubmit={handleFormSubmit}
          onCancel={handleCancel}
          onEnroll={handleEnroll}
        />
      </div>
    </>
  );
}

export default EmployeeCreatePage;
