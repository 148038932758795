import { actionCreator, isType } from './Action';
import prodocApi, { Note, NoteDto } from '../services/prodocApi';
import { PAGES_ITEMS, setPageItemLoading, setPageItemSaved } from './pagesStore';
import { ApplicationState } from './index';
import { showErrorMessage, showSuccessMessage } from './notificationStore';
import { MESSAGES } from '../constants/messages';
import { addCalendarNotes, getDepartmentNotes, reloadCalendarItemsForCitizen, setCalendarNotes } from './calendarStore';
import moment from 'moment';

export interface NotesState {
  notes: NoteDto[];
}

export const defaultState: NotesState = {
  notes: [],
};

export const setNotes = actionCreator<Note[]>('NOTES_SET_ITEMS');

export default (state: NotesState = defaultState, action: any): NotesState => {
  if (isType(action, setNotes)) {
    return { ...state, notes: action.payload };
  }

  return state;
};

export const loadNotes = () => {
  return async dispatch => {
    try {
      dispatch(setPageItemLoading(PAGES_ITEMS.NOTES_LIST, true));
      const items = await prodocApi.note.getAll();

      dispatch(setNotes(items));
    } catch (e) {
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.NOTES_LIST, false));
    }
  };
};

export const createNote = (data: Note) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, true));

      const state: ApplicationState = getState();
      const { calendar } = state;
      const { departmentId: calendarDepartment, selectedCitizenEnrollments } = calendar;
      const { departmentId: selectedDepartment, citizenEnrollmentId } = data;
      const noteData: Note = {
        ...data,
        citizenEnrollmentId: citizenEnrollmentId || '',
        toDate: moment(data.fromDate).add(30, 'minutes').toDate(),
      };

      const createdNote = await prodocApi.note.add(noteData);

      if (selectedDepartment === calendarDepartment) {
        dispatch(setCalendarNotes([]));
        dispatch(getDepartmentNotes(calendarDepartment));
      } else {
        dispatch(addCalendarNotes([createdNote]));
      }

      if (citizenEnrollmentId && selectedCitizenEnrollments.includes(citizenEnrollmentId)) {
        dispatch(reloadCalendarItemsForCitizen(citizenEnrollmentId));
      }

      dispatch(showSuccessMessage({ message: 'Notat oprettet' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, true));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, false));
    }
  };
};

export const editNote = (data: Note) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, false));
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, true));

      const state: ApplicationState = getState();
      const { calendar } = state;
      const { citizenEnrollmentId } = data;
      const updatedItem = await prodocApi.note.update(data);

      dispatch(showSuccessMessage({ message: 'Notat ændret' }));
      dispatch(setPageItemSaved(PAGES_ITEMS.CALENDAR_ITEM, true));

      const updatedNotes = calendar.calendarNotes.map(item => {
        if (item.id === updatedItem.id) return updatedItem;

        return item;
      });

      if (citizenEnrollmentId) dispatch(reloadCalendarItemsForCitizen(citizenEnrollmentId));

      dispatch(setCalendarNotes(updatedNotes));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    } finally {
      dispatch(setPageItemLoading(PAGES_ITEMS.CALENDAR_ITEM, false));
    }
  };
};

export const deleteNote = (id: string) => {
  return async (dispatch, getState) => {
    try {
      const state: ApplicationState = getState();
      const { calendar } = state;

      await prodocApi.note.delete(id);

      dispatch(showSuccessMessage({ message: 'Note is deleted' }));

      const updatedNotes = calendar.calendarNotes.filter(item => item.id !== id);

      dispatch(setCalendarNotes(updatedNotes));
    } catch (e) {
      dispatch(showSuccessMessage({ message: MESSAGES.ERRORS.COMMON_ERROR }));
    }
  };
};
