import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../_shared/_controls/Button/Button';
import { ICONS } from '../../_shared/Icon/Icon';
import { MedicineTable } from '../MedicineTable/MedicineTable';
import { MedicineOrdinationWindow } from '../Ordination/MedicineOrdinationWindow/MedicineOrdinationWindow';
import { ApplicationState } from '../../../store';
import { loadMedicineItems, MedicineState } from '../../../store/medicineStore';
import { Medicine, MedicineType } from '../../../services/prodocApi';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';

import './MedicineSection.scss';

export const MedicineSection: React.FC = () => {
  const dispatch = useDispatch();
  const medicineStore = useSelector<ApplicationState, MedicineState>(state => state.medicine);
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const [showOrdinationWindow, setShowOrdinationWindow] = useState(false);
  const [showExpiredItems, setShowExpiredItems] = useState(false);
  const [windowData, setWindowData] = useState<Medicine | undefined>();
  const { activeCitizen: citizenEnrollmentId, medicineItems, expiredMedicineItems } = medicineStore;
  const isOrdinationSaved = pagesState[PAGES_ITEMS.ORDINATION].saved;
  const itemsList = showExpiredItems ? expiredMedicineItems : medicineItems;
  const restItems = useMemo(
    () =>
      itemsList.filter(item => {
        return (
          item.medicineType.indexOf(MedicineType.PN) < 0 && item.medicineType.indexOf(MedicineType.DoseDispensing) < 0
        );
      }),
    [itemsList]
  );
  const pnItems = useMemo(() => itemsList.filter(item => item.medicineType.indexOf(MedicineType.PN) >= 0), [itemsList]);
  const doseItems = useMemo(
    () => itemsList.filter(item => item.medicineType.indexOf(MedicineType.DoseDispensing) >= 0),
    [itemsList]
  );
  const expiredItemsButtonTitle = showExpiredItems ? 'Skjul seponeret medicin' : 'Vis seponeret medicin';
  const ordinationTableTitle = showExpiredItems ? 'Seponerede ordinationer' : 'Ordinationer';
  const pnTableTitle = showExpiredItems ? 'Seponeret PN' : 'PN';
  const doseTableTitle = showExpiredItems ? 'Seponeret dosisdispenseret' : 'Dosisdispenseret';

  useEffect(() => {
    return () => {
      setShowOrdinationWindow(false);
      setWindowData(undefined);
    };
  }, []);

  useEffect(() => {
    if (citizenEnrollmentId) {
      dispatch(loadMedicineItems(citizenEnrollmentId));
    }
  }, [citizenEnrollmentId]);

  useEffect(() => {
    if (isOrdinationSaved && citizenEnrollmentId) {
      dispatch(loadMedicineItems(citizenEnrollmentId));
    }
  }, [isOrdinationSaved, citizenEnrollmentId]);

  const openOrdinationWindow = () => {
    setShowOrdinationWindow(true);
  };

  const handleToggleInactiveItems = () => {
    setShowExpiredItems(prevState => !prevState);
  };

  const handleWindowClose = () => {
    setWindowData(undefined);
    setShowOrdinationWindow(false);
  };

  const handleItemEdit = (data: Medicine) => {
    setWindowData(data);
    setShowOrdinationWindow(true);
  };

  return (
    <div className="medicine-section">
      <div className="medicine-section__actions">
        <Button
          title="Opret ordination"
          icon={ICONS.plus}
          type="primary"
          disabled={!citizenEnrollmentId}
          onClick={openOrdinationWindow}
        />
        <Button title={expiredItemsButtonTitle} onClick={handleToggleInactiveItems} />
      </div>
      <MedicineTable
        title={ordinationTableTitle}
        data={restItems}
        isExpired={showExpiredItems}
        onEdit={handleItemEdit}
      />
      <MedicineTable title={pnTableTitle} data={pnItems} isExpired={showExpiredItems} onEdit={handleItemEdit} />
      <MedicineTable title={doseTableTitle} data={doseItems} isExpired={showExpiredItems} onEdit={handleItemEdit} />
      <MedicineOrdinationWindow isOpened={showOrdinationWindow} data={windowData} onCLose={handleWindowClose} />
    </div>
  );
};
