import { OrdinationPeriod, OrdinationSchedule, OrdinationTableRow } from '../types/medicine';
import { OrdinationScheduleType } from '../constants/medicine';
import { UtilsData } from '../utils/UtilsData';

const defaultId = UtilsData.generateId();

export const defaultMedicineRow: OrdinationTableRow = {
  id: 0,
  title: '',
  all: null,
  MO: null,
  TU: null,
  WE: null,
  TH: null,
  FR: null,
  SA: null,
  SU: null,
};

export const defaultMedicinePeriod: OrdinationPeriod = {
  id: defaultId,
  startDate: new Date(),
  endDate: new Date(),
  rows: {
    [OrdinationScheduleType.Daily]: [],
    [OrdinationScheduleType.Weekly]: [],
    [OrdinationScheduleType.Monthly]: [],
  },
};

export const defaultMedicineSchedule: OrdinationSchedule = {
  [defaultId]: defaultMedicinePeriod,
};

export const defaultDailyMapItem = () => {
  return {
    hours: [],
    minutes: [],
  };
};

export const getDefaultDailyMap = () => {
  return {
    MO: defaultDailyMapItem(),
    TU: defaultDailyMapItem(),
    WE: defaultDailyMapItem(),
    TH: defaultDailyMapItem(),
    FR: defaultDailyMapItem(),
    SA: defaultDailyMapItem(),
    SU: defaultDailyMapItem(),
  };
};
