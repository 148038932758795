import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import prodocApi, { AuthType, Department, Employee, EmployeeEnrollment, Position } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import Button from '../../_shared/_controls/Button/Button';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import Title from 'antd/lib/typography/Title';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import { employeeSchema } from '../../../utils/UtilsValidation';
import { useCprValidation, useRegisteredFields } from '../../../hooks/validation-hooks';
import { UtilsNavigation } from '../../../utils/UtilsNavigation';
import { URLS } from '../../../constants/urls';
import { useNavigation, useRouterParams } from '../../../hooks/navigation';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { UtilsItems } from '../../../utils/UtilsItems';
import { CalendarState } from '../../../store/calendarStore';
import { useEnumOptionsTranslation, useZipCodeField } from '../../../hooks/common-hooks';
import { DepartmentState } from '../../../store/departmentStore';
import { EmployeeState } from '../../../store/employeeStore';
import { CardActions } from '../../_shared/CardActions/CardActions';

import './EmployeeEditForm.scss';
import { Col, Modal, Row, Tooltip } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import moment from 'moment';

interface Props {
  data: Employee;
  onSubmit(data: Employee): void;
  onCancel(): void;
}

interface PasswordData {
  password: string;
  passwordRepeat: string;
}

const authOptions = UtilsAuth.getAuthTypeOptions();
const defaultPasswordsData = {
  password: '',
  passwordRepeat: '',
};

export default function EmployeeEditForm(props: Props) {
  const { onSubmit, onCancel } = props;

  const [navigateTo] = useNavigation();
  const [match] = useRouterParams();
  const { getZipCodeData } = useZipCodeField();
  const calendarStore = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const pagesItemsStore = useSelector<ApplicationState, PagesState>(state => state.pages);
  const departmentState = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const employeesState = useSelector<ApplicationState, EmployeeState>(state => state.employees);
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const { isCprValid } = useCprValidation();
  const isDataSaved = pagesItemsStore[PAGES_ITEMS.EMPLOYEE_FORM].saved;
  const { employeesEnrollments } = calendarStore;
  const { userDepartments } = departmentState;
  const { availableSearchEmployees } = employeesState;
  const isEmployee = userState.authType === AuthType.Employee;

  const [employee, setEmployee] = useState<Employee>({} as Employee);

  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [enrollData, setEnrollData] = React.useState<PasswordData>(defaultPasswordsData);
  // const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
  //   validationSchema: employeeSchema,
  //   defaultValues: data as any,
  // });
  const filteredEmployeesEnrollments = useMemo(() => {
    return availableSearchEmployees.filter(item => item.departmentId === selectedDepartment);
  }, [selectedDepartment, availableSearchEmployees.length]);

  // useRegisteredFields(employeeSchema, register, unregister);

  const {
    firstname,
    username,
    lastname,
    address,
    zipCode,
    city,
    phone,
    phonePrivate,
    email,
    emailPrivate,
    authType,
    cpr,
  } = employee;
  const { id: employeeId, overview } = match.params;
  const employeeValue = filteredEmployeesEnrollments.length ? employeeId : '';
  console.log(employeeId);

  // const resetFormFields = () => {
  //   // Object.keys(data).forEach(key => {
  //   //   setValue(key, data[key]);
  //   // });

  //   setEmployee(data);
  // };
  useEffect(() => {
    async function init() {
      const employeeResult = await prodocApi.employee.getById(employeeId);
      setEmployee(employeeResult);
    }
    init();
  }, []);
  // useEffect(() => {
  //   // resetFormFields();

  //   setPasswordData({
  //     password: data.password,
  //     passwordRepeat: data.password,
  //   });
  //   // setValue('passwordRepeat', data.password);

  //   if (!data.id) {
  //     setPasswordData(defaultPasswordsData);
  //   }
  // }, [data.id]);

  // useEffect(() => {
  //   if (isDataSaved) resetFormFields();
  // }, [isDataSaved]);

  // useEffect(() => {
  //   if (employeesEnrollments.length) {
  //     const employeeEnrollment = employeesEnrollments.find(item => employeeId === item.employeeId);

  //     setSelectedDepartment(employeeEnrollment?.departmentId || '');
  //   }
  // }, [employeesEnrollments.length]);

  const handleFieldsChange = (value: any, name: string) => {
    const zipData = getZipCodeData(value, name, { ...employee, [name]: value });

    setEmployee(zipData);
    // setValue(name, value);
    // clearError(name);
  };

  const handleCprFieldChange = (value: string) => {
    let handledValue = value;

    if (!isCprValid(value)) return;

    handledValue = UtilsItems.formatCprNumber(value);

    setEmployee({ ...employee, cpr: handledValue });
    // setValue('cpr', handledValue);
    // clearError('cpr');
  };

  const handleSelectChange = (value: string, name: string) => {
    setEmployee({ ...employee, [name]: value });
  };

  const handleDepartmentChange = (id: string) => {
    const route = UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.DETAILS);

    navigateTo(route);
    setSelectedDepartment(id);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleEmployeeSelect = (selectedId: string) => {
    if (selectedId) {
      const route = UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.DETAILS, selectedId);

      navigateTo(route);
    }
  };

  return (
    <div>
      <form className="citizen-form item-form">
        <Card
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span></span>
            </div>
          }>
          <Row gutter={8}>
            <Col span={6}>
              <TextInput
                title="Cpr"
                name="cpr"
                value={cpr}
                // error={errors.cpr && errors.cpr.message}
                onChange={handleCprFieldChange}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={6}>
              <TextInput
                title="Fornavn"
                name="firstname"
                value={firstname}
                // error={errors.firstname && errors.firstname.message}
                onChange={handleFieldsChange}
              />
            </Col>
            <Col span={6}>
              <TextInput
                title="Efternavn"
                name="lastname"
                value={lastname}
                // error={errors.lastname && errors.lastname.message}
                onChange={handleFieldsChange}
              />
            </Col>
            <Col span={6}>
              <TextInput title="Telefon (Arbejde)" name="phone" value={phone} onChange={handleFieldsChange} />
            </Col>
            <Col span={6}>
              <TextInput
                title="Telefon (Privat)"
                name="phonePrivate"
                value={phonePrivate}
                onChange={handleFieldsChange}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <TextInput title="Adresse (Privat)" name="address" value={address} onChange={handleFieldsChange} />
            </Col>
            <Col span={4}>
              <TextInput title="Postnummer" name="zipCode" value={zipCode} onChange={handleFieldsChange} />
            </Col>
            <Col span={8}>
              <TextInput title="By" name="city" value={city} onChange={handleFieldsChange} />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <TextInput
                title="Email (Arbejde)"
                name="email"
                value={email}
                // error={errors.email && errors.email.message}
                onChange={handleFieldsChange}
              />
            </Col>
            <Col span={12}>
              <TextInput
                title="Email (Privat)"
                name="emailPrivate"
                value={emailPrivate}
                onChange={handleFieldsChange}
              />
            </Col>
          </Row>
          <CardActions
            rightActions={
              <>
                <Button title="Annuller" onClick={handleCancel} />
                <Button title="Opdater" type="primary" htmlType="submit" />
              </>
            }
          />
        </Card>
      </form>
    </div>
  );
}
