import { actionCreator, isType } from './Action';

export enum PAGES_ITEMS {
  CITIZEN_FORM = 'citizenForm',
  CENTER_FORM = 'centerForm',
  DEPARTMENT_FORM = 'departmentForm',
  EMPLOYEE_FORM = 'employeeForm',
  CALENDAR_ITEM = 'calendarItem',
  MEASUREMENT_ITEM = 'measurementItem',
  MEASUREMENT_LIST = 'measurementList',
  MEDICINE_STATUS = 'medicineStatus',
  ORDINATION = 'ordination',
  DOCUMENT_UPLOADING = 'documentUploading',
  DOCUMENT_UPDATING = 'documentUpdating',
  GOAL = 'goal',
  GOAL_STATUS = 'goalStatus',
  NOTES_LIST = 'notesList',
}

interface PageItemState {
  saved?: boolean;
  loading?: boolean;
}

export interface PagesState {
  [key: string]: PageItemState;
}

const defaultState: PagesState = {
  [PAGES_ITEMS.CITIZEN_FORM]: {},
  [PAGES_ITEMS.CENTER_FORM]: {},
  [PAGES_ITEMS.DEPARTMENT_FORM]: {},
  [PAGES_ITEMS.EMPLOYEE_FORM]: {},
  [PAGES_ITEMS.CALENDAR_ITEM]: {},
  [PAGES_ITEMS.MEASUREMENT_ITEM]: {},
  [PAGES_ITEMS.MEASUREMENT_LIST]: {},
  [PAGES_ITEMS.MEDICINE_STATUS]: {},
  [PAGES_ITEMS.ORDINATION]: {},
  [PAGES_ITEMS.DOCUMENT_UPLOADING]: {},
  [PAGES_ITEMS.DOCUMENT_UPDATING]: {},
  [PAGES_ITEMS.GOAL]: {},
  [PAGES_ITEMS.GOAL_STATUS]: {},
  [PAGES_ITEMS.NOTES_LIST]: {},
};

export const updatePageItemSavedState = actionCreator<{ key: string, saved: boolean }>('UPDATE_SAVED_STATE');
export const updatePageItemLoadingState = actionCreator<{ key: string, loading: boolean }>('UPDATE_LOADING_STATE');

export default (state = defaultState, action: any) => {
  if (isType(action, updatePageItemSavedState)) {
    const pageItemKey = action.payload.key;

    return {
      ...state,
      [pageItemKey]: {
        ...state[pageItemKey],
        saved: action.payload.saved,
      },
    };
  }

  if (isType(action, updatePageItemLoadingState)) {
    const pageItemKey = action.payload.key;

    return {
      ...state,
      [pageItemKey]: {
        ...state[pageItemKey],
        loading: action.payload.loading,
      },
    };
  }

  return state;
};

export const setPageItemSaved = (key: string, saved: boolean) => {
  return (dispatch) => {
    dispatch(updatePageItemSavedState({ key, saved }));
  };
};

export const setPageItemLoading = (key: string, loading: boolean) => {
  return (dispatch) => {
    dispatch(updatePageItemLoadingState({ key, loading }));
  };
};
