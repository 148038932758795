import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import styled, { createGlobalStyle } from 'styled-components';

interface Props {
  onChange?(checked: boolean, name?: string): void;
  label?: string | JSX.Element;
  checked: boolean;
  name?: string;
  className?: string;
  disabled?: boolean;
  color?: string;
}

const StyledCheckbox = styled(AntdCheckbox)`
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${props => props.color} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${props => props.color} !important;
    border-color: ${props => props.color} !important;
  }

  .ant-checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: none;
    content: '';
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }

  :hover .ant-checkbox-inner {
    border-color: #d9d9d9 !important;
  }
`;

export const Checkbox = (props: Props) => {
  const { onChange, label, name, disabled, checked, className, color } = props;
  const wrapperClass = classNames('checkbox', {
    [className]: !!className,
  });

  const handleChange = (e: CheckboxChangeEvent) => {
    onChange && onChange(e.target.checked, name);
  };

  return (
    <div className={wrapperClass}>
      <StyledCheckbox color={color} onChange={handleChange} checked={checked} disabled={disabled}>
        {label}
      </StyledCheckbox>
    </div>
  );
};
