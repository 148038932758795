import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Col, Row } from 'antd';
import SelectMenu from '../../../components/_shared/_controls/SelectMenu/SelectMenu';
import { ApplicationState } from '../../../store';
import { CitizenEnrollment } from '../../../services/prodocApi';
import { CalendarState, loadCalendarSidebarCitizens } from '../../../store/calendarStore';
import { Card } from '../../../components/_shared/Card/Card';
import { UtilsData } from '../../../utils/UtilsData';
import MeasurementEditForm from '../../../components/measurement/MeasurementEditForm/MeasurementEditForm';
import {
  loadMeasurementEnrollment,
  MeasurementState,
  updateMeasurementEnrollment,
} from '../../../store/measurementStore';
import { usePageTitle } from '../../../hooks/common-hooks';
import { CommonState, loadUserEnrollments } from '../../../store/commonStore';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';

export default function MeasurementEditPage() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const commonState = useSelector<ApplicationState, CommonState>(state => state.common);
  const calendarState = useSelector<ApplicationState, CalendarState>(state => state.calendar);
  const measurementState = useSelector<ApplicationState, MeasurementState>(state => state.measurement);
  const { id: citizenId = '' } = params || {};
  const [selectedCitizen, setSelectedCitizen] = useState(citizenId);
  const { measurementsItems } = useCitizenNavigationItems({ measurementCitizenId: selectedCitizen });
  const [department, setDepartment] = useState('');
  const { userEnrollments } = commonState;
  const { citizensEnrollments } = calendarState;
  const { measurementCitizenEnrollment } = measurementState;
  const filteredCitizensEnrollments = useMemo(() => {
    if (!citizensEnrollments.length) return [];

    return UtilsData.removeDuplicatesFromArray(citizensEnrollments, 'citizenId');
  }, [citizensEnrollments.length]);

  usePageTitle('Målinger');

  useEffect(() => {
    dispatch(loadUserEnrollments());
  }, []);

  useEffect(() => {
    if (userEnrollments.length) {
      dispatch(loadCalendarSidebarCitizens());
    }
  }, [userEnrollments.length]);

  const handleCitizenChange = (citizenId: string) => {
    setSelectedCitizen(citizenId);
  };

  const handleDepartmentChange = (departmentId: string) => {
    setDepartment(departmentId);
    dispatch(loadMeasurementEnrollment(selectedCitizen, departmentId));
  };

  const handleUpdate = (data: CitizenEnrollment) => {
    dispatch(updateMeasurementEnrollment(data));
  };

  return (
    <>
      <Sidebar>
        <NavigationMenu items={measurementsItems} />
      </Sidebar>
      <div className="page measurements">
        <div className="measurements__content">
          <Card>
            <Row gutter={8}>
              <Col span={6}>
                <SelectMenu
                  title="Vælg borger"
                  value={selectedCitizen}
                  valueKey="citizenId"
                  labelKey={['firstname', 'lastname']}
                  options={filteredCitizensEnrollments}
                  onChange={handleCitizenChange}
                />
              </Col>
              <Col span={6}>
                <SelectMenu
                  disabled={!selectedCitizen}
                  title="Vælg afdeling"
                  value={department}
                  valueKey="departmentId"
                  labelKey="departmentName"
                  options={userEnrollments}
                  onChange={handleDepartmentChange}
                />
              </Col>
            </Row>
            <MeasurementEditForm disabled={!department} data={measurementCitizenEnrollment} onSubmit={handleUpdate} />
          </Card>
        </div>
      </div>
    </>
  );
}
