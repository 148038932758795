import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Employee, EmployeeEnrollmentResult } from '../../../services/prodocApi';
import OverviewDetailsField from '../OverviewDetailsField/OverviewDetailsField';
import { EmployeeState, getEmployeeOverviewData, setEmployeeOverview } from '../../../store/employeeStore';
import { ApplicationState } from '../../../store';
import { useEmployeeData } from '../../../hooks/employee-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  data: EmployeeEnrollmentResult | Employee;
  short?: boolean;
}

export default function OverviewDetailsEmployee(props: Props) {
  const { data, short } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // @ts-ignore
  const { employee } = useEmployeeData(data?.employeeId);
  const employeesState = useSelector<ApplicationState, EmployeeState>(state => state.employees);
  // @ts-ignore
  const employeeData = data.employeeId ? employee : data as Employee;
  const { address, zipCode, city, phone, email, username, authType, id, phonePrivate, emailPrivate } = employeeData;
  const { employeeOverview } = employeesState;
  const { enrollments, enrollmentDepartments } = employeeOverview;

  React.useEffect(() => {
    dispatch(getEmployeeOverviewData(id));

    return () => {
      dispatch(setEmployeeOverview({
        enrollments: [],
        enrollmentDepartments: [],
      }));
    };
  }, [id]);

  const employeePositions = enrollments.map((item) => (t(item.position))).join(', ');
  const enrollmentDepartmentsValue = enrollmentDepartments.map((item) => (item.name)).join(', ');

  if (short) {
    return (
      <div>
        <OverviewDetailsField label="Telefon" value={phone} />
        <OverviewDetailsField label="Email" value={email} />
      </div>
    );
  }

  return (
    <div>
      <OverviewDetailsField label="Adresse (Privat)" value={address} />
      <OverviewDetailsField label="Postnummer" value={zipCode} />
      <OverviewDetailsField label="By" value={city} />
      <OverviewDetailsField label="Telefon (Arbejde)" value={phone} />
      <OverviewDetailsField label="Telefon (Privat)" value={phonePrivate} />
      <OverviewDetailsField label="Email (Arbejde)" value={email} />
      <OverviewDetailsField label="Email (Privat)" value={emailPrivate} />
      <OverviewDetailsField label="Brugernavn" value={username} />
      <OverviewDetailsField label="System rettighed" value={authType} />
      <OverviewDetailsField label="Afdeling" value={enrollmentDepartmentsValue} />
      <OverviewDetailsField label="Stilling" value={employeePositions} />
    </div>
  );
}
