import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeEnrollment, Position } from '../../../services/prodocApi';
import { Card } from '../../_shared/Card/Card';
import Button from '../../_shared/_controls/Button/Button';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { employeeEnrollSchema, requiredMessage } from '../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import { ApplicationState } from '../../../store';
import { DepartmentState } from '../../../store/departmentStore';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { setCitizensSearchResult } from '../../../store/citizenStore';
import ItemsSearch from '../../_shared/ItemsSearch/ItemsSearch';
import { EmployeeState, searchEmployee, setEmployeeEnrollmentDepartments } from '../../../store/employeeStore';
import { UtilsData } from '../../../utils/UtilsData';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { CardActions } from '../../_shared/CardActions/CardActions';

import './EmployeeEnrollForm.scss';

interface Props {
  onSubmit(data: EmployeeEnrollment, departmentsIds: string[]): void;
  onCancel(): void;
}

export default function EmployeeEnrollForm(props: Props) {
  const { onSubmit, onCancel } = props;
  const dispatch = useDispatch();
  const employeeState = useSelector<ApplicationState, EmployeeState>(state => state.employees);
  const departmentStore = useSelector<ApplicationState, DepartmentState>(state => state.department);
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const defaultData = useMemo(() => UtilsData.getEmployeeEnrollmentDefaults(), []);
  const departments = departmentStore.userDepartments;
  const { employeeSearchResult: foundEmployees, editEmployee, enrollmentDepartments } = employeeState;
  const loadedEmployee = editEmployee.data;
  const [formData, setFormData] = React.useState<EmployeeEnrollment>(defaultData);
  const [selectedDepartments, setSelectedDepartments] = React.useState<string[]>([]);
  const [departmentsError, setDepartmentsError] = React.useState('');
  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: employeeEnrollSchema,
    defaultValues: defaultData as any,
  });
  useRegisteredFields(employeeEnrollSchema, register, unregister);

  const { enrollmentDate, position } = formData;

  React.useEffect(() => {
    if (loadedEmployee) {
      setFormData({ ...formData, employeeId: loadedEmployee.id });
      setValue('employeeId', loadedEmployee.id);
    }
  }, [loadedEmployee]);

  const handleFieldChange = (value: string | Date, name: string) => {
    setFormData({ ...formData, [name]: value });
    setValue(name, value);
    clearError(name);
  };

  const handleDepartmentsSelect = (value: string[]) => {
    if (value.length) {
      setDepartmentsError('');
    }

    dispatch(setEmployeeEnrollmentDepartments([]));
    setSelectedDepartments(value);
  };

  const handleFormSubmit = () => {
    if (!selectedDepartments.length) {
      setDepartmentsError(requiredMessage);
      return;
    }

    onSubmit(formData, selectedDepartments);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <form className="employee-enroll-form item-form">
      <Card>
        <SelectMenu
          title="Vælg Afdeling"
          name="departmentId"
          valueKey="id"
          labelKey="name"
          value={selectedDepartments}
          multiple
          options={departments}
          error={departmentsError}
          valuesError={enrollmentDepartments}
          onChange={handleDepartmentsSelect}
        />
        <ItemsSearch
          data={foundEmployees}
          optionLabelItems={['firstname', 'lastname', 'username']}
          type="employee"
          title="Vælg medarbejder"
          defaultOption={loadedEmployee}
          name="employeeId"
          placeholder="Skriv for at søge"
          searchAction={searchEmployee}
          error={errors.employeeId && errors.employeeId.message}
          updateResultAction={setCitizensSearchResult}
          onSelect={handleFieldChange}
        />
        <DatePicker
          value={enrollmentDate}
          title="Indskrivnings dato"
          name="enrollmentDate"
          error={errors.enrollmentDate && errors.enrollmentDate.message}
          onChange={handleFieldChange}
        />
        <SelectMenu
          title="Stilling"
          name="position"
          valueKey="value"
          labelKey="label"
          value={position}
          options={translateEnumOptions(Position)}
          error={errors.position && errors.position.message}
          onChange={handleFieldChange}
        />
        <CardActions
          rightActions={
            <>
              <Button title="Opret" htmlType="submit" onClick={handleSubmit(handleFormSubmit)} />
              <Button title="Annuller" onClick={handleCancel} />
            </>
          }
        />
      </Card>
    </form>
  );
}
