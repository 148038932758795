import React from 'react';
import Button from '../../_controls/Button/Button';

import './DrawerActions.scss';

interface Props {
  okDisabled?: boolean;
  onOk(): void;
  onCancel(): void;
}

export const DrawerActions: React.FC<Props> = props => {
  const { onOk, okDisabled, onCancel } = props;

  return (
    <div className="drawer-actions">
      <Button title="Gem" type="primary" disabled={okDisabled} onClick={onOk} />
      <Button title="Annuller" onClick={onCancel} />
    </div>
  );
};
