import React, { useEffect, useState } from 'react';
import { CitizenEnrollment } from '../../../services/prodocApi';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { UtilsData } from '../../../utils/UtilsData';
import Button from '../../_shared/_controls/Button/Button';
import TextArea from '../../_shared/_controls/TextArea/TextArea';

interface Props {
  data: CitizenEnrollment;
  onSubmit(data: CitizenEnrollment): void;
  disabled: boolean;
}

export default function MeasurementEditForm(props: Props) {
  const { data, onSubmit, disabled } = props;
  const [formData, setFormData] = useState(UtilsData.getCitizenEnrollmentDefaults());
  const [fieldIsChanged, setFieldIsChanged] = useState(false);

  const {
    riskAssessmentGreen,
    riskAssessmentYellow,
    riskAssessmentRed,
    riskAssessmentGreenAct,
    riskAssessmentYellowAct,
    riskAssessmentRedAct,
  } = formData;

  useEffect(() => {
    setFormData(data);
  }, [data.id]);

  const handleFieldChange = (value: string | Date, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setFieldIsChanged(true);
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    onSubmit(formData);
  };

  return (
    <form className="common-form" onSubmit={handleFormSubmit}>
      <TextArea
        disabled={disabled}
        title="Beskrivelse af risikovurdering grøn"
        name="riskAssessmentGreenAct"
        value={riskAssessmentGreenAct}
        onChange={handleFieldChange}
      />
      <TextArea
        disabled={disabled}
        title="Handling ved risikovurdering grøn"
        name="riskAssessmentGreen"
        value={riskAssessmentGreen}
        onChange={handleFieldChange}
      />
      <TextArea
        disabled={disabled}
        title="Beskrivelse af risikovurdering gul"
        name="riskAssessmentYellowAct"
        value={riskAssessmentYellowAct}
        onChange={handleFieldChange}
      />
      <TextArea
        disabled={disabled}
        title="Handling ved risikovurdering gul"
        name="riskAssessmentYellow"
        value={riskAssessmentYellow}
        onChange={handleFieldChange}
      />
      <TextArea
        disabled={disabled}
        title="Beskrivelse af risikovurdering rød"
        name="riskAssessmentRedAct"
        value={riskAssessmentRedAct}
        onChange={handleFieldChange}
      />
      <TextArea
        disabled={disabled}
        title="Handling ved risikovurdering rød"
        name="riskAssessmentRed"
        value={riskAssessmentRed}
        onChange={handleFieldChange}
      />
      <Button disabled={!fieldIsChanged} title="Gem" htmlType="submit" onClick={handleFormSubmit} />,
    </form>
  );
}
