import { CalendarStatus } from '../services/prodocApi';
import { CalendarItemsFilterValues } from '../constants/calendar';
import { CalendarItemsData } from '../types/calendar';

export const repetitionOptions = [
  {
    label: 'Dagligt',
    value: 1,
  },
  {
    label: 'Ugentligt',
    value: 2,
  },
  {
    label: 'Ugedage',
    value: 3,
  },
  {
    label: 'Månedligt',
    value: 4,
  },
];

export const statusOptions = [
  {
    label: 'Accepteret',
    value: CalendarStatus.Accepted,
  },
  {
    label: 'Afvist',
    value: CalendarStatus.Declined,
  },
  {
    label: 'Intet svar',
    value: CalendarStatus.Unanswered,
  },
];

export const defaultCalendarData: CalendarItemsData = {
  health: [],
  notes: [],
  goals: [],
  medicine: [],
  timeTrack: [],
  appointments: [],
  measurements: [],
  useOfForce: [],
  postings: [],
};

export const CalendarItemsFilterOptions = [
  {
    label: 'Aftaler',
    value: CalendarItemsFilterValues.Appointments,
  },
  {
    label: 'Notater',
    value: CalendarItemsFilterValues.Notes,
  },
  {
    label: 'Medicin',
    value: CalendarItemsFilterValues.Medicine,
  },
  {
    label: 'Magt',
    value: CalendarItemsFilterValues.UseOfForce,
  },
  {
    label: 'Mål og delmål',
    value: CalendarItemsFilterValues.Goals,
  },
  {
    label: 'Helbred',
    value: CalendarItemsFilterValues.Health,
  },
  {
    label: 'Tidsregistreringer',
    value: CalendarItemsFilterValues.TimeTrack,
  },
  {
    label: 'Målinger',
    value: CalendarItemsFilterValues.Measurements,
  },
];
