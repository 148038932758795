import { Citizen, CalendarItem, CalendarItemType, Employee } from '../services/prodocApi';
import { actionCreator, isType } from './Action';
import { citizenDefault, employeeDefault } from '../services/defaults';
// import { fetchDocument } from './documentStore';
import  prodocApi  from '../services/prodocApi';

export interface MetaState {
  selectedGoalCitizenCollection: Citizen[];
  selectedDate: { start: Date; end: Date };
  isAsyncCollection: { key: string; text: string }[];
  selectedCitizenIds: string[];
  // selectedDocument: Power1 | Power2 | SchemeA | SchemeB;
  calendarItemType: CalendarItemType;
}
export const selectedCalendarCitizenIdCollectionAction = actionCreator<{ citizens: Citizen[] }>('SELECTED_CALENDAR_CITIZEN_ID_COLLECTION_ACTION');
export const updateSelectedDateAction = actionCreator<{ start: Date; end: Date }>('UPDATE_SELECTED_DATE_ACTION');
export const updateIsAsyncAction = actionCreator<{ key: string; text?: string; isAsync: boolean }>('UPDATE_IS_ASYNC_ACTION');
export const updateSelectedDocumentAction = actionCreator<{ prop: string; value: any }>('UPDATE_SELECTED_DOCUMENT_ACTION');
// const setSelectedDocumentAction = actionCreator<{
// 	document: Power1 | Power2 | SchemeA | SchemeB;
// 	calendarItemType: CalendarItemType;
// }>('SET_SELECTED_DOCUMENT_ACTION');
export const updateSelectedCalendarItemAction = actionCreator<{ prop: string; value: any }>('UPDATE_SELECTED_CALENDAR_ITEM_ACTION');

const setSelectedCitizenIdsAction = actionCreator<{ citizenIds: string[] }>('SET_SELECTED_CITIZEN_IDS_ACTION');

export default (
  state: MetaState = {
    selectedGoalCitizenCollection: [],
    selectedDate: null,
    isAsyncCollection: [],
    selectedCitizenIds: [],
    calendarItemType: CalendarItemType.Normal,
  },
  action: any,
) => {
  if (isType(action, selectedCalendarCitizenIdCollectionAction)) {
    return { ...state, selectedCalendarCitizenCollection: action.payload.citizens };
  } else if (isType(action, updateSelectedDateAction)) {
    return { ...state, selectedDate: { start: action.payload.start, end: action.payload.end } };
  } else if (isType(action, updateSelectedCalendarItemAction)) {
    return {
      ...state,
      // selectedCalendarItem: { ...state.selectedCalendarItem, [action.payload.prop]: action.payload.value }
    };
  } else if (isType(action, setSelectedCitizenIdsAction)) {
    return {
      ...state,
      selectedCitizenIds: [...action.payload.citizenIds],
    };
  } else if (isType(action, updateIsAsyncAction)) {
    if (action.payload.isAsync) {
      return {
        ...state,
        isAsyncCollection: [...state.isAsyncCollection, { text: action.payload.text, key: action.payload.key }],
      };
    } else {
      state.isAsyncCollection = [...state.isAsyncCollection.filter((x) => x.key !== action.payload.key)];
    }
    return { ...state };
  }
  return state;
};
export const setSelectedCalendarItem = (item: CalendarItem) => {
  return async (dispatch, getState) => {
    const cit = await prodocApi.citizen.get(item.citizenId);
    dispatch(
      selectedCalendarCitizenIdCollectionAction({
        citizens: [cit],
      }),
    );
    // dispatch(setSelectedCalendarItemAction({ item }));
    // dispatch(fetchDocument(item.id, item.calendarItemType));
  };
};
export const setSelectedCitizenIds = (citizenIds: string[]) => {
  return async (dispatch, getState) => {
    dispatch(setSelectedCitizenIdsAction({ citizenIds }));
  };
};
