import React from 'react';
import { Table } from 'antd';

interface Props {
  data: any[];
}

export const GoalStatusTable: React.FC<Props> = props => {
  const { data } = props;

  const columns = [
    {
      title: 'Dato',
    },
    {
      title: 'Beskrivelse',
    },
    {
      title: 'Score',
    },
  ];

  return (
    <Table pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }} columns={columns} dataSource={data} />
  );
};
