import { OverViewItem } from '../types/commonItems';
import { actionCreator, isType } from './Action';
import prodocApi, { Center } from '../services/prodocApi';
import { UtilsOverview } from '../utils/UtilsOverview';

export interface OverviewState {
  data: OverViewItem[];
  loadedItemsKeys: string[];
}

const defaultState: OverviewState = {
  data: [],
  loadedItemsKeys: [],
};

export const setOverviewData = actionCreator<OverViewItem[]>('SET_OVERVIEW_DATA');
export const setLoadedKeys = actionCreator<string[]>('SET_LOADED_KEYS');
export const addLoadedKey = actionCreator<string>('ADD_OVERVIEW_LOADED_KEY');

export default (state = defaultState, action: any) => {
  if (isType(action, setOverviewData)) {
    return { ...state, data: action.payload };
  }

  if (isType(action, setLoadedKeys)) {
    return { ...state, loadedItemsKeys: [...action.payload] };
  }

  if (isType(action, addLoadedKey)) {
    return {
      ...state,
      loadedItemsKeys: [...state.loadedItemsKeys, action.payload],
    };
  }

  return state;
};

export const loadOverviewCenters = () => {
  return async dispatch => {
    try {
      const centers = await prodocApi.center.getMyCenters();
      const sortedCenters: Center[] = UtilsOverview.sortOverviewItems(centers);
      const overviewItemsCenters: OverViewItem[] = UtilsOverview.buildCompanyOverviewData(sortedCenters);

      // Auto expand tree if a user has only one center
      if (centers.length === 1) {
        dispatch(addLoadedKey(centers[0].id));
      }

      dispatch(setOverviewData(overviewItemsCenters));
    } catch (e) {}
  };
};
