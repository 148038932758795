import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import citizenStore, { CitizensState } from './citizenStore';
import employeeStore, { EmployeeState } from './employeeStore';
import calendarStore, { CalendarState } from './calendarStore';
import overviewStore, { OverviewState } from './overviewStore';
import calendarItemStore from './calendarItemStore';
import medicineStore, { MedicineState } from './medicineStore';
import goalStore, { GoalsState } from './goalStore';
import metaStore from './metaStore';
import documentStore, { DocumentsState } from './documentStore';
import centerStore, { CenterState } from './centerStore';
import departmentStore, { DepartmentState } from './departmentStore';
import userStore from './userStore';
import pagesStore, { PagesState } from './pagesStore';
import commonStore, { CommonState } from './commonStore';
import { Employee } from '../services/prodocApi';
import measurementStore, { MeasurementState } from './measurementStore';
import notesStore, { NotesState } from './notesStore';
import forumStore, { ForumState } from './forumStore';

export interface ApplicationState {
  citizens: CitizensState;
  employees: EmployeeState;
  pages: PagesState;
  department: DepartmentState;
  center: CenterState;
  user: Employee;
  calendar: CalendarState;
  overview: OverviewState;
  goals: GoalsState;
  common: CommonState;
  measurement: MeasurementState;
  medicine: MedicineState;
  documents: DocumentsState;
  notes: NotesState;
  forum: ForumState;
}

const rootReducer = combineReducers({
  citizens: citizenStore,
  employees: employeeStore,
  calendar: calendarStore,
  calendarItem: calendarItemStore,
  goals: goalStore,
  meta: metaStore,
  documents: documentStore,
  center: centerStore,
  department: departmentStore,
  user: userStore,
  pages: pagesStore,
  overview: overviewStore,
  common: commonStore,
  measurement: measurementStore,
  medicine: medicineStore,
  notes: notesStore,
  forum: forumStore,
});

const composeEnhancers =
  typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
