import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import Button from '../../Button/Button';
import TimePicker from '../..//TimePicker/TimePicker';

import './OrdinationDailyTable.scss';
import { OrdinationTableRow } from '../../../../../types/medicine';
import { OrdinationScheduleType } from '../../../../../constants/medicine';
import { defaultMedicineRow } from '../../../../../data/medicine-data';

interface Props {
  type: OrdinationScheduleType;
  items: OrdinationTableRow[];
  onChange(type: OrdinationScheduleType, data: OrdinationTableRow[]): void;
}

export const OrdinationDailyTable: React.FC<Props> = props => {
  const { type, items = [], onChange } = props;
  const disableInputs = type === OrdinationScheduleType.Monthly;

  const handleAddRow = () => {
    const updatedData = [...items, { ...defaultMedicineRow, id: items.length + 1 }];

    onChange(type, updatedData);
  };

  const handleRemoveRow = (clickedIndex: number) => {
    const updatedData = items.filter((item, index) => clickedIndex !== index);

    onChange(type, updatedData);
  };

  const handleTimeChange = (value, name, index) => {
    const updatedData = [...items];

    updatedData[index] = { ...updatedData[index], [name]: value };
    onChange(type, updatedData);
  };

  const renderTimePicker = (row, name, index) => {
    const value = row[name] ? moment(row[name]).toDate() : null;
    const disabled = name !== 'all' && disableInputs;

    return (
      <TimePicker
        value={value}
        disabled={disabled}
        name={name}
        onChange={(value, name) => handleTimeChange(value, name, index)}
      />
    );
  };

  const columns = [
    {
      dataIndex: 'title',
      title: () => <Button title="Tilføj tidspunkt" onClick={handleAddRow} />,
      render: (text, record, index) => <Button title="Slet tidspunkt" onClick={() => handleRemoveRow(index)} />,
    },
    {
      title: 'Alle dage',
      render: (text, record, index) => renderTimePicker(record, 'all', index),
    },
    {
      title: 'Mandag',
      render: (text, record, index) => renderTimePicker(record, 'MO', index),
    },
    {
      title: 'Tirsdag',
      render: (text, record, index) => renderTimePicker(record, 'TU', index),
    },
    {
      title: 'Onsdag',
      render: (text, record, index) => renderTimePicker(record, 'WE', index),
    },
    {
      title: 'Torsdag',
      render: (text, record, index) => renderTimePicker(record, 'TH', index),
    },
    {
      title: 'Fredag',
      render: (text, record, index) => renderTimePicker(record, 'FR', index),
    },
    {
      title: 'Lørdag',
      render: (text, record, index) => renderTimePicker(record, 'SA', index),
    },
    {
      title: 'Søndag',
      render: (text, record, index) => renderTimePicker(record, 'SU', index),
    },
  ];

  return (
    <div className="od-table">
      <Table
        dataSource={items || []}
        columns={columns}
        pagination={{ showSizeChanger: false, hideOnSinglePage: true }}
        rowKey="id"
      />
    </div>
  );
};
