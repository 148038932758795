import { history } from '../store/navigationStore';
import { URLS } from '../constants/urls';
import { UtilsAuth } from './UtilsAuth';

export class UtilsNavigation {
  public static buildRoute(...args: string[]) {
    const routesArr = Array.prototype.slice.call(args);

    return `/${routesArr.join('/')}`;
  }

  public static shouldReturnToOverview(): boolean {
    const pathname = history.location.pathname;

    return pathname.indexOf(URLS.OVERVIEW) > 0;
  }
  public static getHomeUrl(): string {
    return UtilsNavigation.buildRoute(URLS.HOME, UtilsAuth.getEmployeeId());
  }
}
