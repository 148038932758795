import React from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { NavigationItem } from '../../../types/common';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import Button from '../_controls/Button/Button';

import './NavigationMenu.scss';

interface Props {
  items: NavigationItem[];
}

export const NavigationMenu: React.FC<Props> = props => {
  const { items } = props;
  const { push } = useHistory();
  const { pathname } = useLocation();

  const renderItems = () => {
    return items.map(({ route, name, isActive, isHidden }, index) => {
      const isButtonActive = !!matchPath(pathname, route) || isActive;

      const handleClick = () => {
        push(route);
      };
      if (isHidden) {
        return <></>;
      }
      return <Button key={index} title={name} isActive={isButtonActive} onClick={handleClick} />;
    });
  };

  return <div className="navigation-menu">{renderItems()}</div>;
};
