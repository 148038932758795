import React, { useEffect, useState } from 'react';
import prodocApi, {
  Department,
  EmployeeEnrollment,
  EmployeeEnrollmentResult,
  Position,
} from '../../../services/prodocApi';
import { useEnumOptionsTranslation, usePageTitle } from '../../../hooks/common-hooks';
import { Button, Card, Col, Modal, Row, Table, Tooltip } from 'antd';
import moment, { Moment, MomentInput, now } from 'moment';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../constants/common';
import { LoginOutlined, LogoutOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnType } from 'antd/es/table';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import SelectMenu from '../../../components/_shared/_controls/SelectMenu/SelectMenu';
import DatePicker from '../../../components/_shared/_controls/DatePicker/DatePicker';
import { useTranslation } from 'react-i18next';

export function EmployeeOverviewPage() {
  //getManyByCenter
  const [employeeEnrollments, setEmployeeEnrollments] = useState<EmployeeEnrollmentResult[]>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [employeeEnrollment, setEmployeeEnrollment] = useState<EmployeeEnrollment>({} as EmployeeEnrollment);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isEnrollOpen, setIsEnrollOpen] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [cancelEnrollmentDate, setCancelEnrollmentDate] = useState<MomentInput>(new Date());
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState('');
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const { t } = useTranslation();

  usePageTitle('Medarbejdere');
  useEffect(() => {
    async function init() {
      const result = await prodocApi.department.getMyDepartments();
      setDepartments(result);
    }
    init();
    getEnrollments();
  }, []);
  async function getEnrollments() {
    try {
      setIsLoading(true);
      const result = await prodocApi.employeeEnrollment.getManyByEmployee();
      setEmployeeEnrollments(result);
    } finally {
      setIsLoading(false);
    }
  }
  async function deleteEmployeeEnrollment(e: any, row: EmployeeEnrollmentResult) {
    e.stopPropagation();
    Modal.confirm({
      title: 'Slet medarbejder?',
      icon: <QuestionCircleOutlined translate="" />,
      content: <div>Er du sikker på du vil slette medarbejderen</div>,
      okText: 'Ja',
      cancelText: 'Nej',
      onOk: () => {
        // mangler en afslutnings dato
        // prodocApi.employeeEnrollment.discharge(row.id)
        // console.log("deleteEmployeeEnrollment");
      },
    });
  }
  async function handleCancelEmployeeEnrollmemt() {
    await prodocApi.employeeEnrollment.discharge(selectedEnrollmentId, cancelEnrollmentDate as Date);
    await getEnrollments();
    setIsCancelOpen(false);
  }
  async function showDischargeEmployee(e, employeeEnrollmentResult: EmployeeEnrollmentResult) {
    e.stopPropagation();
    setSelectedEnrollmentId(employeeEnrollmentResult.id);
    setIsCancelOpen(true);
  }
  const columns: ColumnType<EmployeeEnrollmentResult>[] = [
    {
      title: 'Navn',
      render: (o: EmployeeEnrollmentResult) => o.firstname + ' ' + o.lastname,
      key: 'name',
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
    },
    {
      title: 'Afdeling',
      render: (o: EmployeeEnrollmentResult) => o.departmentName,
      key: 'departmentName',
      sorter: (a, b) => a.departmentName.localeCompare(b.departmentName),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      render: (item: string) => {
        return t(item);
      },
      sorter: (a, b) => t(a.position).localeCompare(t(b.position)),
      // render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : date),
    },
    {
      title: 'Oprettet',
      dataIndex: 'createdOn',
      render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : date),
      sorter: (a, b) => Date.parse(a.createdOn.toString()) - Date.parse(b.createdOn.toString()),
    },
    {
      title: 'Afsluttes',
      dataIndex: 'dischargedDate',
      render: date => (date ? moment(date).format(DATE_FORMAT) : date),
      sorter: (a, b) => Date.parse((a.dischargedDate || 0).toString()) - Date.parse((b.dischargedDate || 0).toString()),
    },
    {
      align: 'right',
      title: '',
      render: (value, row: EmployeeEnrollmentResult) => {
        return (
          <>
            <Tooltip title={`Ophør på ${row.departmentName}`}>
              <Button onClick={e => showDischargeEmployee(e, row)} icon={<LogoutOutlined translate={undefined} />} />
            </Tooltip>
            &nbsp;
            <Tooltip title="Ansæt på en anden afdeling">
              <Button
                icon={<LoginOutlined translate={undefined} />}
                onClick={e => {
                  e.stopPropagation();
                  setEmployeeEnrollment({ employeeId: row.employeeId });
                  setIsEnrollOpen(true);
                }}
              />
            </Tooltip>
            &nbsp;
            <Tooltip title="Slet">
              <Button onClick={e => deleteEmployeeEnrollment(e, row)} icon={<FontAwesomeIcon icon={faTrashCan} />} />
            </Tooltip>
          </>
        );
      },
    },
  ];
  function handleRowClicked(e) {
    history.push(ROUTES.EMPLOYEE_DETAILS.replace(':id?', e.employeeId));
  }
  function handleCreateCitizen() {
    history.push(ROUTES.EMPLOYEE_ADD);
  }
  async function handleEnroll() {
    await prodocApi.employeeEnrollment.add(employeeEnrollment);
    setIsEnrollOpen(false);
    getEnrollments();
  }

  return (
    <div className="citizen-page page-full">
      <Row>
        <Col span={24}>
          <div style={{ background: '#fafafa', padding: '8px' }}>
            <Tooltip title="Opret medarbejder">
              <Button shape="circle" icon={<PlusOutlined translate="" />} onClick={handleCreateCitizen} />
            </Tooltip>
          </div>
          <Table
            style={{ cursor: 'pointer' }}
            loading={isLoading}
            pagination={{ pageSize: 15 }}
            dataSource={employeeEnrollments}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => handleRowClicked(record),
              };
            }}
            columns={columns}
            rowKey="id"
          />
        </Col>
      </Row>
      <Modal
        width={800}
        title="Ansæt medarbejder"
        okText="Ansæt"
        cancelText="Annuller"
        open={isEnrollOpen}
        onOk={handleEnroll}
        // cancelButtonProps={{ style: { float: 'right' } }}
        onCancel={() => setIsEnrollOpen(false)}>
        <div>
          <SelectMenu
            title="Vælg Afdeling"
            name="departmentId"
            valueKey="id"
            labelKey="name"
            value={employeeEnrollment.departmentId}
            options={departments}
            // error={departmentsError}
            // valuesError={enrollmentDepartments}
            onChange={v => setEmployeeEnrollment({ ...employeeEnrollment, departmentId: v })}
          />
          <Row gutter={8}>
            <Col span={12}>
              <DatePicker
                value={moment(employeeEnrollment.enrollmentDate)}
                title="Indskrivnings dato"
                name="enrollmentDate"
                // error={errors.enrollmentDate && errors.enrollmentDate.message}
                onChange={v => setEmployeeEnrollment({ ...employeeEnrollment, enrollmentDate: moment(v).toDate() })}
              />
            </Col>
            <Col span={12}>
              <SelectMenu
                title="Stilling"
                name="position"
                valueKey="value"
                labelKey="label"
                value={employeeEnrollment.position}
                options={translateEnumOptions(Position)}
                // error={errors.position && errors.position.message}
                onChange={v => setEmployeeEnrollment({ ...employeeEnrollment, position: v })}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        // width={800}
        title="Afslut medarbejder"
        okText="Afslut medarbejder"
        cancelText="Annuller"
        open={isCancelOpen}
        onOk={handleCancelEmployeeEnrollmemt}
        // cancelButtonProps={{ style: { float: 'right' } }}
        onCancel={() => setIsCancelOpen(false)}>
        <div>
          <Row gutter={8}>
            <Col span={24}>
              <DatePicker
                value={cancelEnrollmentDate}
                title="Afslutningsdato"
                name="cancelDate"
                // error={errors.enrollmentDate && errors.enrollmentDate.message}
                onChange={v => setCancelEnrollmentDate(v)}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}
