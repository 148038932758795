import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadUserDepartments } from '../../../store/userStore';
import { navigateToHome } from '../../../store/navigationStore';
import EmployeeDischargeForm from '../../../components/employee/EmployeeDischargeForm/EmployeeDischargeForm';
import { EmployeeDischarge } from '../../../types/employee';
import { showEmployeeDischargeModal } from '../../../store/modalStore';
import { usePageTitle } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { useCitizenNavigationItems } from '../../../hooks/navigation';

export default function EmployeeDischargePage() {
  const dispatch = useDispatch();
  const { employeeItems } = useCitizenNavigationItems();

  usePageTitle('Afslut medarbejder');

  useEffect(() => {
    dispatch(loadUserDepartments());
  }, []);

  const handleSubmit = (data: EmployeeDischarge) => {
    dispatch(showEmployeeDischargeModal(data));
  };

  const handleCancel = () => {
    dispatch(navigateToHome());
  };

  return (
    <>
      <Sidebar>
        <NavigationMenu items={employeeItems} />
      </Sidebar>
      <div className="employee-page page">
        <div className="employee-edit">
          <div className="employee-edit__wrap">
            <EmployeeDischargeForm onSubmit={handleSubmit} onCancel={handleCancel} />
          </div>
        </div>
      </div>
    </>
  );
}
