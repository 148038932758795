import moment from 'moment';

export class UtilsDate {
  public static isBefore(usedDate: Date, edgeDate: Date): boolean {
    const handledUsedDate = moment(usedDate).startOf('day');
    const handledEdgeDate = moment(edgeDate).startOf('day');

    return handledUsedDate.isBefore(handledEdgeDate);
  }

  public static isAfter(usedDate: Date, edgeDate: Date): boolean {
    const handledUsedDate = moment(usedDate).endOf('day');
    const handledEdgeDate = moment(edgeDate).endOf('day');

    return handledUsedDate.isAfter(handledEdgeDate);
  }

  public static setPartsToUTCDate(date: Date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    )
  }

  public static setUTCPartsToDate(date: Date) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  }

  public static isDefaultDate(date: Date): boolean {
    return date.getFullYear() === 1970;
  }
}