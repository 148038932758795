import React, { useEffect, useState } from 'react';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import prodocApi, { CitizenEnrollment, DepartmentResult } from '../../../services/prodocApi';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { UtilsData } from '../../../utils/UtilsData';
import { Col, Row } from 'antd';
import { SelectOption } from '../../../types/commonItems';

interface Props {
  onDepartmentChange: (departments: string) => void;
  onDateChange: (date: Date) => void;
  onEnrolledFromChange: (enrolledForm: SelectOption) => void;
  selectedDepartment: string;
  enrollmentDate: Date;
  enrolledFrom: string;
  errors: CitizenEnrollment;
}

const enrollFromOptions = UtilsData.getEnrollFromOptions();

export default function CitizenEnrollForm(props: Props) {
  const {
    onDepartmentChange,
    onDateChange,
    onEnrolledFromChange,
    selectedDepartment,
    enrollmentDate,
    enrolledFrom,
    errors,
  } = props;
  const [departments, setDepartments] = useState<DepartmentResult[]>([]);
  useEffect(() => {
    async function init() {
      const result = await prodocApi.department.getMyDepartments();
      setDepartments(result);
    }

    init();
  }, []);

  return (
    <>
      <Row gutter={8}>
        <Col span={12}>
          <SelectMenu
            title="Indskriv på afdeling"
            name="departmentId"
            valueKey="id"
            labelKey="name"
            error={(errors as any).enrolledDepartment}
            value={selectedDepartment}
            options={departments}
            onChange={onDepartmentChange}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <DatePicker
            value={enrollmentDate}
            title="Indskrivnings dato"
            name="enrollmentDate"
            error={errors.enrollmentDate?.toString()}
            onChange={onDateChange}
          />
        </Col>
        <Col span={12}>
          <SelectMenu
            title="Indskrevet fra"
            name="enrolledFrom"
            valueKey="value"
            labelKey="label"
            error={errors.enrolledFrom}
            value={enrolledFrom}
            options={enrollFromOptions}
            onChange={onEnrolledFromChange}
          />
        </Col>
      </Row>
    </>
  );
}
