import * as jwtDecode from 'jwt-decode';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { AuthType } from '../services/prodocApi';
import { USER_TOKEN_NAMESPACE } from '../constants/namespaces';

const locationHelper = locationHelperBuilder({});
const claimRole = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const auth = connectedRouterRedirect({
  allowRedirectBack: true,
  authenticatedSelector: () => UtilsAuth.isUserLoggedIn(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || '/login';
  },
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const notAuth = connectedRouterRedirect({
  allowRedirectBack: false,
  authenticatedSelector: () => !UtilsAuth.isUserLoggedIn(),
  redirectPath: (state, ownProps) => {
    return locationHelper.getRedirectQueryParam(ownProps) || `/home/${UtilsAuth.getEmployeeId()}`;
  },
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export class UtilsAuth {
  public static getAuthTypeOptions(): any[] {
    const authTypeKeys = Object.keys(AuthType);

    return authTypeKeys.map((item: string) => {
      return {
        value: item,
      };
    });
  }

  public static getEmployeeId(): string {
    const token = localStorage.getItem(USER_TOKEN_NAMESPACE);

    if (token) {
      const decoded = jwtDecode(token);

      return decoded.userId;
    }

    return '';
  }

  public static isAdmin(): boolean {
    const token = localStorage.getItem(USER_TOKEN_NAMESPACE);

    if (token) {
      const decoded = jwtDecode(token);

      return decoded[claimRole].toLowerCase() === 'admin';
    }

    return false;
  }

  public static isManager(): boolean {
    const token = localStorage.getItem(USER_TOKEN_NAMESPACE);

    if (token) {
      const decoded = jwtDecode(token);
      return decoded[claimRole].toLowerCase() === 'manager';
    }

    return false;
  }

  public static isUserLoggedIn() {
    try {
      var token = localStorage.getItem(USER_TOKEN_NAMESPACE);
      const exp = new Date(jwtDecode(token).exp * 1000);
      return exp > new Date();
    } catch (error) {
      return false;
    }
  }

  public static getToken() {
    return localStorage.getItem(USER_TOKEN_NAMESPACE);
  }

  public static async decodeUserToken(): Promise<any> {
    const token = localStorage.getItem(USER_TOKEN_NAMESPACE);

    if (token) {
      return jwtDecode(token);
    }

    return null;
  }
}
