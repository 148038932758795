import * as React from 'react';
import { Select, Tag } from 'antd';
import classNames from 'classnames';
import ControlError from '../ControlError/ControlError';

import './SelectMenu.scss';

interface Props {
  title?: string;
  placeholder?: string;
  options?: any[];
  value?: string | string[] | number;
  name?: string;
  error?: string;
  valuesError?: string[];
  allowClear?: boolean;
  withSearch?: boolean;
  multiple?: boolean;
  children?: React.ReactNode;
  valueKey?: string;
  className?: string;
  disabled?: boolean;
  selectAll?: boolean;
  labelKey?: string | string[];
  onChange(value: any, name: string, option: any): void;
  onSearch?(value: string): void;
  onSelect?(value: string): void;
  onDropDownChange?(value: boolean): void;
  maxTagCount?: number;
}

export const { Option, OptGroup } = Select;

export default function SelectMenu(props: Props) {
  const {
    onChange,
    title,
    multiple,
    placeholder,
    options = [],
    withSearch,
    allowClear,
    value,
    name,
    labelKey = '',
    valueKey = '',
    error,
    valuesError = [],
    children,
    onSearch,
    onSelect,
    disabled,
    selectAll,
    className,
    maxTagCount,
    onDropDownChange,
  } = props;
  const handledValue = value === null ? undefined : value;

  const wrapperClass = classNames('select-menu', {
    'select-menu--error': error,
  });

  const handleChange = (value: any, option: any) => {
    if (value && value.toString().indexOf('all') === 0) {
      const allOptions = options.map(item => item[valueKey]);
      onChange(allOptions, name, option);

      return;
    }

    onChange(value, name, option);
  };

  const getDisplayedValue = (item: any): string => {
    if (Array.isArray(labelKey)) {
      return labelKey.map((label: string) => item[label]).join(' ');
    }

    return item[labelKey];
  };

  const filterOptions = (input, option): boolean => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const renderOptions = () => {
    if (!options || !options.length) return null;

    const optionsItems = options.map((item: any, index) => {
      return (
        <Option key={index} value={item[valueKey]} className={className}>
          {getDisplayedValue(item)}
        </Option>
      );
    });

    // if (allowClear) {
    //   optionsItems.unshift(
    //     <Option key="none" value={null}>
    //       Ingen
    //     </Option>,
    //   );
    // }

    if (selectAll) {
      optionsItems.unshift(
        <Option key="all" value="all">
          Vælg alle
        </Option>
      );
    }

    return optionsItems;
  };

  const renderTags = tagData => {
    const { label, closable, onClose, value } = tagData;
    const className = classNames({ 'with-error': valuesError.indexOf(value) >= 0 });

    return (
      <Tag className={className} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  return (
    <div className={wrapperClass}>
      {title && <div style={{ whiteSpace: 'nowrap' }}>{title}</div>}
      <Select
        maxTagCount={maxTagCount}
        allowClear={allowClear}
        className={className}
        mode={multiple ? 'multiple' : undefined}
        showSearch={withSearch}
        filterOption={filterOptions}
        optionFilterProp="children"
        value={handledValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onSearch={onSearch}
        onSelect={onSelect}
        tagRender={renderTags}
        getPopupContainer={trigger => trigger.parentNode}
        onDropdownVisibleChange={onDropDownChange}>
        {children || renderOptions()}
      </Select>
      <ControlError text={error} />
    </div>
  );
}
