import React from 'react';

import './CardActions.scss';

interface Props {
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}

export const CardActions: React.FC<Props> = (props) => {
  const { leftActions, rightActions } = props;

  return (
    <div className="card-actions">
      <div className="card-actions__left">{leftActions}</div>
      <div className="card-actions__right">{rightActions}</div>
    </div>
  );
};
