import { CalendarItem, CalendarItemType } from '../services/prodocApi';
import { actionCreator, isType } from './Action';

const updateCalendarItemAction = actionCreator<{ prop: string; value: any }>('UPDATE_CALENDAR_ITEM');
const replaceCalendarItemAction = actionCreator<{ calenderItem: CalendarItem }>('REPLACE_CALENDAR_ITEM');
// const updateSelectedCitizensAction = actionCreator<{ citizens: CitizenExt[] }>('UPDATE_SELECTED_CITIZENS_ACTION');

const init: CalendarItem = {
  fromDate: new Date(),
  toDate: new Date(),
  calendarItemType: CalendarItemType.Normal,
  title: '',
};
export default (state: CalendarItem = init, action: any) => {
  if (isType(action, updateCalendarItemAction)) {
    return { ...state, [action.payload.prop]: action.payload.value };
  } else if (isType(action, replaceCalendarItemAction)) {
    return { ...action.payload.calenderItem };
  }

  return state;
};
export const updateCalendarItem = (prop: string, value: any) => {
  return async (dispatch, getState) => {
    dispatch(updateCalendarItemAction({ prop, value }));
  };
};
// export const fetchCalendarItem = (id: string) => {
// 	return async (dispatch, getState) => {
// 		const calenderItem = await prodocApi.getCalendarItem(id);

// 		const citizen = await prodocApi.getCitizen1(calenderItem.citizenId);
// 		(calenderItem as any).selectedCitizens = [ citizen ];
// 		dispatch(replaceCalendarItemAction({ calenderItem: (calenderItem as unknown) as CalendarItem }));
// 	};
// };
export const replaceCalendarItem = (calenderItem: CalendarItem) => {
  return async (dispatch, getState) => {
    dispatch(replaceCalendarItemAction({ calenderItem }));
  };
};
// export const updateSelectedCitizens = (citizens: CitizenExt[]) => {
// 	return async (dispatch, getState) => {
// 		dispatch(updateSelectedCitizensAction({ citizens }));
// 	};
// };
