import React from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import ControlError from '../ControlError/ControlError';

import './TextArea.scss';

interface Props {
  value: string;
  title?: string;
  name?: string;
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange(value: string, name?: string): void;
  style?: React.CSSProperties;
}

const { TextArea: TextAreaAntd } = Input;

export default function TextArea(props: Props) {
  const { value, title, name, error, disabled, onChange, readonly, style } = props;

  const wrapperClass = classNames('textarea', {
    'textarea--error': error,
    'textarea--readonly': readonly,
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const target = e.target;

    onChange(target.value, target.name);
  };

  return (
    <div className={wrapperClass} style={style}>
      {title && <div>{title}</div>}
      <TextAreaAntd
        name={name}
        readOnly={readonly}
        value={value}
        title={title}
        rows={6}
        disabled={disabled}
        onChange={handleChange}
      />
      <ControlError text={error} />
    </div>
  );
}
