import { saveAs } from 'file-saver';
import prodocApi, { FileClient, FileShareDto } from '../services/prodocApi';
import { UtilsAuth } from '../utils/UtilsAuth';
import { DOCUMENT_DOWNLOAD_URL } from '../constants/common';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorMessage } from '../store/notificationStore';
import { MESSAGES } from '../constants/messages';

export function useUseOfForceEdit() {
  const editItem = async () => {
    try {
      // const res = prodocApi.useOfForce.update();
    } catch (e) {}
  };

  return { editItem };
}

export function useUseOfForceDownload() {
  const dispatch = useDispatch();
  const [downloadingId, setDownloadingLoading] = useState('');

  const downloadItem = async (id: string, name: string) => {
    try {
      setDownloadingLoading(id);

      let url = `${DOCUMENT_DOWNLOAD_URL}/${id}`;

      let headers = new Headers();
      headers.append('Authorization', `Bearer ${UtilsAuth.getToken()}`);

      const res = await fetch(url, { headers });
      const blob = await res.blob();

      saveAs(blob, name);
    } catch (e) {
      dispatch(showErrorMessage({ message: MESSAGES.COMMON_ERROR }));
    } finally {
      setDownloadingLoading('');
    }
  };

  return { downloadItem, downloadingId };
}

export function useDocumentDownloading() {
  const dispatch = useDispatch();
  const [isLoadingName, setIsLoadingName] = useState('');

  const downloadDocument = async (blobFileName: string) => {
    try {
      setIsLoadingName(blobFileName);
      const baseUrl = new FileClient().getBaseUrl('', '');
      const url = `${baseUrl}/api/file/download/${blobFileName}`;
      const token = UtilsAuth.getToken();
      let form = document.createElement('form');
      form.method = 'post';
      form.contentType = 'application/json';
      form.action = url;
      form.innerHTML =
        '<input type="hidden" name="origin" value="' +
        window.location.origin +
        '"><input type="hidden" name="jwtToken" value="' +
        token +
        '">';
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } catch (e) {
      dispatch(showErrorMessage({ message: MESSAGES.COMMON_ERROR }));
    } finally {
      setIsLoadingName('');
    }
  };

  return { downloadDocument, isLoadingName };
}

export function useDocumentReadToggle() {
  const toggleDocumentReadState = async (data: FileShareDto, isRead: boolean) => {
    try {
      const userId = UtilsAuth.getEmployeeId();
      let updatedList = data.readByEmployeeIds || [];

      if (isRead) {
        updatedList.push(userId);
      } else {
        updatedList = updatedList.filter(item => item !== userId);
      }

      const requestData: FileShareDto = { ...data, readByEmployeeIds: updatedList };

      await prodocApi.file.update(requestData);

      return true;
    } catch (e) {
      return false;
    }
  };

  return { toggleDocumentReadState };
}

export function useDocumentDelete() {
  const [isLoadingId, setIsLoadingId] = useState('');

  const deleteDocument = async (id: string) => {
    try {
      setIsLoadingId(id);

      await prodocApi.file.delete(id);

      return true;
    } catch (e) {
      return false;
    } finally {
      setIsLoadingId('');
    }
  };

  return { deleteDocument, isLoadingId };
}
