import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';
import useReactRouter from 'use-react-router';
import { ReactSortable } from 'react-sortablejs';
import { showConfirmModal } from '../_shared/Modal/ModalFunctions';
import { logOut } from '../../store/userStore';
import Icon, { ICONS } from '../_shared/Icon/Icon';
import { UtilsNavigation } from '../../utils/UtilsNavigation';
import { URLS } from '../../constants/urls';
import { MainMenuItem } from './MainMenuItem/MainMenuItem';
import { setLeaderCreateData } from '../../store/employeeStore';
import { LocalStorage } from '../../services/storage';
import { MENU_ORDER_NAMESPACE } from '../../constants/namespaces';
import { UtilsAuth } from '../../utils/UtilsAuth';
import { useUserMenuList } from '../../hooks/user-hooks';
import { MenuItem } from '../../types/common';

import './MainMenu.scss';

export default function MainMenu() {
  const dispatch = useDispatch();
  const menuList = useUserMenuList();
  const { history } = useReactRouter<{ residenceId: string }>();
  const [itemsOrder, setItemsOrder] = useState<MenuItem[]>(menuList);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    const savedData = null; // LocalStorage.getItem(MENU_ORDER_NAMESPACE);
    const userId = UtilsAuth.getEmployeeId();
    const parsedData = JSON.parse(savedData) || {};
    const userData = parsedData[userId];

    if (userData && userData.length === menuList.length) {
      setItemsOrder(userData);
    } else {
      setItemsOrder(menuList);
    }
  }, [menuList.length]);

  const saveItemsOrder = (items: MenuItem[]) => {
    const savedData = LocalStorage.getItem(MENU_ORDER_NAMESPACE);
    const parsedData = JSON.parse(savedData) || {};
    const userId = UtilsAuth.getEmployeeId();

    parsedData[userId] = items;

    // LocalStorage.setItem(MENU_ORDER_NAMESPACE, JSON.stringify(parsedData));
  };

  const handleLogOut = () => {
    setIsOpened(false);

    dispatch(
      showConfirmModal({
        content: 'Ønsker du at logge ud?',
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: () => {
          dispatch(logOut());
        },
      })
    );
  };

  const handleItemsOrderChange = (newState: MenuItem[]) => {
    saveItemsOrder(newState);
    setItemsOrder(newState);
  };

  const renderItems = () => {
    return itemsOrder.map(item => {
      const { route, icon, iconPng, title, id, isLogout } = item;

      const handleClick = () => {
        const addEmployeeUrl = UtilsNavigation.buildRoute(URLS.EMPLOYEE, URLS.ADD);

        if (isLogout) return handleLogOut();
        if (route === addEmployeeUrl) dispatch(setLeaderCreateData(null));

        history.push(route.replace(':employeeId', UtilsAuth.getEmployeeId()));
        setIsOpened(false);
      };

      return <MainMenuItem key={id} icon={icon} iconPng={iconPng} title={title} onClick={handleClick} />;
    });
  };

  const menu = (
    <ReactSortable
      axis="xy"
      animation={500}
      list={itemsOrder}
      className="main-menu-list"
      setList={handleItemsOrderChange}>
      {renderItems()}
    </ReactSortable>
  );

  return (
    <Popover placement="topLeft" content={menu} trigger="hover" open={isOpened} onOpenChange={setIsOpened}>
      <button type="button" className="menu-btn">
        <span className="submenu-title-wrapper">
          <Icon name={ICONS.menu} />
        </span>
      </button>
    </Popover>
  );
}
