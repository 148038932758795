import prodocApi from '../services/prodocApi';
import { Employee } from '../services/prodocApi';
import { Action, actionCreator, isType } from './Action';
import { employeeDefault } from '../services/defaults';
import { LocalStorage } from '../services/storage';
import { setUserDepartments } from './departmentStore';

const setUserAction = actionCreator<{ employee: Employee }>('SET_USER_ACTION');

export default (state: Employee = employeeDefault(), action: Action<any>) => {
  if (isType(action, setUserAction)) {
    return { ...action.payload.employee };
  }

  return state;
};

export const setUser = () => {
  return async dispatch => {
    const employee = await prodocApi.employee.get();
    dispatch(setUserAction({ employee }));
  };
};

export const logOut = () => {
  return dispatch => {
    const emptyUser = employeeDefault();

    LocalStorage.setItem('oauth', '');

    dispatch(setUserAction({ employee: emptyUser }));
    window.location.href = '/login';
  };
};

export const loadUserDepartments = () => {
  return async dispatch => {
    try {
      const departments = await prodocApi.department.getMyDepartments();
      dispatch(setUserDepartments(departments));
    } catch (e) {}
  };
};
