import React, { ReactNode, RefObject } from 'react';
import { Portal } from 'react-portal';

import './CalendarMenuWrap.scss';

interface Props {
  event: MouseEvent;
  children?: ReactNode;
}

const CalendarMenuWrap = (props: Props) => {
  const { event: mouseEvent, children } = props;
  const visible = !!mouseEvent;
  const menuRef = React.useRef() as RefObject<any>;

  const getTopPosition = () => {
    if (!mouseEvent || !menuRef.current) return null;

    const menuListHeight = menuRef.current.offsetHeight;

    if (mouseEvent.clientY + menuListHeight > window.innerHeight) {
      return mouseEvent.clientY - menuListHeight;
    }

    return mouseEvent.clientY + 20;
  };

  const getLeftPosition = () => {
    if (!mouseEvent || !menuRef.current) return null;

    const menuListWidth = menuRef.current.offsetWidth;

    if (mouseEvent.clientX + menuListWidth > window.innerWidth) {
      return window.innerWidth - menuListWidth - 10;
    }

    return mouseEvent.clientX;
  };

  const menuListStyles: any = {
    zIndex: visible ? 100 : -100,
    position: 'absolute',
    visibility: visible ? 'visible' : 'hidden',
    left: getLeftPosition(),
    top: getTopPosition(),
  };

  return (
    <Portal>
      <div className="cm-wrap" id="cm-wrap" ref={menuRef} style={menuListStyles}>
        {children}
      </div>
    </Portal>
  );
};
export default CalendarMenuWrap;
