import moment from 'moment';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import {
  CalendarItem,
  CalendarItemType,
  HealthCare,
  HealthCareType,
  HealthCareProblem,
  Medicine,
  MedicineState,
  MedicineType,
} from '../services/prodocApi';
import { calendarItemDefault, healthCareDefault, medicineStateDefault } from '../services/defaults';
import { OrdinationSchedule, OrdinationTableRow } from '../types/medicine';
import { OrdinationScheduleType, RULE_DAYS_MAP } from '../constants/medicine';
import { defaultMedicineSchedule, getDefaultDailyMap } from '../data/medicine-data';
import { UtilsDate } from './UtilsDate';
import { MedicineCalendarItem } from '../types/calendar';
import { SelectOption } from '../types/commonItems';

export class UtilsMedicine {
  public static createAreasDefaultsList(): HealthCare[] {
    const areasEnumValues = Object.values(HealthCareType);

    return areasEnumValues.map(name => {
      const data = healthCareDefault();

      return { ...data, care: name, problem: HealthCareProblem.NotConsided };
    });
  }

  public static getFilledDays(row: OrdinationTableRow[]) {
    const rulesDailyMap = getDefaultDailyMap();

    row.forEach(row => {
      const { id, title, all, ...days } = row;

      if (all) {
        Object.keys(days).forEach(day => {
          const parsedDate = moment(all).toDate();

          rulesDailyMap[day].hours.push(parsedDate.getHours());
          rulesDailyMap[day].minutes.push(parsedDate.getMinutes());
        });
      } else {
        Object.entries(days).forEach(([day, date]) => {
          if (date) {
            const parsedDate = moment(date).toDate();

            rulesDailyMap[day].hours.push(parsedDate.getHours());
            rulesDailyMap[day].minutes.push(parsedDate.getMinutes());
          }
        });
      }
    });

    return Object.entries(rulesDailyMap).filter(([key, data]) => !!data.hours.length);
  }

  public static getDailyPeriodRules(
    rulesSet: RRuleSet,
    scheduleRows: OrdinationTableRow[],
    start: Date,
    end: Date
  ): RRuleSet {
    const filteredDays = UtilsMedicine.getFilledDays(scheduleRows);

    filteredDays.forEach(([day, timeItems]) => {
      const { hours, minutes } = timeItems;

      const rules = new RRule({
        freq: RRule.DAILY,
        byweekday: [RULE_DAYS_MAP[day]],
        dtstart: UtilsDate.setPartsToUTCDate(moment(start).toDate()),
        until: UtilsDate.setPartsToUTCDate(moment(end).add(1, 'day').toDate()),
        byhour: hours,
        byminute: minutes,
      });

      rulesSet.rrule(rules);
    });

    return rulesSet;
  }

  public static getWeeklyPeriodRules(
    rulesSet: RRuleSet,
    scheduleRows: OrdinationTableRow[],
    start: Date,
    end: Date,
    interval: number
  ): RRuleSet {
    const filteredDays = UtilsMedicine.getFilledDays(scheduleRows);

    filteredDays.forEach(([day, timeItems]) => {
      const { hours, minutes } = timeItems;

      const rules = new RRule({
        freq: RRule.WEEKLY,
        interval,
        byweekday: [RULE_DAYS_MAP[day]],
        dtstart: UtilsDate.setPartsToUTCDate(moment(start).toDate()),
        until: UtilsDate.setPartsToUTCDate(moment(end).add(1, 'day').toDate()),
        byhour: hours,
        byminute: minutes,
      });

      rulesSet.rrule(rules);
    });

    return rulesSet;
  }

  public static getPeriodMonthRules(
    rulesSet: RRuleSet,
    scheduleDailyRow: OrdinationTableRow[],
    start: Date,
    end: Date,
    skipMonth: number,
    dayMonthNumber: number
  ) {
    const rulesDailyMap = getDefaultDailyMap();

    scheduleDailyRow.forEach(row => {
      const { id, title, all, ...days } = row;

      if (all) {
        Object.keys(days).forEach(day => {
          const parsedDate = moment(all).toDate();

          rulesDailyMap[day].hours.push(parsedDate.getHours());
          rulesDailyMap[day].minutes.push(parsedDate.getMinutes());
        });
      }
    });

    const filteredDays = Object.entries(rulesDailyMap).filter(([key, data]) => !!data.hours.length);

    filteredDays.forEach(([day, timeItems]) => {
      const { hours, minutes } = timeItems;

      const rules = new RRule({
        freq: RRule.MONTHLY,
        interval: skipMonth,
        bymonthday: [dayMonthNumber],
        dtstart: UtilsDate.setPartsToUTCDate(moment(start).toDate()),
        until: UtilsDate.setPartsToUTCDate(moment(end).add(1, 'day').toDate()),
        byhour: hours,
        byminute: minutes,
      });

      rulesSet.rrule(rules);
    });

    return rulesSet;
  }

  public static serializeSchedule(data: OrdinationSchedule, disableEndDate: boolean): string {
    const convertedSchedule = {};

    Object.entries(data).forEach(([key, period]) => {
      const { rows, startDate, endDate, skipWeek, skipMonth, dayMonth } = period;
      const dailyItems = rows[OrdinationScheduleType.Daily];
      const weeklyItems = rows[OrdinationScheduleType.Weekly];
      const monthlyItems = rows[OrdinationScheduleType.Monthly];
      const currentYear = new Date().getFullYear();
      // 5 - limit for items without end date
      const edgeEndDate = new Date().setFullYear(currentYear + 5);
      const roundedEndDate = moment(endDate).startOf('day').toDate() as any;
      const handledAndDate = disableEndDate ? edgeEndDate : roundedEndDate;

      let rruleSet = new RRuleSet();

      rruleSet = UtilsMedicine.getDailyPeriodRules(rruleSet, dailyItems, startDate, handledAndDate);
      rruleSet = UtilsMedicine.getWeeklyPeriodRules(rruleSet, weeklyItems, startDate, handledAndDate, skipWeek);
      rruleSet = UtilsMedicine.getPeriodMonthRules(
        rruleSet,
        monthlyItems,
        startDate,
        handledAndDate,
        skipMonth,
        dayMonth
      );

      const schedule = rruleSet.toString();

      convertedSchedule[key] = {
        startDate,
        endDate,
        skipWeek,
        skipMonth,
        dayMonth,
        schedule,
        originalRows: rows,
      };
    });

    return JSON.stringify(convertedSchedule);
  }

  public static getMedicineItemWidthHandledDose(item: Medicine, currentNumber: number): Medicine {
    const { medicineType } = item;
    const isIncreasing = medicineType.indexOf(MedicineType.Increasing) >= 0;
    const isDecreasing = medicineType.indexOf(MedicineType.Decreasing) >= 0;

    if (isIncreasing) {
      return UtilsMedicine.getItemWithIncreasedDose(item, currentNumber);
    }

    if (isDecreasing) {
      return UtilsMedicine.getItemWithDecreasedDose(item, currentNumber);
    }

    return item;
  }

  public static getItemWithIncreasedDose(medicineItem: Medicine, currentNumber: number): Medicine {
    const updatedItem = { ...medicineItem };
    const { incDecEveryX, incDecStartDose, incDecDose, incDecEndDose } = updatedItem;
    const changeStep = parseInt(incDecEveryX);

    const doseCoefficient = Math.floor(currentNumber / changeStep);
    const increasedDose = parseInt(incDecStartDose) + parseInt(incDecDose) * doseCoefficient;
    const handledMaxDose = increasedDose > parseInt(incDecEndDose) ? incDecEndDose : increasedDose;

    // console.log(doseCoefficient, increasedDose, handledMaxDose);

    updatedItem.dailyDose = handledMaxDose.toString();

    return updatedItem;
  }

  public static getItemWithDecreasedDose(medicineItem: Medicine, currentNumber: number): Medicine {
    const updatedItem = { ...medicineItem };
    const { incDecEveryX, incDecStartDose, incDecDose, incDecEndDose } = updatedItem;
    const changeStep = parseInt(incDecEveryX);

    const doseCoefficient = Math.floor(currentNumber / changeStep);
    const decreasedDose = parseInt(incDecStartDose) - parseInt(incDecDose) * doseCoefficient;
    const handledMaxDose = decreasedDose < parseInt(incDecEndDose) ? incDecEndDose : decreasedDose;

    // console.log(doseCoefficient, decreasedDose, handledMaxDose);

    updatedItem.dailyDose = handledMaxDose.toString();

    return updatedItem;
  }

  public static getMedicineMaxItemsCount(item: Medicine): number {
    const { medicineType, incDecStartDose, incDecEndDose, incDecEveryX, incDecDose } = item;
    const isIncreasing = medicineType.indexOf(MedicineType.Increasing) >= 0;
    const isDecreasing = medicineType.indexOf(MedicineType.Decreasing) >= 0;

    if (!isIncreasing && !isDecreasing) return 0;

    if (isIncreasing) {
      const stepsCountToReachMax = Math.ceil(
        (parseInt(incDecEndDose) - parseInt(incDecStartDose)) / parseInt(incDecDose)
      );

      // console.log(stepsCountToReachMax, 'stepsCountToReachMax');

      return stepsCountToReachMax * parseInt(incDecEveryX) + 1;
    }

    const stepsToReachMin = Math.ceil((parseInt(incDecStartDose) - parseInt(incDecEndDose)) / parseInt(incDecDose));

    // console.log(stepsToReachMin, 'stepsToReachMin');

    return stepsToReachMin * parseInt(incDecEveryX) + 1;
  }

  public static createCalendarItemsFromMedicineItems(items: Medicine[]): CalendarItem[] {
    const calendarItems: CalendarItem[] = [];

    items.forEach(item => {
      const { schedules, product, citizenEnrollmentId } = item;
      const maxItemsCount = UtilsMedicine.getMedicineMaxItemsCount(item);

      if (schedules) {
        const parsedSchedule = JSON.parse(schedules);
        const periodSchedules = Object.values(parsedSchedule);

        periodSchedules.forEach((parsedPeriod: any) => {
          const scheduleJson = parsedPeriod.schedule;

          if (!scheduleJson) return;

          const schedule = rrulestr(scheduleJson);
          const datesArray = schedule.all().map(UtilsDate.setUTCPartsToDate);
          const handledArray = maxItemsCount ? datesArray.slice(0, maxItemsCount) : datesArray;

          handledArray.forEach((date, index) => {
            // Find saved state for the item time
            const medicineState = item.medicineStates.find(
              item => new Date(item.timeStamp).getTime() === date.getTime()
            );
            const medicineData = UtilsMedicine.getMedicineItemWidthHandledDose(item, index);
            const itemId = date.getTime() + medicineData.product.varenummer;
            const calendarItem: MedicineCalendarItem = {
              ...calendarItemDefault(),
              id: itemId,
              calendarItemType: CalendarItemType.Medicine,
              title: product?.navn,
              fromDate: date,
              groupId: item.id,
              toDate: moment(date).add(30, 'minutes').toDate(),
              citizenEnrollmentId,
              medicineData,
              medicineState,
              isEditable: true,
            };

            calendarItems.push(calendarItem);
          });
        });
      }
    });

    return calendarItems;
  }

  public static getNextTakeMedicineDate(schedule: string) {
    if (!schedule) return;

    try {
      const parsedSchedule = JSON.parse(schedule);
      const periodSchedules = Object.values(parsedSchedule);
      let nextTakeDate: string | undefined;

      for (let i = 0; i < periodSchedules.length; i++) {
        const periodData = periodSchedules[i] || ({} as any);

        if (!periodData.schedule) continue;

        const schedule = rrulestr(periodData.schedule);
        const nextDate = schedule.after(new Date());

        if (nextDate) {
          const fixedUtcDate = UtilsDate.setPartsToUTCDate(nextDate);
          nextTakeDate = fixedUtcDate.toISOString();
          break;
        }
      }

      return nextTakeDate;
    } catch (e) {
      return;
    }
  }

  public static deserializeSchedule(schedule: string): OrdinationSchedule {
    if (!schedule) return defaultMedicineSchedule;

    const deserializedSchedule = {};
    const parsedSchedule = JSON.parse(schedule);
    const schedulePeriods = Object.entries(parsedSchedule);

    schedulePeriods.forEach(([id, data]) => {
      const { startDate, endDate, schedule, originalRows, skipWeek, skipMonth, dayMonth } = data as any;

      if (!schedule) return;

      const defaultRows = {
        [OrdinationScheduleType.Daily]: [],
        [OrdinationScheduleType.Weekly]: [],
        [OrdinationScheduleType.Monthly]: [],
      };

      deserializedSchedule[id] = {
        startDate,
        endDate,
        id,
        skipWeek,
        skipMonth,
        dayMonth,
        rows: originalRows || defaultRows,
      };
    });

    return deserializedSchedule;
  }

  public static getDefaultMedicineState(): MedicineState {
    return {
      ...medicineStateDefault(),
      timeStamp: undefined,
    };
  }

  public static createMedicineSelectOptions(items: Medicine[]): SelectOption[] {
    return items.map(item => {
      const { id, product } = item;

      return {
        value: id,
        label: `${product?.navn} ${product?.pakning}`,
      };
    });
  }

  public static getMedicineIncDecPrefix(isInc: boolean, isDec: boolean): string {
    if (isInc) return 'Op';
    if (isDec) return 'Ned';

    return 'Op/Ned';
  }
}
