import { LocalStorage } from '../services/storage';
import { GOAL_FILTER_NAMESPACE, USER_ENROLLMENT_NAMESPACE } from '../constants/namespaces';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { AuthType, Employee } from '../services/prodocApi';
import { useEffect, useMemo } from 'react';
import { getMainMenuItems } from '../data/common';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { ROUTES } from '../constants/routes';
import { UtilsNavigation } from '../utils/UtilsNavigation';
import { CommonState } from '../store/commonStore';
import { getDepartmentNotes, setCalendarDepartmentId } from '../store/calendarStore';
import { UtilsAuth } from '../utils/UtilsAuth';

export function useGoalFilter() {
  const userStore = useSelector<ApplicationState, Employee>(state => state.user);
  const { id } = userStore;

  const getFilterResult = () => {
    const savedResult = LocalStorage.getItem(GOAL_FILTER_NAMESPACE) || '{}';
    const parsedResult = JSON.parse(savedResult);

    return parsedResult[id];
  };

  const setFilterResult = (value: string[]) => {
    const savedResult = LocalStorage.getItem(GOAL_FILTER_NAMESPACE) || '{}';
    const parsedResult = JSON.parse(savedResult);
    const updatedResult = { ...parsedResult, [id]: value };

    LocalStorage.setItem(GOAL_FILTER_NAMESPACE, JSON.stringify(updatedResult));
  };

  return { getFilterResult, setFilterResult };
}

export function useUserMenuList() {
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const { authType } = userState;

  const menuList = useMemo(() => {
    if (authType) {
      return getMainMenuItems().filter(item => {
        return !item.notAllowedFor.includes(authType);
      });
    }

    return [];
  }, [authType]);

  return menuList;
}

export function useUserRole() {
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const { authType } = userState;
  const isEmployee = authType === AuthType.Employee;

  return { isEmployee };
}

export function useUserRoleRedirect() {
  const { push } = useHistory();
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const { authType, id } = userState;
  const isCitizenCreatePage = useRouteMatch(ROUTES.CITIZEN_ADD);
  const isCitizenEnrollPage = useRouteMatch(ROUTES.CITIZEN_ENROLL);
  const isCitizenDischargePage = useRouteMatch(ROUTES.CITIZEN_DISCHARGE);
  const isEmployeeCreatePage = useRouteMatch(ROUTES.EMPLOYEE_ADD);
  const isEmployeeEnrollPage = useRouteMatch(ROUTES.EMPLOYEE_ADD);
  const isEmployeeDischargePage = useRouteMatch(ROUTES.EMPLOYEE_DISCHARGE);
  const isCenterCreatePage = useRouteMatch(ROUTES.CENTER_ADD);
  const isCenterEditPage = useRouteMatch(ROUTES.CENTER_DETAILS);
  const isDepartmentCreatePage = useRouteMatch(ROUTES.DEPARTMENT_ADD);
  const isDepartmentEditPage = useRouteMatch(ROUTES.DEPARTMENT_ADD);

  useEffect(() => {
    if (!id) return;

    if (
      authType === AuthType.Employee &&
      (isCitizenCreatePage ||
        isEmployeeCreatePage ||
        isCenterCreatePage ||
        isCenterEditPage ||
        isDepartmentCreatePage ||
        isDepartmentEditPage ||
        isCitizenEnrollPage ||
        isCitizenDischargePage ||
        isEmployeeEnrollPage ||
        isEmployeeDischargePage)
    ) {
      push(UtilsNavigation.getHomeUrl());
    }
  }, [authType, id]);
}

export function useActiveDepartmentSet() {
  const dispatch = useDispatch();
  const commonStore = useSelector<ApplicationState, CommonState>(state => state.common);
  const { userEnrollments } = commonStore;

  const initDefaultDepartment = () => {
    const selectedEnrollmentId = localStorage.getItem(USER_ENROLLMENT_NAMESPACE);

    const activeEnrollment = selectedEnrollmentId
      ? userEnrollments.find(item => item.id === selectedEnrollmentId)
      : userEnrollments[0];

    dispatch(setCalendarDepartmentId(activeEnrollment?.departmentId || ''));
    dispatch(getDepartmentNotes(activeEnrollment?.departmentId || ''));
    localStorage.setItem(USER_ENROLLMENT_NAMESPACE, activeEnrollment?.id || '');
  };

  useEffect(() => {
    if (userEnrollments.length) initDefaultDepartment();
  }, [userEnrollments.length]);
}
