import React from 'react';
import { Form } from '../../../_shared/Form/Form';
import { MedicinePassout, MedicineState } from '../../../../services/prodocApi';
import SelectMenu from '../../../_shared/_controls/SelectMenu/SelectMenu';
import { useEnumOptionsTranslation } from '../../../../hooks/common-hooks';
import TextEditor from '../../../_shared/_controls/TextEditor/TextEditor';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';

interface Props {
  data: MedicineState;
  showDose: boolean;
  onChange(data: MedicineState);
}

export const CalendarMedicineWindowForm: React.FC<Props> = (props) => {
  const { data, showDose, onChange } = props;
  const { translateEnumOptions } = useEnumOptionsTranslation();
  const { medicinePassout, passoutFailedExplanation, doze } = data;

  const handleFieldChange = (value: any, name: string) => {
    onChange({ ...data, [name]: value });
  };

  return (
    <Form>
      <SelectMenu
        title="Udlevering"
        name="medicinePassout"
        options={translateEnumOptions(MedicinePassout)}
        value={medicinePassout}
        valueKey="value"
        labelKey="label"
        onChange={handleFieldChange}
      />
      {showDose && (
        <TextInput
          title="Udleveret dosis"
          name="doze"
          value={doze}
          onChange={handleFieldChange}
        />
      )}
      <TextEditor
        title="Årsag til fejlet udlevering"
        name="passoutFailedExplanation"
        value={passoutFailedExplanation}
        onChange={handleFieldChange}
      />
    </Form>
  );
};
