import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import MeasurementsActions from '../../../components/measurement/MeasurementsActions/MeasurementsActions';
import { ApplicationState } from '../../../store';
import {
  deleteMeasurement,
  loadMeasurements,
  MeasurementState,
  setCitizenMeasurementsAction,
  setMeasurementWindowStateAction,
} from '../../../store/measurementStore';
import MeasurementsTable from '../../../components/measurement/MeasurementsTable/MeasurementsTable';
import { usePageTitle } from '../../../hooks/common-hooks';
import { MeasurementsFilter } from '../../../components/measurement/MeasurementsFilter/MeasurementsFilter';
import { DatesFilterEntity } from '../../../types/common';
import MeasurementWindow from '../../../components/measurement/MeasurementWindow/MeasurementWindow';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import { useCitizenNavigationItems } from '../../../hooks/navigation';
import { NavigationMenu } from '../../../components/_shared/NavigationMenu/NavigationMenu';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';

import './MeasurementsPage.scss';

export default function MeasurementsPage() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const measurementState = useSelector<ApplicationState, MeasurementState>(state => state.measurement);
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { id: citizenId = '' } = params || {};
  const [selectedCitizen, setSelectedCitizen] = useState(citizenId);
  const { measurementsItems } = useCitizenNavigationItems({ measurementCitizenId: selectedCitizen });
  const [showTable, setShowTable] = useState(false);
  const { citizenItems, isWindowOpen } = measurementState;
  const [dates, setDates] = useState<DatesFilterEntity>({ from: null, to: null });
  const [activeMeasurement, setActiveMeasurement] = useState(null);
  const isListLoading = pagesState[PAGES_ITEMS.MEASUREMENT_LIST].loading;
  // const filteredItemsByDates = useMemo(() => {
  //   const { from, to } = dates;

  //   if (!from && !to) return citizenItems;

  //   const filteredItems = citizenItems.filter((item: any) => {
  //     const itemDate = item.date;

  //     if (from && moment(itemDate).isSameOrAfter(moment(from), 'day')) return true;

  //     if (to && moment(itemDate).isSameOrBefore(moment(to), 'day')) return true;

  //     return false;
  //   });

  //   return { ...citizenItems, items: filteredItems };
  // }, [dates, citizenItems]);

  usePageTitle('Målinger');

  useEffect(() => {
    dispatch(loadMeasurements());
    return () => {
      dispatch(setCitizenMeasurementsAction([]));
    };
  }, []);

  useEffect(() => {
    if (!isWindowOpen) {
      setActiveMeasurement(null);
    }
  }, [isWindowOpen]);

  const handleResultsShow = (showResults: boolean) => {
    setShowTable(showResults);
  };

  const handleCitizenChange = (citizenId: string) => {
    setSelectedCitizen(citizenId);
  };

  const handleDatesChange = (data: DatesFilterEntity) => {
    setDates(data);
  };

  const handleItemEdit = (data: any) => {
    dispatch(setMeasurementWindowStateAction(true));
    setActiveMeasurement(data);
  };

  const handleItemDelete = (id: string) => {
    dispatch(deleteMeasurement(id));
  };

  return (
    <>
      {/* <Sidebar>
        <NavigationMenu items={measurementsItems} />
      </Sidebar> */}
      <div className="page-full">
        <div className="measurements__content">
          <MeasurementsTable
            measurements={citizenItems}
            isLoading={isListLoading}
            onEdit={handleItemEdit}
            onDelete={handleItemDelete}
          />
        </div>
      </div>
      <MeasurementWindow editData={activeMeasurement} />
    </>
  );
}
