import * as React from 'react';
import { CPR_SYMBOLS_REGEX } from '../constants/regex';
import { useState } from 'react';
import { error } from 'console';

export function useRegisteredFields(schema: any, register: Function, unregister: Function) {
  React.useEffect(() => {
    const fieldsNames = Object.keys(schema.fields);

    fieldsNames.forEach(name => {
      unregister(name);
    });

    fieldsNames.forEach(name => {
      register({ name: name });
    });

    return () => {
      fieldsNames.forEach(name => {
        unregister(name);
      });
    };
  }, [schema]);
}
export function useValidation(yupValidationSchema: any) {
  const [state, setState] = useState(yupValidationSchema);
  const [errors, setErrors] = useState(yupValidationSchema);
  React.useEffect(() => {
    setState(yupValidationSchema);
  }, [yupValidationSchema]);

  function validate<T>(validationObject: T) {
    try {
      state.validateSync(validationObject, { abortEarly: false });
      setErrors({});
      return true;
    } catch (errors) {
      let err = {} as T;
      if (errors.inner.length > 0) {
        errors.inner.forEach(x => {
          err[x.path] = x.errors[0];
        });
      } else {
        err[errors.path] = errors.errors[0];
      }

      setErrors(err);
      return false;
    }
  }
  return { validate, errors };
}
export function useCprValidation() {
  const isCprValid = (value: string): boolean => {
    // Cpr field accepts only numbers
    if (!CPR_SYMBOLS_REGEX.test(value)) return false;

    if (value.length > 11) return false;

    return true;
  };

  const isCprFilled = (value: string): boolean => {
    return value.length === 10 || value.length === 11;
  };

  return { isCprValid, isCprFilled };
}
