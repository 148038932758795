import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import TextInput from '../../../_shared/_controls/TextInput/TextInput';

import './MedicineCitizenField.scss';

interface Props {
  title: string;
  value: string;
  name: string;
  disabled: boolean;
  onSave(value: string, name: string): void;
}

export const MedicineCitizenField: React.FC<Props> = props => {
  const { value, title, name, onSave, disabled } = props;
  const [localValue, setLocalValue] = useState('');
  const isCaveField = name === 'cave';
  const valueClass = classNames('hc-field__value', {
    'hc-field__value--empty': !value,
    'hc-field__value--cave': isCaveField,
  });

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleSave = () => {
    onSave(localValue, name);
  };

  return (
    <div className="hc-field">
      <div className="hc-field__title">{title}</div>
      <TextInput
        disabled={disabled}
        className={valueClass}
        value={localValue}
        onChange={setLocalValue}
        onBlur={handleSave}
      />
    </div>
  );
};
