import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, Typography } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { AuthType, Employee, HealthCareType, NoteDto } from '../../../services/prodocApi';
import { DATE_TIME_FORMAT } from '../../../constants/common';
import Button from '../../_shared/_controls/Button/Button';
import { ICONS } from '../../_shared/Icon/Icon';
import {
  getColumnDateSearchProps,
  getColumnEnumSearchProps,
  getColumnTextSearchProps,
} from '../../../utils/UtilsTable';
import { UtilsAuth } from '../../../utils/UtilsAuth';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import { Tooltip } from '../../_shared/Tooltip/Tooltip';

import './NotesList.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Dayjs } from 'dayjs';

interface Props {
  data: NoteDto[];
  isLoading: boolean;
  onEdit(data: NoteDto): void;
  onNoteDelete(data: NoteDto): void;
}

interface NoteFilterData {
  goal?: string;
  health?: HealthCareType;
  createdByName?: string;
  modifiedByName?: string;
  citizenName?: string;
  department?: string;
  createdFromDate?: Date;
  createdToDate?: Date;
  createdFromCreated?: Date;
  createdToCreated?: Date;
}

export const NotesList: React.FC<Props> = props => {
  const { data, isLoading, onNoteDelete, onEdit } = props;
  const { t } = useTranslation();
  const userState = useSelector<ApplicationState, Employee>(state => state.user);
  const [filterData, setFilterData] = useState<NoteFilterData>({
    goal: '',
    createdByName: '',
    modifiedByName: '',
  });
  const [rows, setRows] = useState([]);
  const isAdmin = userState.authType === AuthType.Admin;

  function handleDateChange(date: Dayjs[]) {
    search(date[0], 'createdFromDate');
    search(date[1], 'createdToDate');
  }

  function handleDateChangeCreated(date: Dayjs[]) {
    search(date[0], 'createdFromCreated');
    search(date[1], 'createdToCreated');
  }

  const search = (v, prop: string) => {
    const filter = { ...filterData, [prop]: v };
    setFilterData(filter);
    let f = [...data];
    if (filter.goal) {
      f = f.filter(x => x.goal?.title.toLocaleLowerCase().includes(filter.goal.toLocaleLowerCase()));
    }
    if (filter.createdByName) {
      f = f.filter(x => x.createdByName.toLocaleLowerCase().includes(filter.createdByName.toLocaleLowerCase()));
    }
    if (filter.modifiedByName) {
      f = f.filter(x => x.modifiedByName.toLocaleLowerCase().includes(filter.modifiedByName.toLocaleLowerCase()));
    }
    if (filter.citizenName) {
      f = f.filter(x => x.citizenName?.toLocaleLowerCase().includes(filter.citizenName.toLocaleLowerCase()));
    }
    if (filter.department) {
      f = f.filter(x => x.departmentName?.toLocaleLowerCase().includes(filter.department.toLocaleLowerCase()));
    }
    if (filter.health) {
      f = f.filter(x => x.health.includes(filter.health));
    }

    if (filter.createdFromDate) {
      f = f.filter(x => new Date(x.fromDate) >= filter.createdFromDate);
    }
    if (filter.createdToDate) {
      f = f.filter(x => new Date(x.fromDate) <= filter.createdToDate);
    }
    if (filter.createdFromCreated) {
      f = f.filter(x => new Date(x.createdOn) >= filter.createdFromCreated);
    }
    if (filter.createdToCreated) {
      f = f.filter(x => new Date(x.createdOn) <= filter.createdToCreated);
    }
    setRows(f);
  };

  useEffect(() => {
    setRows(data);
  }, [data]);
  const { translateEnumOptions } = useEnumOptionsTranslation();

  const columns: ColumnsType<NoteDto> = [
    {
      title: 'Notat',
      dataIndex: 'text',
      key: 'text',
      render: (note, row: NoteDto) => {
        return (
          <Tooltip text={<span dangerouslySetInnerHTML={{ __html: note }} />}>
            <Typography.Text style={{ maxWidth: '150px', maxHeight: '20px' }} ellipsis={true}>
              <span dangerouslySetInnerHTML={{ __html: note }} />
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: 'Delmål',
      dataIndex: 'goal',
      key: 'goal',
      render: (_field, row: NoteDto) => {
        const { goal } = row;

        return (
          <Tooltip text={goal?.title}>
            <Typography.Text style={{ maxWidth: '150px' }} ellipsis={true}>
              {goal?.title}
            </Typography.Text>
          </Tooltip>
        );
      },
      ...getColumnTextSearchProps(v => search(v, 'goal')),
    },
    {
      title: 'Helbredsområde',
      dataIndex: 'healthCare',
      key: 'healthCare',
      render: (_field, row: NoteDto) => {
        const { health } = row;
        const title = health?.map(x => t(x)).join(',');

        return (
          <Tooltip text={title}>
            <Typography.Text style={{ maxWidth: '150px' }} ellipsis={true}>
              {health?.map(x => t(x)).join(',')}
            </Typography.Text>
          </Tooltip>
        );
      },
      ...getColumnEnumSearchProps(translateEnumOptions(HealthCareType), v => search(v, 'health')),
    },
    {
      title: 'Borger',
      dataIndex: 'citizen',
      key: 'citizen',
      render: (_field, row: NoteDto) => {
        const { citizenName } = row;

        return (
          <Tooltip text={citizenName}>
            <Typography.Text ellipsis={true} style={{ whiteSpace: 'nowrap' }}>
              {citizenName}
            </Typography.Text>
          </Tooltip>
        );
      },
      ...getColumnTextSearchProps(v => search(v, 'citizenName')),
    },
    {
      title: 'Afdeling',
      dataIndex: 'departmentName',
      key: 'department',
      render: (_field, row: NoteDto) => {
        const { departmentName } = row;

        return (
          <Tooltip text={departmentName}>
            <Typography.Text ellipsis={true} style={{ whiteSpace: 'nowrap' }}>
              {departmentName}
            </Typography.Text>
          </Tooltip>
        );
      },
      ...getColumnTextSearchProps(v => search(v, 'department')),
    },
    {
      title: 'Dato',
      dataIndex: 'fromDate',
      key: 'fromDate',
      render: (_field, row: NoteDto) => {
        const { fromDate } = row;
        if (fromDate) {
          return (
            <Typography style={{ whiteSpace: 'nowrap' }}>{`${moment(fromDate).format(DATE_TIME_FORMAT)}`}</Typography>
          );
        }
        return '';
      },
      ...getColumnDateSearchProps(handleDateChange),
    },
    {
      title: 'Oprettet af',
      dataIndex: 'createdByName',
      key: 'createdByName',
      render: (_field, row: NoteDto) => {
        const { createdByName } = row;

        return (
          <Tooltip text={createdByName}>
            <Typography.Text ellipsis={true} style={{ whiteSpace: 'nowrap' }}>
              {createdByName}
            </Typography.Text>
          </Tooltip>
        );
      },
      ...getColumnTextSearchProps(v => search(v, 'createdByName')),
    },
    {
      title: 'Oprettet dato',
      dataIndex: 'created',
      key: 'created',
      render: (_field, row: NoteDto) => {
        const { createdOn } = row;
        if (createdOn) {
          return (
            <Typography style={{ whiteSpace: 'nowrap' }}>{`${moment(createdOn).format(DATE_TIME_FORMAT)}`}</Typography>
          );
        }
        return '';
      },
      ...getColumnDateSearchProps(handleDateChangeCreated),
    },
    {
      title: 'Sidst ændret af',
      dataIndex: 'modifiedByName',
      key: 'modifiedByName',
      render: (_field, row: NoteDto) => {
        const { modifiedByName } = row;

        return (
          <Tooltip text={modifiedByName}>
            <Typography.Text ellipsis={true} style={{ whiteSpace: 'nowrap' }}>
              {modifiedByName}
            </Typography.Text>
          </Tooltip>
        );
      },
      ...getColumnTextSearchProps(v => search(v, 'modifiedByName')),
    },
    {
      title: 'Sidst ændret dato',
      dataIndex: 'modified',
      key: 'modified',
      render: (_field, row: NoteDto) => {
        const { modifiedOn } = row;
        if (modifiedOn) {
          return (
            <Typography style={{ whiteSpace: 'nowrap' }}>{`${moment(modifiedOn).format(DATE_TIME_FORMAT)}`}</Typography>
          );
        }
        return '';
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (field, row: NoteDto) => {
        const isNoteOwner = row.createdBy === UtilsAuth.getEmployeeId();

        return (
          <div className="cn-list__actions">
            {isAdmin && <Button icon={ICONS.delete} onClick={() => onNoteDelete(row)} />}
          </div>
        );
      },
    },
  ];
  function handleRowClicked(row: NoteDto) {
    const isNoteOwner = row.createdBy === UtilsAuth.getEmployeeId();
    if (isAdmin || isNoteOwner) {
      onEdit(row);
    }
  }
  return (
    <div className="cn-list">
      <Table
        style={{ cursor: 'pointer' }}
        pagination={{ defaultPageSize: 15, style: { marginRight: '15px' }, showSizeChanger: false }}
        rowKey="id"
        loading={isLoading}
        dataSource={rows}
        columns={columns}
        expandIcon={props => <DownOutlined {...props} translate />}
        expandable={{
          expandedRowRender: n => <p dangerouslySetInnerHTML={{ __html: n.text }} />,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <RightOutlined translate onClick={e => onExpand(record, e)} />
            ) : (
              <DownOutlined translate onClick={e => onExpand(record, e)} />
            ),
        }}
        onRow={(row, rowIndex) => {
          return {
            onClick: () => handleRowClicked(row),
          };
        }}
      />
    </div>
  );
};
