import React, { useEffect, useState } from 'react';
import Button from '../../_shared/_controls/Button/Button';
import { Card } from '../../_shared/Card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ApplicationState } from '../../../store';
import { DepartmentState } from '../../../store/departmentStore';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { CitizenDischarge } from '../../../types/citizen';
import { UtilsData } from '../../../utils/UtilsData';
import { citizenDischargeSchema } from '../../../utils/UtilsValidation';
import { useRegisteredFields } from '../../../hooks/validation-hooks';
import DatePicker from '../../_shared/_controls/DatePicker/DatePicker';
import { CitizensState, getEnrolledCitizensByDepartment } from '../../../store/citizenStore';
import { useRouterParams } from '../../../hooks/navigation';
import { CardActions } from '../../_shared/CardActions/CardActions';
import { useEnumOptionsTranslation } from '../../../hooks/common-hooks';
import prodocApi, { Citizen, CitizenEnrollmentResult, DischargeCause } from '../../../services/prodocApi';
import { Col, Modal, Row } from 'antd';
import TextInput from '../../_shared/_controls/TextInput/TextInput';
import { useSSR } from 'react-i18next';

interface Props {
  citizenEnrollment: CitizenEnrollmentResult;
  isDischargeOpen: boolean;
  setIsDischargeOpen: (isDischargeOpen: boolean) => void;
  onDischarge: (date: Date, cause: string, citizenEnrollment: string) => void;
}

export default function CitizenDischargeForm(props: Props) {
  const { citizenEnrollment, isDischargeOpen, setIsDischargeOpen, onDischarge } = props;

  const { translateEnumOptions } = useEnumOptionsTranslation();
  const defaultData = UtilsData.getCitizenDischargeDefaults();
  const [date, setDate] = useState<Date>(new Date());
  const [cause, setCause] = useState<DischargeCause>();

  const { register, unregister, errors, clearError, handleSubmit, setValue } = useForm({
    validationSchema: citizenDischargeSchema,
    defaultValues: defaultData as any,
  });
  useRegisteredFields(citizenDischargeSchema, register, unregister);

  function handleCauseFieldChange(value: DischargeCause) {
    setCause(value);
  }
  const handleDateChange = (value: Date) => {
    setDate(value);
  };
  async function handleDischarge() {
    if (date && cause) {
      // await prodocApi.citizenEnrollment.discharge(citizenEnrollment.id, date, cause);
      setIsDischargeOpen(false);
      onDischarge(date, cause, citizenEnrollment.id);
    }
  }
  return (
    <Modal
      width={800}
      title="Udskriv borger"
      okText="Udskriv"
      cancelText="Annuller"
      open={isDischargeOpen}
      onOk={handleDischarge}
      onCancel={() => setIsDischargeOpen(false)}>
      <Row gutter={8}>
        <Col span={12}>
          <TextInput title="Afdeling" disabled name="department" value={citizenEnrollment?.departmentName} />
        </Col>
        <Col span={12}>
          <TextInput
            title="Borger"
            disabled
            name="citizen"
            value={citizenEnrollment?.firstname + ' ' + citizenEnrollment?.lastname}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <DatePicker
            value={date}
            title="Udskrivelses dato"
            name="date"
            error={errors.date && errors.date.message}
            onChange={handleDateChange}
          />
        </Col>
        <Col span={12}>
          <SelectMenu
            title="Udskrivelses årsag"
            name="cause"
            valueKey="value"
            labelKey="label"
            value={cause}
            options={translateEnumOptions(DischargeCause)}
            error={errors.cause && errors.cause.message}
            onChange={handleCauseFieldChange}
          />
        </Col>
      </Row>
    </Modal>
  );
}
