import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addCenter } from '../../../store/centerStore';
import { centerDefault } from '../../../services/defaults';
import prodocApi, { Center } from '../../../services/prodocApi';
import { navigateToHome } from '../../../store/navigationStore';
import CenterForm from '../../../components/centerPage/CenterForm/CenterForm';
import { usePageTitle, useZipCode } from '../../../hooks/common-hooks';
import { Sidebar } from '../../../components/layout/Sidebar/Sidebar';
import Button from '../../../components/_shared/_controls/Button/Button';
import { useValidation } from '../../../hooks/validation-hooks';
import { centerSchema } from '../../../utils/UtilsValidation';
import { Card, Row, Col } from 'antd';
import { CardActions } from '../../../components/_shared/CardActions/CardActions';
import TextInput from '../../../components/_shared/_controls/TextInput/TextInput';
import { ROUTES } from '../../../constants/routes';
import { useHistory } from 'react-router';

export default function CenterCreatePage() {
  const dispatch = useDispatch();
  const [center, setCenter] = useState(centerDefault);
  const { name, address, zipCode, city, phoneNumber, email } = center;
  const { validate, errors } = useValidation(centerSchema);
  const history = useHistory();
  const { getCityName } = useZipCode();
  async function handleFormSubmit() {
    if (validate(center)) {
      await prodocApi.center.add(center);
      history.push(ROUTES.CENTER_OVERVIEW);
    }
  }
  usePageTitle('Opret center');

  function handleCancel() {
    history.push(ROUTES.CENTER_OVERVIEW);
  }
  function handleFieldsChange(value: any, name: string) {
    if (name === 'zipCode') {
      setCenter({ ...center, [name]: value, city: getCityName(value) });
    } else {
      setCenter({ ...center, [name]: value });
    }
  }

  return (
    <>
      {/* <Sidebar>
        <Button title="Opret center" className="fullwidth" />
      </Sidebar> */}
      {/* <CenterForm data={centerData} editMode={false} onSubmit={handleFormSubmit} onCancel={handleCancel} /> */}

      <div className="center-page page">
        <form className="center-form item-form">
          <Card>
            <Row gutter={8}>
              <Col span={12}>
                <TextInput
                  title="Center navn*"
                  name="name"
                  value={name}
                  error={errors.name}
                  onChange={handleFieldsChange}
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <TextInput title="Adresse" name="address" value={address} onChange={handleFieldsChange} />
              </Col>
              <Col span={4}>
                <TextInput title="Postnummer" name="zipCode" value={zipCode} onChange={handleFieldsChange} />
              </Col>
              <Col span={8}>
                <TextInput title="By" readonly value={city} onChange={handleFieldsChange} />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <TextInput title="Telefonnummer" name="phoneNumber" value={phoneNumber} onChange={handleFieldsChange} />
              </Col>
              <Col span={12}>
                <TextInput
                  title="Email"
                  name="email"
                  value={email}
                  error={errors.email}
                  onChange={handleFieldsChange}
                />
              </Col>
            </Row>
            <CardActions
              rightActions={
                <>
                  <Button title={'Opret'} type="primary" onClick={handleFormSubmit} />
                  <Button title="Annuller" onClick={handleCancel} />
                </>
              }
            />
          </Card>
        </form>
      </div>
    </>
  );
}
