import React, { useEffect, useMemo, useState } from 'react';
import { Drawer } from '../../_shared/Drawer/Drawer';
import TextEditor from '../../_shared/_controls/TextEditor/TextEditor';
import { GoalResult, GoalScorer } from '../../../services/prodocApi';
import SelectMenu from '../../_shared/_controls/SelectMenu/SelectMenu';
import { goalScorerDefault } from '../../../services/defaults';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { PAGES_ITEMS, PagesState } from '../../../store/pagesStore';
import { DrawerActions } from '../../_shared/Drawer/DrawerActions/DrawerActions';

interface Props {
  isOpened: boolean;
  defaultGoal?: string;
  goals: GoalResult[];
  onSave(data: GoalScorer[]): void;
  onClose(): void;
}

export const GoalStatusWindow: React.FC<Props> = props => {
  const { goals, defaultGoal, isOpened, onSave, onClose } = props;
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  const [formData, setFormData] = useState<GoalScorer>(goalScorerDefault());
  const { score, description } = formData;
  const isLoading = pagesState[PAGES_ITEMS.GOAL_STATUS].loading;
  const isSaved = pagesState[PAGES_ITEMS.GOAL_STATUS].saved;
  const disableSaveButton = isLoading || !selectedGoals.length;
  const scoreOptions = useMemo(
    () =>
      [0, 1, 2, 3, 4].map(item => ({
        value: item,
        label: item === 0 ? 'None' : item,
      })),
    []
  );

  const closeWindow = () => {
    onClose();
    setFormData(goalScorerDefault());
  };

  useEffect(() => {
    if (isSaved) closeWindow();
  }, [isSaved]);

  useEffect(() => {
    setSelectedGoals([defaultGoal]);
  }, [defaultGoal]);

  const handleFieldChange = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const itemsForSave: GoalScorer[] = selectedGoals.map(goalId => ({ ...formData, goalId }));

    onSave(itemsForSave);
  };

  return (
    <Drawer
      title="Målstatus"
      buttons={<DrawerActions okDisabled={disableSaveButton} onOk={handleSubmit} onCancel={closeWindow} />}
      onClose={closeWindow}
      visible={isOpened}>
      <form className="common-form">
        <SelectMenu
          title="Vælg mål"
          value={selectedGoals}
          options={goals}
          multiple
          valueKey="id"
          labelKey="title"
          onChange={setSelectedGoals}
        />
        <SelectMenu
          title="Score"
          value={score}
          name="score"
          options={scoreOptions}
          valueKey="value"
          labelKey="label"
          onChange={handleFieldChange}
        />
        <TextEditor title="Beskrivelse" value={description} name="description" onChange={handleFieldChange} />
      </form>
    </Drawer>
  );
};
