import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotesFilter from '../../components/notes/NotesFilter/NotesFilter';
import { Note } from '../../services/prodocApi';
import { ApplicationState } from '../../store';
import { usePageTitle } from '../../hooks/common-hooks';
import { NotesList } from '../../components/notes/NotesList/NotesList';
import { NotesFilterData } from '../../types/notes';
import { loadNotes, NotesState } from '../../store/notesStore';
import { showConfirmModal } from '../../components/_shared/Modal/ModalFunctions';
import { useNoteDelete, useNoteUpdate } from '../../hooks/notes-hooks';
import { Sidebar } from '../../components/layout/Sidebar/Sidebar';
import Button from '../../components/_shared/_controls/Button/Button';

import './NotesPage.scss';
import { PAGES_ITEMS, PagesState } from '../../store/pagesStore';
import CalendarNoteWindow from '../../components/Calendar/CalendarNoteWindow/CalendarNoteWindow';

export default function NotesPage() {
  const dispatch = useDispatch();
  const { updateNote, syncUpdatedNoteWithDataSource, isLoading: isNoteSaving } = useNoteUpdate();
  const { deleteNote, syncDeletedNoteWithDataSource } = useNoteDelete();
  const notesState = useSelector<ApplicationState, NotesState>(state => state.notes);
  const pagesState = useSelector<ApplicationState, PagesState>(state => state.pages);
  const { notes } = notesState;
  const [activeNote, setActiveNote] = useState<Note | undefined>();
  const [filterData, setFilterData] = useState<NotesFilterData>({
    citizenId: '',
    departmentId: '',
    goalId: '',
    health: undefined,
  });
  const showCitizensNotes = !!filterData.citizenId;
  const data = notes;
  const isListLoading = pagesState[PAGES_ITEMS.NOTES_LIST].loading;

  usePageTitle('Notater');

  useEffect(() => {
    dispatch(loadNotes());
  }, []);

  const handleNoteUpdate = async (data: Note) => {
    const isUpdated = await updateNote(data);

    if (isUpdated) {
      syncUpdatedNoteWithDataSource(data);
      setActiveNote(undefined);
    }
  };

  const handleNoteDelete = (data: Note) => {
    dispatch(
      showConfirmModal({
        title: 'Ønsker du at slette dette notat?',
        okText: 'Ja',
        cancelText: 'Nej',
        onOk: async () => {
          const isDeleted = await deleteNote(data.id);

          if (isDeleted) syncDeletedNoteWithDataSource(data);
        },
      })
    );
  };

  return (
    <>
      {/* <Sidebar>
        <Button title="Notater" className="fullwidth" />
      </Sidebar> */}
      <div className="page-full notes-content">
        <NotesList
          data={data}
          isLoading={isListLoading}
          onEdit={data => setActiveNote(data)}
          onNoteDelete={handleNoteDelete}
        />
      </div>
      <CalendarNoteWindow
        isOpened={!!activeNote}
        isLoading={isNoteSaving}
        noteData={activeNote}
        onClose={() => setActiveNote(undefined)}
        onUpdateCalendarItem={handleNoteUpdate}
      />
    </>
  );
}
