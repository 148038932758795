import { DocumentType, UploadType, UseOfForceStatus } from '../services/prodocApi';

export interface UseOfForceFilterData {
  citizenId: string;
  citizenName: string;
  status: UseOfForceStatus;
  fromDate?: Date;
  toDate?: Date;
}

export interface DocumentsFilerData {
  citizenEnrollmentId: string;
  departmentId: string;
  tags: string[];
}

export interface DocumentFile {
  data: File;
  tagsIds: string[];
  types: DocumentType[];
}

export class DocumentsUploadData {
  files: DocumentFile[] = [];
  type: UploadType = UploadType.Citizen;
  citizenId: string = '';
  departmentId: string = '';
}
