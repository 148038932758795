import * as React from 'react';
import { Card as AntCard } from 'antd';
import { CardProps } from 'antd/lib/card';

import './Card.scss';

export const Card = (props: CardProps) => {
  const cardProps = {
    ...props,
    className: props.className ? props.className + ' common-card' : 'common-card',
  };

  return (
    <AntCard {...cardProps}>
      {props.children}
    </AntCard>
  );
};
