import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import moment from 'moment';
import 'moment/locale/da';
// import daDK from 'antd/es/locale/da_DK';
import { ConfigProvider } from 'antd';
import { store } from './store';
import Router from './Router';
import './i18n';

import 'antd/dist/reset.css';
import './index.css';
import './assets/styles/main.scss';
import prodocApi from './services/prodocApi';
import 'dayjs/locale/da';
import daDK from 'antd/locale/da_DK';
moment.locale('da');

window.addEventListener('error', event => {
  prodocApi.frontEndErrorLogger.log(event.message);
});

window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
  prodocApi.frontEndErrorLogger.log(event.reason.message);
});

export const App = () => {
  return (
    <Suspense fallback={null}>
      <ConfigProvider
        locale={daDK}
        theme={{
          token: {
            fontFamily: 'Montserrat,sans-serif',
            fontSize: 14,
            // colorFillContent: 'transparent',
          },
        }}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ConfigProvider>
    </Suspense>
  );
};
export default App;
